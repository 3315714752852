import * as Yup from 'yup';

export const siteSchema = Yup.object().shape({
  name: Yup.string()
    .required('Site Name is a required field')
    .typeError('Site Name is a required field')
    .label('Site Name'),
  description: Yup.string()
    .nullable()
    .notRequired()
    .label('Description'),
  customerName: Yup.string()
    .nullable()
    .notRequired()
    .label('Customer Name'),
  primaryContactName: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Name'),
  primaryContactNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  primaryContactNumberCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  primaryContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),
  primaryImage: Yup.string()
    .nullable()
    .notRequired()
    .label('Primary Image'),
  tags: Yup.array()
    .nullable()
    .notRequired()
    .label('Tags'),
  shortid: Yup.string()
    .nullable()
    .notRequired()
    .label('Shortid'),
  assignedAssetIds: Yup.array().label('Assigned Asset Shortids'),
  assignedStaffIds: Yup.array().label('Assigned Staff Shortids'),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .notRequired()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .notRequired()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .notRequired()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .notRequired()
      .label('Postal Code'),
  }),
});

export const confirmSiteSchema = Yup.object().shape({
  organisationId: Yup.string()
    .required('Please select a organisation')
    .typeError('Please select a organisation')
    .label('Organisation'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});

export type Site = Yup.InferType<typeof siteSchema>;

export default siteSchema;
