export const Actions = {
  setLinkerState: (state: string) => {
    return { type: 'SET_LINKER_STATE', state };
  },
  newLinkerData: (data: LinkerData) => {
    return { type: 'NEW_LINKER_DATA', data };
  },
  setLinkerData: (data: LinkerData) => {
    return { type: 'SET_LINKER_DATA', data };
  },
  setLinkerCheck: (linkCheck?: LinkCheck[]) => {
    return { type: 'SET_LINKER_CHECK', linkCheck };
  },
  clearLinkerState: () => {
    return { type: 'CLEAR_LINKER_STATE' };
  },
};

export interface LinkerData extends Link {
  linkDescription?: string;
  linkType?: string;
}

export interface Link {
  item: any;
  itemType: string;
  linkedItem: any;
  linkedItemType: string;
}

export interface LinkerState {
  state: '' | 'item' | 'linkedItem' | 'finalize';
  data: LinkerData;
  linkCheck?: LinkCheck[];
}

export interface LinkCheck {
  error: string;
  action: string;
  link: any;
}

const defaultState: LinkerState = {
  state: '',
  data: {
    item: null,
    itemType: '',
    linkedItem: null,
    linkedItemType: '',
  },
};

export const linker = (linkerState: LinkerState = defaultState, action: any) => {
  switch (action.type) {
    case 'NEW_LINKER_DATA':
      return { ...defaultState, data: action.data };
    case 'SET_LINKER_STATE':
      return { ...linkerState, state: action.state };
    case 'SET_LINKER_DATA':
      return { ...linkerState, data: { ...linkerState.data, ...action.data } };
    case 'SET_LINKER_CHECK':
      return { ...linkerState, linkCheck: action.linkCheck };
    case 'CLEAR_LINKER_STATE':
      return { ...defaultState };
  }
  return linkerState;
};
