import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd';
import { Button } from 'antd'
import { useWindowWidth } from '@bit/necta.hooks.use-window-width'

export const RefreshButton = styled(Button)`
  margin: 0;
  margin-right: 20px;
`

export interface ActionProps {
    onClick?: (e: any) => void,
    hidden?: boolean,
    Icon?: React.ReactNode,
    disabled?: boolean,
    tooltip?: string,
    size?: 'large' | 'middle' | 'small'
  }

export const PageAction: React.FC<ActionProps> = ({ size, disabled = false, onClick, hidden = false, Icon, tooltip, children }) => {

  const [width] = useWindowWidth()

  const _size = useMemo(() => {
    if (size) return size
    return width < 728 ? 'small' : 'middle'
  }, [width])

  if (hidden) return null;

  if (tooltip) return <Tooltip title={tooltip}>
    <RefreshButton shape='circle' type='text' size={_size} disabled={disabled} onClick={onClick}>
      { Icon || children }
    </RefreshButton>
  </Tooltip>

  return <RefreshButton shape='circle' type='text' size={_size} disabled={disabled} onClick={onClick}>
    { Icon || children }
  </RefreshButton>;
}

// For temporary backwards compatibility
export const Action = PageAction;
