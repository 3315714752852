import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { GatewayOutlined } from '@ant-design/icons'
import { FieldContainer } from '../antd/FieldContainer'
import { isEmpty, get, compact } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities';
import useDebouncedEffect from 'use-debounced-effect-hook'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const TICKET_SEARCH = gql`
  query TICKET_SEARCH($keyword: String!) {
    ticketSearch(keyword: $keyword) {
      id
      name
      tags
      shortid
    }
  }
`;

const { Option } = Select

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

/**
 * takes values and returns LoadedUser keys in a single string
 * @param ticket
 * @param labelKeys
 * @returns string
 */
const getValues = (ticket: LoadedTicket, labelKeys: (keyof LoadedTicket)[]) => {
  return compact(labelKeys.map((l: keyof LoadedTicket) => get(ticket, l, null))).join(' ');
}

interface LoadedTicket {
  id: string,
  name: string,
  shortid: string,
  tags: string[]
}

interface TicketSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  //TODO: define these properly
  valueKey?: keyof LoadedTicket,
  labelKeys?: (keyof LoadedTicket)[],
  showPicture?: boolean;
  getOptions?: (tickets: Partial<LoadedTicket>[]) => React.ReactNode[],
  getUser?: (value: any) => void,
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  },
  defaultOptions?: LoadedTicket[]
}

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const TicketSelectorComponent: React.FC<TicketSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  {
    getFormik = true,
    getOptions,
    getUser,
    labelKeys = ['name'],
    valueKey = 'id',
    name = '',
    showPicture = true,
    onChange,
    disabled,
    label,
    required,
    hidden,
    gridProps,
    className,
    defaultValue,
    defaultOptions,
    ...props
  }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')
  const [tickets, setTickets] = useState<LoadedTicket[]>(defaultOptions || [])
  const [keyword, setKeyword] = useState<string>('')

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const [fetchTickets, { loading }] = useLazyQuery(TICKET_SEARCH, {
    variables: { keyword },
    onCompleted: (result: any) => {
        console.log(JSON.stringify(result, null, 2))
        setTickets(cloneDeep(result.ticketSearch))
    },
    onError: (error: any) => {
      console.log(JSON.stringify(error, null, 2))
    }

  })

  const handleSearch = useCallback((_keyword: string) => setKeyword(_keyword), [setKeyword]);

  useDebouncedEffect(() => {
    if (keyword && keyword !== '') fetchTickets({ variables: { keyword } });
  }, [ keyword, fetchTickets, setTickets ], 500);

  const _getUser = useCallback((value: any, key: string) => tickets.find((u: LoadedTicket) => get(u, key) === value), [tickets]);

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value);
    if (onChange) onChange(getUser ? getUser(value) : _getUser(value, valueKey));
    setSelected(value);
    setKeyword('');
  }, [name, setSelected, formik, isFormik, onChange, getUser, valueKey, _getUser, setKeyword]);

  /**
   * Select Options constructor. Builds an array of options children from fetched ticket list.
   */
  const _getOptions = useCallback(() => tickets.map((ticket: any) => (
    <Option key={ticket.id} value={ticket.id} label={getValues(ticket, labelKeys)}>
      {showPicture && <Icon src={getThumbnail(ticket.profilePicUrl)} size="small" icon={<GatewayOutlined />} />}
      {getValues(ticket, labelKeys)}
    </Option>
  )), [tickets])

  const selectOptions = useMemo(() => tickets.length > 0 && (getOptions ? getOptions(tickets) : _getOptions()), [tickets, getOptions]);

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
      editable={false}
    >
      <FormikSelect
        prefix={<GatewayOutlined className="service-form-item-icon" />}
        name={name}
        onSearch={handleSearch}
        onChange={handleOnChange}
        showSearch
        placeholder={'Search tickets...'}
        disabled={disabled || formik.isSubmitting}
        loading={loading}
        filterOption={false}
        {...props}
      >
        {selectOptions}
      </FormikSelect>
    </FieldContainer>
  )

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<GatewayOutlined className='service-form-item-icon' />}
      placeholder={'Search tickets...'}
      loading={loading}
      className={`${className} ant-service-selector`}
      onSearch={handleSearch}
      onSelect={handleOnChange}
      showSearch
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {selectOptions}
    </Select>
  )
}

export const TicketSelector = styled(TicketSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
