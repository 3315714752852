import React, { FC, useCallback, useMemo, useState, ReactNode } from 'react';
import { Table as AntTable } from 'ant-table-extensions';
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { getColFilters, getColSort } from '../../index';
import ResizeableTable from '../resizeable-table';
import ColumnSelect from '../column-select';
import { cloneDeep } from 'lodash'
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Space, Button, Tooltip } from 'antd';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { exportTableCSV } from '../../../../../helpers';

const TableActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  //align-items: flex-end;
  padding: 5px;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  align-self: center;
  flex: 1;
`;

interface IProps {
  data: any[];
  columns: any[];
  title?: ReactNode;
  exportTitle?: string;
  loading?: boolean;
  resizeable?: boolean;
  columnSelection?: boolean;
  searchable?: boolean;
  exportable?: boolean;
  refresh?: () => void;
  scroll?: {
    scrollToFirstRowOnChange?: boolean;
    x?: number | true,
    y?: number;
  };
  getValues?: Function; // Helper to fetch values for filters and sorts
  actions?: ReactNode[];
  [x: string]: any;
}

export const getColumns = (columns: any[]) => columns.map((col: any) => {
  const width = col.width ? col.width : (col.resizeable === false ? undefined : 150);
  const key = col.dataIndex ? col.dataIndex : (col.key.includes('.') ? col.key.split('.') : col.key);
  const column = { ...col, dataIndex: key, width };
  const { filter, sort, ...rest } = column;
  rest.show = rest.show == null ? true : rest.show;
  return Object.assign({ ...rest, ...getColFilters(column), ...getColSort(column)})
});

const useColumns = (initialColumns: any) => {
  const [allColumns, setColumns] = useState<any>(getColumns(initialColumns));

  const filteredColumns = useMemo(() => allColumns.filter((col: any) => col.show), [allColumns]);

  const handleOnColumnSelect = useCallback((key: string) => (e: any) => {
    const _columns = cloneDeep(allColumns)
    const item = _columns.find((col: any) => key === col.key);
    if (item) item.show = e.target.checked;
    setColumns(_columns);
  }, [allColumns, cloneDeep])

  return [allColumns, filteredColumns, handleOnColumnSelect] as const;
}

export const TableComponent: FC<IProps> = ({ data, columns, loading, searchableProps, resizeable, columnSelection, searchable, exportable, exportTitle, scroll = { x: 'max-content' }, actions, refresh, title, ...otherProps }) => {

  const [state, setState] = useState<any>({
    pagination: {},
    filters: {},
    sorter: {},
    extra: {}
  });

  const [allColumns, filteredColumns, handleOnColumnSelect] = useColumns(columns);

  const onChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, CheckboxValueType[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => {
    setState({ pagination, filters, sorter, extra });
  }, [setState]);

  const ATable = useMemo(() => resizeable ? ResizeableTable : AntTable, [resizeable]);

  const handleExport = useCallback(() => exportTableCSV(allColumns, data, exportTitle || 'Data'), [allColumns, data, title, exportTitle]);

  if (!allColumns || allColumns.length === 0) return null;

  return (
    <div>
      <TableActions>
        <Title>
          {title}
        </Title>
        <Space>
          {actions}
          {exportable && <Tooltip title={'Download CSV'}><Button icon={<DownloadOutlined />} onClick={handleExport} shape="circle"/></Tooltip>}
          {columnSelection && <ColumnSelect handleOnColumnToggle={handleOnColumnSelect} columns={allColumns} />}
          {refresh && <Tooltip title={'Refresh'}><Button icon={<ReloadOutlined />} onClick={refresh} shape="circle"/></Tooltip>}
        </Space>
      </TableActions>
      <ATable dataSource={data} columns={filteredColumns} loading={loading} searchableProps={searchable ? searchableProps : null} exportable={false} searchable={searchable} scroll={scroll} onChange={onChange} {...otherProps} />
    </div>
  );
};


export const Table = styled(TableComponent)`
  .clickable {
    cursor: pointer;
  }
`;

// TODO: antd scrolling sucked, let us consider some options to handle horizontal overflow
// Antd scrolling only sucks for resizeable tables, so went back to using it for now
const TableStyler = styled.div`
  overflow-x: auto !important;
  /*Hide scrollbars*/
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;  /* Hide scrollbars for Internet Explorer 10+ */
  scrollbar-width: none;  /* Hide scrollbars for Firefox */
`;

Table.defaultProps = {
  data: [],
  columns: [],
  loading: false,
  resizeable: false,
  searchable: true,
  columnSelection: true,
  exportable: true,
  searchableProps: {
    fuzzySearch: true,
    debounce: true
  },
  scroll: {
    x: true
  }
};

export default Table;
