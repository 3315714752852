import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getUserData } from '@necta-tech/cognito';
import { store } from './redux-config';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const apollo_config = process.env.REACT_APP_APOLLO_CONFIG_TOGGLE === 'true';
const CACHE_OPTIONS = {
  addTypename: false,
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  // TODO: use environments
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SOCKET_API_URL + '',
  options: {
    reconnect: true,
  },
});

//perhaps should not return a promise(incase things dont work)
const asyncAuthMiddleware = setContext(
  ({ headers }: any) =>
    new Promise((success, fail) => {
      getToken()
        .then(token => {
          if (process.env.REACT_APP_DEBUG === 'true') console.log(token);
          success({
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
            },
          });
        })
        .catch(ex => {
          success({ headers });
        });
    }),
);

const splitLink = split(
  ({ query }: any) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  asyncAuthMiddleware.concat(httpLink),
);
//refactor for async middleware

export const getToken = async (): Promise<any> => {
  const cognitoUser = store.getState().cognitoUser;

  if (!cognitoUser.inSession) return Promise.reject('User not in session');

  const fetchedUser = await getUserData();

  return Promise.resolve(fetchedUser.accessToken!.getJwtToken());
};

export const apolloClient = apollo_config
  ? new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(CACHE_OPTIONS),
    })
  : null;

export default apolloClient;
