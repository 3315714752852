import React from 'react';
import { Statistic } from 'antd';

interface StatProps {
  title: string;
  value: number;
  loading?: boolean;
  hidden?: boolean;
}

const Stat: React.FC<StatProps> = ({ title, value, loading, hidden }) => {
  if (hidden) return null;
  return <Statistic title={title} value={value} loading={loading} />;
}

export default Stat;
