import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .label('Email'),
  firstname: Yup.string()
    .required()
    .label('First Name(s)'),
  lastname: Yup.string()
    .required()
    .label('Last Name'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumber: Yup.string()
    .required()
    .label('Contact Number'),
  role: Yup.string()
    .default('Organisation')
    .required()
    .label('Role'),
  organisationId: Yup.string()
    .nullable()
    .label('Organisation'),
  isTechnician: Yup.boolean()
    .nullable()
    .label('Extra Roles:'),

  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }).nullable().label('Search for address'),
});
