import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUploadFile } from '@bit/necta.hooks.upload-file';
import gql from 'graphql-tag'
import { Popconfirm, Button, Modal, Card, Avatar, Progress, Skeleton } from 'antd';
import { DeleteOutlined, UploadOutlined, LoadingOutlined, DownloadOutlined, FileOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { Form } from 'formik-antd';
import { CheckBox, DatePicker, FormRow, Input } from '../antd';
import { presentError, presentSuccess } from '@necta-tech/alert';
import { useFormData } from '../../hooks';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { message, Tooltip } from 'antd';
import { useFiles } from '../../graphql/hooks';

const fileSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('File Name'),
  expires: Yup.string()
    .nullable()
    .label('Expiry Date'),
  sendExpiryNotification: Yup.boolean()
    .default(false)
    .label('Notifications'),
  isPublic: Yup.boolean()
    .default(false)
    .label('Visibility'),
});


export const ADD_FILE_MUTATION = gql`
  mutation ADD_FILE_MUTATION($newFileData: NewFileInput!) {
    addFile(newFileData: $newFileData) {
      id
      name
      key
      documentClass
      createdAt
      signedUrl
      secure
      isPublic
    }
  }
`;


export const DELETE_FILE_MUTATION = gql`
  mutation DELETE_FILE_MUTATION($id: String!) {
    removeFile(id: $id)
  }
`;

const UPDATE_FILE_MUTATION = gql`
  mutation UPDATE_FILE_MUTATION($id: String!, $updateFile: UpdateFileInput!) {
    updateFile(id: $id, updateFile: $updateFile) {
      id
    }
  }
`;

const HelperText = styled.div`
  margin-top: 5px;
`;

const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const DropzoneOuter = styled.div`
  border-image: none;
  &:hover {
    color: ${p => p.theme.primary};
    border-color: ${p => p.theme.primary};
  }
  border-color: #d9d9d9;
  border-style: dashed;
  border-width: 1px;
  border-radius: 2px;
  background: #FAFAFA;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
`;

const DropzoneInner = styled.div`
  height: 100%;
  color: black;
  text-align: center;
  padding: 2rem 1.5rem;
`;

const FileList = styled.div`
  width: 100%;
  margin-top: 15px;
`

const FileItem = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  cursor: pointer;
  margin-bottom: 5px;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
`;

const Preview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
`;

const Image = styled(Avatar)`
  background: transparent !important;
  svg {
    color: ${p => p.theme.primary} !important;
  }
`;

const Loading = styled(LoadingOutlined)<{ fileType?: 'IMAGE' | 'DOCUMENT' | null }>`
  svg {
    color: ${ p => {
      switch (p.fileType) {
        case 'IMAGE':
          return 'white';
        case 'DOCUMENT':
          return '#878787';
        default:
          return '#878787';
      }
    }}  
  }
`;

interface LinkInput {
  linkedId: string;
  type: string;
}

const key = 'file-upload';

interface IFileUploaderProps {
  id: string;
  parentType: string;
  fileType?: 'IMAGE' | 'DOCUMENT' | null;
  documentClass?: string;
  initialFiles?: any[];
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string; // Alternative loading text to display
  infoText?: string; // Alternative helper text to display,
  MUTATIONS?: { ADD_FILE: any, DELETE_FILE: any },
  successCallback?: boolean | Function;
  errorCallback?: boolean | Function;
  deletedCallback?: boolean | Function;
  showFields?: string[] | false,
}

export const FileUploader: React.FC<IFileUploaderProps> = ({ disabled = false, loadingText, infoText, fileType, documentClass, initialFiles, id, parentType, MUTATIONS = {}, successCallback, errorCallback,  deletedCallback, showFields, loading: externalLoading, children }) => {

  const onAdded: () => void = useCallback(() => {
    if (successCallback === true) return message.success({ content: 'File uploaded successfully!', key, duration: 2, onClose: checkLoading() });
    return successCallback ? successCallback : () => {};
  }, [successCallback])

  const onError: () => void = useCallback(() => {
    if (errorCallback === true) return message.error({ content: 'A file failed to upload!', key, duration: 4, onClose: checkLoading() });
    return errorCallback ? errorCallback : () => {};
  }, [errorCallback]);

  const onDeleted: () => void = useCallback(() => {
    if (deletedCallback === true) return message.success({ content: 'File removed', key, duration: 2, icon: <DeleteOutlined />, onClose: checkLoading() });
    return deletedCallback ? deletedCallback : () => {};
  }, [deletedCallback]);

  const [fileList, { updateFile, addFile }] = useFiles(id, fileType, initialFiles, undefined, { onAdded, onDeleted });

  const { ADD_FILE, DELETE_FILE } = MUTATIONS

  const [addFileMutation, { loading: isLoading }] = useMutation(ADD_FILE ?? ADD_FILE_MUTATION, { onError });

  const {
    handleSubmit,
    loading,
    progress
  } = useUploadFile(
    (result: any, fileKey: string, name?: string) => {
      updateFile(fileKey, { percent: 100, status: 'uploaded' })
      try {
        const newFileData = {
          key: result.key,
          name,
          fileType,
          documentClass,
          secure: true,
          createLink: { linkedId: id, type: parentType }
        }
        addFileMutation({ variables: { newFileData } });
      } catch (ex) {
        updateFile(fileKey, { status: 'error' });
        onError();
      }
    }, (err: any, fileKey: string) => {
      updateFile(fileKey, { status: 'error' });
      onError();
    }, (p: number, fileKey: string) => {
      const percent = Math.round(p < 15 ? 15 : p);
      updateFile(fileKey, { percent })
    }
  );

  const checkLoading = useCallback(() => (loading || isLoading) && message.loading('Files are uploading...'), [loading, isLoading]);

  useEffect(() => {
    checkLoading();
  }, [loading, isLoading, checkLoading]);

  const handleFileSelect = useCallback(
    (files: any[]) => {
      files.forEach(async (file: any) => {
        const path = `${fileType?.toLowerCase()}`;
        const config = { path, secure: true, replace: true };
        const uid = await handleSubmit(file, config);
        addFile({
          uid,
          percent: 5,
          name: file.name,
          status: 'uploading',
        })
      });
    },
    [fileType, handleSubmit, addFile, updateFile, fileList],
  );

  const UploadButton = () => {
    if (disabled) return null;

    return (
      <DropzoneOuter>
        <Dropzone onDrop={acceptedFiles => handleFileSelect(acceptedFiles)} disabled={loading}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <DropzoneInner>
                  <UploadOutlined />
                  <HelperText>{infoText ? infoText : 'Upload Files'}</HelperText>
                </DropzoneInner>
              </div>
            </section>
          )}
        </Dropzone>
      </DropzoneOuter>
    );
  };

  if (externalLoading) return <Skeleton active />;

  return (
    <Outer>
      <UploadButton />
      <FileList>
        {fileList.map((file: any) =>
          <File file={file} fileType={fileType} onEdit={updateFile} showFields={showFields} DELETE_FILE={DELETE_FILE} disabled={disabled} />
        )}
      </FileList>
    </Outer>
  );
}

const DEFAULT_FIELDS = ['name', 'sendExpiryNotification', 'expires'];

interface FieldsProps {
  showFields?: string[] | false,
  children: any
}
const Fields = ({ showFields, children }: FieldsProps) => {
  if (!showFields) return null;

  return <FormRow gutter={20} justify='space-between'>
    {children.map((child: any) =>
      showFields.includes(child?.props?.name) && child
    )}
  </FormRow>
}

FileUploader.defaultProps = {
  fileType: 'IMAGE',
  successCallback: true,
  errorCallback: true,
  deletedCallback: true
}

const getValues = (values: any, showFields: string[]) => {
  const _values: any = {};
  showFields.map((f: string) => _values[f] = values[f]);
  return _values;
}

interface FileProps {
  file: any,
  fileType?: 'IMAGE' | 'DOCUMENT' | null;
  onEdit: (key: string, newFile: any) => void,
  showFields?: string[] | false,
  DELETE_FILE: any,
  disabled?: boolean
}

const File: React.FC<FileProps> = ({ file, fileType, onEdit, showFields = DEFAULT_FIELDS , DELETE_FILE, disabled }) => {
  const [visible, setVisible] = useState(false);
  const [updateFile, { loading: updateLoading }] = useMutation(UPDATE_FILE_MUTATION);
  const [removeFileMutation, { loading: isDeleting }] = useMutation(DELETE_FILE ?? DELETE_FILE_MUTATION);

  const { fields, ...formikCTX } = useFormData(fileSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      try {
        const { id } = values;
        const _updateFile = getValues(values, showFields || [])
        await updateFile({
          variables: {
            id,
            updateFile: _updateFile,
          },
        });
        presentSuccess('File updated successfully!');
        onEdit(file.uid, { ..._updateFile });
        setVisible(false);
      } catch (e) {
        console.log(e);
        presentError('Unable to update file, please try fix any form errors and try again');
      } finally {
        actions.setSubmitting(false);
      }
    }
  })

  const handleConfirmRemove = useCallback((file: any, setFieldValue: any) => (e?: any) => {
    onEdit(file.uid, { status: 'removed' })
    setFieldValue('status', 'removed');
    e.stopPropagation();
    removeFileMutation({ variables: { id: file.id } });
  }, [onEdit, removeFileMutation]);

  const handleDownload = useCallback((e?: any) => {
    e.stopPropagation();
    if (file.status === 'done') window.open(file.url, '_blank');
  }, [file]);

  const handleClick = useCallback((e?: any) => {
    e.stopPropagation();
    if (file.status === 'done' && showFields !== false) setVisible(true);
    else if (showFields === false) handleDownload(e);
  },[setVisible, file, showFields, handleDownload]);

  const handleCancel = useCallback( (setFieldValue: any) => (e?: any) => {
    e.stopPropagation();
    setVisible(false)
    setFieldValue('sendExpiryNotification', file.sendExpiryNotification);
    setFieldValue('expires', file.expires);
    setFieldValue('name', file.name);
    setFieldValue('isPublic', file.isPublic);
  }, [setVisible, file]);

  return (
    <Formik {...formikCTX} initialValues={file} enableReinitialize validateOnBlur>
      {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
        <>
          <FileItem hoverable key={file.uid} onClick={handleClick}>
            <Image shape="square" src={file.url} icon={<FileOutlined />} />
            <Preview>
              <span>
                {file.isPublic && !disabled && <Tooltip title={'Publicly visible'}><EyeOutlined /> </Tooltip>}
                {file.name}
              </span>
              {file.percent && ['uploading', 'uploaded'].includes(file.status) && <Progress percent={file.percent} />}
            </Preview>
            <Actions onClick={(e: any) => e.stopPropagation()}>
              {file.status === 'removed' && <Loading fileType={fileType} />}
              {file.status === 'done' && <>
                <Button shape="circle" type="text" icon={<DownloadOutlined />} onClick={handleDownload} />
                { !disabled && <Popconfirm
                  title={`Are you sure you want to delete this file?`}
                  onConfirm={handleConfirmRemove(file, setFieldValue)}
                >
                  <Button shape="circle" type="text" icon={<DeleteOutlined />} />
                </Popconfirm>}
              </>}
            </Actions>
          </FileItem>
          <Modal
            title="Update File"
            visible={visible}
            onOk={() => handleSubmit()}
            confirmLoading={isSubmitting || updateLoading}
            onCancel={handleCancel(setFieldValue)}
          >
            <Form layout={'vertical'}>
              <Fields showFields={showFields}>
                <Input {...fields.name} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }} />
                <CheckBox {...fields.sendExpiryNotification}>
                  Send Expiry Notifications
                </CheckBox>
                <CheckBox {...fields.isPublic}>
                  Visible to Client
                </CheckBox>
                <DatePicker {...fields.expires} />
              </Fields>
            </Form>
          </Modal>
        </>
      )}
    </Formik>
  );
};

export default FileUploader;
