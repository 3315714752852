import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Collapse, Input as AntInput, message, Tooltip } from 'antd';
import { LinkOutlined, DeleteOutlined, DeleteRowOutlined, UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { formatDate } from '../../../helpers/string-transforms';
import { useMoveStep, useSetStep } from './redux';
import { DELETE_STEP, EDIT_STEP } from '../gql';
import { Step as StepT } from '../types';

const { Panel } = Collapse;

const StepBase = styled.div`
  width: 100%;
  margin-bottom: 10px;
  background-color: #FFFEFEFE;
  padding: 10px;
  border-radius: 2px;
`;

const StepItem = styled(StepBase)<{isDragging: boolean}>`
  :hover {
    outline: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }
  border: ${p => p.isDragging ? '1px solid ' + p.theme.primary : ''};
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SpaceOut = styled.div`
  flex: 1;
`;

const DisabledStepItem = styled(StepBase)`
  background-color: #FCFCFC;
  color: #DADADA;
  border: 1px solid #DADADA;
`;

const Desc = styled(StepContent)`
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const DescTitle = styled.div`
  padding: 5px 10px 5px 10px;
  //font-weight: bold;
  border-right: 1px solid #f0f0f0;
  background-color: #f5f5f5;
`;

const DescContent = styled.div`
  padding: 5px 10px 5px 10px;
`;

interface DisabledStepProps {
  step: StepT;
  stepChanged?: (step: StepT) => void;
  disabled?: boolean;
}
export const DisabledStep = ({ step, stepChanged, disabled } : DisabledStepProps) => {

  const unarchiveStep = useMoveStep();

  const [updateStep] = useMutation(EDIT_STEP);

  const handleUnarchive = useCallback((e?: any) => {
    const _step = { ...step, disabled: false };
    unarchiveStep(_step);
    if (stepChanged) stepChanged(_step);
    updateStep({ variables: { id: step.id, step: { disabled: false }}});
  }, [unarchiveStep, step, stepChanged, updateStep]);

  if (!step) return null;

  return (
    <DisabledStepItem>
      <StepContent>
        <span>{step.title}</span>
        <SpaceOut />
        <Tooltip title="Add asset back into ticket">
          <Button type="dashed" shape="circle" icon={<UpOutlined />} onClick={handleUnarchive} disabled={disabled} />
        </Tooltip>
      </StepContent>
    </DisabledStepItem>
  );
}

interface DescItemProps {
  label: string;
  text?: any;
}
export const DescItem = ({ label, text }: DescItemProps) => {
  if (!text || text === '') return null;

  return <Desc><DescTitle>{label}</DescTitle><DescContent>{text}</DescContent></Desc>;
}

interface StepInputProps {
  step: StepT;
  setEditing: (editing: boolean) => void;
}
const StepInput = ({ step, setEditing }: StepInputProps) => {

  const [value, setValue] = useState(step.title);

  const setStep = useSetStep();

  const [updateStep] = useMutation(EDIT_STEP);

  const onComplete = useCallback((e?: any) => {
    setEditing(false);
    if (step.title !== value) {
      setStep({ ...step, title: value });
      updateStep({ variables: { id: step.id, step: { title: value } }});
    }
  }, [setEditing, setStep, step, updateStep, value]);

  const onChange = useCallback((e?: any) => {
    setValue(e.target.value);
  }, [setValue]);

  return (
    <AntInput autoFocus defaultValue={step.title} onChange={onChange} size={'small'} onPressEnter={onComplete} onBlur={onComplete}/>
  )
}

interface StepInnerProps {
  step: StepT;
  stepChanged?: (step: StepT) => void;
  disabled?: boolean;
}
const StepInner = ({ step, stepChanged, disabled }: StepInnerProps) => {

  const [editing, setEditing] = useState(false);

  const handleSetEditing = useCallback((edit: boolean) => (e?: any) => setEditing(edit), [setEditing]);

  const [removeStep, { loading }] = useMutation(DELETE_STEP);

  const [updateStep] = useMutation(EDIT_STEP);

  const archiveStep = useMoveStep();

  const history = useHistory();

  const handleArchiveAsset = useCallback((e?: any) => {
    const _step = { ...step, disabled: true };
    archiveStep(_step);
    if (stepChanged) stepChanged(_step);
    updateStep({ variables: { id: step.id, step: { disabled: true } }});
  }, [archiveStep, step, stepChanged, updateStep]);

  const handleArchiveStep = useCallback((e?: any) => {
    removeStep({ variables: { id: step.id }});
  }, [removeStep, step]);

  const handleViewAsset = useCallback((id: string) => (e?: any) => history.push(`/asset/edit/${id}`), [history]);

  if (editing) return <StepInput step={step} setEditing={setEditing} />

  // Asset step
  if (!editing && !!step.assignedAsset) return (
    <StepContent>
      <Collapse ghost>
        <Panel header={<b><LinkOutlined /> {step.assignedAsset?.name || step.title}</b>} key="1">
          <DescItem label="Manufacturer" text={step.assignedAsset.manufacturer} />
          <DescItem label="Model Number" text={step.assignedAsset.modelNumber} />
          <DescItem label="Registration Number" text={step.assignedAsset.registrationNumber} />
          <DescItem label="Condition" text={step.assignedAsset.condition} />
          <DescItem label="Category" text={step.assignedAsset.category} />
          <DescItem label="Tags" text={step.assignedAsset.tags?.join(', ')} />
          <DescItem label="Date Purchased" text={formatDate(step.assignedAsset.datePurchased)} />
          <Button onClick={handleViewAsset(step.assignedAsset.id)}>View</Button>
        </Panel>
      </Collapse>
      <SpaceOut />
      <Tooltip title="Archive from ticket">
        <Button type="dashed" shape="circle" icon={<DeleteRowOutlined />} onClick={handleArchiveAsset} disabled={disabled} />
      </Tooltip>
    </StepContent>
  )

  // Text step
  return (
    <StepContent>
      <span style={{ cursor: 'text' }} onClick={handleSetEditing(true)}>{step.title}</span>
      <SpaceOut />
      <Tooltip title="Delete Step">
        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} onClick={handleArchiveStep} disabled={loading || disabled} loading={loading} />
      </Tooltip>
    </StepContent>
  );

}


interface StepProps {
  step: StepT;
  index: number;
  stepChanged?: (step: StepT) => void;
  disabled?: boolean;
}
export const Step = ({ step, index, stepChanged, disabled } : StepProps) => {

  if (!step) return null;

  return (
    <Draggable draggableId={step.id} index={index} isDragDisabled={disabled}>
      {(provided: any, snapshot: any) => (
        <StepItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <StepInner step={step} stepChanged={stepChanged} disabled={disabled} />
        </StepItem>
      )}
    </Draggable>
  );
}

export default Step;
