import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { Page, AuthGuard, SiteSelector } from '../../components';
import { getIsAdmin, getActiveOrganisationDId, getIsOrganisation } from '../../selectors';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Tags, TextArea, Input, ContactNumber, Select, FormRow, FormButton, CheckBox } from '../../components/antd';
import { Card, Collapse as Collapser, Col } from 'antd';
import { message } from 'antd';
import { cleanError } from '../../helpers/error-helper';
import { useHistory } from 'react-router';
import { DatePicker } from '../../components/antd';
import { addTicketSchema } from './schema';
import { ADD_TICKET } from './gql';
import { mapArrayToObjects } from '../../helpers';
import { TICKET_TYPE, REPEATE_INTERVAL, TICKET_STATUS } from '../../constants';
import { LocationSearch } from '../../components/location-search';
import { useCurrentOrganisation } from '../../components/organisation-selector';
import { get } from 'lodash';
import { CustomerSelector } from '../../components/customer-selector';

const { Panel } = Collapser;
const ticket_type = mapArrayToObjects(Object.keys(TICKET_TYPE));
const repeatInterval = mapArrayToObjects(REPEATE_INTERVAL);

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

const GET_CUSTOMER_BY_ID = gql`
  query($id: String!) {
    customerSingle(id: $id) {
      id
      email
      companyName
      vatNum
      regNum
      contactName
      contactNumber
      contactNumberCountryCode
      shortid
      organisationId
      address {
        addressLine1
        country
        state
        postalCode
      }
      headquarters
      createdAt
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const TicketAdd: React.FC<any> = () => {
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationDId);
  const isOrganisation = useSelector(getIsOrganisation);
  const [currentOrg] = useCurrentOrganisation();
  const [selectedCustomerAndSite, setSelectedCustomerAndSite] = useState<any>({});

  const [fetchCustomer, { loading: customerLoading }] = useLazyQuery(GET_CUSTOMER_BY_ID, {
    variables: { id: selectedCustomerAndSite.site ? selectedCustomerAndSite.site.customerId : null },
    onCompleted: (result: any) => {
      setSelectedCustomerAndSite({ ...selectedCustomerAndSite, customer: result.customerSingle });
    },
    fetchPolicy: 'network-only',
  });

  console.log(isAdmin);
  console.log(activeOrgId);
  console.log(isOrganisation);

  const [handleAdd] = useMutation(ADD_TICKET);
  const history = useHistory();

  const { fields, initialValues, ...formikCTX } = useFormData(addTicketSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.success({ content: 'Ticket created. Please allow a few minutes for assets to import.', duration: 2 });
      message.loading({ content: 'Creating ticket...', duration: 10 });
      values.organisationId = currentOrg?.id;
      return handleAdd({
        variables: {
          newTicketData: { ...values, status: TICKET_STATUS.NEW },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Ticket created. Please wait a few minutes for assets to import.', duration: 4 });
      history.push(`/ticket/edit/${result?.data?.addTicket?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add Ticket'), duration: 4 });
    },
    override: 'ticket',
  });

  const [defaultValues] = useState({
    ...initialValues,
  });

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Page card title='Add Ticket'>
              <Form layout='vertical'>
                <Collapse defaultActiveKey={['ticket', 'contact', 'date']} ghost>
                  <Panel header='Ticket Information' key='ticket'>
                    <FormRow>
                      <Input {...fields.name} />
                      <CustomerSelector
                        onChange={(customer: any, label: any) => {
                          setSelectedCustomerAndSite({ ...selectedCustomerAndSite, customer });
                        }}
                        defaultValue={
                          selectedCustomerAndSite.customer ? selectedCustomerAndSite.customer.id : undefined
                        }
                        value={selectedCustomerAndSite.customer ? selectedCustomerAndSite.customer.id : undefined}
                        loading={customerLoading}
                        {...fields.customerName}
                      />
                      <SiteSelector
                        selectedCustomerId={
                          selectedCustomerAndSite.customer ? selectedCustomerAndSite.customer.id : null
                        }
                        onChange={(site: any) => {
                          if (selectedCustomerAndSite.customer == null && site != null) {
                            setSelectedCustomerAndSite({ ...selectedCustomerAndSite, site });
                            fetchCustomer();
                          }
                        }}
                        {...fields.assignedSiteId}
                      />
                      <Select options={ticket_type} value={undefined} {...fields.type} required={'yes'} placeholder={'Ticket Type must be selected'} />
                    </FormRow>
                  </Panel>

                  <Panel header='Contact Information' key='contact'>
                    <FormRow>
                      <Input {...fields.primaryContactName} />
                      <ContactNumber
                        numberField={fields.primaryContactNumber}
                        countryCodeField={fields.primaryContactNumberCountryCode}
                      />
                      <Input {...fields.primaryContactEmail} />
                      <TextArea gridProps={GRID_PROPS} {...fields.description} rows={7} />
                    </FormRow>
                  </Panel>

                  <Panel header='Date and Time' key='date'>
                    <DatePicker format='DD/MM/YYYY HH:mm' {...fields.dueDate} showTime={{ format: 'HH:mm' }} />
                    <CheckBox {...fields.isRepeats}>This ticket will be repeated</CheckBox>
                    <Select options={repeatInterval} {...fields.repeatInterval} hidden={values.isRepeats === false} />
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow>
                      <LocationSearch
                        {...fields.address}
                        gridProps={GRID_PROPS}
                        parentKey={'address'}
                        defaultValue={get(values, 'address.fullAddress')}
                      />
                      <Input {...fields.address.addressLine1} />
                      <Input {...fields.address.country} />
                      <Input {...fields.address.state} />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>

                  <Panel header='Categories' key='categories'>
                    <Tags gridProps={GRID_PROPS} {...fields.tags} />
                  </Panel>
                </Collapse>

                <Add loading={isSubmitting} type={'submit'}>
                  Add
                </Add>
              </Form>
            </Page>
          );
        }}
      </Formik>
    </AuthGuard>
  );
};

export default TicketAdd;
