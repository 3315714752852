import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

// USAGE: updating own user
// This can be used to update passwords and emails too
export const UPDATE_MY_USER = gql`
    mutation UPDATE_MY_USER ($user: UpdateUserInput!) {
        updateUser(user: $user) {
            id
            email
        }
    }
`

interface UpdateOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useUpdateUser = ({onCompleted, onError}: UpdateOptions) => {
  const ctx = useMutation(UPDATE_MY_USER, {
    onCompleted,
    onError
  })
  return ctx
}
