import React, { FC, useCallback, useMemo, useState } from 'react';
import { Pie as AntPie } from '@ant-design/charts';
import styled from 'styled-components';
import { Data } from './types';
import { Empty } from 'antd';

const TitleOuter = styled.div`
  padding: 7px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: normal;
`;

const DEFAULT_CONF: any ={
  appendPadding: 10,
  angleField: 'value',
  colorField: 'label',
  radius: 1,
  innerRadius: 0.6,
  label: {
    type: 'inner',
    offset: '-50%',
    content: '{value}',
    autoRotate: false,
    style: {
      textAlign: 'center',
      fontSize: 14,
    },
  },
  interactions: [{ type: 'element-selected' }, { type: 'element-active' }]
};


interface IProps {
  data: Data[];
  config?: any;
  title?: string;
  subtitle?: string;
  selectedKeys?: any;
  loading?: boolean;
  [x: string]: any;
}

const Pie: FC<IProps> = ({ config = DEFAULT_CONF, data, title, subtitle, selectedKeys, loading }) => {

  const [width, setWidth] = useState(null);
  const div = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const finalConfig = useMemo(() => {
    const _config = {
      ...config,
      loading,
      width,
      legend: {
        maxWidth: width,
        layout: 'horizontal',
        position: 'bottom'
      },
      data,
    };

    if (title || subtitle) _config.statistic = {title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customHtml: <TitleOuter><Title>{title}</Title><Subtitle>{subtitle}</Subtitle></TitleOuter>,
      },
    }

    if (!_config.legend) _config.legend = {};
    if (selectedKeys) _config.legend.selected = selectedKeys;

    return _config;
  }, [config, data, title, subtitle, selectedKeys, width, loading]);

  if (!finalConfig) return null;

  if (data.length <= 0 && !loading) return <>
    <TitleOuter><Title>{title}</Title><Subtitle>{subtitle}</Subtitle></TitleOuter>
    <Empty style={{ marginBottom: 10 }} />
  </>

  return (
    <div ref={div} style={{ padding: 10 }}>
      <AntPie {...finalConfig} />
    </div>
  );
};

Pie.defaultProps = {};

export default Pie;
