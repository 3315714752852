import { useActiveSteps, useInactiveSteps, useOrderSteps, useTicket } from './redux';
import { useSteps } from '../../../graphql/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EditOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined, UnlockOutlined, MailOutlined, DeleteOutlined } from '@ant-design/icons';
import { useFormData } from '../../../hooks';
import { ticketConfirmSchema } from '../schema';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Button, Card, Col, Collapse, Input as AntInput, message, Row, Typography, Popconfirm, Modal } from 'antd';
import { cleanError } from '../../../helpers/error-helper';
import { CheckBox, DatePicker, FormButton, FormRow, Input, TextArea } from '../../../components/antd';
import styled from 'styled-components';
import { Form as AntForm } from 'formik-antd';
import gql from 'graphql-tag';
import { allTicketFields, stepFields } from '../../../graphql/fragments';
import { ORDER_STEPS, ADD_STEP } from '../gql';
import { orderSteps, StepList } from './index';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import { Map } from '../../../components';
import { TICKET_STATUS } from '../../../constants';

const { Panel } = Collapse;
const { Link } = Typography;

const StepsContainer = styled.div`
  h4 {
    margin-top: 0;
    font-weight: bold;
  }
`;

const CPanel = styled(Panel)`
  .ant-collapse-content {
    background-color: #ebecf0;
  }
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SettingOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 260px;
`;

const SettingTitle = styled.div`
  flex: 1;
`;

const TicketCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .ant-card-body {
    flex: 1;
  }
`;

const GreyBox = styled.div`
  background: #FAFAFA;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
`

const Setting: React.FC<{ hidden?: boolean }> = ({ hidden, children }) => {
  if (hidden) return null;

  return <SettingOuter>
    {children}
  </SettingOuter>
}

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

const EDITABLE_STATUSES = [null, '', 'Creating', 'NEW'];
const UNLOCKABLE_STATUSES = [...EDITABLE_STATUSES, 'Pending', 'NEW'];
const COMPLETE_STATUSES = ['Awaiting Verification', 'Complete', 'NEW'];
const SCROLL_CONFIG: ScrollIntoViewOptions = { behavior: 'smooth' };

const UPDATE_TICKET = gql`
  mutation($id: String!, $updateTicket: UpdateTicketInput!) {
    updateTicket(id: $id, updateTicket: $updateTicket) {
      ${allTicketFields}
      steps {
        ${stepFields}
      }
    }
  }
`;

const ARCHIVE_TICKET = gql`
    mutation($id: String!) {
        archiveTicket(id: $id)
    }
`;

const RESEND_REPORT = gql`
    mutation($id: String!) {
        resendTicketReport(id: $id)
    }
`;

const MESSAGE_TECHS = gql`
    mutation($id: String!, $message: String!) {
        messageTicketTechs(id: $id, message: $message)
    }
`;

const getStatusDescription = (status: string) => {
  switch (status) {
    case 'Pending':
      return 'Technicians have been contacted with your confirmed ticket and notified of the requested date. If you wish to make changes, you will need to unlock this ticket again before any edits are made.';
    case 'In Progress':
      return 'Technicians are currently on site, ticket status will be updated when the journey has been completed.';
    case 'Awaiting Verification':
      return 'Ticket has been completed, please add in any final comments and documents before submitting to the customer.';
    case 'Complete':
      return 'Ticket has been fully finalized, please visit and/or share the below report link with your customer.';
    default:
      return 'Please finalize the steps for your ticket journey and mark as ready when you want to finalize the journey.';
  }
};

const getStatusMessage = (status: string) => {
  return 'Ticket details updated successfully!'
};

interface StepsProps {
  disabled?: boolean;
  children?: any;
}
const Steps = ({ disabled, children }: StepsProps) => {
  const steps = useActiveSteps();
  const [ticket] = useTicket();

  const updateOrder = useOrderSteps();

  const [handleOrder] = useMutation(ORDER_STEPS, {
    onCompleted: (result: any) => {
      // message.success({ content: 'Steps updated successfully', duration: 2 })
    },
  });

  // Order steps and update API
  const onDragEnd = useCallback(
    (result: any) => {
      const _steps = orderSteps(result, steps);
      if (!_steps?.length) return;
      updateOrder(_steps);
      const order = _steps.map((s: any) => s.id);
      handleOrder({ variables: { id: ticket?.id, order } });
    },
    [updateOrder, handleOrder, ticket, steps],
  );

  return (
    <StepsContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {/*<h4>Ticket Journey</h4>*/}
              <StepList steps={steps} disabled={disabled} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {children}
    </StepsContainer>
  );
};

interface DisabledStepsProps {
  disabled?: boolean;
}
const DisabledSteps = ({ disabled }: DisabledStepsProps) => {
  const steps = useInactiveSteps();

  if (!steps) return <>No disabled steps yet...</>;

  return (
    <StepsContainer style={{ marginTop: '5px' }}>
      {/*<h4>Disabled Steps</h4>*/}
      <StepList steps={steps} disabledMode disabled={disabled} />
    </StepsContainer>
  );
};

interface BtnProps {
  confirm?: string;
  loading?: boolean;
  onClick?: (e?: any) => void;
}

const Btn: React.FC<BtnProps> = ({ confirm, loading, onClick, children }) => {
  if (loading) return <Button type={'text'} danger disabled><LoadingOutlined spin /></Button>;

  if (confirm) return (
    <Popconfirm
      title={confirm}
      onConfirm={onClick}
    >
      <Button type={'text'} danger>{children}</Button>
    </Popconfirm>
  )

  return <Button type={'text'} danger onClick={onClick}>{children}</Button>;
}

interface EnterButtonProps {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}
const EnterButton = ({ onClick, disabled, loading }: EnterButtonProps) => {
  if (loading) return <LoadingOutlined />;
  if (disabled) return <PlusOutlined />;
  return <PlusOutlined onClick={onClick} style={{ cursor: 'pointer' }} />;
};

// Parent component for ticket steps that handles most of state and hooks
// Do not load this component without a loaded ticket
export const TicketSteps = () => {
  const [ticket, _, updateTicket] = useTicket();

  const history = useHistory();

  useSteps(ticket?.id || '');

  const [status, setStatus] = useState(ticket?.status || 'Creating');
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [messageToTechs, setMessage] = useState('');


  const statusDescription = useMemo(() => getStatusDescription(status), [status]);

  const titleChanged = useCallback((e?: any) => setTitle(e.target.value), [setTitle]);
  const messageChanged = useCallback((e?: any) => setMessage(e.target.value), [setMessage]);

  const [handleAdd, { loading: loadingStep }] = useMutation(ADD_STEP, { onCompleted: () => setTitle('') });
  const [handleUpload, { loading }] = useMutation(UPDATE_TICKET);
  const [handleArchive, { loading: archiving }] = useMutation(ARCHIVE_TICKET, { variables: { id: ticket?.id }});
  const [handleResend, { loading: resending }] = useMutation(RESEND_REPORT);
  const [handleMessage, { loading: messaging }] = useMutation(MESSAGE_TECHS);

  const disabled = useMemo(() => loading, [loading]);

  const handleArchiveClick = useCallback(async (e?: any) => {
    try {
      const result = await handleArchive();
      if (result) {
        message.success({ content: `Ticket has been archived`, duration: 2 });
        history.push('/ticket/list')
      }
    } catch (e) {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to archive ticket'), duration: 4 });
    }
  }, [handleArchive, history]);
  const handleMessageClick = useCallback((e?: any) => { setVisible(true) }, [setVisible]);
  const handleClose = useCallback(() => {
    setVisible(false);
    setMessage('');
  }, [setVisible, setMessage]);
  const handleSendMessage = useCallback(async (e?: any) => {
    try {
      const result = await handleMessage({
        variables: {
          id: ticket?.id,
          message: messageToTechs
        }
      });
      if (result) {
        message.success({ content: `Technicians messaged successfully `, duration: 2 });
        handleClose();
      }
    } catch (e) {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to message technicians'), duration: 4 });
    }
  }, [handleMessage, messageToTechs, handleClose, ticket]);
  const handleReportClick = useCallback(async (e?: any) => {
    try {
      const result = await handleResend({
        variables: {
          id: ticket?.id
        }
      });
      if (result) {
        message.success({ content: `Report re-sent successfully `, duration: 2 });
      }
    } catch (e) {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to re-send report'), duration: 4 });
    }
  }, [handleResend, ticket]);
  const handleMarkIncomplete = useCallback(async (e?: any) => {
    try {
      const result = await handleUpload({
        variables: {
          id: ticket?.id,
          updateTicket: { inProgress: true, status: TICKET_STATUS.IN_PROGRESS}
        }
      });
      if (result?.data) {
        const _status = result?.data?.updateTicket?.status;
        message.success({ content: `Ticket has been re-opened `, duration: 2 });
        updateTicket({ ...ticket, status: _status });
      }
    } catch (e) {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to re-open ticket'), duration: 4 });
    }
  }, [handleUpload]);

  const handleAddClick = useCallback(
    async (e?: any) => {
      if (!title || title === '') return;
      const newStep = {
        title,
        assignedTicketId: ticket?.id,
      };
      await handleAdd({ variables: { newStep } });
    },
    [handleAdd, title],
  );

  const { fields, initialValues, ...formikCTX } = useFormData(ticketConfirmSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const lock = ['Awaiting Verification', 'Complete'].includes(status)
        ? undefined
        : EDITABLE_STATUSES.includes(status);
      const done = status === 'Awaiting Verification' ? true : status === 'Complete' ? false : undefined;
      const { dueDate, description, completionComment } = values;
      return handleUpload({
        variables: {
          id: ticket?.id,
          updateTicket: { dueDate, description, completionComment, lock, done },
        },
      });
    },
    onCompleted: (result: any) => {
      const _status = result?.data?.updateTicket?.status;
      message.success({ content: getStatusMessage(_status), duration: 2 });
      updateTicket({ ...ticket, status: _status || 'NEW' });
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to confirm ticket'), duration: 4 });
    },
    override: 'ticket',
  });

  const isUnlockable = useMemo(() => UNLOCKABLE_STATUSES.includes(status), [status]);
  const isCompletable = useMemo(() => COMPLETE_STATUSES.includes(status), [status]);
  const isComplete = useMemo(() => status === 'Complete', [status]);
  const isInProgress = useMemo(() => status === 'IN_PROGRESS', [status]);

  const defaultActiveKeys = useMemo(() => (isCompletable ? ['finalize'] : ['journey', 'confirm']), [isCompletable]);

  const handleScroll = useCallback(() => {
    const el = document.getElementById(isCompletable ? 'finalize-ticket' : 'confirm-ticket');
    if (el) el.scrollIntoView(SCROLL_CONFIG);
  }, [isCompletable]);

  const link = useMemo(() => `https://oversite.cloud/ticket/report/${ticket.shortid}`, [ticket.shortid]);

  useEffect(() => setStatus(ticket.status), [ticket.status]);

  if (!ticket) return null;

  return (
    <Formik {...formikCTX} initialValues={ticket} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values, dirty }) => (
        <>
          <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
              <TicketCard
                title={'Ticket Summary'}
                actions={[
                  // TODO: help link
                  <InfoCircleOutlined key='help' />,
                  <EditOutlined key='edit' onClick={handleScroll} />,
                ]}
              >
                <div>
                  <b>Status: </b>
                  {status}
                </div>
                <div><i>{statusDescription}</i></div>
                {isComplete && (
                  <div>
                    <b>Report:</b>{' '}
                    <Link href={link} target={'_blank'} copyable={{ text: link }}>
                      {ticket.shortid}
                    </Link>
                  </div>
                )}
              </TicketCard>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={18}>
              <TicketCard
                title={'Ticket Settings'}
              >
                {/* Disabled unless we decide to allow moving tickets back to pending */}
                {/*<Setting hidden={!isInProgress}>*/}
                {/*  <SettingTitle>Revert Ticket:</SettingTitle>*/}
                {/*  <Btn loading={loading} confirm={'Are you sure you want to move this ticket back to pending?'} onClick={handleMarkPending}>*/}
                {/*    Mark as Pending*/}
                {/*  </Btn>*/}
                {/*</Setting>*/}
                <Map fullAddress={ticket?.address?.fullAddress} />
                <GreyBox>
                  <Setting hidden={isInProgress}>
                    <SettingTitle>Re-open Ticket:</SettingTitle>
                    <Btn loading={loading} confirm={'Are you sure you want to re-open this ticket?'} onClick={handleMarkIncomplete}>
                      Mark Incomplete
                    </Btn>
                  </Setting>
                  <Setting hidden={!isComplete}>
                    <SettingTitle>Re-send Report:</SettingTitle>
                    <Btn onClick={handleReportClick} confirm={'Are you sure you want to re-send the ticket report to your client?'} loading={resending}>
                      <MailOutlined />
                    </Btn>
                  </Setting>
                  <Setting hidden={!isUnlockable}>
                    <SettingTitle>Archive Ticket:</SettingTitle>
                    <Btn loading={archiving} confirm={'Are you sure you want to archive this ticket?'} onClick={handleArchiveClick}>
                      <DeleteOutlined />
                    </Btn>
                  </Setting>
                  <Setting hidden={!isInProgress}>
                    <SettingTitle>Message Technicians:</SettingTitle>
                    <Btn onClick={handleMessageClick} loading={messaging}>
                      <MailOutlined />
                    </Btn>
                  </Setting>
                </GreyBox>
                <Modal
                  title="Message Technicians"
                  visible={visible}
                  onOk={handleSendMessage}
                  confirmLoading={messaging}
                  onCancel={handleClose}
                >
                  <AntInput placeholder="Enter message..." value={messageToTechs} onChange={messageChanged} />
                </Modal>
              </TicketCard>
            </Col>
          </Row>
          <br />
          <Collapse defaultActiveKey={defaultActiveKeys}>
            <CPanel header='Ticket Journey' key='journey'>
              <Steps disabled={disabled}>
                <AntInput
                  placeholder='Add new step'
                  allowClear
                  size={'large'}
                  addonAfter={<EnterButton onClick={handleAddClick} loading={loadingStep} disabled={disabled} />}
                  disabled={loadingStep || disabled}
                  onChange={titleChanged}
                  value={title}
                  onPressEnter={handleAddClick}
                />
              </Steps>
            </CPanel>
            <CPanel header='Disabled Steps' key='disabled-steps'>
              <DisabledSteps disabled={disabled} />
            </CPanel>
            <CPanel header='Confirmation' key='confirm' id={'confirm-ticket'}>
              <Form layout='vertical'>
                <Card style={{ width: '100%' }}>
                  <FormRow>
                    <DatePicker
                      format='DD/MM/YYYY HH:mm'
                      {...fields.dueDate}
                      showTime={{ format: 'HH:mm' }}
                    />
                    <TextArea gridProps={GRID_PROPS} {...fields.description} rows={7} />
                    <FormButton loading={loading} type={'submit'}>
                      Save
                    </FormButton>
                  </FormRow>
                </Card>
              </Form>
            </CPanel>
            {/*TODO: finish finalization section*/}
            {isCompletable && (
              <CPanel header='Finalize' key='finalize' id={'finalize-ticket'}>
                <Form layout='vertical'>
                  <Card style={{ width: '100%' }}>
                    <FormRow>
                      <TextArea
                        gridProps={GRID_PROPS}
                        {...fields.completionComment}
                        rows={7}
                      />
                      <FormButton loading={loading} type={'submit'} ghost={isComplete}>
                            Save
                      </FormButton>
                    </FormRow>
                  </Card>
                </Form>
              </CPanel>
            )}
          </Collapse>
        </>
      )}
    </Formik>
  );
};

export default TicketSteps;
