import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Link } from '../../components/linking/linker';

const CHECK_LINK = gql`
  query($link: LinkCheckInput!) {
    linkCheck(link: $link) {
      error
      action
      link {
        id
      }
    }
  }
`;

type hookProps = {
  link: Link;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useCheckLink = ({ onCompleted, onError, link }: hookProps) => {
  const [checkLink, ctx] = useLazyQuery(CHECK_LINK, {
    variables: { link },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    checkLink();
  }, []);

  return [checkLink, ctx] as const;
};
