import React, { useMemo } from 'react'
import { RedoOutlined as Refresh } from '@ant-design/icons'
import { PageAction } from '../Page'

interface RefreshProps {
    onClick: (e?: Event) => void,
    hidden?: boolean,
    disabled?: boolean,
    tooltip?: string
}

export const RefreshAction: React.FC<RefreshProps> = ({ tooltip, hidden, disabled, onClick, ...props }) => {

    return (
        <PageAction
            tooltip={ tooltip ?? 'Refresh'}
            onClick={onClick}
            hidden={hidden}
            disabled={disabled}
        >
            { <Refresh /> }
        </PageAction>
    )
}