import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, AuthGuard, FormLoading, FileUploader } from '../../components';
import { useFetchUser } from '../../graphql/hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { mapArrayToObjects } from '../../helpers';
import { useSelector } from 'react-redux';
import { getCurrentUser, getIsAdmin } from '../../selectors';
import { Form as AntForm } from 'formik-antd';
import { Input, ContactNumber, Select, FormRow, DatePicker, Password, CheckBox } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs, Tooltip } from 'antd';
import PicPlaceholder from '../../assets/default_profile.png';
import { message } from 'antd'
import { useFormData } from '../../hooks'
import { capitalize } from '../../helpers/string-transforms';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd'
import { COUNTRIES, SA_PROVINCES, USER_TYPES } from '../../constants';
import { allUserDataSingle } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';
import { userSchema } from './schema';
import { LinkedOrganisations } from './components';

const countries = mapArrayToObjects(COUNTRIES);
const provinces = mapArrayToObjects(SA_PROVINCES);

const { Panel } = Collapser;
const { TabPane } = AntTabs;

const Collapse = styled(Collapser)`
  width: 100%;
`;
const Tabs = styled(AntTabs)`
  width: 100%;
`;

const finalSchema = userSchema.shape({
  password: Yup.string().label('Password')
});

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $user: UpdateUserInput!) {
    adminUpdateUser(id: $id, user: $user) {
      ${allUserDataSingle}
    }
  }
`;

interface Props {
  mode: 'view' | 'edit';
}

export const User: React.FC<Props> = ({ mode }) => {
  const { shortid } = useParams<{ shortid: string }>();
  const isAdmin = useSelector(getIsAdmin);

  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchUser, { loading }] = useFetchUser({
    shortid,
    onCompleted: (result: any) => {
      setUser(result.userByShortid);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const [handleUpload] = useMutation(UPLOAD);

  const { fields, initialValues, ...formikCtx } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        const { id, contactNumberFull, activeOrgId, activeOrg, linkedOrganisations, documents, ...rest } = values;
        return handleUpload({
          variables: {
            id,
            user: {
              ...rest,
            },
          },
        });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'User updated successfully', duration: 2 })
      setUser({ ...result?.data?.adminUpdateUser })
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update user'), duration: 4 });
    },
    override: 'user'
  })

  const [user, setUser] = useState({ ...initialValues });

  const { id: currentUserId } = useSelector(getCurrentUser);

  const isCurrentUser = useMemo(() => user?.id === currentUserId, [user, currentUserId]);

  if (loading) return <FormLoading />

  return (
    <AuthGuard needsActiveOrganisation>

    <Formik {...formikCtx } initialValues={user} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values, dirty }) => (
        <Page
          card
          title={`${capitalize(mode)} User`}
          onBack={() => history.goBack()}
          extra={[
            <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
            <RefreshAction onClick={() => fetchUser()} />,
            <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
          ]}
        >
          <Form layout='vertical'>

            <Tabs defaultActiveKey='info' tabPosition='top' size='large'>
              <TabPane tab='Info' key='info'>
                <Collapse defaultActiveKey='information' ghost>
                  <Panel header='User Information' key='information'>
                    <ProfilePictureUploader
                      disabled={!edit || isSubmitting}
                      fieldKey='profilePicUrl'
                      defaultPic={PicPlaceholder}
                    />

                    <FormRow disabled={!edit}>
                      <Input {...fields.firstname} />
                      <Input {...fields.lastname} />
                      <ContactNumber numberField={fields.contactNumber} countryCodeField={fields.contactNumberCountryCode} />
                      <Input disabled={isCurrentUser} {...fields.email} />
                    </FormRow>
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow disabled={!edit}>
                      <Input {...fields.address.addressLine1} />
                      <Select options={countries} {...fields.address.country} allowClear />
                      <Select options={provinces} {...fields.address.state} allowClear />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>

                  <Panel header='Security' key='security'>
                    <FormRow disabled={!edit}>
                      <Password {...fields.password} disabled={isCurrentUser} generateButton />
                      <Select options={USER_TYPES} {...fields.role} disabled={!isAdmin} />
                      <CheckBox {...fields.isTechnician}>Technician</CheckBox>
                    </FormRow>
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab='Organsations' key='orgs'>
                <LinkedOrganisations user={user} />
              </TabPane>
              <TabPane tab={'Documents'} key={'Documents'}>
                <FileUploader id={user.id} parentType={'user'} fileType={'DOCUMENT'} initialFiles={user.documents} />
              </TabPane>
            </Tabs>
          </Form>
        </Page>
      )}
    </Formik>

    </AuthGuard>
  );
};
