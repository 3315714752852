import React from 'react';
import {
  BlockOutlined as Dash,
  UserOutlined as Person,
  UserAddOutlined as PersonAdd,
  UsergroupAddOutlined,
  ContactsOutlined as ImportContacts,
  TeamOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  SmileOutlined,
  UserOutlined,
  UserAddOutlined,
  AuditOutlined,
  ImportOutlined,
  SolutionOutlined,
  DatabaseOutlined,
  BarsOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  GatewayOutlined,
  UngroupOutlined,
  NodeIndexOutlined,
  FormOutlined,
  CheckSquareOutlined,
  SnippetsOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';

export const paths = [
  { name: 'Dashboard', path: '/', key: 'dashboard', icon: <Dash /> },
  {
    name: 'Staff',
    path: '/staff',
    key: 'staff',
    icon: <TeamOutlined />,
    subItems: [
      { name: 'View All', path: '/staff/list', key: 'list-staff', icon: <SolutionOutlined /> },
      { name: 'Add Staff', path: '/staff/add', key: 'add-staff', icon: <UsergroupAddOutlined /> },
      { name: 'Import Staff', path: '/staff/import', key: 'import-staff', icon: <ImportOutlined /> },
    ],
  },
  {
    name: 'Assets',
    path: '/assets',
    key: 'assets',
    icon: <AppstoreOutlined />,
    subItems: [
      { name: 'View All', path: '/asset/list', key: 'list-assets', icon: <BarsOutlined /> },
      { name: 'Add Asset', path: '/asset/add', key: 'add-assets', icon: <AppstoreAddOutlined /> },
      { name: 'Import Assets', path: '/asset/import', key: 'import-assets', icon: <ImportOutlined /> },
    ],
  },
  {
    name: 'Organisations',
    path: '/organisation',
    key: 'organisation',
    icon: <AuditOutlined />,
    subItems: [
      { name: 'View All', path: '/organisation/list', key: 'list-organisation', icon: <DatabaseOutlined /> },
      { name: 'Add Organisation', path: '/organisation/add', key: 'add-organisation', icon: <ImportContacts /> },
      {
        name: 'Import Organisations',
        path: '/organisation/import',
        key: 'import-organisations',
        icon: <ImportOutlined />,
      },
    ],
  },
  {
    name: 'Sites',
    path: '/site',
    key: 'site',
    icon: <GatewayOutlined />,
    subItems: [
      { name: 'View All', path: '/site/list', key: 'list-site', icon: <NodeIndexOutlined /> },
      { name: 'Add Site', path: '/site/add', key: 'add-site', icon: <UngroupOutlined /> },
      { name: 'Import Sites', path: '/site/import', key: 'import-sites', icon: <ImportOutlined /> },
    ],
  },
  {
    name: 'Tickets',
    path: '/ticket',
    key: 'hey',
    icon: <FormOutlined />,
    subItems: [
      { name: 'View All', path: '/ticket/list', key: 'list-ticket', icon: <SnippetsOutlined /> },
      { name: 'Add Ticket', path: '/ticket/add', key: 'add-ticket', icon: <CheckSquareOutlined /> },
      { name: 'Instructions', path: '/ticketInstructions', key: 'ticket-instructions', icon: <OrderedListOutlined /> },
    ],
  },

  {
    name: 'Customers',
    path: '/customer',
    key: 'customer',
    icon: <UserOutlined />,
    subItems: [
      { name: 'View All', path: '/customer/list', key: 'list-customer', icon: <UserOutlined /> },
      { name: 'Add Customer', path: '/customer/add', key: 'add-customer', icon: <UserAddOutlined /> },
    ],
  },
  {
    name: 'Contacts',
    path: '/contact',
    key: 'contact',
    icon: <UserOutlined />,
    subItems: [
      { name: 'View Customer Contacts', path: '/contact/list', key: 'list-contact', icon: <UserOutlined /> },
      { name: 'View Site Contacts', path: '/contact/sitelist', key: 'list-contact', icon: <UserOutlined /> },
      { name: 'Add Contact', path: '/contact/add', key: 'add-contact', icon: <UserAddOutlined /> },
    ],
  },
  {
    name: 'Management',
    path: '/management',
    key: 'management',
    icon: <SmileOutlined />,
    subItems: [
      { name: 'View All', path: '/management/user-list', key: 'list-management', icon: <Person /> },
      { name: 'Add User', path: '/management/create-user', key: 'add-management', icon: <PersonAdd /> },
    ],
  },
];

export default paths;
