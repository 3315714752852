export const cleanError = (error: any, fallback?: string): string => {
  if (typeof error === 'string') {
    return error;
  } else if (error.graphQLErrors) {
    const _error = JSON.parse(JSON.stringify(error));
    if (_error?.message) {
      return noQL(_error.message.length > 0 ? _error.message : fallback);
    } else if (_error?.graphQLErrors?.length > 0) {
      return noQL(_error.graphQLErrors[0]?.message?.length > 0 ? _error.graphQLErrors[0].message : fallback);
    }
  } else {
    return error.message ? error.message : fallback;
  }
  return fallback + '';
}

const noQL = (error: string) => {
  return error.replace('GraphQL error: ', '')
}

cleanError.defaultProps = {
  fallback: 'An unknown error occurred. Please check your internet connection.'
}
