import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, Action as PageAction, FormLoading, AuthGuard } from '../../components';
import { useFetchStaff } from '../../graphql/hooks';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { ETHNICITIES, GENDERS, STAFF_DEPARTMENTS } from '../../constants';
import { mapArrayToObjects } from '../../helpers';
import { FileUploader } from '../../components/file-uploader'
import * as Yup from 'yup';
import { cloneDeep } from '@apollo/client/utilities';
import { ASSIGN_STAFF_TO_SITE, ASSIGN_ASSET_TO_STAFF } from '../../components/linking/linker/components';
import { useFormData } from '../../hooks'
import { Form as AntForm } from 'formik-antd';
import { Input, ContactNumber, Select, FormRow, FormButton } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs } from 'antd';
import PicPlaceholder from '../../assets/default_profile.png';
import { message } from 'antd'
import { LinkedSites, LinkedAssets } from '../../components/linked-items';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd'
import { allStaffFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser;
const { TabPane } = AntTabs;

const Tabs = styled(AntTabs)`
  width: 100%;
`;

const Collapse = styled(Collapser)`
  width: 100%;
`;

const staffSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  firstname: Yup.string()
    .required()
    .label('First Name(s)'),
  lastname: Yup.string()
    .required()
    .label('Last Name'),
  contactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  position: Yup.string()
    .nullable()
    .label('Position'),
  gender: Yup.string()
    .nullable()
    .label('Gender'),
  ethnicity: Yup.string()
    .nullable()
    .label('Ethnicity'),
  identificationPassport: Yup.string()
    .nullable()
    .label('Passport'),
  identificationIDNumber: Yup.string()
    .nullable()
    .label('ID Number'),
  mainSkill: Yup.string()
    .nullable()
    .label('Skill'),
  departments: Yup.array().label('Departments'),

  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .label('Province'),
      country: Yup.string()
        .nullable()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .label('Postal Code'),
    })
    .nullable(),
});

const { initialValues, schema, fields } = getFormData(staffSchema);

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateStaff: UpdateStaffInput!) {
    updateStaff(id: $id, updateStaff: $updateStaff) {
      ${allStaffFields}
    }
  }
`;

const ethnicities = mapArrayToObjects(ETHNICITIES);
const genders = mapArrayToObjects(GENDERS);
const departments = mapArrayToObjects(STAFF_DEPARTMENTS);

const Submit = styled(FormButton)`
  margin-top: 30px;
`;

interface Props {
  mode: 'view' | 'edit';
}

export const Staff: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const [staff, setStaff] = useState(initialValues);
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchStaff, { loading }] = useFetchStaff({
    id,
    onCompleted: (result: any) => {
      setStaff(result.staffSingle);
    },
  });

  const [handleUpload] = useMutation(UPLOAD);

  const { fields, ...formikCtx } = useFormData(schema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        console.log(values);
        const { id, documents, assignedAssets, assignedSite, contactNumberFull, ...rest } = values;
        return handleUpload({
          variables: {
            id,
            updateStaff: {
              ...rest,
            },
          },
        });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Staff member updated successfully', duration: 2 })
      setStaff({ id, ...result?.data?.updateStaff })
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update staff member'), duration: 4 });
    },
    override: 'staff'
  })

  const assignedAssets = useMemo(() => (staff && staff.assignedAssets ? cloneDeep(staff.assignedAssets) : null), [
    staff,
  ]);

  const assignedSite = useMemo(() => (staff && staff.assignedSite ? [cloneDeep(staff.assignedSite)] : null), [staff]);

  if (loading) return <FormLoading />

  return (
    <AuthGuard needsActiveOrganisation>

    <Formik {...formikCtx} initialValues={staff} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values, dirty }) => (
        <Page
          card
          title={`${edit ? 'Edit' : 'View'} Staff`}
          onBack={() => history.goBack()}
          extra={[
            <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
            <RefreshAction onClick={() => fetchStaff()} />,
            <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
          ]}
        >
          <Form layout='vertical'>

            <Tabs defaultActiveKey='info' tabPosition='top' size='large'>
              <TabPane tab='Verification Data' key='info'>
                <Collapse defaultActiveKey='verification' ghost>
                  <Panel header='Verification Data' key='verification'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ProfilePictureUploader
                        disabled={!edit || isSubmitting}
                        fieldKey='profilePicUrl'
                        defaultPic={PicPlaceholder}
                      />
                    </Col>

                    <FormRow disabled={!edit}>
                      <Input {...fields.firstname} />
                      <Input {...fields.lastname} />
                      <ContactNumber numberField={fields.contactNumber} countryCodeField={fields.contactNumberCountryCode} />
                      <Input {...fields.position} />
                      <Select options={departments} mode={'multiple'} {...fields.departments} allowClear />
                    </FormRow>
                  </Panel>

                  <Panel header='Personal Data' key='personal'>
                    <FormRow disabled={!edit}>
                      <Select options={genders} {...fields.gender} allowClear />
                      <Select options={ethnicities} {...fields.ethnicity} allowClear />
                      <Input {...fields.identificationPassport} />
                      <Input {...fields.identificationIDNumber} />
                      <Input {...fields.contactEmail} />
                      <Input {...fields.mainSkill} />
                    </FormRow>
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow disabled={!edit}>
                      <Input {...fields.address.addressLine1} />
                      <Input {...fields.address.country} />
                      <Input {...fields.address.state} />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>
                </Collapse>
              </TabPane>

              <TabPane tab='Linked Assets' key='linked-assets'>
                <FormRow disabled={!edit}>
                  <LinkedAssets item={staff} linkedItem={null} data={assignedAssets} protocol={ASSIGN_ASSET_TO_STAFF} />
                </FormRow>
              </TabPane>

              <TabPane tab='Linked Sites' key='linked-sites'>
                <FormRow disabled={!edit}>
                  <LinkedSites item={null} linkedItem={staff} data={assignedSite} protocol={ASSIGN_STAFF_TO_SITE} />
                </FormRow>
              </TabPane>

              <TabPane tab='Documents' key='documents'>
                <FormRow disabled={!edit}>
                  <FileUploader
                    id={staff.id}
                    parentType='staff'
                    fileType='DOCUMENT'
                    initialFiles={staff.documents}
                  />
                </FormRow>
              </TabPane>
            </Tabs>

            <Submit type='submit'>SAVE</Submit>
          </Form>
        </Page>
      )}
    </Formik>

    </AuthGuard>
  );
};

export default Staff;
