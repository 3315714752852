import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { allOrganisationFields } from '../fragments';

const ORGANISATION = gql`
  query($id: String!) {
    organisation(id: $id) {
      ${allOrganisationFields}
    }
  }
`;

type hookProps = {
  id: string | undefined;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useFetchOrganisation = ({ onCompleted, onError, id }: hookProps) => {
  const [fetchOrganisation, ctx] = useLazyQuery(ORGANISATION, {
    variables: { id },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchOrganisation();
  }, []);

  return [fetchOrganisation, ctx] as const;
};
