export const PERMISSIONS = ['User', 'Organisation', 'Admin'];

export const USER_TYPES = [
  { id: 'Admin', name: 'Admin'},
  { id: 'Organisation', name: 'Org Admin'},
  { id: 'User', name: 'User'}
];

export const SA_PROVINCES = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'Northern Cape',
  'North West',
  'Western Cape',
];

export const COUNTRIES = ['South Africa'];

export const GENDERS = ['Male', 'Female', 'Other'];

export const ETHNICITIES = ['Unspecified', 'Asian', 'Black', 'Coloured', 'Indian', 'White', 'Other'];

export const AUTHORIZED_ROLES = ['Admin', 'Organisation'];
export const BIRTHDAY_ERROR = 'Please enter a valid date of birth';
export const PASSWORD_ERROR =
  'Password must be 8-20 characters and include an uppercase letter, lowercase letter and a number';
export const EMAIL_ERROR = 'Please enter a valid email'

export const STATUSES = ['Assigned', 'Unassigned', 'Transferred'];

export const SERVICE_STATUSES = [
  'Due for Service',
  'Service Booked',
  'In for Service',
  'Service Overdue',
  'Fit for Use',
];

export const STEP_STATUSES = [
  'Assessing',
  'Working',
  'Working but Defective',
  'Not Working',
  'Service Required',
  'Unit Replacement Required',
  'Parts Replacement Required',
];

export const CONDITIONS = ['New', 'Used', 'Damaged', 'Stolen/Lost', 'Due to Expire', 'Expired'];

export const CATEGORIES = [
  'CCTV',
  'IT Hardware',
  'IT Software',
  'Splicing Equipment',
  'Tools and Equipment',
  'Vehicle'
];

export enum TICKET_TYPE {
  MAINTENANCE = 'MAINTENANCE',
  SERVICE_CALLS = 'SERVICE_CALLS',
  SMALL_WORKS = 'SMALL_WORKS',
  PROJECTS = 'PROJECTS',
}

export enum TICKET_STATUS {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  HOLD = 'HOLD',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CLOSED = 'CLOSED',
}

export const REPEATE_INTERVAL = [
  'Monthly',
  'Quarterly',
  'Half yearly',
  'Annually'
]

export const REGIONS = [
  'Johannesburg',
  'Soweto',
  'Cape Town',
  'Bloemfontein',
  'Port Elizabeth',
  'East London',
  'Polokwane',
  'Nigel',
  'Newcastle',
]

export const ORGANISATION_DEPARTMENTS = [
  'Installations',
  'Projects',
  'Core',
]

export const STAFF_DEPARTMENTS = [
  'Installations',
  'Maintenance',
  'Environmental Maintenance',
  'Projects',
  'Core',
]

export const ORGANISATION_STATUSES = [
  'Gate 1 Pass',
  'Gate 1 Fail',
  'Gate 2 Pass',
  'Gate 2 Fail',
]

export const WORK_STATUSES = [
  'Active',
  'Inactive',
  'Waiting'
]
