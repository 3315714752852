import Moment from 'moment';

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const formatDate = (date?: Date | string) => {
  if (!date || date === '') return '';
  return Moment(date).format('DD/MM/YYYY');
}
