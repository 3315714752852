import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getDateFormatted, getDateFull, mapArrayToOptions } from '../../helpers';
import { Page, AuthGuard, Avatar } from '../../components';
import { getThumbnail } from '@necta-tech/s3';
import { cloneDeep } from '@apollo/client/utilities';
import { ETHNICITIES, GENDERS, STAFF_DEPARTMENTS } from '../../constants';
import { useFetchStaffList } from '../../graphql/hooks/use-fetch-staff-list';
import { TeamOutlined } from '@ant-design/icons';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';

const genders = mapArrayToOptions(GENDERS);
const ethnicities = mapArrayToOptions(ETHNICITIES);
const departments = mapArrayToOptions(STAFF_DEPARTMENTS);

export const StaffList: React.FC<any> = props => {
  const [staff, setStaff] = useState([]);

  const history = useHistory();

  const [fetchStaff, { loading }] = useFetchStaffList((result: any) => {
    if (result.staff) {
      setStaff(cloneDeep(result.staff));
    }
  });

  const columns = useOverrideColumns(
    [
      {
        title: 'Picture',
        key: 'profilePicUrl',
        render: (r: any) => <Avatar src={getThumbnail(r)} icon={<TeamOutlined />} />,
        export: false,
      },
      {
        title: 'First Name(s)',
        key: 'firstname',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Last Name',
        key: 'lastname',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Position',
        key: 'position',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Gender',
        key: 'gender',
        filtering: {
          filters: genders,
          filterMultiple: true,
          type: Filter.SELECT,
        },
        sorting: true,
        show: false,
      },
      {
        title: 'Ethnicity',
        key: 'ethnicity',
        filtering: {
          filters: ethnicities,
          filterMultiple: true,
          type: Filter.SELECT,
        },
        sorting: true,
      },
      {
        title: 'Departments',
        key: 'departments',
        filtering: {
          filters: departments,
          filterMultiple: true,
          type: Filter.SELECT,
        },
        render: (r: any) => r.join(', '),
        sorting: true,
      },
      {
        title: 'Main Skill',
        key: 'mainSkill',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Contact Number',
        key: 'contactNumberFull',
        filtering: true,
        show: false,
      },
      {
        title: 'Email Address',
        key: 'contactEmail',
        filtering: true,
        sorting: true,
        show: false,
      },
      {
        title: 'Date of Birth',
        key: 'dateOfBirth',
        show: false,
        sorting: { compare: Sort.DATE },
        render: (r: any) => (r ? getDateFormatted(r) : ''),
      },
      {
        title: 'ID Number',
        key: 'identificationIDNumber',
        show: false,
        filtering: true,
      },
      {
        title: 'Passport',
        key: 'identificationPassport',
        show: false,
        filtering: true,
      },
      {
        title: 'Shortid',
        key: 'shortid',
        show: false,
        filtering: true,
      },
      {
        title: 'Database ID',
        key: 'id',
        show: false,
      },
      {
        title: 'Created At',
        key: 'createdAt',
        sorting: { compare: Sort.DATE },
        show: false,
        render: (r: any) => (r ? getDateFull(r) : ''),
      },
    ],
    'staff',
  );

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page card>
        <Table
          data={staff}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/staff/edit/${record.id}`),
          })}
          refresh={fetchStaff}
          title={'Staff List'}
          exportTitle={'Staff'}
          pagination={{
            defaultPageSize: 20,
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default StaffList;
