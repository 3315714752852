export const orderSteps = (result: any, steps: any[]) => {
  if (!result.destination || result.destination.index === result.source.index) {
    return; //Abort early
  }

  return reorder(
    steps,
    result.source.index,
    result.destination.index
  ).map((s: any, i: number) => Object.assign({ ...s, priority: i }));
}

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
