import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { DropDownProps } from 'antd/lib/dropdown'
import { ButtonProps } from 'antd/lib/button'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined, LoadingOutlined, AppstoreOutlined } from '@ant-design/icons'
import { FieldContainer } from '../antd/FieldContainer'
import { isEmpty, get, compact } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities';
import useDebouncedEffect from 'use-debounced-effect-hook'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const ASSET_SEARCH = gql`
  query ASSET_SEARCH($keyword: String!, $organisationId: String) {
    assetSearch(keyword: $keyword, organisationId: $organisationId) {
      name
      manufacturer
      modelNumber
      registrationNumber
      status
      serviceStatus
      assignedTo {
        firstname
        lastname
      }
      id
      category
      tags
      shortid
    }
  }
`;

const { Option } = Select

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

/**
 * takes values and returns LoadedAsset keys in a single string
 * @param asset
 * @param labelKeys
 * @returns string
 */
const getValues = (asset: LoadedAsset, labelKeys: (keyof LoadedAsset)[]) => {
  return compact(labelKeys.map((l: keyof LoadedAsset) => get(asset, l, null))).join(' ');
}

interface LoadedAsset {
  id: string,
  name: string,
  manufacturer: string,
  modelNumber: string,
  registrationNumber: string,
  status: string,
  serviceStatus: string,
  assigneTo?: {
    firstname: string,
    lastname: string
  },
  category: string,
  tags: string[],
  shortid: string
}

interface AssetSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  //TODO: define these properly
  valueKey?: keyof LoadedAsset,
  labelKeys?: (keyof LoadedAsset)[],
  showPicture?: boolean;
  getOptions?: (assets: Partial<LoadedAsset>[]) => React.ReactNode[],
  getAsset?: (value: any) => void,
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  },
  defaultOptions?: LoadedAsset[],
  organisationId?: string,
}

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const AssetSelectorComponent: React.FC<AssetSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  {
    getFormik = true,
    getOptions,
    getAsset,
    labelKeys = ['name', 'registrationNumber'],
    valueKey = 'id',
    name = '',
    showPicture = true,
    onChange,
    disabled,
    label,
    required,
    hidden,
    gridProps,
    className,
    defaultValue,
    defaultOptions,
    organisationId,
    ...props
  }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')
  const [assets, setAssets] = useState<LoadedAsset[]>(defaultOptions || [])
  const [keyword, setKeyword] = useState<string>('')

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const [fetchAssets, { loading }] = useLazyQuery(ASSET_SEARCH, {
    variables: { keyword, organisationId },
    onCompleted: (result: any) => {
        setAssets(cloneDeep(result.assetSearch))
    }

  })

  const handleSearch = useCallback((_keyword: string) => setKeyword(_keyword), [setKeyword]);

  useDebouncedEffect(() => {
    if (keyword && keyword !== '') fetchAssets({ variables: { keyword, organisationId } });
  }, [ keyword, fetchAssets, setAssets, organisationId ], 500);

  const _getAsset = useCallback((value: any, key: string) => assets.find((u: LoadedAsset) => get(u, key) === value), [assets]);

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value);
    if (onChange) onChange(getAsset ? getAsset(value) : _getAsset(value, valueKey));
    setSelected(value);
    setKeyword('');
  }, [name, setSelected, formik, isFormik, onChange, getAsset, valueKey, _getAsset, setKeyword]);

  /**
   * Select Options constructor. Builds an array of options children from fetched asset list.
   */
  const _getOptions = useCallback(() => assets.map((asset: any) => (
    <Option key={asset.id} value={asset.id} label={getValues(asset, labelKeys)}>
      {showPicture && <Icon src={getThumbnail(asset.profilePicUrl)} size="small" icon={<AppstoreOutlined />} />}
      {getValues(asset, labelKeys)}
    </Option>
  )), [assets])

  const selectOptions = useMemo(() => assets.length > 0 && (getOptions ? getOptions(assets) : _getOptions()), [assets, getOptions]);

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
      editable={false}
    >
      <FormikSelect
        prefix={<AppstoreOutlined className="site-form-item-icon" />}
        name={name}
        onSearch={handleSearch}
        onChange={handleOnChange}
        showSearch
        placeholder={'Search assets...'}
        disabled={disabled || formik.isSubmitting}
        loading={loading}
        filterOption={false}
        {...props}
      >
        {selectOptions}
      </FormikSelect>
    </FieldContainer>
  )

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<AppstoreOutlined className='site-form-item-icon' />}
      placeholder={'Search assets...'}
      loading={loading}
      className={`${className} ant-asset-selector`}
      onSearch={handleSearch}
      onSelect={handleOnChange}
      showSearch
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {selectOptions}
    </Select>
  )
}

export const AssetSelector = styled(AssetSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
