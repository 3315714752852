import React from 'react'
import styled from 'styled-components'
import { Form as AntForm } from 'formik-antd'

export const Card = styled(AntForm)`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 1px 1px 5px gray;
    transition: width 0.5s ease;
    border-radius: 2px;
    width: 95%;
    padding: 20px 0;
`

export const FormCard: React.FC<any> = ({ width, handleSubmit, children, ...props }) => {
    return <Card style={props.style} className={props.className} layout='vertical'>
        { children }
    </Card>
}
