import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { getDateFull } from '../../helpers';
import { Page, AuthGuard } from '../../components/Page';
import { getThumbnail } from '@necta-tech/s3';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar } from '../../components';
import { GatewayOutlined } from '@ant-design/icons';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';
import { allSiteFields } from '../../graphql/fragments';
import { message } from 'antd';
import { cleanError } from '../../helpers/error-helper';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const QUERY = gql`
  query($siteQuery: SiteQuery!) {
    sites(queryOptions: { relations: ["organisation"] }, siteQuery: $siteQuery) {
      ${allSiteFields}
      createdAt
    }
  }
`;

export const SiteList: React.FC<any> = props => {
  const [sites, setSites] = useState([]);

  const history = useHistory();
  const [currentOrg] = useCurrentOrganisation();

  const [fetchSites, { loading }] = useLazyQuery(QUERY, {
    variables: { siteQuery: { organisationId: currentOrg?.id } },
    onCompleted: (result: any) => {
      if (result && result.sites) setSites(cloneDeep(result.sites));
    },
    onError: (error: any) => {
      console.log(JSON.stringify(error, null, 2));
      message.error({ content: cleanError(error, 'Unable to fetch sites'), duration: 4 });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchSites();
  }, []);

  const columns: any[] = useOverrideColumns([
    {
      title: 'Picture',
      key: 'primaryImage',
      render: (r: any) => <Avatar src={getThumbnail(r)} icon={<GatewayOutlined />} />,
      export: false,
    },
    {
      title: 'Site Name',
      key: 'name',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Contact Name',
      key: 'primaryContactName',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Contact Number',
      key: 'primaryContactNumberFull',
      filtering: true,
      show: false,
    },
    {
      title: 'Email Address',
      key: 'primaryContactEmail',
      filtering: true,
      show: false,
    },
    {
      title: 'Tags',
      key: 'tags',
      filtering: true,
      sorting: true,
      show: false,
      render: (r: any) => r.join(', '),
    },
    {
      title: 'Organisation',
      key: 'organisation.name',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Database ID',
      key: 'id',
      show: false,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      sorting: { compare: Sort.DATE },
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
  ], 'site');

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page
        card
      >
        <Table
          data={sites}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/site/edit/${record.id}`)
          })}
          refresh={fetchSites}
          title={'Site List'}
          exportTitle={'Site'}
          pagination={{
            defaultPageSize: 20
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default SiteList;
