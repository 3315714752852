import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { addressFields, allUserDataSingle, userFields } from '../fragments';

const USER = gql`
  query USER($shortid: String!) {
    userByShortid(shortid: $shortid) {
      ${allUserDataSingle}
    }
  }
`;

type hookProps = {
  shortid: string | undefined;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useFetchUser = ({ onCompleted, onError, shortid }: hookProps) => {
  const [fetchUser, ctx] = useLazyQuery(USER, {
    variables: { shortid },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchUser();
  }, []);

  return [fetchUser, ctx] as const;
};
