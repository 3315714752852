import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { allStaffFields, documentFields, siteFields } from '../fragments';

const STAFF = gql`
  query($id: String!) {
    staffSingle(id: $id) {
      ${allStaffFields}
    }
  }
`;

type hookProps = {
  id: string | undefined;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useFetchStaff = ({ onCompleted, onError, id }: hookProps) => {
  const [fetchStaff, ctx] = useLazyQuery(STAFF, {
    variables: { id },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchStaff();
  }, []);

  return [fetchStaff, ctx] as const;
};
