import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery, } from '@apollo/client';
import gql from 'graphql-tag';
import { useHistory } from 'react-router';
import { getIsAdmin } from '../../selectors';
import { getDateFull } from '../../helpers';
import { getThumbnail } from '@necta-tech/s3';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar } from '../../components';
import { UserOutlined } from '@ant-design/icons';
import { AuthGuard, Page } from '../../components'
import Table, { Filter, Sort } from '../../components/antd/table';
import { useOverrideColumns } from '../../hooks';
import { USER_TYPES } from '../../constants';
import { userFields } from '../../graphql/fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const ALL_USERS_QUERY = gql`
  query USER_QUERY ($organisationId: String!) {
    usersByOrganisation(organisationId: $organisationId) {
      ${userFields}
      createdAt
    }
  }
`;

const useFetchUserList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchUsers, ctx] = useLazyQuery(ALL_USERS_QUERY, {
    variables: { organisationId: currentOrg?.id },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return [fetchUsers, ctx] as const;
};

export const UserList: React.FC<any> = () => {
  const [users, setUsers] = useState([]);
  const isAdmin = useSelector(getIsAdmin);

  const history = useHistory();

  const [fetchUsers, { loading }] = useFetchUserList((result: any) => {
    console.log(result);
    if (result.usersByOrganisation) {
      setUsers(cloneDeep(result.usersByOrganisation));
    }
    // setUsers(result.users)
  });

  const userChanged = useCallback(
    (user: any) => {
      const found = users.find((u: any) => u.id === user.id) as any;
      if (found) found.role = user.role;
    },
    [users],
  );

  const columns = useOverrideColumns([
    {
      title: 'Picture',
      key: 'profilePicUrl',
      render: (r: any) => (
        <Avatar src={getThumbnail(r)} icon={<UserOutlined />} />
      ),
      export: false,
    },
    {
      title: 'First Name',
      key: 'firstname',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Surname',
      key: 'lastname',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Email',
      key: 'email',
      filtering: true,
    },
    {
      title: 'Cell',
      key: 'contactNumberFull',
      filtering: true,
    },
    {
      title: 'Role',
      key: 'role',
      getValues: (r: any) => USER_TYPES.find((u: any) => u.id === r)?.name,
      render: (r: any) => USER_TYPES.find((u: any) => u.id === r)?.name,
      sorting: true,
      filtering: true,
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Database ID',
      key: 'id',
      show: false,
    },
    {
      title: 'Signed Up',
      key: 'createdAt',
      sorting: { compare: Sort.DATE },
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
  ], 'user');

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>

    <Page
      card
    >
      <Table
        data={users}
        columns={columns}
        loading={loading}
        onRow={(record: any, rowIndex: any) => ({
          onClick: () => history.push(`/management/user-edit/${record.shortid}`)
        })}
        refresh={fetchUsers}
        title={'User List'}
        exportTitle={'User'}
        pagination={{
          defaultPageSize: 20
        }}
        rowClassName={'clickable'}
      />
    </Page>

    </AuthGuard>
  );
};

export default UserList;
