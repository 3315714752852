import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash';
import { Ticket, Step, StepState } from '../types';

// Actions
export const Actions = {
    setStep: (step: Step) => {
        return { type: 'SET_STEP', step }
    },
    addStep: (step: Step) => {
        return { type: 'ADD_STEP', step }
    },
    removeStep: (step: Step) => {
        return { type: 'REMOVE_STEP', step }
    },
    moveStep: (step: Step) => {
        return { type: 'MOVE_STEP', step }
    },
    orderSteps: (steps: Step[]) => {
        return { type: 'ORDER_STEPS', steps }
    },
    loadTicket: (ticket: Ticket) => {
        return { type: 'LOAD_TICKET', ticket }
    },
    updateTicket: (ticket: Ticket) => {
        return { type: 'UPDATE_TICKET', ticket }
    },
}

// Selectors
const getActiveSteps = (state: any) => get(state, 'steps.activeSteps');
const getInactiveSteps = (state: any) => get(state, 'steps.inactiveSteps');
const getTicket = (state: any) => get(state, 'steps.ticket');

// Hooks
export const useActiveSteps = () => {
    return useSelector(getActiveSteps);
}

export const useInactiveSteps = () => {
    return useSelector(getInactiveSteps);
}

export const useSetStep = () => {
    const dispatch = useDispatch()
    return useCallback((step: Step) => dispatch(Actions.setStep(step)), [dispatch])
}

export const useAddStep = () => {
    const dispatch = useDispatch()
    return useCallback((step: Step) => dispatch(Actions.addStep(step)), [dispatch])
}

export const useRemoveStep = () => {
    const dispatch = useDispatch()
    return useCallback((step: Step) => dispatch(Actions.removeStep(step)), [dispatch])
}

export const useMoveStep = () => {
    const dispatch = useDispatch()
    return useCallback((step: Step) => dispatch(Actions.moveStep(step)), [dispatch])
}

export const useOrderSteps = () => {
    const dispatch = useDispatch()
    return useCallback((steps: Step[]) => dispatch(Actions.orderSteps(steps)), [dispatch])
}

export const useLoadTicket = () => {
    const dispatch = useDispatch()
    return useCallback((ticket: Ticket) => dispatch(Actions.loadTicket(ticket)), [dispatch])
}

export const useUpdateTicket = () => {
    const dispatch = useDispatch()
    return useCallback((ticket: Ticket) => dispatch(Actions.updateTicket(ticket)), [dispatch])
}

export const useTicket = () => {
    const ticket = useSelector(getTicket);
    const load = useLoadTicket();
    const update = useUpdateTicket();
    return [ ticket, load, update ] as const;
}

export const useUpdateSteps = () => {
    const add = useAddStep();
    const remove = useRemoveStep();
    const move = useMoveStep();
    const set = useSetStep();
    return [ add, remove, move, set ] as const;
}

// Helper / mapping functions
const setStep = (step: Step, state: StepState) => {
    const key = step.disabled ? 'inactiveSteps' : 'activeSteps';
    const _steps = state[key].map((s: Step) => s.id === step.id ? Object.assign(step) : s);
    return { ...state, [key]: _steps };
}

const addStep = (step: Step, state: StepState)  => {
    const key = step.disabled ? 'inactiveSteps' : 'activeSteps';
    const _steps = [...state[key], step];
    return { ...state, [key]: _steps };
}

const removeStep = (step: Step, state: StepState)  => {
    const key = step.disabled ? 'inactiveSteps' : 'activeSteps';
    const _steps = state[key].filter((s: Step) => s.id !== step.id);
    return { ...state, [key]: _steps };
}

const moveStep = (step: Step, state: StepState)  => {
    const keyFrom = step.disabled ? 'activeSteps' : 'inactiveSteps';
    const keyTo = step.disabled ? 'inactiveSteps' : 'activeSteps';
    const _steps = state[keyFrom].filter((s: Step) => s.id !== step.id);
    const __steps = [ ...state[keyTo], { ...step, disabled: !step.disabled }];
    return { ...state, [keyFrom]: _steps, [keyTo]: __steps };
}

const loadTicket = (ticket: Ticket)  => {
    const { steps, ..._ticket } = ticket;
    const activeSteps = steps?.filter((s: Step) => !s.disabled);
    const inactiveSteps = steps?.filter((s: Step) => s.disabled);
    return { ticket: _ticket, activeSteps, inactiveSteps };
}

// Reducer
const initialState: StepState = {
    activeSteps: [],
    inactiveSteps: []
}

export const steps = (state: StepState = initialState, action: any) => {
    switch (action.type) {
        case 'SET_STEP':
            return { ...setStep(action.step, state) };
        case 'ADD_STEP':
            return { ...addStep(action.step, state) };
        case 'REMOVE_STEP':
            return { ...removeStep(action.step, state) };
        case 'MOVE_STEP':
            return { ...moveStep(action.step, state) };
        case 'ORDER_STEPS':
            return { ...state, activeSteps: action.steps };
        case 'LOAD_TICKET':
            return { ...loadTicket(action.ticket) };
        case 'UPDATE_TICKET':
            const { ticket } = action;
            const { steps, ..._ticket } = ticket;
            return { ...state, ticket: _ticket };
        default:
            return state;
    }
}
