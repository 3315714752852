import React, { useMemo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd';
import Select, { SelectProps } from 'antd/lib/select';
import { ColProps } from 'antd/lib/grid';
import { useFormikContext } from 'formik';
import { FieldContainer } from '../antd/FieldContainer';
import { get, isEmpty } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LoadedCustomer } from './types';
import { useCurrentOrganisation } from '../../components/organisation-selector';



const CUSTOMER_SELECT_QUERY = gql`
  query($customerQuery: CustomerQuery!) {
    customers(customerQuery: $customerQuery) {
      id
      email
      companyName
      vatNum
      regNum
      contactName
      contactNumber
      contactNumberCountryCode
      shortid
      organisationId
      address {
        addressLine1
        country
        state
        postalCode
      }
      headquarters 
      createdAt
    }
  }
`;

type Customer = any;

const { Option } = Select;

interface CustomerSelectorProps {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  gridProps?: ColProps;
  defaultValue?: any;
  name?: string;
  onChange?: (value: any) => void;
  //TODO: define these properly
  valueKey?: keyof Customer;
  labelKey?: keyof Customer;
  getOptions?: (orgs: Partial<Customer>[]) => React.ReactNode[];
  //TODO: update this to a more sophisticated prop
  getFormik?: boolean;
  value?: string;
}

export type CustomerSelectorComponentProps = CustomerSelectorProps & (FormikSelectProps | SelectProps<string>);

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const CustomerSelectorComponent: React.FC<CustomerSelectorComponentProps> = ({
  value,
  getFormik,
  getOptions,
  labelKey = 'companyName',
  valueKey = 'id',
  name = '',
  onChange,
  disabled,
  label,
  required,
  hidden,
  gridProps,
  className,
  defaultValue = value,
  ...props
}) => {
  //the loaded list of orgs from the api
  const [customers, setCustomers] = useState<any[]>([]);

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>(value || '');

  //if incoming value defined, diffcheck and set as current value (should override other routines)
  useEffect(() => {
    if (value !== undefined && value !== selected) setSelected(value);
  }, [value]);

  const formik = useFormikContext() || {};
  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik]);

  const getCustomer = useCallback(
    (key: string | number | symbol, value: any) =>
      customers.find((customer: Customer) => get(customer, key, null) === value),
    [customers],
  );

  //TODO: review the flow of handleOnChange and defaultValue
  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback(
    (value: any) => {
      if (isFormik) formik.setFieldValue(name, value);
      if (onChange) onChange(getCustomer(valueKey, value));
      setSelected(value);
    },
    [getCustomer, valueKey, name, formik, onChange],
  );

  /**
   * Select Options constructor. Builds an array of options children from loaded orgs list.
   */
  const _getOptions = useCallback(
    () =>
      customers.map((customer: any) => (
        <Option key={customer.id} value={customer[valueKey]} label={customer[labelKey]}>
          {customer[labelKey]}
        </Option>
      )),
    [customers],
  );

  //query here
  const [currentOrg] = useCurrentOrganisation();
  const [fetchCustomerList, { loading, data }] = useLazyQuery(CUSTOMER_SELECT_QUERY, {
    variables: { customerQuery: { organisationId: currentOrg?.id } },
    onCompleted: (result: any) => {
      if (result && result.customers) setCustomers(result.customers);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !data) fetchCustomerList();
  }, [fetchCustomerList, loading]);

  useEffect(() => {
    if (customers.length > 0) {
      setSelected(defaultValue);
    }
  }, [customers, defaultValue]);

  // TODO: review this flow
  useEffect(() => {
    if (data && data.customers) setCustomers(data.customers);
  }, [data, setCustomers]);

  //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik)
    return (
      <FieldContainer
        {...gridProps}
        name={name}
        required={required}
        label={label}
        className={`${className} ant-field-container`}
        hidden={hidden}
        editable={false}
      >
        <FormikSelect
          name={name}
          loading={loading}
          disabled={disabled || (loading && customers.length <= 0)}
          onChange={handleOnChange}
          {...props}
        >
          {/* call option getOptions prop to fetch children. default to map over orgs. */}
          {getOptions ? getOptions(customers) : _getOptions()}
        </FormikSelect>
      </FieldContainer>
    );

  if (hidden) return null;

  //fallback return using no formik context
  return (
    <Select
      value={loading && customers.length <= 0 ? 'Loading...' : selected}
      placeholder={'Select customers'}
      loading={loading}
      disabled={disabled || (loading && customers.length <= 0)}
      className={`${className} ant-org-selector`}
      onChange={handleOnChange}
      {...props}
    >
      {customers.length > 0 && (getOptions ? getOptions(customers) : _getOptions())}
    </Select>
  );
};

CustomerSelectorComponent.defaultProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'label',
  getFormik: true,
};

export * from './types';

export const CustomerSelector = styled(CustomerSelectorComponent)`
  width: 100%;
  min-width: 170px;
  .ant-select-selector,
  input {
    min-width: 170px;
  }
`;

export const FilledCustomerSelector = styled(CustomerSelector)`
  .ant-select-selector,
  input {
    border: none !important;
  }
  .ant-select-selector,
  .an {
    background: ${p => p.theme.secondary} !important;
    border-radius: 6px !important;
    -webkit-box-shadow: 0 1px 2px #33333333 !important;
    -moz-box-shadow: 0 1px 2px #33333333 !important;
    box-shadow: 0 1px 2px #33333333 !important;
  }
  .ant-select-clear {
    background: ${p => p.theme.secondary};
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #eee !important;
  }
`;
