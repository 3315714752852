import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { getDateFull } from '../../helpers';
import { FETCH_REPEAT_TICKET } from './gql';
import { Ticket } from './types';
import { useOverrideColumns } from '../../hooks';
import Table, { Sort } from '../../components/antd/table';

interface RepeatTicketProps {
  ticket: Ticket;
}
export const RepeatTickets: React.FC<RepeatTicketProps> = ({ticket}) => {
  const { id } = useParams<{ id: string }>();
  const [tickets, setTickets] = useState([]);

  const [fetchTicket, { loading }] = useLazyQuery(FETCH_REPEAT_TICKET, {
    variables: { id },
    onCompleted: (result: any) => {
      setTickets(result.repeatTicketById);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchTicket();
    //console.log(ticket);
  }, []);

  const columns: any[] = useOverrideColumns([
    {
      title: 'Ticket Name',
      key: 'name',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Site',
      key: 'assignedSite.name',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Contact Name',
      key: 'primaryContactName',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Contact Number',
      key: 'primaryContactNumberFull',
      filtering: true,
      show: false,
    },
    {
      title: 'Email Address',
      key: 'primaryContactEmail',
      filtering: true,
      show: false,
    },
    {
      title: 'Tags',
      key: 'tags',
      filtering: true,
      sorting: true,
      show: true,
      render: (r: any) => r.join(', '),
    },
    {
      title: 'Organisation',
      key: 'organisation.name',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Database ID',
      key: 'id',
      show: false,
    },
    {
      title: 'Status',
      key: 'status',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      sorting: { compare: Sort.DATE },
      defaultSortOrder:'ascend',
      render: (r: any) => (r ? getDateFull(r) : '')
    },
    {
      title: 'Created At',
      key: 'createdAt',
      sorting: { compare: Sort.DATE },
      show: false,
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
  ], 'ticket');

  return (
    <Table
      data={tickets}
      columns={columns}
      loading={loading}
      refresh={fetchTicket}
      title={''}
      exportTitle={'Repeat Ticket'}
      pagination={{
        defaultPageSize: 20
      }}
      rowClassName={'clickable'}
    />
  );
};

export default RepeatTickets;
