import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Action as PageAction, Page, AuthGuard, PageHeader } from '../components';
import { Linker, useLinkerData, useLinkerState, LinkTypes } from '../components/linking';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal } from 'antd';

const { confirm } = Modal;

const LinkTypesOuter = styled.div`
  padding: 30px 10px;
  width: 100%;
`;

const TypesTitle = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${p => p.theme.primary};
  margin-bottom: 15px;
  text-align: center;
`;

export const Linking: React.FC<any> = props => {
  const history = useHistory();

  const [mode, setMode, clear] = useLinkerState();
  const [data] = useLinkerData();

  const isDataEmpty = useMemo(() => !data || (data.itemType == '' && data.linkedItemType == ''), [data]);

  const cancelLinking = useCallback(async () => {
    confirm({
      title: 'Cancel Linking',
      content: 'Are you sure you discard this link?',
      okText: 'Delete',
      async onOk () {
        clear();
      },
    });
  }, [clear]);

  const back = useCallback(() => {
    if (mode === 'item' || mode === 'linkedItem' || mode === 'finalize') {
      return setMode('');
    }
    if (mode === '' && !isDataEmpty) {
      return cancelLinking();
    }
    if (mode === '' && isDataEmpty) {
      return history.push('/');
    }
  }, [cancelLinking, history, mode, setMode, isDataEmpty]);

  const adornments = useMemo(
    () =>
      !isDataEmpty
        ? [
            <PageAction onClick={cancelLinking}>
              <DeleteOutlined />
            </PageAction>,
          ]
        : [],
    [isDataEmpty],
  );

  return (
    <AuthGuard needsActiveOrganisation>

    <Page card>
      <PageHeader
        onBack={back}
        title='Linking'
        extra={adornments}
      />

        {isDataEmpty ? (
          <LinkTypesOuter>
            <TypesTitle>Please select a link type:</TypesTitle>
            <LinkTypes />
          </LinkTypesOuter>
        ) : (
          <Linker />
        )}
    </Page>

    </AuthGuard>
  );
};

export default Linking;
