import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const Actions = {
    setDrawerOpen: (open: boolean) => {
        return { type: 'SET_DRAWER_OPEN', open }
    }
}

export const selector = (state: any) => state.drawerOpen

const useSetDrawerOpen = () => {
    const dispatch = useDispatch()
    return useCallback((open: boolean) => dispatch(Actions.setDrawerOpen(open)), [dispatch])
}

export const useDrawerOpen = () => {
    const open = useSelector(selector)
    const setDrawerOpen = useSetDrawerOpen()
    return [
        open,
        setDrawerOpen
    ] as const
}

export const drawerOpen = (state: boolean = false, action: any) => {
    switch (action.type) {
        case 'SET_DRAWER_OPEN':
            return action.open
    }
    return state
}