import React, { useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { Space, Card as AntCard, Result, Button, Skeleton } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import { PageHeader } from './page-header';
import { get, isEmpty } from 'lodash';
import { getIsAdmin, getActiveOrganisationId, canSelectOrganisations } from '../selectors';
import useSelectors from '@bit/necta.hooks.use-selectors/dist';
import { FilledOrgSelector, useCurrentOrganisation, LoadedOrganisation } from './organisation-selector';
import { FormCard as Card } from './antd';
import NotFoundSvg from '../assets/404.png';
import InternalServerErrorSvg from '../assets/500.png';
import NoAccessSvg from '../assets/403.png';
import { ErrorBoundary } from '@bit/necta.hooks.error-boundary';

const Img = styled.img`
  width: 400px;
`;

const UnderConstructionSvg = InternalServerErrorSvg;

export * from './PageAction';

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width || '100%'};

  transition: width 0.3s ease;
  padding-top: 30px;
  min-height: 100vh;
  padding-bottom: 30px;
`;

const FullCard = styled(AntCard)`
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 2px;
  .ant-card-body {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .ant-card-body {
      padding: 10px;
    }
  }
`;

interface UnderConstructionProps {
  subTitle?: string | React.ReactNode;
  handleBack: () => void;
  icon?: React.ReactNode;
  contentOnly?: boolean;
}

export const UnderConstruction: React.FC<UnderConstructionProps> = ({
  contentOnly = false,
  subTitle,
  handleBack,
  children,
  icon,
}) => {
  const history = useHistory();

  const _handleBack = useCallback(() => (handleBack ? handleBack() : history.push('/')), [handleBack, history]);

  const Container = contentOnly ? React.Fragment : Page;

  const handleClick = useCallback(() =>
      window.open('https://necta.atlassian.net/servicedesk/customer/portal/2', '_blank'),
    []);

  return (
    <Container>
      <Result
        title='Page under construction'
        subTitle={
          subTitle || (
            <>
              We are still working on this. For more info, see <a onClick={handleClick}>Help</a>.
            </>
          )
        }
        icon={icon || <Img src={UnderConstructionSvg} alt={'Under construction'} />}
        extra={
          <Space>
            <Button onClick={_handleBack} type={'primary'}>
              Go Home
            </Button>
            {children}
          </Space>
        }
      />
    </Container>
  );
};

UnderConstruction.defaultProps = {};

interface Props {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  back?: (e: any) => void;
  adornments?: React.ReactNodeArray;
  extra?: React.ReactNode;
  headerProps?: PageHeaderProps;
  style?: React.CSSProperties;
  className?: string;
}

export interface NotFoundProps {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  handleBack?: () => void;
  handleRefresh?: () => void;
  icon?: React.ReactNode;
}

export const NotFound: React.FC<NotFoundProps> = ({ title, subTitle, handleBack, handleRefresh, icon }) => {
  return (
    <Container>
      <Result
        title={ title || '404' }
        subTitle={subTitle || `We couldn't find what you were looking for`}
        icon={icon || <Img src={NotFoundSvg} alt={'Not found'} />}
        extra={
          <>
            {handleBack && <Button onClick={handleBack}>Go Back</Button>}
            {handleRefresh && (
              <Button type='primary' onClick={handleRefresh}>
                Refresh
              </Button>
            )}
          </>
        }
      />
    </Container>
  );
};

interface AuthGuardProps extends NotFoundProps {
  checkForOrganisation?: boolean;
  needsActiveOrganisation?: boolean;
  needsSelectOrganisations?: boolean;
  pushTo?: string;
}

const selectors = {
  isAdmin: getIsAdmin,
  activeOrgId: getActiveOrganisationId,
  canSelectOrganisations: canSelectOrganisations,
};

export const AuthGuard: React.FC<AuthGuardProps> = ({
  pushTo = '/',
  subTitle,
  handleBack,
  handleRefresh,
  icon,
  children,
  checkForOrganisation,
  needsActiveOrganisation = true,
  needsSelectOrganisations,
  ...props
}) => {
  const [{ inSession }] = useCognitoUser();
  const AuthContext = useSelectors(selectors);

  const [currentOrg] = useCurrentOrganisation();

  const { isAdmin, activeOrgId, canSelectOrganisations } = AuthContext;

  const history = useHistory();

  const predicate = useCallback(() => !inSession, [inSession]);
  // useAuthRedirect('/not-authorized', predicate)

  const back = useCallback(() => history.push(pushTo), [pushTo, history]);
  const _handleBack = useMemo(() => handleBack || back, [handleBack, back]);

  if (needsSelectOrganisations && !canSelectOrganisations)
    return (
      <NotFound
        handleBack={_handleBack}
        subTitle={subTitle || 'You dont have the correct permissions to view this page'}
      />
    );

  if (needsActiveOrganisation && !activeOrgId && !isAdmin)
    return (
      <NotFound
        icon={<Img src={NoAccessSvg} alt='no access' />}
        handleBack={_handleBack}
        subTitle={subTitle || 'You need an active organisation to view this page.'}
      />
    );

  //TODO: additional case for checkForOrganisation + !canSelectOrganisations?
  if (checkForOrganisation && canSelectOrganisations && !currentOrg)
    return <SelectOrganisation subTitle={subTitle} handleBack={_handleBack} />;

  return <React.Fragment>{children}</React.Fragment>;
};

interface SelectOrganisationProps {
  subTitle?: string | React.ReactNode;
  handleBack?: () => void;
  icon?: React.ReactNode;
}

export const SelectOrganisation: React.FC<SelectOrganisationProps> = ({ subTitle, handleBack, icon }) => {
  const [currentOrg, setCurrentOrganisation] = useCurrentOrganisation();

  const handleOnOrganisationSelect = useCallback((value: LoadedOrganisation) => setCurrentOrganisation(value), [
    setCurrentOrganisation,
  ]);

  const handleClick = useCallback(() =>
    window.open('https://necta.atlassian.net/servicedesk/customer/portal/2', '_blank'),
[]);
  //
  return (
    <Container>
      <Result
        title='Please select an organisation'
        subTitle={
          subTitle || (
            <>
              You need to select a organisation to view, this can also be done from the top menu. For more info, see{' '}
              <a onClick={handleClick}>Help</a>
            </>
          )
        }
        icon={icon || <Img src={NoAccessSvg} alt={'No organisation'} />}
        extra={
          <Space>
            {handleBack && <Button onClick={handleBack}>Go Home</Button>}
            <FilledOrgSelector onChange={handleOnOrganisationSelect} defaultValue={get(currentOrg, 'id')} />
          </Space>
        }
      />
    </Container>
  );

  return null;
};

interface FormLoadingProps {
  children?: any;
}

export const FormLoading: React.FC<FormLoadingProps> = ({ children }: FormLoadingProps) => {
  return <Page card>{children || <Skeleton active avatar paragraph={{ rows: 5 }} />}</Page>;
};

interface Props {}

interface PageProps {
  card?: boolean;
  notFound?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onBack?: (e: any) => void;
  adornments?: React.ReactNodeArray;
  extra?: React.ReactNode;
  headerProps?: PageHeaderProps;
  style?: React.CSSProperties;
  className?: string;
  sentry?: boolean;
}

export const Page: React.FC<PageProps> = ({
  card = false,
  style,
  className = '',
  headerProps = {},
  extra,
  onBack,
  title,
  subTitle,
  children,
  sentry = true,
  ...props
}) => {
  const withHeader = useMemo(() => !isEmpty(headerProps) || extra || onBack || title || subTitle, [
    headerProps,
    extra,
    onBack,
    title,
    subTitle,
  ]);

  if (card) {
    return (
      <ErrorBoundary>
        <Container>
          <FullCard>
            {withHeader ? (
              <PageHeader title={title} onBack={onBack} subTitle={subTitle} extra={extra} {...headerProps} />
            ) : null}
            {children}
          </FullCard>
        </Container>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Container>{children}</Container>
    </ErrorBoundary>
  );
};

export default Page;
