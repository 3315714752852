import React, { useCallback, useMemo, useState } from 'react';
import { Col, ColProps } from 'antd/lib/grid'
import { Button, Form, Input, message, Modal, Select, Tooltip } from 'antd';
import { getIn, useFormikContext, FieldAttributes } from 'formik';
import { useSelector } from 'react-redux';
import { getIsAdmin, getSelectedOrganisation } from '../../selectors';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { formOverrideFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';

const { Option } = Select;

export declare type GridProps = ColProps
export declare type InputGridProps = { gridProps?: GridProps }
export declare type FieldProps = { label?: string, required?: boolean }
export declare type InputFieldProps<T, A = {}> = T & FieldAttributes<T & A> & InputGridProps & A

const Edit = styled.div`
  cursor: alias;
`;

const Helper = styled.div`
  font-size: 11px;
  margin-bottom: 10px;
  color: #737373;
`;

const { Item } = Form
export type ContainerProps = ColProps & { required?: boolean, label?: string, name?: string, errorText?: string, meta?: any, editable?: boolean }

const getError = (errors: any, touched: any, name?: string): any => {
  if (!name) return ''
  const error = getIn(errors, name);
  if (typeof error === 'object' && Object.keys(error).length > 0) return getIn(touched, name) && error[Object.keys(error)[0]];
  return getIn(touched, name) && error
}

const ADD_OVERRIDE = gql`
  mutation ($newOverrideData: NewOverrideInput!) {
      addOverride(newOverrideData: $newOverrideData) {
          ${formOverrideFields}
      }
  }
`

export type ValidateStatus = "" | "error" | "success" | "validating" | "warning";

const EditModal = ({ label, name, meta, visible, setVisible, currentModule }: any) => {

  const currentOrg = useSelector(getSelectedOrganisation)
  const [newName, setNewName] = useState('');
  const [newOptions, setNewOptions] = useState([]);
  const options = useMemo(() => meta?.options ? meta?.options.map((m: any) => <Option key={m.id} value={m.name}>{m.name}</Option>) : [], [meta?.options]);

  const [handleSubmit, { loading }] = useMutation(ADD_OVERRIDE);

  const onComplete = useCallback(async () => {
    try {
      const result = await handleSubmit({ variables: { newOverrideData: { label: newName, options: newOptions, module: currentModule, field: name, organisationId: currentOrg?.id } } });
      if (result) {
        message.success('Override added successfully', 2000);
        setVisible(false);
      }
    } catch (e) {
      message.error(cleanError(e, 'Unable to add override'), 3000);
    }
  }, [handleSubmit, newName, newOptions, currentModule, newOptions, setVisible, currentOrg])

  const onRemove = useCallback(async () => {
    try {
      const result = await handleSubmit({ variables: { newOverrideData: { label: '', options: [], module: currentModule, field: name, organisationId: currentOrg?.id } } });
      if (result) {
        message.success('Override removed successfully', 2000);
        setVisible(false);
      }
    } catch (e) {
      message.error(cleanError(e, 'Unable to remove override'), 3000);
    }
  }, [handleSubmit, newName, newOptions, currentModule, newOptions, setVisible, currentOrg])

  const handleClose = useCallback(() => !loading && setVisible(false), [setVisible]);

  return (
    <Modal
      title={`Editing: ${label}`}
      visible={visible}
      onCancel={handleClose}
      destroyOnClose
      footer={<>
        <Button onClick={handleClose} disabled={loading}>Cancel</Button>
        <Tooltip title={'Revert back to original'}><Button danger onClick={onRemove} disabled={loading}>Revert</Button></Tooltip>
        <Button type={'primary'} onClick={onComplete} loading={loading}>Save</Button>
      </>}
    >
      <Helper><InfoCircleOutlined /> Account: {currentOrg?.name}. Module: {currentModule}. Field: {name}</Helper>
      <Input name={'new-name'} placeholder={'New name...'} value={newName} onChange={(e) => setNewName(e!.target!.value)} />
      { meta?.options && <Select mode="tags" style={{ width: '100%', marginTop: '10px' }} placeholder="Options"  value={newOptions} onChange={(value) => setNewOptions(value)} >
        {options}
      </Select> }
    </Modal>
  );
}

const EDITABLE_MODULES = ['asset', 'staff', 'organisation', 'site', 'ticket'];

const FieldContainerComponent: React.FC<ContainerProps> = ({
  errorText, className = '', required = false, label = '', name = '', children, hidden = false,
  xs = 24, sm = 24, md = 12, lg = 12, xl = 12, xxl = 12, meta, editable = true, ...props
}) => {
  const { errors, touched, ...ctx } = useFormikContext() || {}
  const location = useLocation();
  const currentModule = useMemo(() => {
    // Uncomment this to disable popup on import pages
    // if (location.pathname.includes(`/import`)) return false;
    for (const m of EDITABLE_MODULES) {
      if (location.pathname.includes(`/${m}/`)) return m;
    }
    return false;
  }, [location]);
  const currentOrg = useSelector(getSelectedOrganisation)
  const [isModalVisible, setVisible] = useState(false);
  const onClick = useCallback(() => {
    if (!currentOrg) return message.error('Please select an organisation');
    setVisible(true);
  }, [setVisible, currentOrg]);
  const isAdmin = useSelector(getIsAdmin);
  const _errorText = useMemo(() => getError(errors, touched, name), [touched, name, errors])
  const labelText = useMemo(() => {
    if (!label) return;
    if (!isAdmin || !editable || !currentModule) return `${label}${required ? '*' : ''}`;
    return <Tooltip title={'Edit field for selected organisation'}><Edit onClick={onClick}>{label}{required ? '*' : ''}</Edit></Tooltip>
  }, [label, required, ctx, name, editable, currentModule]);
  const validateStatus: ValidateStatus = useMemo(() => (errorText || _errorText ? 'error' : ''), [_errorText, errorText]);
  const breakpoints = { xs, sm, md, lg, xl, xxl }

  if (hidden) return null

  return (
    <Col
      {...breakpoints}
      {...props}
      style={{ paddingLeft: '3px', paddingRight: '3px'}}
    >
      <Item
        label={labelText}
        help={errorText || _errorText}
        className={`${className} ant-input-root`}
        validateStatus={validateStatus}
      >
        { children }
      </Item>
      <EditModal visible={isModalVisible} setVisible={setVisible} label={label} name={name} meta={meta} currentModule={currentModule} />
    </Col>
  )
}

export default FieldContainerComponent

export { FieldContainerComponent as FieldContainer }
