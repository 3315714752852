import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormOverrides, Override } from '../selectors';
import Yup, { reach } from 'yup';
import createSchemaConfig from '../helpers/yup/create-schema-config';
import createYupSchema from '../helpers/yup/create-yup-schema';

const overrideFields = (yupSchema: Yup.ObjectSchema<any>, formOverrides: Override[]) => {
  const validations = formOverrides.map((o: Override) => {
    const field = reach(yupSchema, o.field) as any;
    const description = field.describe();
    const validation = {
      id: o.field,
      ...description,
    };

    if (o?.options && o?.options?.length > 0) validation.oneOf = [null, ...o.options];
    else if (o?.options && description.meta?.options) validation.oneOf = [...description.meta.options];

    if (o?.label) validation.label = o.label;
    return Object.assign(validation);
  });
  try {
    const config = createSchemaConfig(validations);
    return config.reduce(createYupSchema, {});
  } catch (ex) {
    console.log('unable to generate yup schema', ex);
    return {};
  }
};

export const useOverrideSchema = (yupSchema: Yup.ObjectSchema<any>, module?: string): Yup.ObjectSchema<any> => {
  const formOverrides = useSelector(state => getFormOverrides(state, module));
  const schema = useMemo(() => yupSchema.shape(overrideFields(yupSchema, formOverrides)), [yupSchema, formOverrides]);
  return schema;
};
