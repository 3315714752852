import * as Yup from 'yup';
import { ETHNICITIES, SA_PROVINCES, BIRTHDAY_ERROR, PASSWORD_ERROR, STAFF_DEPARTMENTS, GENDERS } from '../constants';
// import { generatePassword } from '../helpers';
// import { regExPassword } from '../regex';
// const DATE_REGEX = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export const staffSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),
  firstname: Yup.string()
    .required('First name is a required field')
    .typeError('First name is a required field')
    .label('First Name(s)'),
  lastname: Yup.string()
    .required('Last name is a required field')
    .typeError('Last name is a required field')
    .label('Last Name'),
  contactNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  position: Yup.string()
    .nullable()
    .notRequired()
    .label('Position'),
  gender: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...GENDERS])
    .meta({ type: 'select' })
    .label('Gender'),
  ethnicity: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...ETHNICITIES])
    .meta({ type: 'select' })
    .label('Ethnicity'),
  identificationIDNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('ID Number'),
  identificationPassport: Yup.string()
    .nullable()
    .notRequired()
    .label('Passport'),
  mainSkill: Yup.string()
    .nullable()
    .notRequired()
    .label('Skill'),
  departments: Yup.array()
    .nullable()
    .notRequired()
    .meta({ type: 'multiselect', options: [...STAFF_DEPARTMENTS] })
    .default([])
    .label('Departments'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .notRequired()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .notRequired()
        .oneOf([null, ...SA_PROVINCES])
        .meta({ type: 'select' })
        .label('Province'),
      country: Yup.string()
        .nullable()
        .notRequired()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .notRequired()
        .label('Postal Code'),
    })
    .label('address'),
    shortid: Yup.string()
      .nullable()
      .notRequired()
      .label('Shortid'),
    assignedAssetIds: Yup.array().label('Assigned Asset Shortids'),
});

export const confirmStaffSchema = Yup.object().shape({
  organisationId: Yup.string()
    .required('Please select a organisation')
    .typeError('Please select a organisation')
    .label('Organisation'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});

export type Staff = Yup.InferType<typeof staffSchema>;

export default staffSchema;
