import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Select, Input, SelectProps, InputProps } from 'formik-antd';
import { useFormikContext, getIn } from 'formik';
import { FieldContainer } from './FieldContainer';
import DefaultCodes from './country-codes';
import * as Yup from 'yup';
import { useErrors } from '@bit/necta.hooks.use-errors';
import { ColProps } from 'antd/lib/grid';
import { OptionType } from 'antd/lib/select';
import { FieldAttributes } from 'formik';

declare type GridProps = ColProps;
declare type InputGridProps = { gridProps?: GridProps };
declare type FieldProps = { label?: string; required?: boolean };
declare type InputFieldProps<T, A = {}> = T & FieldAttributes<T & A> & InputGridProps & A;

interface Code {
  label: string;
  value: string;
}

interface YupProps {
  name: string;
  required?: boolean;
  label?: string;
}

interface ContactNumberProps {
  inputProps?: InputProps;
  selectProps?: SelectProps;
  codes?: any[];
  countryCodeField?: YupProps;
  numberField: YupProps;
}

type Props = Omit<InputFieldProps<ContactNumberProps, FieldProps>, 'name'>;

const ContactNumberComponent: React.FC<Props> = ({
  numberField,
  countryCodeField,
  inputProps = {},
  selectProps = {},
  disabled,
  label,
  required,
  codes = DefaultCodes,
  hidden,
  gridProps,
  className,
  ...props
}) => {
  const { isSubmitting, setFieldValue } = useFormikContext() || {};

  const fields = useMemo(() => {
    if (countryCodeField) return [numberField.name, countryCodeField.name];
    return [numberField.name];
  }, [numberField, countryCodeField]);
  const [{ errorText }] = useErrors(fields);

  //TODO: make this automagically switch out country codes on code change
  const handleOnCodeChange = useCallback(
    (value: string) => {
      const name = (countryCodeField ?? { name: '' }).name;
      setFieldValue(name, value || name);
    },
    [countryCodeField, setFieldValue],
  );

  const _required = useMemo(() => numberField.required || (countryCodeField ?? { required: false }).required, [
    numberField,
    countryCodeField,
  ]);

  return (
    <FieldContainer
      className={`${className} ant-field-container`}
      hidden={hidden}
      label={numberField.label || ''}
      name={numberField.name}
      required={_required}
      errorText={errorText}
    >
      <Input
        name={numberField.name}
        addonBefore={
          countryCodeField ? (
            <Select
              style={{ minWidth: '70px' }}
              onChange={handleOnCodeChange}
              name={countryCodeField.name}
              options={codes}
              showSearch
              disabled={isSubmitting || disabled}
              {...selectProps}
            />
          ) : null
        }
        disabled={isSubmitting || disabled}
        {...inputProps}
      />
    </FieldContainer>
  );
};

export const ContactNumber = styled(ContactNumberComponent)``;
