import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import './index.css';
import { store, persistor } from './config/redux-config';
import apolloClient from './config/apollo-config';
import './config/amplify-config';
import './config/theme-config';
import * as serviceWorker from './serviceWorker';
import { toastConfigure } from '@necta-tech/alert';
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'antd/dist/antd.css';

toastConfigure();

Sentry.init({
  dsn: "https://ef4a98fd4bb24d07b2ca9ad6179be47e@o230337.ingest.sentry.io/5666597",
  autoSessionTracking: true,
  integrations(integrations) {
    // get all integrations
    return integrations
      // remove `GlobalHandlers`
      .filter(i => i.name !== 'GlobalHandlers')
      // override `ReportingObserver` with custom config
      .concat([new Integrations.BrowserTracing()])
  },
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Root apolloClient={apolloClient} store={store} persistor={persistor} />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
