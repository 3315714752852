import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import gql from "graphql-tag";
import { allTicketInstructionFields } from '../../graphql/fragments';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { Card, Collapse as Collapser, message, Button as AntButton, Popconfirm } from 'antd';
import { cleanError } from '../../helpers/error-helper';
import { AuthGuard, FormLoading, NotFound, Page } from '../../components';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import {
  FormButton,
  FormRow,
  Input,
  RefreshAction,
  Select,
  TextArea
} from '../../components/antd';
import { useFormData } from '../../hooks';
import { ticketSchema } from './schema';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getActiveOrganisationId, getIsAdmin } from '../../selectors';
import { OrganisationSelector } from '../../components/organisation-selector';
import { mapArrayToObjects } from '../../helpers';
import { CATEGORIES } from '../../constants';
import styled from 'styled-components';
import { Form as AntForm } from 'formik-antd';

const { Panel } = Collapser;

const categories = mapArrayToObjects(CATEGORIES);

const Collapse = styled(Collapser)`
  margin-bottom: 30px;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled(FormButton)`
  margin-top: 30px;
`;

const Desc = styled.div`
  white-space: pre;
  width: 100%;
  cursor: pointer;
`;

const Row = styled(FormRow)`
  width: 100%;
`;

const Btn = styled(AntButton)`
  align-self: flex-start;
  margin-bottom: 5px;
`;

const QUERY = gql`
    query {
        ticketInstructions(ticketInstructionQuery: {}) {
            ${allTicketInstructionFields}
        }
    }
`;

const UPDATE = gql`
    mutation($id: String!, $updateTicketInstruction: UpdateTicketInstructionInput!) {
        updateTicketInstruction(id: $id, updateTicketInstruction: $updateTicketInstruction) {
            ${allTicketInstructionFields}
        }
    }
`;

const ADD = gql`
    mutation($newTicketInstructionData: NewTicketInstructionInput!) {
        addTicketInstruction(newTicketInstructionData: $newTicketInstructionData) {
            ${allTicketInstructionFields}
        }
    }
`;

const ARCHIVE = gql`
    mutation($id: String!) {
        archiveTicketInstruction(id: $id)
    }
`;

const ticketInstruction = Yup.object().shape({
  description: Yup.string()
    .required('Please enter a description')
    .typeError('Please enter a description')
    .label('Instructions for Technicians'),
  category: Yup.string()
    .required('Please select a category')
    .typeError('Please select a category')
    .label('Category'),
  organisationId: Yup.string()
    .required('Please select a organisation')
    .typeError('Please select a organisation')
    .label('Organisation')
});

const ticketInstructionEdit = Yup.object().shape({
  description: Yup.string()
    .required('Please enter a description')
    .typeError('Please enter a description')
    .label('Instructions for Technicians')
});

const Instruction: FC<any> = ({ instruction, onRemove, onEdit }) => {

  const [editing, setEditing] = useState(false);

  const [handleUpload] = useMutation(UPDATE);
  const [handleArchive, { loading }] = useMutation(ARCHIVE, {
    variables: { id: instruction.id },
    onCompleted: () => {
      if (onRemove) onRemove(instruction.id);
    }
  });

  const handleEdit = useCallback((edit: boolean) => (e?: any) => {
    if (!loading) setEditing(edit);
  }, [setEditing, loading]);

  const { fields, initialValues, ...formikCTX } = useFormData(ticketInstructionEdit, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { description } = values;
      message.loading({ content: 'Updating ticket instructions...', duration: 10 });
      return handleUpload({
        variables: {
          id: instruction.id,
          updateTicketInstruction: { description },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Ticket instructions updated successfully', duration: 2 });
      if (onEdit) onEdit(instruction.id, {...result?.data?.updateTicketInstruction});
      setEditing(false);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update ticket instructions'), duration: 4 });
    },
    override: 'asset',
  });

  const handleRemove = useCallback((e?: any) => {
    handleArchive()
  }, [handleArchive]);

  return (
    <Formik {...formikCTX} initialValues={instruction} enableReinitialize validateOnBlur>
      {({ }) => (
        <Form layout={'vertical'}>
          {!editing && (
            <>
              <Desc onClick={handleEdit(true)}>{instruction.description}</Desc>
              <Popconfirm
                title={`Are you sure you want to archive this?`}
                disabled={loading}
                onConfirm={handleRemove}
              >
                <Btn type={'default'} danger style={{ marginTop: '10px' }} loading={loading}>Archive</Btn>
              </Popconfirm>
            </>
          )}
          {editing && (
            <>
              <Btn type={'default'} onClick={handleEdit(false)}>Cancel</Btn>
              <Row disabled={loading}>
                <TextArea {...fields.description} gridProps={GRID_PROPS} />
                <Button>Save</Button>
              </Row>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

interface IProps {
}

export const TicketInstructions: FC<IProps> = ({}) => {

  const [ticketInstructions, setTicketInstructions] = useState<any>([]);

  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);

  const history = useHistory();

  const [handleAdd] = useMutation(ADD);

  const [fetch, { loading }] = useLazyQuery(QUERY, {
    variables: {},
    onCompleted: (result: any) => {
      if (result && result.ticketInstructions) setTicketInstructions(cloneDeep(result.ticketInstructions));
    },
    onError: (error: any) => {
      console.log(JSON.stringify(error, null, 2));
      message.error({ content: cleanError(error, 'Unable to fetch ticket instructions'), duration: 4 });
    },
    fetchPolicy: 'network-only',
  });

  const { fields, initialValues, ...formikCTX } = useFormData(ticketInstruction, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      try {
        message.loading({ content: 'Adding new ticket instructions...', duration: 10 });
        const result = await handleAdd({
          variables: {
            newTicketInstructionData: { ...values },
          },
        });
        if (result) {
          const _new = result?.data?.addTicketInstruction;
          if (_new) {
            message.success({ content: 'Ticket instructions added', duration: 2 });
            setTicketInstructions((s: any[]) => [...s, _new]);
            actions.resetForm();
          }
        }
      } catch (e) {
        console.log(e);
        message.error({ content: cleanError(e, 'Unable to add ticket instructions'), duration: 4 });
      } finally {
        actions.setSubmitting(false);
      }
    },
    override: 'asset',
  });

  const handleUpdated = useCallback(async (id: string, newValues: any) => {
    try {
      setTicketInstructions((s: any) => {
        const found = s.find((_s: any) => _s.id === id);
        Object.keys(newValues).map((k: string) => found[k] = newValues[k]);
        return [...s];
      })
    } catch (ex) {
      console.log(ex)
    }
  }, [setTicketInstructions])

  const handleRemoved = useCallback((id: string) => {
    setTicketInstructions((s: any[]) => [...s.filter((_s: any) => _s.id !== id)]);
  }, [setTicketInstructions]);

  const [defaultValues] = useState({
    ...initialValues,
    organisationId: isAdmin ? null : activeOrgId,
  });

  useEffect(() => {
    fetch();
  }, []);

  const handleRefresh = useCallback(() => fetch(), [fetch]);
  const handleBack = useCallback(() => history.goBack(), [history]);

  if (loading) return <FormLoading />;

  if (!ticketInstructions) return <NotFound handleBack={() => history.goBack()} handleRefresh={fetch} />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, dirty }) => (
          <Page
            card
            title={`Ticket Instructions`}
            onBack={handleBack}
            extra={[
              <RefreshAction onClick={handleRefresh} />
            ]}
          >
            <Collapse>
              {ticketInstructions.map((i: any) => <Panel key={i.id} header={<>{i.category}{isAdmin && <b> - {i.organisation?.name}</b>}</>}>
                  <Instruction instruction={i} onRemove={handleRemoved} onEdit={handleUpdated}/>
                </Panel>
              )}
            </Collapse>
            <Card title={'Add New Instructions'}>
              <Form layout={'vertical'}>
                <Row>
                  <Select options={categories} {...fields.category} />
                  <OrganisationSelector {...fields.organisationId} hidden={!isAdmin} />
                  <TextArea {...fields.description} gridProps={GRID_PROPS}/>
                  <Button>Add</Button>
                </Row>
              </Form>
            </Card>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

TicketInstructions.defaultProps = {};

export default TicketInstructions;
