import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LoadedOrganisation } from './types'

export const ActionTypes = Object.freeze({
    SET_CURRENT_ORGANISATION: 'SET_CURRENT_ORGANISATION'
})

export const Actions = {
    setOrganisation: (payload?: LoadedOrganisation) => {
        return { type: ActionTypes.SET_CURRENT_ORGANISATION, payload }
    }
}

export const DefaultState = null

export const currentOrg = (state: LoadedOrganisation | null = DefaultState, { type, payload}: { type: string, payload: string }) => {
    if (type === ActionTypes.SET_CURRENT_ORGANISATION) return payload ?? null
    return state
}

export const getCurrentOrganisation = (state: any) => state.currentOrg

export const useSetCurrentOrganisation = () => {
    const dispatch = useDispatch()
    const setCurrentOrganisation = useCallback((organisation?: LoadedOrganisation) => {
        dispatch(Actions.setOrganisation(organisation))
    }, [dispatch, Actions])
    return setCurrentOrganisation
}

export const useCurrentOrganisation = () => {
    const currentOrg: LoadedOrganisation = useSelector(getCurrentOrganisation)
    const setCurrentOrganisation = useSetCurrentOrganisation()

    const ret = useMemo(() => ([currentOrg, setCurrentOrganisation] as const), [currentOrg, setCurrentOrganisation])
    return ret
}
