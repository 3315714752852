import React, { useMemo, useCallback, useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { FieldContainer } from '../antd/FieldContainer'
import { isEmpty, get, compact } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities';
import useDebouncedEffect from 'use-debounced-effect-hook'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const STAFF_SEARCH = gql`
  query STAFF_SEARCH($keyword: String!) {
    staffSearch(keyword: $keyword) {
      firstname
      lastname
      contactEmail
      position
      ethnicity
      mainSkill
      id
      shortid
      assignedAssets {
        name
      }
    }
  }
`;

const { Option } = Select

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

/**
 * takes values and returns LoadedStaff keys in a single string
 * @param staff
 * @param labelKeys
 * @returns string
 */
const getValues = (staff: LoadedStaff, labelKeys: (keyof LoadedStaff)[]) => {
  return compact(labelKeys.map((l: keyof LoadedStaff) => get(staff, l, null))).join(' ');
}

interface LoadedStaff {
  id: string,
  firstname: string,
  lastname: string,
  shortid: string,
  email: string,
}

interface StaffSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  //TODO: define these properly
  valueKey?: keyof LoadedStaff,
  labelKeys?: (keyof LoadedStaff)[],
  showPicture?: boolean;
  getOptions?: (staff: Partial<LoadedStaff>[]) => React.ReactNode[],
  getStaff?: (value: any) => void,
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  },
  defaultOptions?: LoadedStaff[]
}

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const StaffSelectorComponent: React.FC<StaffSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  { getFormik = true, getOptions, getStaff, labelKeys = ['firstname', 'lastname'], valueKey = 'id', name = '', showPicture = true, onChange, disabled, label, required, hidden, gridProps, className, defaultValue, defaultOptions, ...props }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')
  const [staff, setStaff] = useState<LoadedStaff[]>(defaultOptions || [])
  const [keyword, setKeyword] = useState<string>('')

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const [fetchStaff, { loading }] = useLazyQuery(STAFF_SEARCH, {
    variables: { keyword },
    onCompleted: (result: any) => {
        console.log(JSON.stringify(result))
        setStaff(cloneDeep(result.staffSearch))
    }

  })

  const handleSearch = useCallback((_keyword: string) => setKeyword(_keyword), [setKeyword]);

  useDebouncedEffect(() => {
    if (keyword && keyword !== '') fetchStaff({ variables: { keyword } });
  }, [ keyword, fetchStaff, setStaff ], 500);

  const _getStaff = useCallback((value: any, key: string) => staff.find((u: LoadedStaff) => get(u, key) === value), [staff]);

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value);
    if (onChange) onChange(getStaff ? getStaff(value) : _getStaff(value, valueKey));
    setSelected(value);
    setKeyword('');
  }, [name, setSelected, formik, isFormik, onChange, getStaff, valueKey, _getStaff, setKeyword]);

  /**
   * Select Options constructor. Builds an array of options children from fetched staff list.
   */
  const _getOptions = useCallback(() => staff.map((staff: any) => (
    <Option key={staff.id} value={staff.id} label={getValues(staff, labelKeys)}>
      {showPicture && <Icon src={getThumbnail(staff.profilePicUrl)} size="small" icon={<TeamOutlined />} />}
      {getValues(staff, labelKeys)}
    </Option>
  )), [staff])

  const selectOptions = useMemo(() => staff.length > 0 && (getOptions ? getOptions(staff) : _getOptions()), [staff, getOptions]);

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
      editable={false}
    >
      <FormikSelect
        prefix={<TeamOutlined className="site-form-item-icon" />}
        name={name}
        onSearch={handleSearch}
        onChange={handleOnChange}
        showSearch
        placeholder={'Search staff...'}
        disabled={disabled || formik.isSubmitting}
        loading={loading}
        filterOption={false}
        {...props}
      >
        {selectOptions}
      </FormikSelect>
    </FieldContainer>
  )

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<TeamOutlined className='site-form-item-icon' />}
      placeholder={'Search staff...'}
      loading={loading}
      className={`${className} ant-staff-selector`}
      onSearch={handleSearch}
      onChange={handleOnChange}
      showSearch
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {selectOptions}
    </Select>
  )
}

export const StaffSelector = styled(StaffSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
