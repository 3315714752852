import React, { FC, useCallback, useMemo } from 'react'
import { Input, PasswordProps } from 'formik-antd'
import styled from 'styled-components'
import { FieldContainer } from './FieldContainer'
import { useFormikContext } from 'formik'
import { generatePassword } from '@bit/necta.hooks.generate-password';
import { Button } from 'antd';
import { ColProps } from 'antd/lib/grid'
import { FieldAttributes } from 'formik'

export declare type InputGridProps = {
    gridProps?: ColProps;
};
export declare type FieldProps = {
    label?: string;
    required?: boolean;
};
export declare type InputFieldProps<T, A = {}> = T & FieldAttributes<T & A> & InputGridProps & A;

const { Password: PasswordBase } = Input;

interface GeneratePasswordProps {
  generateButton: boolean;
}

export type PasswordPropType = InputFieldProps<PasswordProps, FieldProps> & GeneratePasswordProps

const PasswordComponent: React.FC<PasswordPropType> = ({ ref = null, hidden, gridProps = {}, label, required, className, generateButton = false, addonAfter, disabled, ...props }) => {

    const { isSubmitting } = useFormikContext() || {}

    const afterAdornments = useMemo(() => {
      if (addonAfter) return addonAfter;
      else if (generateButton) return <GeneratePasswordBtn disabled={isSubmitting || disabled} />;
      return null;
    }, [addonAfter, generateButton, isSubmitting, disabled]);

    return (
        <FieldContainer
            {...gridProps}
            name={props.name}
            required={required}
            label={label}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <PasswordBase
                disabled={isSubmitting || disabled}
                addonAfter={afterAdornments}
                {...props}
            />
        </FieldContainer>
    )
}

export const Password = styled(PasswordComponent)`
    button.ant-btn { 
        height: calc(100% - 2px);
        padding: 0;
        margin: 0;
    }
`

export const GeneratePasswordBtn: FC<{ disabled?: boolean }> = ({ disabled}) => {
  const { setFieldValue } = useFormikContext();
  const handleClick = useCallback((e: any) => setFieldValue('password', generatePassword()), [setFieldValue]);
  return (
    <Button type='text' onClick={handleClick} disabled={disabled}>
      generate
    </Button>
  );
};
