import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { Button, Row, Col, Divider } from 'antd';
import NTIcon from '../../assets/oversite-blue.png';
import { CopyrightOutlined } from '@ant-design/icons';

const Bar = styled.div`
  background: white;
  color: #0f4c81;
  padding: 0 40px;
  width: 100%;
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

const Nav = styled.div`
  color: #0f4c81;
  width: 100%;
  padding: 20px 0 10px 0;=
`;

const Copy = styled.div`
  color: #0f4c81;
  width: 100%;
  padding: 10px 0px 10px 20px;
  display: flex;
`;

const RightNav = styled(Col)`
  display: flex;
  justify-content: end;

  @media only screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
    justify-content: center !important;
  }
`;

const NavIcon = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-content: center;

    svg {
      color:
    };
    @media only screen and (max-width: 768px) {
      padding-bottom: 20px;
      text-align: center;
      align-items: center;
      justify-content: center !important;
    }
`

export const Footer: React.FC<any> = props => {
  const history = useHistory();

  return (
    <Bar>
        <Nav>
          <Row>
            <Col xs={24} md={12} style={{display: 'grid', alignContent: 'center'}}>
              <NavIcon onClick={() => history.push('/welcome')}>
                  <NTIcon />
              </NavIcon>
            </Col>
            <RightNav xs={24} md={12} style={{display: 'flex', justifyContent:'end'}}>
              <Button type="text" color='inherit' onClick={() => history.push('/welcome')}>About</Button>
              <Button type="text" color='inherit' onClick={() => history.push('/faq')}>FAQ</Button>
              <Button type="text" color='inherit' onClick={() => history.push('/contact')}>Contact</Button>
              <Button type="text" color='inherit' onClick={() => window.open('https://necta.atlassian.net/servicedesk/customer/portal/2')}>Help | Service Desk</Button>
            </RightNav>
          </Row>
        </Nav>
        <Divider />
        <Copy>
          <Row style={{width: '100%'}}>
              <Col xs={24} md={12}  style={{ color: '#333'}}>
                <p><CopyrightOutlined style={{margin: '0 5px 0 -15px', padding:'0 0 0 0'}}/> Copyright 2021 OverSiteTech</p>
              </Col>
              <RightNav xs={24} md={12} style={{display: 'flex', justifyContent: 'end'}}>
                {/* <Button style={{marginTop: '-5px', color: '#333'}} type="link" onClick={() => history.push('/welcome')}>Sitemap</Button> |  */}
                <Button style={{marginTop: '-5px', color: '#333'}} type="link" onClick={() => history.push('/faq')}>Privacy Policy</Button> |
                <Button style={{marginTop: '-5px', color: '#333'}} type="link" onClick={() => history.push('/contact')}>Terms of Use</Button>
              </RightNav>
            </Row>
        </Copy>
    </Bar>
  );
};

export default Footer;
