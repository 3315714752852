import React, { useCallback, useState } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Col, ColProps, Row, RowProps } from 'antd/lib/grid'
import { default as CardBase, CardProps } from 'antd/lib/card'

const Card = styled(CardBase)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 6px;
`

interface Props {
  path?: string;
  disabled?: boolean;
  Icon: React.ReactNode;
  title: string;
  cardProps?: CardProps;
  onClick?: (e?: any) => void
}

export const DashCard: React.FC<Props & ColProps> = ({ onClick, cardProps = {}, title, Icon, path, disabled, ...props }) => {

  const history = useHistory()

  const handleOnClick = useCallback(() => {
    if (onClick) onClick()
    if(path) history.push(path)
  }, [path])

  return (
    <Col {...props}>
      <Card {...cardProps} hoverable onClick={handleOnClick}>
        { Icon }
        <div>{title}</div>
      </Card>
    </Col>
  )
}

DashCard.defaultProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 8,
  disabled: false,
  hidden: false
}

interface WidgetProps {
  path?: string;
  disabled?: boolean;
  cardProps?: CardProps;
  onClick?: (e?: any) => void
}

const FakeCard = styled.div`
  padding: 5px;
  border-radius: 2px;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WidgetCard: React.FC<WidgetProps & ColProps> = ({ onClick, cardProps = {}, children, path, disabled, hidden, ...props }) => {

  const history = useHistory()

  const handleOnClick = useCallback(() => {
    if (onClick) onClick()
    if (path) history.push(path)
  }, [path]);

  if (hidden) return null;

  return (
    <Col {...props}>
      <FakeCard onClick={handleOnClick}>
        {children}
      </FakeCard>
    </Col>
  )
}

WidgetCard.defaultProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 8,
  disabled: false,
  hidden: false
}

export const PrimaryCard = styled(DashCard)<{ primary?: string, secondary?: string }>`
    padding: 6px;
    .ant-card {
      background: ${p => p.primary || p.theme.primary};
      color: white;
      font-size: 20px;
      min-height: 260px;
      align-items: center;
      justify-content: center;
    }
    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }
    .anticon > svg {
      width: 32px;
      height: 32px;
    }
    @media only screen and (max-width: 768px) {
      .ant-card {
        font-size: 16px;
      }
      .ant-card-body {
        flex-direction: row;
      }
      .anticon > svg {
        margin-right: 5px;
      }
    }
`;

const GridOuter = styled(Row)`
  padding: 20px 40px 40px 40px;
  @media only screen and (max-width: 1100px) {
    padding: 20px 20px 40px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px 30px 10px;
  }
  width: 100%;
`

type DashGridProps = RowProps

export const DashGrid: React.FC<DashGridProps> = ({ gutter, children }) => {
  return (
    <GridOuter justify="center" gutter={gutter}>
      { children }
    </GridOuter>
  )
}

DashGrid.defaultProps = {
  gutter: 12,
  justify: 'center'
}
