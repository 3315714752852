import React from 'react'
import styled from 'styled-components'
import { Button, ButtonProps } from 'antd'

interface IconButtonProps {
    hidden?: boolean;
    Render?: React.FC;
    mode?: 'light' | 'dark'
  }

  const StyledButton = styled(Button)<{ mode?: 'light' | 'dark'}>`
  svg {
    color: ${p => p.mode === 'light' ? p.theme.primary : 'white' };   
  }
`;
  
  export const IconButton: React.FC<IconButtonProps & ButtonProps> = ({ mode = 'light', hidden, Render, ...props }) => {
    if (hidden) return null;
    if (!Render) return <StyledButton mode={mode} {...props} />
    return <Render {...props} />
  }