import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { addressFields, organisationFields } from '../fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const ORGANISATION_QUERY = gql`
  query($organisationQuery: OrganisationQuery!) {
    organisations(organisationQuery: $organisationQuery) {
      ${organisationFields}
      createdAt
      archived
      address {
        ${addressFields}
      }
    }
  }
`;

export const useFetchOrganisationList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchOrganisation, ctx] = useLazyQuery(ORGANISATION_QUERY, {
    variables: { organisationQuery: { parentOrganisationId: currentOrg?.id } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchOrganisation();
  }, [fetchOrganisation]);

  return [fetchOrganisation, ctx] as const;
};
