import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, FormLoading, DatePicker } from '../../components';
import { useFetchOrganisation } from '../../graphql/hooks';
import { EditOutlined as Edit } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { FileUploader } from '../../components/file-uploader';
import * as Yup from 'yup';
import { presentError } from '@necta-tech/alert';
import { cloneDeep } from '@apollo/client/utilities';
import { getThumbnail } from '@necta-tech/s3';
import { useFormData } from '../../hooks';
import { useSelector } from 'react-redux';
import { getCurrentUser, getIsAdmin } from '../../selectors';
import { mapArrayToObjects } from '../../helpers';
import { ORGANISATION_DEPARTMENTS, ORGANISATION_STATUSES, REGIONS, WORK_STATUSES } from '../../constants';
import { Form as AntForm } from 'formik-antd';
import { TextArea, CheckBox, Input, ContactNumber, Select, FormRow } from '../../components/antd';
import { Card, Collapse as Collapser, Tabs as AntTabs } from 'antd';
import { Avatar } from '../../components';
import { AuditOutlined } from '@ant-design/icons';
import { AuthGuard } from '../../components';
import PicPlaceholder from '../../assets/asset_picture.png';
import { message } from 'antd';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd';
import { LinkedOrganisations } from '../../components/linked-items';
import { allOrganisationFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';
import { OrganisationSelector } from '../../components/organisation-selector';
import { Checkboxes, POLICIES } from './OrganisationAdd';
import Moment from 'moment';

const { Panel } = Collapser;
const { TabPane } = AntTabs;

const Collapse = styled(Collapser)`
  width: 100%;
`;
const Tabs = styled(AntTabs)`
  width: 100%;
`;
const OwnerBlock = styled.div`
  b {
    display: inline-block;
    width: 70px;
  }
`;

const CardRow = ({ hidden, children }: any) => {
  if (hidden) return null;
  return <OwnerBlock>{children}</OwnerBlock>;
};

const organisationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Company Name'),
  contactName: Yup.string()
    .trim()
    .nullable()
    .label('Primary Contact Name'),
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  contactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  primaryImage: Yup.string()
    .nullable()
    .label('Primary Image'),
  receiveFileNotifications: Yup.boolean()
    .nullable()
    .label('Notify about documents expiring'),
  notificationEmail: Yup.string()
    .trim()
    .nullable()
    .label('Notification Email'),
  notificationEmailAlt: Yup.string()
    .trim()
    .nullable()
    .label('Notification Email Alt'),
  departments: Yup.array().label('Departments'),
  regions: Yup.array().label('Regions'),
  status: Yup.string()
    .nullable()
    .label('Status'),
  workStatus: Yup.string()
    .nullable()
    .label('Work Status'),
  notes: Yup.string()
    .nullable()
    .label('Notes'),
  policies: Yup.array()
    .default([])
    .label('Policies'),
  newOwner: Yup.string()
    .trim()
    .nullable()
    .label('New Owner Email'),
  testAccount: Yup.boolean()
    .nullable()
    .label(''),
  testAccountExpiry: Yup.string()
    .nullable()
    .meta({ type: 'date' })
    .label('Demo Account Expiry'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .label('Province'),
      country: Yup.string()
        .nullable()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .label('Postal Code'),
    })
    .nullable(),
});

const departments = mapArrayToObjects(ORGANISATION_DEPARTMENTS);
const statuses = mapArrayToObjects(ORGANISATION_STATUSES);
const regions = mapArrayToObjects(REGIONS);
const workStatuses = mapArrayToObjects(WORK_STATUSES);

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Check = styled(CheckBox)`
  padding: 0px 12px;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateOrganisation: UpdateOrganisationInput!) {
    updateOrganisation(id: $id, updateOrganisation: $updateOrganisation) {
      ${allOrganisationFields}
    }
  }
`;

interface Props {
  mode: 'view' | 'edit';
}

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const Organisation: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const isAdmin = useSelector(getIsAdmin);
  const currentUser = useSelector(getCurrentUser);

  const [handleUpload] = useMutation(UPLOAD);

  const { fields, initialValues, ...formikCTX } = useFormData(organisationSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const {
        id,
        documents,
        childOrganisations,
        parentOrganisation,
        policies,
        contactNumberFull,
        ownedBy,
        ownedById,
        newOwner,
        ownedByEmail,
        ...rest
      } = values;
      if (newOwner) rest.ownedByEmail = newOwner;
      const modifyPolicies = POLICIES.map((policy: string) => {
        return Object.assign({ name: policy, active: policies.includes(policy) });
      });
      return handleUpload({
        variables: {
          id,
          updateOrganisation: {
            ...rest,
            modifyPolicies,
          },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Organisation updated successfully', duration: 2 });
      const _organisation = result?.data?.updateOrganisation;
      if (_organisation) {
        const defaultPolicies = _organisation.policies.map((p: any) => p.name);
        setOrganisation({ id, ..._organisation, policies: defaultPolicies });
      }
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update organisation'), duration: 4 });
    },
    override: 'organisation',
  });

  const [organisation, setOrganisation] = useState({ ...initialValues });
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchOrganisation, { loading }] = useFetchOrganisation({
    id,
    onCompleted: (result: any) => {
      console.log(result);
      const _organisation = result?.organisation;
      if (_organisation) {
        const defaultPolicies = _organisation.policies.map((p: any) => p.name);
        setOrganisation({ ..._organisation, policies: defaultPolicies });
      }
    },
  });

  const childOrganisations = useMemo(
    () => (organisation && organisation.childOrganisations ? [...cloneDeep(organisation.childOrganisations)] : null),
    [organisation],
  );

  const canChangeOwner = useMemo(() => {
    if (isAdmin) return true;
    if (!currentUser?.activeOrg?.id || !organisation?.id) return false;
    if (currentUser?.activeOrg?.id === organisation?.id) {
      return currentUser?.id === organisation.ownedById;
    }
    return organisation.parentOrganisation?.id === currentUser?.activeOrg?.id;
  }, [currentUser, isAdmin, organisation]);

  const pendingOwner = useMemo(() => {
    if (organisation.ownedByEmail == null) return false;
    return organisation.ownedBy?.email !== organisation.ownedByEmail;
  }, [organisation]);

  const handleTestAccount = useCallback((setFieldValue: any) => (e: any) => {
    if (e?.target?.checked) {
      setFieldValue('testAccountExpiry', Moment().add(90, 'days').format('YYYY-MM-DD'))
    } else {
      setFieldValue('testAccountExpiry', null)
    }
  }, []);

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={organisation} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, dirty, setFieldValue }) => (
          <Page
            card
            title={`${edit ? 'Edit' : 'View'} Organisation`}
            onBack={() => history.goBack()}
            extra={[
              <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
              <RefreshAction onClick={() => fetchOrganisation()} />,
              <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
            ]}
          >
            <Form layout='vertical'>
              <Tabs defaultActiveKey='info' tabPosition='top' size='large'>
                <TabPane tab='Info' key='info'>
                  <Collapse defaultActiveKey='information' ghost>
                    <Panel header='Contact Information' key='information'>
                      <ProfilePictureUploader
                        disabled={isSubmitting}
                        fieldKey='primaryImage'
                        defaultPic={PicPlaceholder}
                      />
                      <FormRow disabled={!edit}>
                        <Input {...fields.name} />
                        <Input {...fields.contactName} />
                        <ContactNumber
                          numberField={fields.contactNumber}
                          countryCodeField={fields.contactNumberCountryCode}
                        />
                        <Input {...fields.contactEmail} />
                        <Input {...fields.notificationEmail} />
                        <Input {...fields.notificationEmailAlt} />
                        <Check
                          {...fields.receiveFileNotifications}
                          label={''}
                          hidden={!isAdmin}
                          disabled={isSubmitting}
                        >
                          Notify about documents expiring
                        </Check>
                        <Checkboxes hidden={!isAdmin} {...fields.policies} />
                      </FormRow>
                    </Panel>

                    <Panel header='Verification Data' key='verification'>
                      <FormRow disabled={!edit}>
                        <Select options={departments} mode={'multiple'} {...fields.departments} allowClear />
                        <Select options={regions} mode={'multiple'} {...fields.regions} allowClear />
                        <Select options={statuses} {...fields.status} allowClear />
                        <Select options={workStatuses} {...fields.workStatus} allowClear />
                      </FormRow>
                    </Panel>

                    <Panel header='Location' key='location'>
                      <FormRow disabled={!edit}>
                        <Input {...fields.address.addressLine1} />
                        <Input {...fields.address.country} />
                        <Input {...fields.address.state} />
                        <Input {...fields.address.postalCode} />
                      </FormRow>
                    </Panel>

                    <Panel header='Notes' key='notes'>
                      <FormRow disabled={!edit}>
                        <TextArea {...fields.notes} rows={10} gridProps={GRID_PROPS} />
                      </FormRow>
                    </Panel>

                    {isAdmin && (
                      <Panel header='Settings' key='settings'>
                        <FormRow>
                          <CheckBox {...fields.testAccount} onChange={handleTestAccount(setFieldValue)}>Demo Account</CheckBox>
                          <DatePicker {...fields.testAccountExpiry} />
                        </FormRow>
                      </Panel>
                    )}

                    <Panel header='Owner' key='owner'>
                      <Card size='small' title='Current Owner' style={{ marginBottom: 16 }}>
                        <CardRow hidden={!organisation.ownedBy?.firstname && !organisation.ownedBy?.lastname}>
                          <b>Name </b>
                          {organisation.ownedBy?.firstname + ' ' + organisation.ownedBy?.lastname}
                        </CardRow>
                        <CardRow hidden={!organisation.ownedBy?.email}>
                          <b>Email </b>
                          {organisation.ownedBy?.email}
                        </CardRow>
                        <CardRow hidden={!pendingOwner}>
                          <b>Pending </b>
                          {organisation.ownedByEmail}
                        </CardRow>
                      </Card>
                      {canChangeOwner && (
                        <FormRow disabled={!edit}>
                          <Input {...fields.newOwner} />
                        </FormRow>
                      )}
                    </Panel>
                  </Collapse>
                </TabPane>

                <TabPane tab='Links' key='links' disabled={!childOrganisations || childOrganisations.length <= 0}>
                  <FormRow disabled={!edit}>
                    <LinkedOrganisations
                      item={organisation}
                      linkedItem={null}
                      data={childOrganisations}
                      protocol={null}
                      showAssign={false}
                    />
                  </FormRow>
                </TabPane>

                <TabPane tab='Documents' key='documents'>
                  <FormRow disabled={!edit}>
                    <FileUploader
                      id={organisation.id}
                      parentType='organisation'
                      fileType='DOCUMENT'
                      initialFiles={organisation.documents}
                    />
                  </FormRow>
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

export default Organisation;
