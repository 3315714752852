import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useNewLinker } from '../linking/linker/hooks/use-new-linker';
import { Avatar } from '../Avatar';
import { getThumbnail } from '@necta-tech/s3';
import { TeamOutlined, LinkOutlined } from '@ant-design/icons';
import Table from '../antd/table';
import { Button } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';

export const STAFF_COLUMNS: any[] = [
  {
    title: 'Picture',
    key: 'profilePicUrl',
    render: (r: any) => (
      <Avatar src={getThumbnail(r)} icon={<TeamOutlined />} />
    ),
  },
  { title: 'First Name', key: 'firstname', sorting: true },
  { title: 'Surname', key: 'lastname' },
  { title: 'Email', key: 'contactEmail' },
  { title: 'Position', key: 'position' },
  { title: 'Shortid', key: 'shortid' },
];

interface IProps {
  protocol: any;
  item: any | null;
  linkedItem: any | null;
  data?: any[] | null;
  hidden?: boolean;
  showAssign?: boolean;
  title?: string;
}

export const LinkedStaff: FC<IProps> = (props) => {
  const { protocol, item, linkedItem, hidden, data, showAssign, title } = props;

  console.log(props);

  const history = useHistory();

  const setLinker = useNewLinker();

  const handleStaffClick = useCallback((staff: any) => (e?: any) => history.push(`/staff/edit/${staff.id}`), [history]);

  const handleAssign = useCallback(() => {
    setLinker({ ...protocol, item, linkedItem });
    history.push(`/linking`);
  }, [history, setLinker, item, linkedItem]);

  const tableData = useMemo(() => cloneDeep(data) || [], [data]);

  if (hidden) return null;

  return (
    <div style={{ width: '96%', margin: '0 auto' }}>
      <Table
        title={title}
        data={tableData}
        columns={STAFF_COLUMNS}
        onRow={(record: any, rowIndex: any) => ({
          onClick: handleStaffClick(record)
        })}
        rowClassName={'clickable'}
        searchable={false}
        exportable={false}
        columnSelection={false}
        size={'middle'}
        hidden={tableData.length <= 0}
      />
      {showAssign && <Button onClick={handleAssign}>Assign Staff <LinkOutlined /></Button>}
    </div>
  );
};

LinkedStaff.defaultProps = {
  showAssign: true,
  title: 'Linked Staff:'
};

export default LinkedStaff;
