import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { presentError } from '@necta-tech/alert';
import gql from 'graphql-tag';

const CURRENT_USER = gql`
  query CURRENT_USER {
    user(queryOptions: {}) {
      id
      firstname
      lastname
      email
      activeOrg {
        id
        name
        childOrganisations {
          id
          name
        }
        formOverrides {
          type
          field
          module
          description
          options
        }
        policies {
          name
          description
        }
      }
    }
  }
`;

export const useFetchCurrentUser = () => {

  const [fetch, ctx] = useLazyQuery(CURRENT_USER, {
    onCompleted: (result: any) => {
    },
    onError: (error: any) => {
      presentError('Failed to load your user data, please refresh!');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
      fetch()
  }, [fetch]);

  return useMemo(() => [fetch, ctx] as const, [fetch, ctx])
};
