import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

const ImageContainer = styled.div<{ cursor: 'pointer' | 'default' }>`
  width: 96%;
  padding: 0.75rem 0 0.75rem 0;
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  img {
    height: 120px;
    width: 120px;
    align-self: flex-start;
    margin: 5px;
    border: 2px solid ${p => p.theme.primary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    transition: box-shadow 0.3s;
  }
  img:hover {
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
  }
  cursor: ${p => p.cursor};
`;

const ImageClick = styled.div`
  border: 1px solid #ccc;
  border-radius: 2px;
  align-self: flex-start;
  height: 121px;
  margin-top: 5px;
  margin-right: 5px;
  flex: 1;
`;

const DropzoneInner = styled.div`
  align-self: center;
  width: 100%;
`;

const DropzoneContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;
  align-self: center;
  color: #0c0c0c;
  line-height: 30px;
  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const Highlight = styled.span`
  color: ${p => p.theme.primary};
`;

interface IProps {
  [x: string]: any;
  value?: string;
  uploadImage: Function;
  alt?: string;
  disabled?: boolean;
}

export const PictureUpload: FC<IProps> = props => {
  const { value, uploadImage, children, alt, disabled } = props;

  const handleDrop = useCallback((acceptedFiles?: any) => uploadImage(acceptedFiles), [uploadImage]);
  const handleImageClick = useCallback(() => uploadImage(), [uploadImage]);

  const cursor = disabled ? 'default' : 'pointer';

  return (
    <ImageContainer cursor={cursor}>
      <img onClick={!disabled ? handleImageClick : undefined} alt={alt || 'Profile'} src={value} />
      <ImageClick>
        <Dropzone disabled={disabled} onDrop={!disabled ? handleDrop : undefined}>
          {({ getRootProps, getInputProps }) => (
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <DropzoneInner>
                {children}
                {!children && (
                  <>
                    <h4>
                      Profile Picture
                      <br />
                      Drag and Drop or <Highlight>Click</Highlight> to search
                    </h4>
                  </>
                )}
              </DropzoneInner>
            </DropzoneContainer>
          )}
        </Dropzone>
      </ImageClick>
    </ImageContainer>
  );
};

PictureUpload.defaultProps = {};

export default PictureUpload;
