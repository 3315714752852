import React, { FC, useCallback, useMemo } from 'react';
import Table from '../antd/table';
import { useOverrideColumns } from '../../hooks';
import { Filter } from '../antd/table/helpers/filters';
import { Sort } from '../antd/table/helpers/sorters';
import { getDateFormatted, getDateFull, mapArrayToOptions } from '../../helpers';
import { CATEGORIES, CONDITIONS, STEP_STATUSES } from '../../constants';
import { useHistory } from 'react-router';
import { Button } from 'antd';
import styled from 'styled-components';

const conditions = mapArrayToOptions(CONDITIONS);
const serviceStatuses = mapArrayToOptions(STEP_STATUSES);
const categories = mapArrayToOptions(CATEGORIES);

const Title = styled.div`
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-weight: normal;
  font-size: 13px;
`;

interface IProps {
  [x: string]: any;
}

const dateRanges = [
  {
    text: '< 6 Months',
    value: '0-5',
  },
  {
    text: '6 Months',
    value: '6-8',
  },
  {
    text: '9 Months',
    value: '9-11',
  },
  {
    text: '1 Year',
    value: '12-23',
  },
  {
    text: '2 Years',
    value: '23-35',
  },
  {
    text: '3 Years',
    value: '36-47',
  },
  {
    text: '> 4 Years',
    value: '48-999',
  },
];

const FaultyAssets: FC<IProps> = ({ assets, loading, refetch }) => {

  const history = useHistory();

  const handleTicketClick = useCallback((r: any) => (e?: any) => {
    e.stopPropagation();
    history.push(`/ticket/edit/${r.assignedTicketId}`)
  }, [history]);

  const columns = useOverrideColumns([
    {
      title: 'Asset Name',
      filtering: true,
      sorting: true,
      key: 'assignedAsset.name',
    },
    {
      title: 'Database ID',
      key: 'assignedAsset.id',
      show: false,
      editable: 'never',
    },
    {
      title: 'Manufacturer',
      key: 'assignedAsset.manufacturer',
      filtering: true,
      show: false,
      sorting: true,
    },
    {
      title: 'Model Number',
      key: 'assignedAsset.modelNumber',
      filtering: true,
      show: false,
    },
    {
      title: 'Registration Number',
      key: 'assignedAsset.registrationNumber',
      filtering: true,
      sorting: true
    },
    {
      title: 'Condition',
      key: 'assignedAsset.condition',
      show: false,
      filtering: {
        filters: conditions,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Value of Purchase',
      key: 'assignedAsset.valueOriginal',
      filtering: true,
      show: false,
    },
    {
      title: 'Current Book Value',
      key: 'assignedAsset.valueCurrent',
      filtering: true,
      show: false,
    },
    {
      title: 'Service Status',
      key: 'completionStatus',
      filtering: {
        filters: serviceStatuses,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    { // TODO: This will be the date the serviceStep was serviced
      title: 'Service Date',
      key: 'completionDate',
      sorting: { compare: Sort.DATE },
      filtering: {
        filters: dateRanges,
        filterMultiple: true,
        type: Filter.SELECT,
        customFilter: Filter.DATE_RANGE('months'),
      },
      render: (r: any) => (r ? getDateFormatted(r) : ''),
    },
    {
      title: 'Date of Purchase',
      key: 'assignedAsset.datePurchased',
      show: false,
      sorting: { compare: Sort.DATE },
      filtering: {
        filters: dateRanges,
        filterMultiple: true,
        type: Filter.SELECT,
        customFilter: Filter.DATE_RANGE('months'),
      },
      render: (r: any) => (r ? getDateFormatted(r) : ''),
    },
    {
      title: 'Created At',
      key: 'assignedAsset.createdAt',
      sorting: { compare: Sort.DATE },
      show: false,
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
    {
      title: 'Category',
      key: 'assignedAsset.category',
      show: false,
      filtering: {
        filters: categories,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Tags',
      key: 'assignedAsset.tags',
      show: false,
      render: (r: any) => (r?.length ? r.join(', ') : ''),
    },
    {
      title: 'Shortid',
      key: 'assignedAsset.shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Actions',
      key: '',
      render: (r: any) => <Button onClick={handleTicketClick(r)} type={'primary'}>View Ticket</Button>
    },
    // TODO: actions column
  ], 'asset');

  const title = useMemo(() => {
    return <><Title>Faulty Assets</Title><Subtitle>Last 6 months</Subtitle></>
  }, []);

  return (
    <Table
      data={assets}
      columns={columns}
      loading={loading}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/asset/edit/${record.assignedAssetId}`)
      })}
      // refresh={refetch}
      title={title}
      exportTitle={'Faulty Assets'}
      searchable={false}
      pagination={{
        defaultPageSize: 5
      }}
      rowClassName={'clickable'}
    />
  );
};

FaultyAssets.defaultProps = {};

export default FaultyAssets;
