import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Page, AuthGuard } from '../../components';
import ProfilePictureUploader from '../../components/profile-picture-uploader';
import { mapArrayToObjects } from '../../helpers';
import { useSelector } from 'react-redux';
import { getActiveOrganisationId, getIsAdmin, getIsOrganisation } from '../../selectors';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Password, Input, ContactNumber, Select, FormRow, FormButton, CheckBox } from '../../components/antd';
import { Card, Collapse as Collapser, Col } from 'antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import Default from '../../assets/default_profile.png';
import { message } from 'antd';
import { COUNTRIES, SA_PROVINCES, USER_TYPES } from '../../constants';
import { cleanError } from '../../helpers/error-helper';
import { userSchema } from './schema';

const { Panel } = Collapser;

const countries = mapArrayToObjects(COUNTRIES);
const provinces = mapArrayToObjects(SA_PROVINCES);

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

const finalSchema = userSchema.shape({
  password: Yup.string().label('Password')
});

const ADD_USER = gql`
  mutation ADD_USER($newUser: NewLoginUserInput!) {
    addLogin(newUser: $newUser) {
      id
      firstname
      lastname
      shortid
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const UserAdd: React.FC<any> = props => {
  const history = useHistory();
  const [cognitoUser] = useCognitoUser();
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);
  const isOrganisation = useSelector(getIsOrganisation);

  const [handleAdd] = useMutation(ADD_USER);

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      if (values.role !== 'Admin' && !values.organisationId) {
        message.error({
          content: 'Please ensure to select an active organisation in the Security section!',
          duration: 2,
        });
        return;
      }
      return handleAdd({ variables: { newUser: { ...values } } });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'User added successfully', duration: 2 });
      history.push(`/management/user-edit/${result?.data?.addLogin?.shortid}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add user'), duration: 4 });
    },
    override: 'user',
  });

  const defaultValues = useMemo(
    () =>
      Object.assign({
        ...initialValues,
        role: isOrganisation ? 'Organisation' : 'User',
        organisationId: isAdmin ? null : activeOrgId,
      }),
    [activeOrgId, isAdmin, isOrganisation],
  );

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <Page card title='Add User'>
              <Form layout='vertical'>
                <Collapse defaultActiveKey='verification' ghost>
                  <Panel header='User Information' key='verification'>
                    <ProfilePictureUploader disabled={isSubmitting} fieldKey='profilePicUrl' defaultPic={Default} />
                    <FormRow>
                      <Input {...fields.firstname} />
                      <Input {...fields.lastname} />
                      <ContactNumber
                        numberField={fields.contactNumber}
                        countryCodeField={fields.contactNumberCountryCode}
                      />
                      <Input {...fields.email} />
                    </FormRow>
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow>
                      <Input {...fields.address.addressLine1} />
                      <Select options={countries} {...fields.address.country} allowClear />
                      <Select options={provinces} {...fields.address.state} allowClear />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>

                  <Panel header='Security' key='security'>
                    <FormRow>
                      <Password {...fields.password} generateButton />
                      <Select options={USER_TYPES} {...fields.role} disabled={!isAdmin} />
                      <OrganisationSelector
                        {...fields.organisationId}
                        hidden={values.role === 'Admin'}
                        disabled={!isAdmin}
                      />
                      <CheckBox {...fields.isTechnician}>Technician</CheckBox>
                    </FormRow>
                  </Panel>
                </Collapse>

                <Add loading={isSubmitting} type='submit'>
                  ADD
                </Add>
              </Form>
            </Page>
          );
        }}
      </Formik>
    </AuthGuard>
  );
};

export default UserAdd;
