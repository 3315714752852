import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const CREATE_LINK = gql`
  mutation($newLinkData: NewLinkInput!) {
    addLink(newLinkData: $newLinkData) {
      id
    }
  }
`;

type hookProps = {
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useCreateLink = ({ onCompleted, onError }: hookProps) => {
  const [createLink, ctx] = useMutation(CREATE_LINK, {
    onCompleted,
    onError,
  });

  return [createLink, ctx] as const;
};
