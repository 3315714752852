import React, { useMemo } from 'react'
import { FileExcelOutlined as File } from '@ant-design/icons'
import { PageAction } from '../Page'

interface DownloadTemplateAction {
    onClick: (e?: Event) => void,
    hidden?: boolean,
    disabled?: boolean
}

export const DownloadTemplateAction: React.FC<DownloadTemplateAction> = ({ disabled, hidden, onClick, ...props }) => {

    return (
        <PageAction 
            hidden={hidden} 
            disabled={disabled} 
            tooltip='Download Template'
            onClick={onClick}
        >
            <File />
        </PageAction>
    )
}