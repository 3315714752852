import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Layout, Space, Affix } from 'antd';
import { Avatar, Button } from 'antd';
import { useHistory } from 'react-router';
import OSIcon from '../assets/oversite-blue.png';
import OSIconWhite from '../assets/oversite-white.png';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';

const { Header: UnstyledHead } = Layout;

const NavImg = styled.img`
  width: auto;
  height: 25px;
  margin-right: 10px;
`;

const NavIcon = styled.div<{ mode?: 'light' | 'dark' }>`
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-content: center;

  svg {
    color: white !important;
    width: 160px;
  }
`;

const NavTitle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
`;

const Title = styled.div<{ mode?: 'light' | 'dark' }>`
  color: ${p => (p.mode === 'light' ? p.theme.primary : 'white')};
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
`;

const Icon = styled(Avatar)`
  margin-right: 15px;
`;

const Alt = styled.span`
  font-weight: bold;
  font-style: italic;
`;

const Head = styled(UnstyledHead)`
  height: 50px;
  svg {
    color: white;
  }
`;

const LeftBox = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 18px;
  padding-left: 18px;
  height: inherit;
  @media only screen and (max-width: 768px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  actions?: React.ReactNode[];
  leftActions?: React.ReactNode[];
  mode?: 'light' | 'dark';
  title?: string;
}

export const HeaderComponent: React.FC<HeaderProps> = ({
  title = '',
  leftActions = [],
  actions = [],
  className,
  hidden,
  mode,
  ...props
}) => {
  const [{ inSession }] = useCognitoUser();

  const history = useHistory();

  const handleHome = useCallback(
    () => (inSession ? history.push('/') : window.open('https://oversite.cloud', '_self')),
    [inSession, history],
  );

  const icon = useMemo(() => (mode === 'light' ? OSIcon : OSIconWhite), [mode]);

  if (hidden) return null;

  return (
    <Affix>
      <Head className={`${className || ''} header`}>
        <Container>
          <LeftBox className='left-box'>
            <Space>{leftActions}</Space>
            <Title mode={mode} onClick={handleHome}>
              <NavIcon>
                <NavImg src={icon} />
              </NavIcon>
              <NavTitle>
                Over<strong>Site</strong>
              </NavTitle>
            </Title>
          </LeftBox>
          <Space>{actions}</Space>
        </Container>
      </Head>
    </Affix>
  );
};

export const Header = styled(HeaderComponent)<{ mode?: 'light' | 'dark' }>`
  ${p =>
    p.theme
      ? css`
          width: ${p.theme.width};
        `
      : ''};
  background: ${p => (p.mode === 'light' ? 'white' : p.theme.primary)} !important;
  svg {
    color: ${p => (p.mode === 'light' ? p.theme.primary : 'white')};
  }
  padding: 0;
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0 2px 3px gray !important;
  -moz-box-shadow: 0 2px 3px gray !important;
  box-shadow: 0 1px 4px gray !important;
`;
