import styled from 'styled-components';
import { FormButton, FormRow } from '../../antd';

export const ConfirmForm = styled(FormRow)`
  && {
    padding: 20px 30px 0 30px;
    @media only screen and (max-width: 1100px) {
      padding: 20px 20px 0 20px;
    }
    @media only screen and (max-width: 768px) {
      padding: 10px 10px 0 10px;
    }
  }
`;

export const ImportFinalize = styled.div`
  width: 100%;
  text-align: left;
`;

export const ImportConfirm = styled.div`
  margin: 5px 25px 15px 25px;
`;

export const NextButton = styled(FormButton)`
  margin-top: 15px;
`;
