import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { allAssetFields, documentFields, siteFields } from '../fragments';

const ASSET = gql`
  query($id: String!) {
    asset(id: $id) {
      ${allAssetFields}
    }
  }
`;

type hookProps = {
  id: string | undefined;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useFetchAsset = ({ onCompleted, onError, id }: hookProps) => {
  const [fetchAsset, ctx] = useLazyQuery(ASSET, {
    variables: { id },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAsset();
  }, []);

  return [fetchAsset, ctx] as const;
};
