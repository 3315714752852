import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useNewLinker } from '../linking/linker/hooks/use-new-linker';
import { Avatar } from '../Avatar';
import { getThumbnail } from '@necta-tech/s3';
import { AppstoreOutlined, LinkOutlined } from '@ant-design/icons';
import Table from '../antd/table';
import { Button } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';

export const SITE_COLUMNS: any[] = [
  {
    title: 'Picture',
    key: 'key',
    render: (r: any) => (
      <Avatar src={getThumbnail(r)} icon={<AppstoreOutlined />} />
    ),
  },
  { title: 'Name', key: 'name', sorting: true },
  { title: 'Customer Name', key: 'customerName' },
  { title: 'Contact', key: 'primaryContactEmail' },
  { title: 'Shortid', key: 'shortid' },
];

interface IProps {
  protocol: any;
  item: any | null;
  linkedItem: any | null;
  data?: any[] | null;
  hidden?: boolean;
  showAssign?: boolean;
  title?: string;
}

export const LinkedSites: FC<IProps> = (props) => {
  const { protocol, item, linkedItem, hidden, data, showAssign, title } = props;

  const history = useHistory();

  const setLinker = useNewLinker();

  const handleSiteClick = useCallback((site: any) => (e?: any) => history.push(`/site/edit/${site.id}`), [history]);

  const handleAssign = useCallback(() => {
    setLinker({ ...protocol, item, linkedItem });
    history.push(`/linking`);
  }, [history, setLinker, item, linkedItem]);

  const tableData = useMemo(() => cloneDeep(data) || [], [data]);

  if (hidden) return null;

  return (
    <div style={{ width: '96%', margin: '0 auto' }}>
      <Table
        title={title}
        data={tableData}
        columns={SITE_COLUMNS}
        onRow={(record: any, rowIndex: any) => ({
          onClick: handleSiteClick(record)
        })}
        rowClassName={'clickable'}
        searchable={false}
        exportable={false}
        columnSelection={false}
        size={'middle'}
        hidden={tableData.length <= 0}
      />
      {showAssign && <Button onClick={handleAssign}>Assign Sites <LinkOutlined /></Button>}
    </div>
  );
};

LinkedSites.defaultProps = {
  showAssign: true,
  title: 'Linked Sites:'
};

export default LinkedSites;
