import { get } from 'lodash';
import { paths } from './paths';
import { getCurrentOrganisation } from './components/organisation-selector';
import { Organisation } from './schemas';

export const getIsCurrentOrganisationOwner = (state: any): any => {
  const owner = get(state, 'currentUser.activeOrg.ownedBy') || null;
  const id = get(state, 'cognitoUser.username') || null;
  if (!owner) return false;
  return id === owner;
};

export const getIsAdmin = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  return permissions === 'Admin';
};

export const getIsOrganisation = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  const isOrganisation = permissions === 'Organisation';
  if (!isOrganisation) return false;
  const active = get(state, 'currentUser.activeOrg.id') || null;
  if (!active) return false;
  return true;
};

export const getActiveOrganisationId = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  const isOrganisation = permissions === 'Organisation';
  if (!isOrganisation) return null;
  return get(state, 'currentUser.activeOrg.id') || null;
};

export const getActiveOrganisationDId = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  return get(state, 'currentUser.activeOrg.id') || null;
};


export const getActiveOrganisation = (state: any): any => {
  const permissions = get(state, 'cognitoUser.permissions') || null;
  const isOrganisation = permissions === 'Organisation';
  if (!isOrganisation) return null;
  return get(state, 'currentUser.activeOrg') || null;
};

export const getCurrentUserRole = (state: any): any => {
  return get(state, 'currentUser.role');
}

export const getCurrentUser = (state: any): any => {
  return get(state, 'currentUser');
}

export const getCognitoUser = (state: any): any => {
  return get(state, 'currentUser')
}

export interface Override {
  label?: string;
  field: string;
  module: string;
  type: string;
  description?: string;
  options: string[];
}

export const getFieldOverrides = (state: any, fieldKey: string, module: string): Override | null => {
  const overrides = get(state, 'currentUser.activeOrg.formOverrides', null);
  if (overrides == null || overrides.length < 0) return null;
  return overrides.find((o: any) => o.field === fieldKey && o.module === module);
}

export const getFormOverrides = (state: any, module?: string): Override[] => {
  if (module == null || module == '') return [];
  if (canSelectOrganisations(state)) {
    const selected = get(state, 'currentOrg');
    if (selected) {
      const overrides = get(state, 'currentOrg.formOverrides', null);
      return overrides ? overrides.filter((o: any) => o.module === module) : [];
    }
  }
  const overrides = get(state, 'currentUser.activeOrg.formOverrides', null);
  return overrides ? overrides.filter((o: any) => o.module === module) : [];
}

export const canSelectOrganisations = (state: any): boolean => {
  const isAdmin = getIsAdmin(state)
  if (isAdmin) return true;
  const isOrganisation = getIsOrganisation(state);
  if (isOrganisation)  {
    const activeOrg = get(state, 'currentUser.activeOrg', {});
    if (!activeOrg) return false;
    const policies = get(activeOrg, 'policies', []).map((policy: any) => policy.name) ?? [];
    return policies.includes('CanSelectOrg');
  }
  return false;
}

export const getSelectedOrganisation = (state: any): Organisation => {
  const canSelect = canSelectOrganisations(state);
  if (!canSelect) return getActiveOrganisation(state);
  const currentOrg = getCurrentOrganisation(state);
  if (currentOrg) return currentOrg;
  return getActiveOrganisation(state);
}

export const Selectors = {
  getIsCurrentOrganisationOwner,
  getIsAdmin,
  getIsOrganisation,
  getActiveOrganisationId,
  getActiveOrganisation,
  getCurrentUserRole,
  getFieldOverrides,
  getFormOverrides,
  canSelectOrganisations,
  getSelectedOrganisation
};

export default Selectors
