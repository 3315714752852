import { Button } from 'antd'
import styled from 'styled-components'

export const BaseButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 2px;
  background-color: #0F4C81;
  color: white;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  :disabled {
    background-color: #0F4C8166;
    color: white;
  }
  :hover {
    background-color: #0F4C81AA !important;
    color: white;
  }
  :focus {
    background-color: #0F4C81A0 !important;
    color: white;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`