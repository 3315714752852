import React, { FC, useMemo } from 'react';
import { useLinkerData } from '../hooks/use-linker-data';
import { AuditOutlined, ReconciliationOutlined, TeamOutlined, ContactsOutlined } from '@ant-design/icons';
import { DashGrid, PrimaryCard } from '../../../dash-card';

export const ASSIGN_ASSET_TO_STAFF = {
  itemType: 'staff',
  linkedItemType: 'asset',
  linkDescription: 'Assign asset to staff member',
  linkType: 'PARENT_CHILD',
};

export const ASSIGN_STAFF_TO_SITE = {
  itemType: 'site',
  linkedItemType: 'staff',
  linkDescription: 'Assign staff to site',
  linkType: 'PARENT_CHILD'
}

export const ASSIGN_STAFF_TO_TICKET = {
  itemType: 'ticket',
  linkedItemType: 'staff',
  linkDescription: 'Assign staff to ticket',
  linkType: 'PARENT_CHILD'
}

export const ASSIGN_ASSET_TO_SITE = {
  itemType: 'site',
  linkedItemType: 'asset',
  linkDescription: 'Assign asset to site',
  linkType: 'PARENT_CHILD'
}

// TODO: include filters
interface IProps {
  [x: string]: any;
}

export const LinkTypes: FC<IProps> = props => {
  const {} = props;
  const [_, setLinkerData] = useLinkerData();

  const linkTypes = useMemo(
    () => [
      {
        onClick: () => setLinkerData(ASSIGN_ASSET_TO_STAFF),
        icon: <AuditOutlined />,
        title: 'Assign asset to staff member',
      },
      {
        onClick: () => setLinkerData(ASSIGN_ASSET_TO_SITE),
        icon: <ReconciliationOutlined />,
        title: 'Assign asset to site',
      },
      {
        onClick: () => setLinkerData(ASSIGN_STAFF_TO_SITE),
        icon: <TeamOutlined />,
        title: 'Assign staff member to site',
      },
      {
        onClick: () => setLinkerData(ASSIGN_STAFF_TO_TICKET),
        icon: <ContactsOutlined />,
        title: 'Assign staff member to ticket',
      },
    ],
    [setLinkerData],
  );

  return (
    <DashGrid>
      {linkTypes.map((l: any, k: number) => (
        <PrimaryCard onClick={l.onClick} Icon={l.icon} title={l.title} />
      ))}
    </DashGrid>
  );
};

LinkTypes.defaultProps = {};

export default LinkTypes;
