import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getDateFull, mapArrayToOptions, renderArray } from '../../helpers';
import { getThumbnail } from '@necta-tech/s3';
import { cloneDeep } from '@apollo/client/utilities';
import { useFetchOrganisationList } from '../../graphql/hooks';
import { ORGANISATION_DEPARTMENTS, REGIONS, ORGANISATION_STATUSES, WORK_STATUSES } from '../../constants';
import { Avatar } from '../../components';
import { AuditOutlined } from '@ant-design/icons';
import { Page, AuthGuard } from '../../components'
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';

const departments = mapArrayToOptions(ORGANISATION_DEPARTMENTS);
const regions = mapArrayToOptions(REGIONS);
const statuses = mapArrayToOptions(ORGANISATION_STATUSES);
const workStatuses = mapArrayToOptions(WORK_STATUSES);

export const OrganisationList: React.FC<any> = props => {
  const [organisations, setOrganisations] = useState([]);

  const history = useHistory();

  const [fetchOrganisations, { loading }] = useFetchOrganisationList((result: any) => {
    if (result.organisations) {
      setOrganisations(cloneDeep(result.organisations));
    }
  });

  const columns = useOverrideColumns([
    {
      title: 'Picture',
      key: 'primaryImage',
      render: (r: any) => <Avatar src={getThumbnail(r)} icon={<AuditOutlined />} />,
      export: false,
    },
    {
      title: 'Name',
      key: 'name',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Primary Contact Name',
      key: 'contactName',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Contact Number',
      key: 'contactNumberFull',
      filtering: true,
      show: false,
    },
    {
      title: 'Email Address',
      key: 'contactEmail',
      filtering: true,
      sorting: true,
      show: false,
    },
    {
      title: 'Regions',
      key: 'regions',
      filtering: {
        filters: regions,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
      render: (r: any) => r.join(', '),
    },
    {
      title: 'Departments',
      key: 'departments',
      filtering: {
        filters: departments,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
      render: (r: any) => r.join(', '),
    },
    {
      title: 'Status',
      key: 'status',
      filtering: {
        filters: statuses,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Work Status',
      key: 'workStatus',
      filtering: {
        filters: workStatuses,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Database ID',
      key: 'id',
      show: false,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      sorting: { compare: Sort.DATE },
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
  ], 'organisation');

  return (
    <AuthGuard needsActiveOrganisation>

    <Page
      card
    >
      <Table
        data={organisations}
        columns={columns}
        loading={loading}
        onRow={(record: any, rowIndex: any) => ({
          onClick: () => history.push(`/organisation/edit/${record.id}`)
        })}
        refresh={fetchOrganisations}
        title={'Organisation List'}
        exportTitle={'Organisation'}
        pagination={{
          defaultPageSize: 20
        }}
        rowClassName={'clickable'}
      />
    </Page>

    </AuthGuard>
  );
};

export default OrganisationList;
