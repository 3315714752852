import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormOverrides, Override } from '../selectors';
import { mapArrayToOptions } from '../helpers';

export const useOverrideColumns = (columns: any[], module?: string) => {
  const formOverrides = useSelector(state => getFormOverrides(state, module));

  const _columns = useMemo(() => columns.map((c: any) => {
      const override = formOverrides.find((fo: Override) => fo.field === c.key);
      if (override?.options && override?.options.length > 0 && c?.filtering?.filters) c.filtering.filters = mapArrayToOptions(override.options);
      if (override?.label) c.title = override.label;
      return c;
  }), [columns, formOverrides]);

  return _columns;
};
