import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useDrawerOpen } from './redux'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

interface CollapseButtonProps extends React.HTMLAttributes<HTMLElement> {

}

export const CollapseButtonComponent: React.FC<CollapseButtonProps> = ({ ...props }) => {
    const [open, setOpen] = useDrawerOpen()
    const handleOnClick = useCallback(() => setOpen(!open), [open, setOpen])
    const Icon = useMemo(() => open ? <ArrowLeftOutlined /> : <ArrowRightOutlined />, [open])
    return <Button icon={Icon} type={'primary'} shape='circle' size='small' onClick={handleOnClick} {...props} />
}

export const CollapseButton = styled(CollapseButtonComponent)`
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 3;
    transform: translateX(50%);
`
