import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../reducer';
import { get } from 'lodash';

export const selectLinkState = (state: any) => get(state, 'linker.state', '');

const makeSetLinkerState = (dispatch: (action: any) => void) => (state: string) =>
  dispatch(Actions.setLinkerState(state));
const makeClearLinkerState = (dispatch: (action: any) => void) => () => dispatch(Actions.clearLinkerState());

export const useSetLinkerState = () => {
  const dispatch = useDispatch();
  return makeSetLinkerState(dispatch);
};

export const useClearLinkerState = () => {
  const dispatch = useDispatch();
  return makeClearLinkerState(dispatch);
};

export const useLinkerState = () => {
  const state = useSelector(selectLinkState);
  const setLinkerState = useSetLinkerState();
  const clearLinkerState = useClearLinkerState();
  return [state, setLinkerState, clearLinkerState] as const;
};
