import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

// const defaultHeight = 55;

const generateArray = (n: number) => [...Array(n)].map((_, index) => index + 1);

interface FieldLoaderProps {
  width: number;
  speed: number;
  backgroundColor: string;
  foregroundColor: string;
  iterations: number;
  defaultHeight?: number;
}

const FieldLoader: FC<FieldLoaderProps> = props => {
  const { backgroundColor, foregroundColor, speed, width, iterations, defaultHeight } = props;

  const iArray = useMemo(() => generateArray(iterations), [iterations]);

  const height = useMemo(() => ((defaultHeight || 0) + 5) * (iterations || 1), [iterations, defaultHeight]);

  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      viewBox={'0 0 ' + width + ' ' + height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      {iArray.map((i: number) => {
        return (
          <React.Fragment key={i}>
            <rect
              x={5}
              y={(10 + (defaultHeight || 0)) * (i - 1)}
              rx='3'
              ry='3'
              width={width - 5 < 0 ? width : width - 5}
              height={defaultHeight}
            />
          </React.Fragment>
        );
      })}
    </ContentLoader>
  );
};

FieldLoader.defaultProps = {
  backgroundColor: '#f3f3f3',
  foregroundColor: '#e6f7fe',
  speed: 2,
  iterations: 1,
  defaultHeight: 50,
};

export default FieldLoader;
