import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useHistory } from 'react-router';
import {
  getDateFormatted,
  getDateFull,
  mapArrayToOptions
} from '../../helpers';
import { Page, AuthGuard } from '../../components';
import { cloneDeep } from '@apollo/client/utilities';
import { CATEGORIES, CONDITIONS, SERVICE_STATUSES, STATUSES } from '../../constants';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';
import { assetFields } from '../../graphql/fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const conditions = mapArrayToOptions(CONDITIONS);
const statuses = mapArrayToOptions(STATUSES);
const serviceStatuses = mapArrayToOptions(SERVICE_STATUSES);
const categories = mapArrayToOptions(CATEGORIES);

const dateRanges = [
  {
    text: '< 6 Months',
    value: '0-5',
  },
  {
    text: '6 Months',
    value: '6-8',
  },
  {
    text: '9 Months',
    value: '9-11',
  },
  {
    text: '1 Year',
    value: '12-23',
  },
  {
    text: '2 Years',
    value: '23-35',
  },
  {
    text: '3 Years',
    value: '36-47',
  },
  {
    text: '> 4 Years',
    value: '48-999',
  },
];

const ASSETS_QUERY = gql`
  query ASSETS_QUERY($assetQuery: AssetQuery!) {
    assets(assetQuery: $assetQuery) {
      ${assetFields}
      createdAt
    }
  }
`;

const useFetchAssetList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchAssets, ctx] = useLazyQuery(ASSETS_QUERY, {
    variables: { assetQuery: { organisationId: currentOrg?.id } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  return [fetchAssets, ctx] as const;
};

export const AssetList: React.FC<any> = props => {
  const [assets, setAssets] = useState([]);

  const history = useHistory();

  const [fetchAssets, { loading }] = useFetchAssetList((result: any) => {
    if (result.assets) {
      setAssets(cloneDeep(result.assets));
    }
  });

  const columns = useOverrideColumns([
    {
      title: 'Asset Name',
      filtering: true,
      sorting: true,
      key: 'name',
    },
    {
      title: 'Database ID',
      key: 'id',
      show: false,
      editable: 'never',
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Model Number',
      key: 'modelNumber',
      filtering: true,
      show: false,
    },
    {
      title: 'Registration Number',
      key: 'registrationNumber',
      filtering: true,
      sorting: true
    },
    {
      title: 'Condition',
      key: 'condition',
      show: false,
      filtering: {
        filters: conditions,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Value of Purchase',
      key: 'valueOriginal',
      filtering: true,
      show: false,
    },
    {
      title: 'Current Book Value',
      key: 'valueCurrent',
      filtering: true,
      show: false,
    },
    {
      title: 'Status',
      key: 'status',
      filtering: {
        filters: statuses,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Service Status',
      key: 'serviceStatus',
      filtering: {
        filters: serviceStatuses,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Date of Last Service',
      key: 'dateLastServiced',
      sorting: { compare: Sort.DATE },
      filtering: {
        filters: dateRanges,
        filterMultiple: true,
        type: Filter.SELECT,
        customFilter: Filter.DATE_RANGE('months'),
      },
      render: (r: any) => (r ? getDateFormatted(r) : ''),
    },
    {
      title: 'Date of Purchase',
      key: 'datePurchased',
      show: false,
      sorting: { compare: Sort.DATE },
      filtering: {
        filters: dateRanges,
        filterMultiple: true,
        type: Filter.SELECT,
        customFilter: Filter.DATE_RANGE('months'),
      },
      render: (r: any) => (r ? getDateFormatted(r) : ''),
    },
    {
      title: 'Created At',
      key: 'createdAt',
      sorting: { compare: Sort.DATE },
      show: false,
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
    {
      title: 'Category',
      key: 'category',
      filtering: {
        filters: categories,
        filterMultiple: true,
        type: Filter.SELECT
      },
      sorting: true,
    },
    {
      title: 'Tags',
      key: 'tags',
      show: false,
      render: (r: any) => (r?.length ? r.join(', ') : ''),
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
  ], 'asset');

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page card>
        <Table
          data={assets}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/asset/edit/${record.id}`)
          })}
          refresh={fetchAssets}
          title={'Asset List'}
          exportTitle={'Assets'}
          pagination={{
            defaultPageSize: 20
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default AssetList;
