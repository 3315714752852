import React from 'react';
import Step, { DisabledStep } from './Step';
import { Step as StepT } from '../types';

interface StepListProps {
  steps?: StepT[];
  stepChanged?: (step: StepT) => void;
  disabledMode?: boolean;
  disabled?: boolean;
}
// Complaints with typing so added & any
export const StepList = React.memo(function StepList({ steps, stepChanged, disabledMode, disabled }: StepListProps & any) {

  if (!steps || steps.length <= 0) return <>No {disabledMode ? 'disabled ' : ''}steps yet...</>;

  if (disabledMode) return steps.map((step: any) => <DisabledStep step={step} key={step.id} stepChanged={stepChanged} disabled={disabled} />);

  return steps.map((step: any, index: number) => (
    <Step step={step} index={index} key={step.id} stepChanged={stepChanged} disabled={disabled} />
  ));
});

export default StepList;
