import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { LinkOutlined } from '@ant-design/icons';
import { useCheckLink } from '../../../../graphql/hooks';
import { useLinkerData } from '../hooks/use-linker-data';
import SkeletonLoader from '../../../loaders/LoaderContainer';
import { Title } from './Linker';
import { LinkCheck } from '../reducer';

const ConfirmOuter = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 10px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  padding: 4px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  svg {
    margin-right: 5px;
  }
`;

const LinkInfo: FC<{ links?: LinkCheck[]; hidden?: boolean }> = ({ links, hidden, children }) => {
  if (hidden) return null;
  return (
    <>
      <Title>{children}</Title>
      {links &&
        links.map((l: LinkCheck, i: number) => (
          <Text key={i}>
            <LinkOutlined />
            {l.error}
          </Text>
        ))}
    </>
  );
};

export const ConfirmLink: FC = () => {
  const [links, setLinks] = useState({ fetched: false, ARCHIVE: [], UPDATE: [] });

  const [linkData] = useLinkerData();
  const [checkLink, { loading }] = useCheckLink({
    link: {
      item: linkData.item.id,
      itemType: linkData.itemType,
      linkedItem: linkData.linkedItem.id,
      linkedItemType: linkData.linkedItemType,
    },
    onCompleted: (result: any) => {
      const ARCHIVE = result.linkCheck.filter((l: LinkCheck) => l.action === 'ARCHIVE');
      const UPDATE = result.linkCheck.filter((l: LinkCheck) => l.action === 'UPDATE');
      setLinks({ fetched: true, ARCHIVE, UPDATE });
    },
  });

  const hasNoChanges = useMemo(() => links.UPDATE.length === 0 && links.ARCHIVE.length === 0, [links]);

  if (loading || !links.fetched)
    return (
      <ConfirmOuter>
        <SkeletonLoader
          type={'field'}
          iterations={2}
          showHeader={false}
          showCard={false}
          marginBottom={'0px'}
          marginTop={'0px'}
          defaultHeight={30}
        />
      </ConfirmOuter>
    );

  return (
    <ConfirmOuter>
      <LinkInfo hidden={links.ARCHIVE.length <= 0} links={links.ARCHIVE}>
        <b>REMOVE LINKS</b> These links will be archived:
      </LinkInfo>
      <LinkInfo hidden={links.UPDATE.length <= 0} links={links.UPDATE}>
        <b>UPDATE LINKS</b> These links will be updated:
      </LinkInfo>
      <LinkInfo hidden={!hasNoChanges}>
        <b>CONFIRMATION</b> Link can be created safely
      </LinkInfo>
    </ConfirmOuter>
  );
};

ConfirmLink.defaultProps = {};

export default ConfirmLink;
