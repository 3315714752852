import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { presentError } from '@necta-tech/alert';
import gql from 'graphql-tag';
import { myUserData } from '../fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    user(queryOptions: {}) {
      ${myUserData}
    }
  }
`;

export const useLoadCurrentUser = () => {
  const [cognitoUser] = useCognitoUser();
  const [currentUser, setCurrentUser] = useCurrentUser();

  const [fetch, ctx] = useLazyQuery(CURRENT_USER, {
    onCompleted: (result: any) => {
      if (result) setCurrentUser(result.user);
    },
    onError: (error: any) => {
      presentError('Failed to load your user data, please refresh!');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (cognitoUser.inSession) {
      // Temporary "fix" for switching accounts
      if (cognitoUser.username !== currentUser.id) {
        setCurrentUser(null);
      }
      fetch();
    }
  }, [cognitoUser]);

  return [fetch, ctx] as const;
};
