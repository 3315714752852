import styled from 'styled-components'
import { Button } from 'antd'

export const AntButton= styled(Button)`
  color: #0F4C81;
  min-width: 160px;
  min-height: 40px;
  margin: 10px;

  :hover {
    color: #0F4C81;
    border-color: #0F4C81;
  }
  :focus {
    background-color: #0F4C81A0 !important;
    border: #0F4C81;
  }
`;

export default AntButton