import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOverrideSchema } from './use-override-schema';
import { mapFieldsFromSchema } from '../services/yup-mapper';
import { FormikHelpers, FormikValues } from 'formik';
import { presentError } from '@necta-tech/alert';
import Yup from 'yup';
import shortid from 'shortid';
import { exportTemplate } from '../services/template-exporter';
import { message } from 'antd'

const isNotEmpty = (obj: any): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] != null) {
      if (typeof obj[key] === 'object') return isNotEmpty(obj[key]);
      return true;
    }
  }
  return false;
};

interface Values {
  validData: any[],
  invalidData: any[]
}

export const useImport = (initialValues: any, module: string, schema: Yup.ObjectSchema<any>) => {
  const [values, setValues] = useState<Values>({ validData: [], invalidData: []});
  const _schema = useOverrideSchema(schema, module);
  const fields = useMemo(() => mapFieldsFromSchema(_schema), [_schema]);
  const [editing, setEditing] = useState<any | null>(null);
  const handleSpreadsheet = useCallback(() => exportTemplate(fields, 'oversite-' + module + '-import-template.xlsx'), [fields]);

  const validateRows: any = useCallback(
    async (data: any) => {
      const validData: any[] = [];
      const invalidData: any[] = [];

      for (const row of data) {
        const defaultRow = { ...initialValues, ...row };
        try {
          if (isNotEmpty(defaultRow)) {
            const val = await _schema.validate(defaultRow, { strict: false, stripUnknown: true });
            if (typeof val.error !== 'undefined') {
              delete val.error;
            }
            val.key = shortid.generate();
            validData.push(val);
          }
        } catch (error) {
          if (error.errors) invalidData.push({ ...defaultRow, error: error.errors[0], key: shortid.generate() });
          else invalidData.push({ ...defaultRow, error: 'Processing error', key: shortid.generate() });
        }
      }

      setValues({ validData, invalidData });
    },
    [initialValues, _schema],
  );

  const handleUpdate = useCallback(
    async (oldData: any, newData: any) => {
      if (values) {
        const _values = {...values};
        const found = _values[oldData.error ? 'invalidData' : 'validData'].find((d: any) => d.key === oldData.key);
        Object.keys(found).map((k: string) => found[k] = newData[k]);
        validateRows([..._values.validData, ..._values.invalidData]);
      }
    },
    [values, validateRows],
  );

  const handleRemove = useCallback(
    async (row: any) => {
      if (values) {
        const _values = {...values};
        _values[row.error ? 'invalidData' : 'validData'] = _values[row.error ? 'invalidData' : 'validData'].filter((d: any) => d.key !== row.key);
        validateRows([..._values.validData, ..._values.invalidData]);
      }
    },
    [values, validateRows],
  );

  const handleEditComplete = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      handleUpdate(editing, values);
      actions.resetForm(initialValues);
      setEditing(null);
    } catch (e) {
      message.error({ content: 'Unable to edit' })
      console.log(JSON.stringify(e, null, 2))
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleEdit = useCallback(
    (row: any) => {
      const _values = { ...initialValues, ...row };
      setEditing(_values);
    },
    [setEditing, initialValues],
  );

  const handleEditCancel = useCallback(
    () => {
      setEditing(null);
    },
    [setEditing],
  );

  const handleClear = useCallback(() => {
    setValues({ validData: [], invalidData: [] });
  }, [setValues]);

  const hasValues = useMemo(() => values.validData.length > 0 || values.invalidData.length > 0, [values]);

  return {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleUpdate,
    handleRemove,
    handleSpreadsheet
  };
};
