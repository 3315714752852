import { compact } from 'lodash';

export const mapArrayToObjects = (arr: string[]) => {
  return arr.map(a => Object.assign({ id: a, name: a }));
};

export const mapArrayToEnum = (arr: string[]) => {
  return arr.reduce((obj: any, a: string) => {
    return { ...obj, [a]: a };
  }, {});
};

export const mapArrayToOptions = (arr: string[]) => {
  return compact(arr.map((a: any) => Object.assign({ text: a, value: a})));
}
