import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getDateFormatted, getDateFull, mapArrayToOptions } from '../../helpers';
import { Page, AuthGuard, Avatar } from '../../components';
import { cloneDeep } from '@apollo/client/utilities';
import { useFetchCustomerList } from '../../graphql/hooks/use-fetch-customer-list';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';


export const CustomerList: React.FC<any> = props => {
  const [customer, setCustomer] = useState([]);

  const history = useHistory();

  const [fetchCustomer, { loading }] = useFetchCustomerList((result: any) => {
    if (result.customer) {
      setCustomer(cloneDeep(result.customer));
    }
  });

  const columns = useOverrideColumns(
    [
      {
        title: 'Company Name',
        key: 'companyName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Company Registration Number',
        key: 'regNum',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Company VAT Reference Number',
        key: 'vatNum',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Headquarters',
        key: 'headquarters',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Contact Person Name',
        key: 'contactName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Contact Number',
        key: 'contactNumberFull',
        filtering: true,
      },
      {
        title: 'Email Address',
        key: 'email',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Shortid',
        key: 'shortid',
        show: false,
        filtering: true,
      },
      {
        title: 'Created At',
        key: 'createdAt',
        sorting: { compare: Sort.DATE },
        render: (r: any) => (r ? getDateFull(r) : ''),
      },
    ],
    'customer',
  );

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page card>
        <Table
          data={customer}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/customer/edit/${record.id}`),
          })}
          refresh={fetchCustomer}
          title={'Customer List'}
          exportTitle={'Customer'}
          pagination={{
            defaultPageSize: 20,
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default CustomerList;
