import Moment from 'moment';
import { get } from 'lodash';

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
const dateSort = (dateA: string, dateB: string) => Moment(dateA).diff(Moment(dateB));

/**
 *
 * @param {number | string} a
 * @param {number | string} b
 */
const defaultSort = (a: number | string, b: number | string) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

// Get a value to sort by, defaults to empty string
const getValue = (record: any, col: any, id: string) => {
  return col.getValues ? col.getValues(get(record, id, '')) : get(record, id, '');
}

export const getColSort = (col: any) => {
  const id = typeof col.dataIndex === 'string' ? col.dataIndex : col.dataIndex.join('.');
  if (!col || !col.sorting) return {};
  if (col.sorting === true) return {
    sorter: {
      compare: (rowA: any, rowB: any) => Sort.DEFAULT(getValue(rowA, col, id), getValue(rowB, col, id)),
    }
  };

  const { compare, ...otherSortProps } = col.sorting;

  return {
    sorter: {
      compare: (rowA: any, rowB: any) => compare(get(rowA, id), get(rowB, id)),
      ...otherSortProps,
    }
  };
};

export const Sort = {
  DEFAULT: defaultSort,
  DATE: dateSort,
};
