import React, { useCallback, useMemo, useState } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { useDrawerOpen } from './components/antd/sidenav/redux';
import { useWindowWidth } from './hooks';
import { Layout, Button } from 'antd';
import { NotFound, UnderConstruction } from './components';
import { SideNav as Side, SideNavDrawer, AccountBanner, NavItemsAuthorized } from './components/antd/sidenav';
import { Header } from './components/Header';
import { LogoutPane } from './components/logoout-pane';
import { SessionGuard } from './components/SessionGuard';
import {
  Dashboard,
  Login,
  ForgotPassword,
  Register,
  StaffAdd,
  StaffList,
  Staff,
  UserAdd,
  AssetAdd,
  UserList,
  User,
  AssetList,
  Asset,
  StaffImport,
  AssetImport,
  Linking,
  Organisation,
  OrganisationAdd,
  OrganisationList,
  WelcomeLanding,
  Enquire,
  FAQ,
  SiteAdd,
  SiteList,
  Site,
  MyAccount,
  TicketAdd,
  Ticket,
  TicketList,
  TicketReport,
  TicketInstructions,
  OrganisationImport,
  Settings,
  CustomerList,
  CustomerAdd,
  Customer,
  ContactList,
  SiteContactList,
  ContactAdd,
  Contact,
} from './pages';
import { useLoadCurrentUser } from './graphql/hooks';
import { LoginButton } from './components/antd';
import { paths } from './paths';
import { ScrollToTop } from './components/scroll-to-top';
import { LockOutlined as Lock, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import SiteImport from './pages/site/SiteImport';
import { IconButton } from './components/antd';
import { canSelectOrganisations, getIsAdmin, Selectors } from './selectors';
import { useSelector } from 'react-redux';
import LoadingSpinner from './components/LoadingSpinner';
import { FilledOrgSelector, LoadedOrganisation, useCurrentOrganisation } from './components/organisation-selector';
import { get } from 'lodash';
import Background from './assets/clouds.png';

// TODO: Move this theming to a more global / config location
export const PRIMARY_COLOR = '#0F4C81';
export const SECONDARY_COLOR = '#5CAFE1';

const { Content } = Layout;

//DEFINE theme globals
const SCTheme = {
  primary: PRIMARY_COLOR,
  secondary: SECONDARY_COLOR,
  warning: '#FDAB0D',
  success: '#45BB58',
};

const AppContainer = styled(Layout)`
  width: 100%;
  height: 100vh;
`;

const StyledLayout = styled(Layout)`
  height: 100vh !important;
  background-image: linear-gradient(${p => p.theme.primary + 22}, ${p => p.theme.primary + 22}), url(${Background}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
`;

const LockButton = styled(Button)`
  color: #0f4c81 !important;
  svg {
    color: #0f4c81;
  }
`;

//DEFINE styled component globals
const Global = createGlobalStyle`
  body, textarea {
    margin: 0;
  }
  h1, h2, h3 {
    font-style: normal;
    font-weight: normal;
    color: #0F4C81;
  }
`;

const NavInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const Pad = styled.div`
  padding: 5px 5px 0 5px;
  width: 100%;
`;

const App: React.FC<any> = props => {
  useLoadCurrentUser();
  const [{ inSession }] = useCognitoUser();

  const history = useHistory();

  const handleContact = useCallback(() => window.open('https://oversite.cloud/contact', '_self'), []);

  return (
    <AppContainer>
      <ThemeProvider theme={SCTheme}>
        <Global />
        {!inSession && (
          <StyledLayout>
            <Header
              mode={inSession ? 'dark' : 'light'}
              actions={[
                <Button type='text' color='inherit' onClick={handleContact}>
                  Contact
                </Button>,
                <LockButton type='text' onClick={() => history.push('/')}>
                  <Lock />
                </LockButton>,
              ]}
            />

            <Content style={{ height: 'calc(100vh - 50px)', overflowY: 'auto' }} id={SCROLL_ID}>
              <ScrollToTop scrollId={SCROLL_ID} />
              <Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/contact' component={Enquire} />
                <Route exact path='/faq' component={FAQ} />
                <Route exact path='/ticket/report/:id'>
                  <TicketReport />
                </Route>
              </Switch>
            </Content>
          </StyledLayout>
        )}
        <SessionGuard
          pushTo='/'
          validPaths={['/register', '/forgot-password', '/welcome', '/faq', '/contact']}
          partmatchPaths={['/ticket/report/']}
        >
          <AuthorizedApp />
        </SessionGuard>
      </ThemeProvider>
    </AppContainer>
  );
};

// The scroll id to use for scrolling to the top on route change
const SCROLL_ID = 'layout-container';

const rules: any = {
  staff: 'CanCreateStaff',
  assets: 'CanCreateAsset',
  organisation: 'CanCreateOrg',
  site: 'CanCreateSite',
  ticket: 'CanCreateTicket',
};

const AuthorizedApp = () => {
  const [{ permissions }] = useCognitoUser();
  const isAdmin = useSelector(getIsAdmin);
  const [currentUser] = useCurrentUser();
  const [open, setOpen] = useDrawerOpen();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [width] = useWindowWidth();
  const handleOpen = useCallback((_open: boolean) => (e?: any) => setMobileOpen(_open), [mobileOpen, setMobileOpen]);

  const [currentOrg, setCurrentOrg] = useCurrentOrganisation();
  const handleOnOrgSelect = useCallback((value: LoadedOrganisation) => setCurrentOrg(value), [setCurrentOrg]);

  const canSelectOrgs = useSelector(canSelectOrganisations);

  const filteredPaths = useMemo(() => {
    if (isAdmin) return [...paths];
    const policies = currentUser?.activeOrg?.policies.map((policy: any) => policy.name) ?? [];
    return paths.filter((p: any) => {
      return !rules[p.key] || (rules[p.key] && policies.includes(rules[p.key]));
    });
  }, [paths, currentUser, isAdmin]);

  if (!currentUser || !currentUser.id) return <LoadingSpinner />;

  return (
    <React.Fragment>
      <SideNavDrawer
        title={
          <AccountBanner
            actions={[
              <IconButton
                shape='circle'
                type='text'
                icon={<MenuFoldOutlined />}
                onClick={handleOpen(false)}
                size='large'
                mode='dark'
              />,
            ]}
          />
        }
        width={276}
        placement={'left'}
        closable={false}
        onClose={handleOpen(false)}
        visible={mobileOpen}
        key={'mobile-sidenav'}
      >
        <NavInner>
          <Pad>
            <FilledOrgSelector
              hidden={!canSelectOrgs}
              value={currentOrg?.id}
              onChange={handleOnOrgSelect}
              defaultValue={get(currentOrg, 'id')}
            />
          </Pad>
          <NavItemsAuthorized paths={filteredPaths} openOverride={true} />
        </NavInner>
      </SideNavDrawer>

      <Header
        actions={[
          <FilledOrgSelector
            hidden={!canSelectOrgs || width < 768}
            value={currentOrg?.id}
            onChange={handleOnOrgSelect}
            defaultValue={get(currentOrg, 'id')}
          />,
        ]}
        leftActions={[
          <IconButton
            shape='circle'
            type='text'
            icon={<MenuUnfoldOutlined />}
            onClick={handleOpen(true)}
            size='large'
            hidden={width >= 768}
            mode='dark'
          />,
        ]}
      />

      <LogoutPane />
      <Layout>
        <Side title='OverSite' paths={filteredPaths} hidden={width < 768} />

        <Content style={{ height: 'inherit', overflowY: 'auto' }} id={SCROLL_ID}>
          <ScrollToTop scrollId={SCROLL_ID} />
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/staff/add' component={StaffAdd} />
            <Route exact path='/staff/list' component={StaffList} />
            <Route exact path='/staff/import' component={StaffImport} />
            <Route exact path='/management/create-user' component={UserAdd} />
            <Route exact path='/asset/add' component={AssetAdd} />
            <Route exact path='/asset/list' component={AssetList} />
            <Route exact path='/asset/import' component={AssetImport} />
            <Route exact path='/management/user-list' component={UserList} />
            <Route exact path='/linking' component={Linking} />
            <Route exact path='/organisation/add' component={OrganisationAdd} />
            <Route exact path='/organisation/list' component={OrganisationList} />
            <Route exact path='/organisation/import' component={OrganisationImport} />
            <Route exact path='/site/add' component={SiteAdd} />
            <Route exact path='/site/list' component={SiteList} />
            <Route exact path='/site/import' component={SiteImport} />
            <Route exact path='/ticket/add' component={TicketAdd} />
            <Route exact path='/ticket/list' component={TicketList} />
            <Route exact path='/customer/add' component={CustomerAdd} />
            <Route exact path='/customer/list' component={CustomerList} />
            <Route exact path='/contact/add' component={ContactAdd} />
            <Route exact path='/contact/list' component={ContactList} />
            <Route exact path='/contact/sitelist' component={SiteContactList} />
            <Route exact path='/ticketInstructions' component={TicketInstructions} />

            <Route exact path='/settings' component={Settings} />
            <Route exact path='/account' component={MyAccount} />
            <Route exact path='/terms' component={UnderConstruction} />

            <Route exact path='/management/user-edit/:shortid'>
              <User mode='edit' />
            </Route>
            <Route exact path='/asset/edit/:id'>
              <Asset mode='edit' />
            </Route>
            <Route exact path='/staff/edit/:id'>
              <Staff mode='edit' />
            </Route>
            <Route exact path='/customer/edit/:id'>
              <Customer mode='edit' />
            </Route>
            <Route exact path='/contact/edit/:id'>
              <Contact mode='edit' />
            </Route>
            <Route exact path='/organisation/edit/:id'>
              <Organisation mode='edit' />
            </Route>
            <Route exact path='/site/edit/:id'>
              <Site mode='edit' />
            </Route>
            <Route exact path='/ticket/edit/:id'>
              <Ticket mode='edit' />
            </Route>
            <Route exact path='/ticket/report/:id'>
              <TicketReport />
            </Route>

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default App;
