import React, { useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { AssetSelector } from '../../../asset-selector'
import { StaffSelector } from '../../../staff-selector'
import { TicketSelector } from '../../../service-selector';
import styled from 'styled-components';
import Selected from '../../selected';
import { useLinkerData, useLinkerState } from '../hooks';
import { LinkerData } from '../reducer';
import { ConfirmLink } from './ConfirmLink';
import { useCreateLink } from '../../../../graphql/hooks';
import { presentError, presentSuccess } from '@necta-tech/alert';
import { FullButtonLg } from '../../../antd';
import { SiteSelector } from '../../../site-selector';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { cleanError } from '../../../../helpers/error-helper';
import { useHistory } from 'react-router';

const { info } = Modal;

const LinkerOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const Link = styled.div<{ activated?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  text-align: center;
  border: 1px solid ${p => (p.activated ? p.theme.primary : '#333')};
  color: ${p => (p.activated ? p.theme.primary : '#333')};
  background: ${p => (p.activated ? p.theme.primary + '1a' : '')};
  padding: 20px;
  cursor: pointer;
`;

const LinkDots = styled.div`
  height: 2px;
  width: 150px;
  border-bottom: 2px dashed ${p => p.theme.primary};
  align-self: center;
`;

const SelectHeader = styled.div`
  font-weight: bold;
`;

export const Title = styled.div`
  color: ${p => p.theme.primary};
  font-size: 16px;
  padding: 20px;
  b {
    font-size: 13px;
    padding: 3px 5px;
    color: #fff;
    border-radius: 5px;
    background: ${p => p.theme.primary};
  }
`;

const SearchLabel = styled.p`
  width: 100%;
  padding-left: 5px;
  text-align: left;
`

const StaffSearch: React.FC<any> = ({ item, setItem }) => {
  return (
    <div style={{width: '90%'}}>
      <SearchLabel>Search Staff</SearchLabel>
      <StaffSelector getFormik={false} value={item?.id} onChange={setItem} defaultOptions={item ? [item] : undefined}  />
    </div>
  )
}

const AssetSearch: React.FC<any> = ({ item, setItem, organisationId }) => {
  return (
    <div style={{width: '90%'}}>
      <SearchLabel>Search Assets</SearchLabel>
      <AssetSelector getFormik={false} value={item?.id} onChange={setItem} defaultOptions={item ? [item] : undefined} organisationId={organisationId}  />
    </div>
  )
}

const SiteSearch: React.FC<any> = ({ item, setItem }) => {
  return (
    <div style={{width: '90%'}}>
      <SearchLabel>Search Sites</SearchLabel>
      <SiteSelector getFormik={false} value={item?.id} onChange={setItem} defaultOptions={item ? [item] : undefined} />
    </div>
  )
}

const TicketSearch: React.FC<any> = ({ item, setItem }) => {
  return (
    <div style={{width: '90%'}}>
      <SearchLabel>Search Tickets</SearchLabel>
      <TicketSelector getFormik={false} value={item?.id} onChange={setItem} defaultOptions={item ? [item] : undefined} />
    </div>
  )
}

const selectComponent = (str: string) => {
  switch (str) {
    case 'staff':
      return StaffSearch;
    case 'asset':
      return AssetSearch;
    case 'site':
      return SiteSearch;
    case 'ticket':
      return TicketSearch;
  }
};

const getInitialMode = (props: LinkerData) => {
  let mode = '';
  if (!props.linkedItem || isEmpty(props.linkedItem)) {
    mode = 'linkedItem';
  }
  if (!props.item || isEmpty(props.item)) {
    mode = 'item';
  }
  return mode;
};

export const Linker: React.FC<{ navigation?: boolean}> = ({ navigation }) => {
  const [linkerData, setLinkerData] = useLinkerData();
  const { item, itemType, linkedItem, linkedItemType, linkDescription } = linkerData || {};

  const history = useHistory();

  const [mode, setMode, clear] = useLinkerState();
  const [createLink, { loading }] = useCreateLink({
    onCompleted: (result: any) => {
      message.success({ content: 'Link created successfully', duration: 2 })
      clear();
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to create link'), duration: 4 });
    },
  });

  useEffect(() => {
    setMode(getInitialMode(linkerData as any));
  }, []);

  const Item = useMemo(() => selectComponent(itemType), [itemType]);
  const LinkedItem = useMemo(() => selectComponent(linkedItemType), [linkedItemType]);

  // const [selectedItem, setItem] = useState(item || null);
  // const [selectedLinkedItem, setLinkedItem] = useState(linkedItem || null);
  const setItem = useCallback((item: any) => setLinkerData({ item }), [setLinkerData]);
  const setLinkedItem = useCallback((linkedItem: any) => setLinkerData({ linkedItem }), [setLinkerData]);
  // const initialItem = useMemo(() => (item ? [item] : []), [item]);
  // const initialLinkedItem = useMemo(() => (linkedItem ? [linkedItem] : []), [linkedItem]);

  const hasSelectedItem = useMemo(() => !isEmpty(item), [item]);
  const hasSelectedLinkedItem = useMemo(() => !isEmpty(linkedItem), [linkedItem]);

  const finalize = useCallback(async (navigation?: boolean) => {
    // const confirm = await Swal.fire({
    //   title: 'Create link',
    //   text: `Please confirm you have read and understand all the link updates to be performed on creation of this link`,
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonText: 'Confirm',
    // });

    info({
      title: 'Create link',
      content: 'Please confirm you have read and understand all the link updates to be performed on creation of this link.',
      okText: 'Confirm',
      icon: <QuestionCircleOutlined />,
      closable: true,
      maskClosable: true,
      async onOk () {
        const newLinkData = {
          item: linkerData.item.id,
          itemType: linkerData.itemType,
          linkedItem: linkerData.linkedItem.id,
          linkedItemType: linkerData.linkedItemType,
          performFixes: true,
        };
        await createLink({ variables: { newLinkData } });
        if (navigation) history.goBack();
      },
    });
  }, [linkerData, createLink]);

  const next = useCallback(() => {
    if (mode === 'item' || mode === 'linkedItem') {
      return setMode('');
    }
    if (mode === '') {
      if (hasSelectedItem && hasSelectedLinkedItem) {
        return setMode('finalize');
      }
    }
    if (mode === 'finalize' && hasSelectedItem && hasSelectedLinkedItem) {
      finalize(navigation);
    }
    // Finalize below
  }, [mode, setMode, hasSelectedItem, navigation, history, hasSelectedLinkedItem, finalize]);

  const changeMode = useCallback((newMode: string) => () => setMode(newMode), [setMode]);

  const organisationId = useMemo(() => item?.organisationId || linkedItem?.organisationId, [item, linkedItem]);

  const nextText = useMemo(() => {
    if (mode === 'item' || mode === 'linkedItem') {
      return 'Confirm';
    }
    if (mode === '') {
      return 'Next';
    }
    return 'Finalize';
  }, [mode]);

  return (
    <LinkerOuter>
      {mode === '' && (
        <Title>
          <b>CREATE LINK</b> {linkDescription || `${linkedItemType} will be linked to ${itemType}`}:
        </Title>
      )}
      {mode === 'finalize' && (
        <Title>
          <b>CONFIRM</b> Review the link you want to make:
        </Title>
      )}
      {(mode === '' || mode === 'finalize') && (
        <Links>
          <Link activated={hasSelectedItem} onClick={changeMode('item')}>
            <SelectHeader>Selected {itemType}:</SelectHeader>
            <Selected item={item} itemType={itemType} />
          </Link>
          <LinkDots> </LinkDots>
          <Link activated={hasSelectedLinkedItem} onClick={changeMode('linkedItem')}>
            <SelectHeader>Selected {linkedItemType}:</SelectHeader>
            <Selected item={linkedItem} itemType={linkedItemType} />
          </Link>
        </Links>
      )}
      {mode === 'item' && Item && <Item getFormik={false} item={item} setItem={setItem} organisationId={organisationId} />}
      {mode === 'linkedItem' && LinkedItem && <LinkedItem getFormik={false} item={linkedItem} setItem={setLinkedItem} organisationId={organisationId} />}
      {mode === 'finalize' && <ConfirmLink />}

      <FullButtonLg loading={loading} onClick={next}>
        {nextText}
      </FullButtonLg>
    </LinkerOuter>
  );
};

Linker.defaultProps = {
  navigation: true
}

export default Linker;
