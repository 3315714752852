import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, Action as PageAction, FormLoading, AuthGuard } from '../../components';
import { useFetchCustomer } from '../../graphql/hooks';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ETHNICITIES, GENDERS, STAFF_DEPARTMENTS } from '../../constants';
import { mapArrayToObjects } from '../../helpers';
import * as Yup from 'yup';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Input, ContactNumber, Select, FormRow, FormButton } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs } from 'antd';
import { message } from 'antd';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd';
import { allCustomerFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser;

const Collapse = styled(Collapser)`
  width: 100%;
`;

const staffSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  companyName: Yup.string()
    .required()
    .label('Company Name'),
  regNum: Yup.string()
    .required()
    .label('Company Registration Number'),
  vatNum: Yup.string()
    .required()
    .label('Company VAT Number'),
  headquarters: Yup.string()
    .required()
    .label('Headquarters'),
  contactName: Yup.string()
    .required()
    .label('Contact Person Name'),
  contactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .label('Province'),
      country: Yup.string()
        .nullable()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .label('Postal Code'),
    })
    .nullable(),
});

const { initialValues, schema, fields } = getFormData(staffSchema);

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateCustomer: UpdateCustomerInput!) {
    updateCustomer(id: $id, updateCustomer: $updateCustomer) {
      ${allCustomerFields}
    }
  }
`;

const Submit = styled(FormButton)`
  margin-top: 30px;
`;

interface Props {
  mode: 'view' | 'edit';
}

export const Customer: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const [customer, setCustomer] = useState(initialValues);
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchCustomer, { loading }] = useFetchCustomer({
    id,
    onCompleted: (result: any) => {
      setCustomer(result.customerSingle);
    },
  });

  const [handleUpload] = useMutation(UPLOAD);

  const { fields, ...formikCtx } = useFormData(schema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      console.log(values);
      const { id, contactNumberFull, ...rest } = values;
      return handleUpload({
        variables: {
          id,
          updateCustomer: {
            ...rest,
          },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Customer updated successfully', duration: 2 });
      setCustomer({ id, ...result?.data?.updateCustomer });
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update Customer'), duration: 4 });
    },
    override: 'customer',
  });

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCtx} initialValues={customer} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, dirty }) => (
          <Page
            card
            title={`${edit ? 'Edit' : 'View'} Customer`}
            onBack={() => history.goBack()}
            extra={[
              <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
              <RefreshAction onClick={() => fetchCustomer()} />,
              <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
            ]}
          >
            <Form layout='vertical'>
              <Collapse defaultActiveKey='verification' ghost>
                <Panel header='Customer Information' key='verification'>
                  <FormRow disabled={!edit}>
                    <Input {...fields.companyName} />
                    <Input {...fields.regNum} />
                    <Input {...fields.vatNum} />
                    <Input {...fields.contactName} />
                    <ContactNumber
                      numberField={fields.contactNumber}
                      countryCodeField={fields.contactNumberCountryCode}
                    />
                    <Input {...fields.email} />
                  </FormRow>
                </Panel>

                <Panel header='Location' key='location'>
                  <FormRow disabled={!edit}>
                    <Input {...fields.headquarters} />
                    <Input {...fields.address.addressLine1} />
                    <Input {...fields.address.country} />
                    <Input {...fields.address.state} />
                    <Input {...fields.address.postalCode} />
                  </FormRow>
                </Panel>
              </Collapse>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

export default Customer;
