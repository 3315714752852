import React, { FC } from 'react';
import styled from 'styled-components';
import TrackWhite from '../../assets/oversite-blue.png'

interface IProps {
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: 60px;
  }
  h1 {
    padding-left: 20px;
    color: white;
    font-size: 40px;
    padding-bottom: 0;
  }
`;

export const TrackHeader: FC<IProps> = ({ ...props}) => {
  const {} = props;
  return (
    <HeaderContainer>
      <img alt='header logo' src={TrackWhite} />
      <h1>OverSite</h1>
    </HeaderContainer>
  );
};

TrackHeader.defaultProps = {};

export default TrackHeader;
