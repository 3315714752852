import React, { useCallback, useState, useEffect, FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from 'lodash'
import { Layout, Divider, Menu as AntMenu, Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer';
import { useDrawerOpen } from './redux'
import { CollapseButton } from './CollapseButton'
import NavFooter from './AccountBanner';
import { useSelector } from 'react-redux';
import { getCurrentUserRole } from '../../../selectors';

const { Item } = AntMenu

const { Sider } = Layout

const defaultWidth = {
  open: 240,
  closed: 70
}

const Menu = styled(AntMenu)`
  li {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  svg {
    margin-right: 5px;
  }
`;

const SideContainer = styled(Sider)`

  position: relative;
  .ant-layout-sider {
    background: white;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .ant-layout {
    background-color: white;
    height: 100%;
  }

  .sider-button-container, .sider-banner {
    height: 64px;
  }

  & > div {
    width: 100%;
  }
`

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;

  height: calc(100vh - 50px);

  & > * {
    width: 100%;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
`

interface ListItemProps {
  color?: string;
  small?: boolean;
}
const ListItem = styled(Item)<ListItemProps>`
  ${ p => !p.color && css`color: ${p.color};` || '' };
  font-size: ${p => p.small ? '12px' : '14px'};
  svg {
    width: ${p => p.small ? '12px' : '14px'};
    height: ${p => p.small ? '12px' : '14px'};
  }
`

const Divide = styled(Divider)`
  margin: 0;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
`

interface SubMenuProps {
  open?: boolean;
}

const SubMenu = styled(Menu)<SubMenuProps>`
  padding-left: ${p => p.open ? '5px' : 0};
  background: #f0f2f536 !important;
`

type ThemeType = 'light' | 'dark' | 'default'

interface NavProps {
  paths: any;
  theme?: ThemeType;
  title: string,
  logo?: any
  hidden?: boolean;
}

const useThemeProp = (val: ThemeType) => {
  const [theme, setTheme] = useState('light')
  useEffect(() => {
    if (val !== theme) setTheme(val)
  }, [setTheme, val])
  return [theme, setTheme] as const
}

const useOpenKeys = (initialOpenKeys: string[] = []) => {
  const [openKeys, setOpenKeys] = useState<string[]>(initialOpenKeys)

  const isExpanded = useCallback((path: string) => openKeys.includes(path), [openKeys])

  const handleExpanded = useCallback((path: string) => {
    const newKeys = cloneDeep(openKeys)
    if (isExpanded(path)) {
      setOpenKeys(newKeys.filter((key: string) => key !== path))
      return
    }
    newKeys.push(path)
    setOpenKeys(newKeys)

  }, [isExpanded, setOpenKeys])

  return [
    openKeys,
    {
      isExpanded,
      handleExpanded
    }
  ] as const
}

export const SideNavComponent: React.FC<NavProps & DrawerProps> = ({ hidden, theme: themeProp = 'light', logo, className, paths, ...props }) => {
  const [theme, setTheme] = useThemeProp(themeProp)
  const [open, setOpen] = useDrawerOpen()

  if (hidden) return null;

  return (
    <SideContainer
      collapsible
      collapsed={!open}
      onCollapse={() => setOpen(false)}
      trigger={null}
      theme={theme}
      width={open ? defaultWidth.open : defaultWidth.closed}
    >
      <CollapseButton />
      <SideContent className='sider-content'>
        <NavItemsAuthorized paths={paths} />
        <NavFooterFull />
      </SideContent>
    </SideContainer>
  )
};

interface NavItemsAuthorized {
  paths: any;
  openOverride?: boolean;
}

export const NavItemsAuthorized: FC<NavItemsAuthorized> = ({ paths, openOverride }) => {

  const [open] = useDrawerOpen()
  const history = useHistory();
  const [openKeys, { isExpanded, handleExpanded }] = useOpenKeys()
  const location = useLocation()

  const role = useSelector(getCurrentUserRole)

  const checkRoles = useCallback((roles: any) => {
    if (!roles) return true;
    return roles.includes(role);
  }, [role]);

  const handleNavigate = useCallback((path: string) => (e: any) => history.push(path), [history]);

  const getColor = useCallback((item: { path: string }) => location.pathname.includes(item.path) ? 'green' : undefined, [location]);

  const _open = useMemo(() => openOverride != null ? openOverride : open, [openOverride, open]);

  return (
    <Menu mode='inline' inlineCollapsed={!_open} openKeys={openKeys}>
      {
        paths.map((item: any) => {
          if (!checkRoles(item.roles) || item.hidden) return null
          return !item.subItems ?
            <ListItem title={item.name} onClick={handleNavigate(item.path)} key={item.path} color={getColor(item)}>
              { item.icon }
              { _open && item.name }
            </ListItem>
            : (
              <React.Fragment>

                <ListItem title={item.name} onClick={(e: any) => handleExpanded(item.path)} key={item.path} color={getColor(item)}>
                  { item.icon }
                  { _open && item.name }
                </ListItem>
                { isExpanded(item.path) && <Divide /> }
                <SubMenu mode='inline' open={_open} inlineCollapsed={!_open} title={item.name} hidden={!isExpanded(item.path)}>
                  { item.subItems.map((subItem: any) => {
                    if (!checkRoles(subItem.roles) || subItem.hidden) return null
                    return (
                      <ListItem title={subItem.name} onClick={handleNavigate(subItem.path)} key={subItem.path} color={getColor(subItem)} small>
                        { subItem.icon }
                        { _open && subItem.name }
                      </ListItem>
                    )
                    }
                  )}
                </SubMenu>
                { isExpanded(item.path) && <Divide /> }
              </React.Fragment>
            )
        })
      }
    </Menu>
  )
}

export const NavFooterFull: FC = () => {
  const [open] = useDrawerOpen()

  return <BottomContainer>
    <NavFooter collapsed={!open}/>
  </BottomContainer>
}

export const SideNavDrawer = styled(Drawer)`
  .ant-drawer-body, .ant-drawer-header {
    padding: 0;
  }
`

export const SideNav = SideNavComponent

export * from './AccountBanner';

export default SideNav
