import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate as Gate } from 'redux-persist/integration/react';
import App from './App';
import { message } from 'antd';
import { LoadingSpinner } from './components';

message.config({
  maxCount: 1
});

const Root = ({ apolloClient, store, persistor }: any) => {
  const Apollo = apolloClient ? ApolloProvider : React.Fragment;
  const Provider = store ? ReduxProvider : React.Fragment;
  const PersistGate = store ? Gate : React.Fragment;

  return (
    <Apollo client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </Apollo>
  );
};

export default hot(module)(Root);
