import * as Yup from 'yup';
import {
  SA_PROVINCES,
  ORGANISATION_DEPARTMENTS,
  REGIONS, STATUSES, WORK_STATUSES
} from '../constants';

export const organisationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Company Name is a required field')
    .typeError('Company Name is a required field')
    .label('Company Name'),
  contactName: Yup.string()
    .nullable()
    .notRequired()
    .label('Primary Contact'),
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),
  contactNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  departments: Yup.array()
    .nullable()
    .notRequired()
    .meta({ type: 'multiselect', options: [null, ...ORGANISATION_DEPARTMENTS] })
    // TODO: we can use the .test() method on each key to confirm validity
    .default([])
    .label('Departments'),
  regions: Yup.array()
    .nullable()
    .notRequired()
    .meta({ type: 'multiselect', options: [null, ...REGIONS] })
    .default([])
    .label('Regions'),
  status: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...STATUSES])
    .meta({ type: 'select' })
    .label('Status'),
  workStatus: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...WORK_STATUSES])
    .meta({ 'string': true })
    .label('Work Status'),
  notes: Yup.string()
    .nullable()
    .notRequired()
    .label('Notes'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .notRequired()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .notRequired()
        .oneOf([null, ...SA_PROVINCES])
        .meta({ type: 'select' })
        .label('Province'),
      country: Yup.string()
        .nullable()
        .notRequired()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .notRequired()
        .label('Postal Code'),
    })
    .label('address'),
});

export const confirmOrganisationSchema = Yup.object().shape({
  parentOrganisationId: Yup.string()
    .nullable()
    .notRequired()
    .label('Parent Organisation'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});

export type Organisation = Yup.InferType<typeof organisationSchema>;

export default organisationSchema;
