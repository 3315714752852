import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, FormLoading, AuthGuard } from '../../components/Page';
import { useFetchAsset } from '../../graphql/hooks';
import {
  EditOutlined as Edit,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { ASSIGN_ASSET_TO_SITE } from '../../components';
import { mapArrayToObjects } from '../../helpers';
import { CATEGORIES, CONDITIONS, SERVICE_STATUSES, STATUSES } from '../../constants';
import { cloneDeep } from '@apollo/client/utilities';
import { ASSIGN_ASSET_TO_STAFF } from '../../components/linking/linker/components';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Tags, Input, ContactNumber, Select, FormRow, DatePicker, TextArea } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs } from 'antd';
import { FileUploader } from '../../components/file-uploader'
import { TeamOutlined } from '@ant-design/icons';
import PicPlaceholder from '../../assets/asset_picture.png';
import ProfilePictureUploader from '../../components/profile-picture-uploader';
import { message } from 'antd'
import { LinkedSites, LinkedStaff } from '../../components/linked-items';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd'
import { allAssetFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser;
const { TabPane } = AntTabs

const Collapse = styled(Collapser)`
  width: 100%;
`

const Tabs = styled(AntTabs)`
  width: 100%;
`

const statuses = mapArrayToObjects(STATUSES);
const serviceStatuses = mapArrayToObjects(SERVICE_STATUSES);
const conditions = mapArrayToObjects(CONDITIONS);
const categories = mapArrayToObjects(CATEGORIES);

const assetSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Asset Name'),
  manufacturer: Yup.string()
    .nullable()
    .label('Manufacturer'),
  modelNumber: Yup.string()
    .nullable()
    .label('Model Number'),
  registrationNumber: Yup.string()
    .nullable()
    .label('Registration Number'),
  condition: Yup.string()
    .nullable()
    .label('Condition'),
  dateLastServiced: Yup.string()
    .nullable()
    .meta({ type: 'date' })
    .label('Date of Last Service'),
  datePurchased: Yup.string()
    .nullable()
    .meta({ type: 'date' })
    .label('Date of Purchase'),
  valueOriginal: Yup.string()
    .nullable()
    .label('Value of Purchase'),
  valueCurrent: Yup.string()
    .nullable()
    .label('Current Book Value'),
  status: Yup.string()
    .nullable()
    .label('Status'),
  serviceStatus: Yup.string()
    .nullable()
    .label('Service Status'),
  category: Yup.string()
    .nullable()
    .label('Category'),
  primaryImage: Yup.string()
    .nullable()
    .label('Primary Image'),
  notes: Yup.string()
    .nullable()
    .label('Notes'),
  tags: Yup.array()
    .default([])
    .nullable()
    .label('Tags'),
});

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateAsset: UpdateAssetInput!) {
    updateAsset(id: $id, updateAsset: $updateAsset) {
      ${allAssetFields}
    }
  }
`;

interface Props {
  mode: 'view' | 'edit';
}

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const Asset: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const { fields, initialValues, ...formikCTX } = useFormData(assetSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { dateLastServiced, datePurchased, documents, assignedTo, assignedSite, contactNumberFull, ...restValues } = values;
      const updateAsset = {
        ...restValues,
        dateLastServiced: dateLastServiced ? dateLastServiced : null,
        datePurchased: datePurchased ? datePurchased : null,

      }
      return handleUpload({
        variables: {
          id,
          updateAsset
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Asset updated successfully', duration: 2 })
      setAsset({ id, ...result?.data?.updateAsset })
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update asset'), duration: 4 });
    },
    override: 'asset'
  });

  const [asset, setAsset] = useState({ ...initialValues });
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchAsset, { loading }] = useFetchAsset({
    id,
    onCompleted: (result: any) => {
      setAsset(result.asset);
    },
  });

  const [handleUpload] = useMutation(UPLOAD);

  const assignedTo = useMemo(() => (asset && asset.assignedTo ? [cloneDeep(asset.assignedTo)] : null), [asset]);
  const assignedSite = useMemo(() => (asset && asset.assignedSite ? [cloneDeep(asset.assignedSite)] : null), [asset]);

  if (loading) return <FormLoading />

  return (
    <AuthGuard
      needsActiveOrganisation
    >

    <Formik {...formikCTX} initialValues={asset} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values, dirty }) => (
        <Page
          card
          title={`${edit ? 'Edit' : 'View'} Asset`}
          onBack={() => history.goBack()}
          extra={[
            <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
            <RefreshAction onClick={() => fetchAsset()} />,
            <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
          ]}
        >
          <Form layout='vertical'>

            <Tabs defaultActiveKey='info' tabPosition='top' size='large'>

              <TabPane tab='Info' key='info'>

                <Collapse defaultActiveKey='information' ghost>

                  <Panel header='Asset Information' key='information'>
                    <ProfilePictureUploader
                      disabled={!edit || isSubmitting}
                      fieldKey='primaryImage'
                      defaultPic={PicPlaceholder}
                    >
                      <h4>
                        Primary Picture
                        <br />
                        Drag and Drop or Click to search for a File.
                      </h4>
                    </ProfilePictureUploader>

                    <FormRow disabled={!edit}>
                      <Input {...fields.name} />
                      <Input {...fields.manufacturer} />
                      {/*<OrgSelector hidden={ values.role !== 'Organisation' } disabled={!canSelectOrgs} {...fields.activeOrgId}  />*/}
                      <Input {...fields.modelNumber} />
                      <Input {...fields.registrationNumber} />
                      <Select options={conditions} {...fields.condition} allowClear />
                      <DatePicker {...fields.dateLastServiced} />
                      <DatePicker {...fields.datePurchased} />
                      <Input {...fields.valueOriginal} />
                      <Input {...fields.valueCurrent} />
                      <Select options={statuses} {...fields.status} allowClear />
                      <Select options={serviceStatuses} {...fields.serviceStatus} allowClear />
                    </FormRow>
                   </Panel>

                   <Panel header='Asset Categories' key='categories'>
                     <FormRow disabled={!edit}>
                       <Select gridProps={GRID_PROPS} options={categories} {...fields.category} allowClear />
                       <Tags
                          isEditable={false}
                          gridProps={GRID_PROPS}
                          {...fields.tags}
                          placeholder='Type Text and press Enter'
                        />
                     </FormRow>
                   </Panel>

                   <Panel header='Notes' key='notes'>
                      <FormRow disabled={!edit}>
                        <TextArea {...fields.notes} rows={7} gridProps={GRID_PROPS} />
                      </FormRow>
                  </Panel>


                </Collapse>

              </TabPane>

              <TabPane tab='Linked Staff' key='linked-staff'>
                <FormRow disabled={!edit}>
                  <LinkedStaff item={null} linkedItem={asset} data={assignedTo} protocol={ASSIGN_ASSET_TO_STAFF} />
                </FormRow>
              </TabPane>

              <TabPane tab='Linked Sites' key='linked-sites'>
                <FormRow disabled={!edit}>
                  <LinkedSites item={null} linkedItem={asset} data={assignedSite} protocol={ASSIGN_ASSET_TO_SITE} />
                </FormRow>
              </TabPane>

              <TabPane tab='Documents' key='documents'>
                <FormRow disabled={!edit}>
                  <FileUploader id={asset.id} parentType='asset' fileType='DOCUMENT' initialFiles={asset.documents} />
                </FormRow>
              </TabPane>


            </Tabs>

          </Form>
        </Page>
      )}
    </Formik>
    </AuthGuard>
  );
};

export default Asset;
