import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 10px;
`;

const getDisplayText = (item: any, itemType: string) => {
  switch (itemType) {
    case 'asset':
      return `${item.name || ''} ${item.registrationNumber || ''}`;
    case 'staff':
      return `${item.firstname || ''} ${item.lastname || ''}`;
    case 'site':
      return `${item.name || ''}`;
    case 'ticket':
      return `${item.name || ''}`;
    default:
      return item.id;
  }
};

interface IProps {
  item?: any;
  itemType: string;
}

const Selected: FC<IProps> = props => {
  const { item, itemType } = props;
  return (
    <Container>
      {item && getDisplayText(item, itemType)}
      {!item && 'None'}
    </Container>
  );
};

Selected.defaultProps = {};

export default Selected;
