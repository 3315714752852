import React, { Fragment, FC, useLayoutEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CenteredColumn } from '../../styles/global';
import CardTitle from '../card-title'
import ListLoader from './ListLoader';
import { useWindowWidth } from '../../hooks';
import FieldLoader from './FieldLoader';
import { Button, Divider } from 'antd';
import { ArrowLeftOutlined as Back } from '@ant-design/icons';
import { Card } from '../antd';

export const Page = styled.div<{ paddingTop: string, paddingBottom: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;

  width: 100%;

  padding-top: ${p => p.paddingTop};
  padding-bottom: ${p => p.paddingBottom};
`;

declare interface LoaderContainerProps {
  marginTop?: string;
  marginBottom?: string;
}
const LoaderContainer = styled.div<LoaderContainerProps>`
  margin-top: ${p => (p.marginTop ? p.marginTop : '30px')};
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '30px')};
`;

interface SkeletonLoader {
  type: string;
  title?: string;
  showHeader?: boolean;
  showCard?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  iterations?: number;
  speed?: number;
  marginTop?: string;
  marginBottom?: string;
  defaultHeight?: number;
  paddingTop?: string;
  paddingBottom?: string;
}

const SkeletonLoader: FC<SkeletonLoader> = ({
  backgroundColor,
  foregroundColor,
  speed,
  showHeader,
  showCard,
  title,
  iterations,
  type,
  marginTop,
  marginBottom,
  defaultHeight,
  paddingTop,
  paddingBottom,
}) => {
  const targetRef: any = useRef();
  const history = useHistory();

  const [width, setWidth] = useState(0);

  const [windowWidth] = useWindowWidth();

  useLayoutEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth * 0.96);
    }
  }, [setWidth, targetRef, windowWidth]);

  const getLoader = () => {
    switch (type) {
      case 'list':
        return (
          <ListLoader
            width={width}
            speed={speed || 0}
            foregroundColor={foregroundColor || ''}
            backgroundColor={backgroundColor || ''}
            iterations={iterations || 0}
          />
        );
      case 'field':
        return (
          <FieldLoader
            width={width}
            speed={speed || 0}
            foregroundColor={foregroundColor || ''}
            backgroundColor={backgroundColor || ''}
            iterations={iterations || 1}
            defaultHeight={defaultHeight}
          />
        );
    }
  };

  const Content = () => {
    return (
      <CenteredColumn ref={targetRef}>
        {showHeader ? (
          <Fragment>
            <CardTitle>
              <Button type="text" onClick={() => history.goBack()}>
                <Back />
              </Button>
              {title}
            </CardTitle>
            <Divider />
          </Fragment>
        ) : null}
        <LoaderContainer marginTop={marginTop} marginBottom={marginBottom}>
          {getLoader()}
        </LoaderContainer>
      </CenteredColumn>
    );
  };

  return (
    <Page paddingTop={paddingTop || ''} paddingBottom={paddingBottom || ''}>
      {showCard ? (
        <Card>
          <Content />
        </Card>
      ) : (
        <Content />
      )}
    </Page>
  );
};

SkeletonLoader.defaultProps = {
  backgroundColor: '#f3f3f3',
  foregroundColor: '#e6f7fe',
  speed: 2,
  iterations: 2,
  title: 'Go Back',
  showHeader: true,
  showCard: true,
  type: 'list',
  paddingTop: '20px',
  paddingBottom: '20px',
};

export default SkeletonLoader;
