import * as Yup from 'yup';

export const ticketSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Ticket Name'),
  type: Yup.string()
    .required()
    .label("Ticket Type"),
  status: Yup.string()
    .nullable()
    .label("Ticket Status"),
  description: Yup.string()
    .nullable()
    .label('Description'),  
  customerName: Yup.string()
    .trim()
    .required()
    .label('Customer Name'),
  primaryContactName: Yup.string()
    .nullable()
    .label('Contact Name'),
  primaryContactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactNumberCountryCode: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  dueDate: Yup.date()
    .required('Please enter a valid due date')
    .typeError('Please enter a valid due date')
    .meta({ type: 'date' })
    .label('Due Date'),
  tags: Yup.array()
    .nullable()
    .label('Tags'),
  address: Yup.object().shape({
    fullAddress: Yup.string()
      .nullable()
      .label('Full Address'),
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }).label('Search for address'),
});

export const addTicketSchema = ticketSchema.shape({
  assignedSiteId: Yup.string()
    .required()
    .label('Site'),
  isRepeats: Yup.boolean()
    .label('Repeats'),
  repeatInterval: Yup.string()
    .label("Repeat Interval")
});

export const ticketConfirmSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .label('Instructions for Technicians'),
  completionComment: Yup.string()
    .nullable()
    .label('Comment for Client'),
  dueDate: Yup.date()
    .required('Please enter a valid due date')
    .typeError('Please enter a valid due date')
    .label('Due Date'),
});

export const ticketAddTechSchema = Yup.object().shape({
  userId: Yup.string()
    .required('Please select a technician')
    .typeError('Please select a technician')
    .label('Assign Technician'),
});
