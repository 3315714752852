import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Page, FormLoading, NotFound, Avatar } from '../../components';
import { useLazyQuery } from '@apollo/client';
import { FileUploader } from '../../components/file-uploader';
import { cloneDeep } from '@apollo/client/utilities';
import { Button, Card as AntCard, Collapse, Timeline, Comment, List, Tooltip, Progress, Popconfirm } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { LinkedSites, LinkedStaff } from '../../components/linked-items';
import { RefreshAction } from '../../components/antd';
import { FETCH_TICKET_SAFE } from './gql';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { Ticket, Step } from './types';
import Moment from 'moment';
import { formatDate } from '../../helpers/string-transforms';
import { getThumbnail } from '@necta-tech/s3';
import { useActiveSteps } from './components/redux';
import {
  FileOutlined,
  DownloadOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { DescItem } from './components';

const { Panel } = Collapse;

const FilesOuter = styled(AntCard)`
  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  margin-bottom: 10px;
  width: 100%;
`;

const FileItem = styled(FilesOuter)`
  cursor: pointer;
  margin-bottom: 5px;
`;

const Preview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
`;

const Image = styled(Avatar)`
  background: transparent !important;
  svg {
    color: ${p => p.theme.primary} !important;
  }
`;

const Card = ({ hidden, children, ...props }: any) => {
  if (hidden) return null;

  return (
    <AntCard style={{ marginBottom: '15px' }} {...props}>
      {children}
    </AntCard>
  );
};

const formatDateTime = (date?: Date | string) => {
  if (!date || date === '') return '';
  return Moment(date).format('DD/MM/YYYY - HH:mm a');
};

const formatTime = (date?: Date | string) => {
  if (!date || date === '') return '';
  return Moment(date).format('HH:mm a');
};

const formatDateNice = (date?: Date | string) => {
  if (!date || date === '') return '';
  return Moment(date).format(`Do MMM 'YY - HH:mm a`);
};

const EmailLink = ({ email }: any) => {
  if (!email || email === '') return null;
  return (
    <a href={`mailto:${email}`}>
      <span key='comment-nested-reply-to'>Reply to</span>
    </a>
  );
};

const Comments = ({ comments, actions }: any) => {
  if (!comments || comments.length <= 0) return null;

  return (
    <List
      className='comment-list'
      header={`${comments.length} comment${comments.length > 1 ? 's' : ''}`}
      itemLayout='horizontal'
      dataSource={comments}
      renderItem={(c: any) => (
        <li>
          <Comment
            actions={[<EmailLink email={c.email} />]}
            author={<a>{c.author}</a>}
            avatar={<Avatar src={getThumbnail(c?.profilePicUrl)} icon={<UserOutlined />} />}
            datetime={c.date ? formatDateNice(c.date) : undefined}
            content={c.content}
          >
            <Comments comments={c.comments} actions={actions} />
          </Comment>
        </li>
      )}
    />
  );
};

const Files: React.FC<{ files?: any[] }> = ({ files }) => {
  const handleDownload = useCallback(
    (file: any) => (e?: any) => {
      e.stopPropagation();
      window.open(file.signedUrl, '_blank');
    },
    [],
  );

  if (!files || files.length <= 0) return null;

  return (
    <FilesOuter title={'Files'} size={'small'}>
      {files.map((file: any) => (
        <FileItem hoverable key={file.id} onClick={handleDownload(file)} size={'small'}>
          <Image shape='square' src={file.signedUrl} icon={<FileOutlined />} />
          <Preview>{file.name}</Preview>
          <DownloadOutlined />
        </FileItem>
      ))}
    </FilesOuter>
  );
};

interface TicketReportProps {
  ticket: Ticket;
}

export const TicketReportInner: React.FC<TicketReportProps> = ({ ticket }) => {
  const history = useHistory();
  const [currentUser] = useCurrentUser();

  const assignedStaff = useMemo(() => (ticket?.assignedStaff ? cloneDeep(ticket.assignedStaff) : null), [ticket]);
  const assignedSite = useMemo(() => (ticket?.assignedSite ? [cloneDeep(ticket.assignedSite)] : null), [ticket]);

  const ticketPending = useMemo(
    () => ticket && !['Complete', 'In Progress', 'Awaiting Verification'].includes(ticket.status || ''),
    [ticket],
  );

  // This is simply to filter out non-public documents in tab view
  // Currently does not refresh when a new doc is added or edited
  const documents = useMemo(() => ticket?.documents?.filter((d: any) => d.isPublic) || [], [ticket?.documents]);

  const getColour = useCallback((step: Step) => {
    if (step.done) {
      switch (step.completionStatus) {
        case 'Working':
          return 'green';
        case 'Assessing':
          return 'blue';
      }
    }
    return 'grey';
  }, []);

  const getIcon = useCallback((step: Step) => {
    if (step.done) {
      switch (step.completionStatus) {
        case 'Working':
          return (
            <Tooltip title={step.completionStatus}>
              <CheckCircleOutlined style={{ color: '#52C41A' }} />
            </Tooltip>
          );
        case 'Working but Defective':
        case 'Service Required':
          return (
            <Tooltip title={step.completionStatus}>
              <IssuesCloseOutlined style={{ color: '#FF7900' }} />
            </Tooltip>
          );
        case 'Not Working':
        case 'Unit Replacement Required':
        case 'Parts Replacement Required':
          return (
            <Tooltip title={step.completionStatus}>
              <WarningOutlined style={{ color: '#FF4D4F' }} />
            </Tooltip>
          );
      }
    }
    return undefined;
  }, []);

  const handleViewAsset = useCallback((id: string) => (e?: any) => history.push(`/asset/edit/${id}`), [history]);

  if (!ticket) return null;

  return (
    <>
      <Card title={'Summary'} style={{ marginBottom: '10px' }}>
        <div>
          <b>Site:</b> {ticket.assignedSite?.name}
        </div>
        <div>
          <b>Date:</b> {formatDate(ticket.dueDate)}
        </div>
        <div>
          <b>ETA:</b> {formatTime(ticket.dueDate)}
        </div>
        <div>
          <b>Status:</b> {ticket.status}
        </div>
        {ticketPending && (
          <div>
            <b>Info:</b> Ticket will update with more information once technician has arrived on site.
          </div>
        )}
      </Card>

      <Card title={'Ticket Journey'} hidden={ticketPending}>
        <Timeline pending={ticket.status === 'In Progress' ? 'Technician on site...' : false} mode={'left'}>
          <Timeline.Item>
            <div style={{ marginBottom: '5px' }}>{formatDateTime(ticket.startDate)}</div>
            <div>Technician Arrived on Site</div>
          </Timeline.Item>
          {ticket.steps?.map((step: Step) => {
            if (step.assignedAsset)
              return (
                <Timeline.Item color={getColour(step)} dot={getIcon(step)}>
                  <>
                    <div style={{ marginBottom: '5px' }}>
                      {step.completionDate ? formatTime(step.completionDate) : ''}
                    </div>
                    <Collapse>
                      <Panel header={step.assignedAsset.name} key={step.id}>
                        <DescItem label='Status' text={step.completionStatus} />
                        <DescItem label='Comment' text={step.completionInfo} />
                        <DescItem label='Manufacturer' text={step.assignedAsset.manufacturer} />
                        <DescItem label='Model Number' text={step.assignedAsset.modelNumber} />
                        <DescItem label='Registration Number' text={step.assignedAsset.registrationNumber} />
                        <DescItem label='Condition' text={step.assignedAsset.condition} />
                        <DescItem label='Category' text={step.assignedAsset.category} />
                        <DescItem label='Tags' text={step.assignedAsset.tags?.join(', ')} />
                        <DescItem label='Date Purchased' text={formatDate(step.assignedAsset.datePurchased)} />
                        <Comments comments={step.comments} />
                        {currentUser && <Files files={step.documents} />}
                        {currentUser && <Button onClick={handleViewAsset(step.assignedAsset.id)}>View</Button>}
                      </Panel>
                    </Collapse>
                  </>
                </Timeline.Item>
              );
          })}
          {ticket.status !== 'In Progress' && (
            <Timeline.Item>
              <div style={{ marginBottom: '5px' }}>{formatDateTime(ticket.completionDate)}</div>
              <div>Technician Completed Ticket</div>
            </Timeline.Item>
          )}
        </Timeline>
      </Card>

      <Card title={'Comments'} hidden={!ticket.comments || ticket.comments?.length <= 0}>
        <Comments comments={ticket.comments} />
      </Card>

      <Card title={'Documents'} hidden={documents?.length <= 0}>
        <FileUploader
          id={ticket?.id}
          parentType='ticket'
          fileType='DOCUMENT'
          initialFiles={documents}
          showFields={false}
          disabled
        />
      </Card>
    </>
  );
};

interface Props {}

export const TicketReport: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const [currentUser] = useCurrentUser();
  const history = useHistory();
  const [ticket, setTicket] = useState<any>(null);

  const [fetchTicket, { loading }] = useLazyQuery(FETCH_TICKET_SAFE, {
    variables: { id },
    onCompleted: (result: any) => {
      setTicket(result.ticketByShortid);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchTicket();
  }, []);

  const handleBack = useCallback(() => (currentUser ? history.goBack() : undefined), [history, currentUser]);
  const handleRefresh = useCallback(() => fetchTicket(), [fetchTicket]);

  if (loading) return <FormLoading />;

  if (!ticket) return <NotFound handleBack={handleBack} handleRefresh={handleRefresh} />;

  return (
    <Page
      card
      title={`${ticket.name} - Report`}
      onBack={handleBack}
      extra={[<RefreshAction onClick={handleRefresh} />]}
    >
      <TicketReportInner ticket={ticket} />
    </Page>
  );
};

export default TicketReport;
