import React, { useMemo } from 'react'
import styled from 'styled-components'
import { default as ButtonBase  } from 'antd/lib/button'
import { SubmitButtonProps } from './SubmitButton';

type ButtonProps = SubmitButtonProps;

interface Props {
  loading?: boolean;
}

const ButtonInner: React.FC<SubmitButtonProps & Props> = ({ component: Component, buttonType, type, hidden, loading, ...props }) => {

  const Render = Component || ButtonBase;

  if (hidden) return null

  return (
    <Render
      htmlType={type}
      type={buttonType}
      loading={loading}
      {...props}
    />
  )
}

const ButtonOuter: React.FC<ButtonProps & Props> = ({ style, type = 'submit', buttonType = 'primary', block = true, className, ...props }) => {

  return (
    <div style={style} className={`${className}`}>
      <ButtonInner
        type={type}
        buttonType={buttonType}
        block={block}
        {...props}
      />
    </div>
  )
}

export const FullButton = styled(ButtonOuter)`
  width: 100%;
`

export const FullButtonLg = styled(FullButton)`
  padding: 20px 15px 0 15px !important;
  
  @media only screen and (max-width: 768px) {
    padding: 15px 10px 0 10px !important; 
  }
`
