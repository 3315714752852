import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const slogans = ['Allocating floor space...', 'Rolling out...', 'Hiring interns...', 'Mapping out logistics...'];

const Loading = styled.div`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${p => p.theme.primary + '55'};
`;

const Spinner = styled(Spin)`
  font-size: 20px;
`;

interface IProps {
  [x: string]: any;
}

export const LoadingSpinner: FC<IProps> = ({}) => {

  const selectedSlogan = useMemo(() => slogans[Math.floor(Math.random() * (slogans.length))], []);

  return (
    <Loading>
      <Spinner spinning tip={selectedSlogan} size='large' />
    </Loading>
  );
};

LoadingSpinner.defaultProps = {};

export default LoadingSpinner;
