import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { allContactFields, documentFields, siteFields } from '../fragments';

const CONTACT = gql`
  query($id: String!) {
    contactSingle(id: $id) {
      ${allContactFields}
    }
  }
`;

type hookProps = {
  id: string | undefined;
  onCompleted?: (result: any) => void;
  onError?: (error: any) => void;
};

export const useFetchContact = ({ onCompleted, onError, id }: hookProps) => {
  const [fetchContact, ctx] = useLazyQuery(CONTACT, {
    variables: { id },
    onCompleted,
    onError,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchContact();
  }, []);

  return [fetchContact, ctx] as const;
};
