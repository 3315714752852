import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
//review this ASAP
import { Row, RowProps } from 'antd/lib/grid'

type SpacingProps = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;

interface Props {
    [key: string]: any,
    disabled?: boolean
}

const Component: React.FC<RowProps & Props> = ({ disabled = false, children, ...props }) => {

    const { isSubmitting } = useFormikContext() || {}
    const isDisabled = useCallback((props: any) => disabled || props.disabled || isSubmitting, [disabled, isSubmitting])

    return (
        <Row {...props}>
            { React.Children.map(children, (child: any) => {
                return child
                        ? React.cloneElement(child, {
                            ...child.props, disabled: isDisabled(child.props)
                          }) : null
            }) }
        </Row>
    )
}

export const FormRow = Component;

FormRow.defaultProps = {
    gutter: 6
}

export const StyledFormRow = styled(Component)`
  padding: 20px 40px 40px 40px;
  @media only screen and (max-width: 1100px) {
    padding: 20px 20px 40px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px 30px 10px;
  }
`
