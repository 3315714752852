import React from 'react';
import styled from 'styled-components';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { presentError, presentSuccess } from '@necta-tech/alert';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useFormData } from '../../hooks';
import { PageHeader, Page } from '../../components';
import { Input, ContactNumber, FormRow, TextArea, FormButton } from '../../components/antd'
import { Form as AntForm } from 'formik-antd'

import { Collapse as Collapser } from 'antd'

const { Panel } = Collapser

const Collapse = styled(Collapser)`
  width: 100%;
`

const RegisterSchema = Yup.object().shape({
  firstname: Yup.string()
    .required()
    .label('First Names(s)'),
  lastname: Yup.string()
    .required()
    .label('Last Name'),
  email: Yup.string()
    .email()
    .required()
    .label('Email'),
  contactNumber: Yup.string()
    .required()
    .label('Contact Number'),
  address: Yup.string().label('Address'),
  companyName: Yup.string().label('Company Name'),
  companyContactNumber: Yup.string().label('Primary Contact Number'),
  companyVatNumber: Yup.string().label('Comany VAT Number'),
  companyContactEmail: Yup.string()
    .email()
    .label('Comany Email'),
  companyAddress: Yup.string().label('Company Address'),
  message: Yup.string().label('Message'),
});

const Form = styled(AntForm)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const SectionTitle = styled.h2`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
`;

const Info = styled.p`
  font-size: 14px;
  width: 90%;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  div.ant-card {
    max-width: 700px;
  }
`;

const Reg = styled(FormButton)`
  margin-top: 30px;
`

const SEND_MESSAGE = gql`
  mutation SEND_MESSAGE($newEnquiryRequest: NewEnquiryRequest!) {
    addEnquiryRequest(newEnquiryRequest: $newEnquiryRequest) {
      id
    }
  }
`;

const GRID_PROPS = {
  xs: 24, sm: 24, md: 12, lg: 12, xl: 12
}

export const Register: React.FC<any> = props => {
  const history = useHistory();

  const [handleSendMessage] = useMutation(SEND_MESSAGE);

  const handleOnSubmit = async (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<any>) => {
    setSubmitting(true);
    try {
      await handleSendMessage({
        variables: {
          newEnquiryRequest: {
            ...values,
          },
        },
      });
      presentSuccess('Thanks! We will be in touch with you soon', 'Register Request Received');
      resetForm();
    } catch (e) {
      presentError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const { fields, ...formikCTX } = useFormData(RegisterSchema, {
    onSubmit: handleOnSubmit,
  });

  return (
    <Formik {...formikCTX} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <Container>

          <Page card>

            <Form layout='vertical'>
                <PageHeader style={{marginLeft: '-30px'}} title='Sign Up' onBack={() => history.push('/login')} />
                  <Info>
                    Thank you for showing interest in OverSite! Please fill out the details below, and let us know how
                    you envision OverSite helping you, and one of our Admins will be in contact.
                  </Info>
                <Collapse defaultActiveKey={['user']} ghost>
                  <Panel header='User Details' key='user'>
                    <FormRow>
                      <Input {...fields.firstname} />
                      <Input {...fields.lastname} />
                      <Input {...fields.email} />
                      <ContactNumber numberField={fields.contactNumber} />
                      <Input
                        {...fields.address}
                        gridProps={{ xs: 24, sm: 24, md: 24, lg: 24 }}
                      />
                    </FormRow>
                  </Panel>

                  <Panel header="Company Details" key='company'>
                    <FormRow>
                      <Input {...fields.companyName} />
                      <Input {...fields.companyVatNumber} />
                      <Input {...fields.companyContactEmail} />
                      <Input {...fields.companyAddress} />
                      <TextArea
                        rows={7}
                        {...fields.message}
                        gridProps={{ sm: 24, md: 24, lg: 24 }}
                      />
                    </FormRow>
                  </Panel>
                </Collapse>



                <Reg type='submit'>
                  Register
                </Reg>
            </Form>
          </Page>
          </Container>
        );
      }}
    </Formik>
  );
};

export default Register;
