import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FileUploader, NotFound, Page } from '../../components'
import { Skeleton, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { EditMyProfile, LinkedOrganisations } from './components';
import { useCurrentUser } from '@bit/necta.hooks.current-user'
import { useLoadCurrentUser } from '../../graphql/hooks';

const { TabPane } = Tabs;

interface Props {
  mode: 'view' | 'edit'
}

export const MyAccount: React.FC<Props> = ({ mode }) => {
  const [user] = useCurrentUser();

  const [refetch, { loading }] = useLoadCurrentUser();

  const history = useHistory();

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (!user || isEmpty(user)) {
    return <NotFound subTitle={'Unable to find account'} handleBack={handleBack} />;
  }

  return (
    <Page title='My Account' onBack={handleBack} card>
      <Tabs defaultActiveKey={'Player Info'} tabPosition={'top'} size={'large'}>
        <TabPane tab={'Personal Info'} key={'Personal Info'}>
          <EditMyProfile user={user} edit={true} loading={loading} />
        </TabPane>
        <TabPane tab={'Organisations'} key={'Organisations'}>
          <LinkedOrganisations user={user} refresh={refetch} loading={loading} />
        </TabPane>
        <TabPane tab={'Documents'} key={'Documents'}>
          <FileUploader id={user.id} parentType={'user'} fileType={'DOCUMENT'} initialFiles={user.documents} loading={loading} />
        </TabPane>
      </Tabs>
    </Page>
  )
}
