import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Input, AutoComplete } from 'antd';
import { useFormikContext } from 'formik'
import { FieldContainer } from '../antd/FieldContainer';
import PlacesAutocomplete, { Suggestion, geocodeByAddress } from 'react-places-autocomplete';
import { compact, get } from 'lodash';
import { SelectProps as FormikSelectProps } from 'formik-antd'
import { LoadingOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib/select';
import { ColProps } from 'antd/lib/grid';

interface FormattedAddress {
  country?: string;
  state?: string;
  town?: string;
  addressLine1?: string;
  postalCode?: string;
}

const getAddress = (address_components: any[]): FormattedAddress => {
  try {
    const country = get(address_components.find(component => component.types.includes('country')), 'long_name', '');
    const state = get(address_components.find(component => component.types.includes('administrative_area_level_1')), 'long_name', '');
    const town = get(address_components.find(component => component.types.includes('locality')), 'long_name', '');
    const postalCode = get(address_components.find(component => component.types.includes('postal_code')), 'long_name', '');
    const street_no = get(address_components.find(component => component.types.includes('street_number')), 'long_name', '');
    const street = get(address_components.find(component => component.types.includes('route')), 'long_name', '');
    const addressLine1 = compact([street_no, street]).join(' ');
    return { country, state, town, addressLine1, postalCode };
  } catch (e) {
    //console.log(e);
    return {}; // Fail silently
  }
};

interface LocationSelectProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  fields?: any,
  name?: string,
  onChange?: (value: any) => void,
  address: any,
  parentKey?: string;
  showPicture?: boolean;
}

const Component: React.FC<LocationSelectProps & (FormikSelectProps | SelectProps<string>)> = ({ disabled, label, required, address, hidden, gridProps, className, parentKey, defaultValue, name = '', fields, ...props }) => {
  const { isSubmitting, setFieldValue } = useFormikContext() || {}
  const [selectedAddress, setSelectedAddress] = useState(defaultValue || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddressChange = useCallback((value: string) => {
    setSelectedAddress(value)
    const fullAddressKey = parentKey ? `${parentKey}.fullAddress` : 'fullAddress';
    setFieldValue(fullAddressKey, value);
  }, [setSelectedAddress, parentKey, setFieldValue]);

  // TODO: shall we get lat and long?

  const onAddressSelect = useCallback((value: any) => {
    setIsLoading(true);
    geocodeByAddress(value)
      .then(results => {
        const newAddress = getAddress(results[0].address_components);
        setSelectedAddress(results[0].formatted_address);
        Object.keys(newAddress).forEach((k: string) => {
          const key = parentKey ? `${parentKey}.${k}` : k;
          setFieldValue(key, get(newAddress, k, ''))
        });
        const fullAddressKey = parentKey ? `${parentKey}.fullAddress` : 'fullAddress';
        setFieldValue(fullAddressKey, results[0].formatted_address);
        // return getLatLng(results[0])
      })
      // .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error))
      .finally(() => setIsLoading(false))
  }, [setSelectedAddress, setIsLoading, parentKey, setFieldValue]);

  const getSuggestions = useCallback((suggestions: Suggestion[]) => suggestions.map((suggestion: Suggestion) => suggestion.description), []);

  const getSelectProps = useCallback((getInputProps: any) => getInputProps({ id: "address-input", autoComplete: 'never' }), []);

  const getStyle = useCallback((loading: boolean) => loading ? {} : { display: 'none' }, []);

  return (
    <PlacesAutocomplete onChange={handleAddressChange} onSelect={onAddressSelect} value={selectedAddress}>
      {({ getInputProps, suggestions, loading }: any) => (
        <FieldContainer
          { ...gridProps }
          name={name}
          required={required}
          label={label}
          className={`${className} ant-field-container`}
          hidden={hidden}
          editable={false}
        >
          <AutoComplete
            onSearch={setSelectedAddress}
            onSelect={onAddressSelect}
            showSearch
            placeholder={'Search...'}
            disabled={disabled || isSubmitting}
            dataSource={getSuggestions(suggestions)}
            defaultActiveFirstOption={false}
          >
            <Input { ...getSelectProps(getInputProps)} suffix={<LoadingOutlined spin style={getStyle(loading || isLoading)}/>} />
          </AutoComplete>
        </FieldContainer>
      )}
    </PlacesAutocomplete>
  );
}

export const LocationSearch = styled(Component)`
`
