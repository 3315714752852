import { createStore } from 'redux';
import { makeRootReducer } from '@bit/necta.hooks.make-root-reducer';
import { themeReducer as themes } from '@bit/necta.hooks.themes';
import { cognito as cognitoUser } from '@bit/necta.hooks.cognito-user';
import { redirectOnLogin } from '../hooks';
import { drawerOpen } from '../components/antd/sidenav/redux'
import { currentUser } from '@bit/necta.hooks.current-user'
import { pageTitle } from '@bit/necta.hooks.page-title'
import { logoutPaneActive } from '@bit/necta.hooks.logout-pane'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { linker } from '../components/linking';
import { currentOrg } from '../components/organisation-selector'
import { steps } from '../pages/ticket/components/redux';

const persistConfig = {
  key: 'root', //key for local storage
  storage, //storage adapter, defaults to local storage
  blacklist: ['steps']
};

const persistedReducer = persistReducer(persistConfig, makeRootReducer({
  pageTitle,
  redirectOnLogin,
  cognitoUser,
  currentUser,
  drawerOpen,
  themes,
  linker,
  logoutPaneActive,
  currentOrg,
  steps
}));

//redux store to be passed to Provider
export const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(store);
