import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Card, Carousel, Row, Col } from 'antd';
import Footer from '../components/footer/index';
import AntButton from '../components/antd-button/index';
//ILLUSTRATIONS
import PhoneMock from '../assets/illustrations/mock_phone.png';
import MacMock from '../assets/illustrations/mock_macbook.png';
import WorkMock from '../assets/illustrations/mock_workspace.png';
import Deliver from '../assets/illustrations/deliver.png';
import Meeting from '../assets/illustrations/meeting.png';
import QR from '../assets/illustrations/qr.png';
import ConnectGrey from '../assets/illustrations/connect_grey.png';
import MockScreens from '../assets/illustrations/mock_screens.png';
import MockPhoneScreen from '../assets/illustrations/mock_phone_screen.png';
import SignUp from '../assets/illustrations/signup.png';

// LOGOS
import SVS from '../assets/client/svs.png';
import DirectClean from '../assets/client/directclean.jpg';
import Britelink from '../assets/client/bl.png';
import OverSiteLogo from '../assets/illustrations/necta_logo.png';

//BACKGROUNDS
import AboutBG from '../assets/illustrations/about_bg.png';
import AboutBGMD from '../assets/illustrations/about_bg_md.png';
import AboutBGXS from '../assets/illustrations/about_bg_xs.png';
import ClipBG1 from '../assets/illustrations/clipbg_1.png';
import ClipBG2 from '../assets/illustrations/clipbg_2.png';
import ClipBG1MD from '../assets/illustrations/clipbg_1_md.png';
import ClipBG2MD from '../assets/illustrations/clipbg_2_md.png';

const { Header, Content } = Layout;

export const Container = styled.div`
  width: 100%;
`;

export const Blurb = styled.h1`
  font-size: 24px;
  color: #0f4c81;

  @media only screen and (max-width: 1400px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 992px) {
    font-size: 18px;
    text-align: center;
  }

  @media only screen and (min-width: 1920px) {
    padding-top: 20px;
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  color: #0f4c81;
`;

export const PLayout = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const Width = styled.div`
  @media only screen and (min-width: 1400px) {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0 80px 0;
  }

  @media only screen and (max-width: 1399px) {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
`;

export const CWidth = styled.div`
  @media only screen and (min-width: 1400px) {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0 80px 0;
  }
  @media only screen and (max-width: 1399px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const Slide = styled.div`
  min-height: 500px;
  background: #fff;
  padding: 0 150px;
  @media only screen and (max-width: 1400px) {
    padding: 100px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 650px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
`;

const SlideContent = styled(Col)`
  color: #0f4c81;
  display: flex;
  align-items: center;
  height: 500px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
    height: 250px;
    display: inline-grid;
    align-self: center;
    padding: 0 40px 0 40px !important;
  }
`;

const SlideImage = styled(Col)`
  color: #0f4c81;
  align-content: center;
  justify-content: center;
  display: inline-grid;
  height: 500px;
  @media only screen and (max-width: 992px) {
    height: 250px;
    justify-content: center;
    display: inline-grid;
  }
`;

const CCarousel = styled(Carousel)`
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  && .slick-dots li button {
    background: #0f4c81 !important;
  }
`;

const ClientLogo = styled.div`
  padding: 10px 0;
  height: 100%;
  text-align: center;
  align-content: center;
  justify-content: center;
  display: grid;
`;

const Description = styled.div`
  text-align: center;
  line-height: 1em;
`;

const ImageSize = styled.div`
  height: 300px;
  @media only screen and (max-width: 992px) {
    height: 200px;
  }
`;

const Text = styled.p`
  font-size: 14px;
`;

export const LCard = styled(Card)`
  @media only screen and (max-width: 992px) {
    padding: 0;
    margin: 0 auto;
  }
  && .ant-card-body {
    padding: 0px;
  }
`;

export const AboutHeader = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  background-image: url(${AboutBG});
  background-size: cover;
  height: 500px;
  background-position-x: center;

  @media only screen and (max-width: 992px) {
    background-image: url(${AboutBGMD});
    background-size: cover;
    height: 600px;
    display: grid;
    background-position-x: center;
  }

  @media only screen and (max-width: 600px) {
    background-image: url(${AboutBGXS});
    background-size: cover;
    height: 600px;
    display: grid;
    background-position-x: center;
    margin-top: 20px;
  }

  @media only screen and (min-width: 1920px) {
    background-image: url(${AboutBG});
    background-size: cover;
    height: 650px;
    display: grid;
    background-position-x: center;
  }
`;

export const ClipBar1 = styled.div`
  width: 100%;
  height: 500px;
  align-items: center;
  display: grid;
  background-image: url(${ClipBG1});
  background-size: cover;
  display: grid;

  @media only screen and (max-width: 992px) {
    background-image: url(${ClipBG1MD});
    background-size: cover;
    height: 600px;
    display: grid;
  }

  @media only screen and (min-width: 1920px) {
    background-image: url(${ClipBG1});
    background-size: cover;
    height: 650px;
    display: grid;
  }
`;

export const ClipBar2 = styled.div`
  width: 100%;
  height: 500px;
  align-items: center;
  display: grid;
  background-image: url(${ClipBG2});
  background-size: cover;
  display: grid;

  @media only screen and (max-width: 992px) {
    background-image: url(${ClipBG2MD});
    background-size: cover;
    height: 600px;
    display: grid;
  }

  @media only screen and (min-width: 1920px) {
    background-image: url(${ClipBG2});
    background-size: cover;
    height: 650px;
    display: grid;
  }
`;

export const WelcomeLanding: React.FC<any> = props => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Content>
        <Container>
          <CWidth>
            <CCarousel autoplay autoplaySpeed={8000} pauseOnHover dotPosition={'bottom'}>
              <div>
                <Slide>
                  <Row gutter={[20, { xs: 5 }]}>
                    <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Blurb>
                        Ensure <strong>complete visibility</strong> and <strong>accountability</strong> in your business
                        with <b>Over</b>
                        <strong>Site</strong>
                      </Blurb>
                    </SlideContent>
                    <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                      <img style={{ width: '300px' }} alt='OverSite business asset management' src={ConnectGrey} />
                    </SlideImage>
                  </Row>
                </Slide>
              </div>
              <div>
                <Slide>
                  <Row gutter={20}>
                    <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Blurb>
                        Maintain line of sight on your business' <strong>assets</strong> and <strong>staff</strong> with
                        ease!
                      </Blurb>
                    </SlideContent>
                    <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                      <img style={{ width: '300px' }} alt='OverSite easy business management' src={MockScreens} />
                    </SlideImage>
                  </Row>
                </Slide>
              </div>
              <div>
                <Slide>
                  <Row gutter={20}>
                    <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Blurb>
                        <strong>OverSite</strong> is web-based and mobile responsive.
                        <br />
                        No need to download an app!
                      </Blurb>
                    </SlideContent>
                    <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                      <img
                        style={{ width: '300px' }}
                        alt='OverSite mobile and web compatible'
                        src={MockPhoneScreen}
                      />
                    </SlideImage>
                  </Row>
                </Slide>
              </div>
              <div>
                <Slide>
                  <Row>
                    <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Blurb>Transparent | Accountable | Efficient</Blurb>
                    </SlideContent>
                    <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                      <img style={{ width: '300px' }} alt='OverSite business asset management' src={OverSiteLogo} />
                    </SlideImage>
                  </Row>
                </Slide>
              </div>
            </CCarousel>
          </CWidth>
        </Container>

        <Container>
          <AboutHeader>
            <Width>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                  <Blurb style={{ color: 'white' }}>
                    <b>Manage</b> your <b>staff, meetings, assets, rentals, contacts,</b>
                    <br />
                    and anything else all through your <b>OverSite profile.</b>
                  </Blurb>
                </Col>
              </Row>
            </Width>
          </AboutHeader>
        </Container>

        <Container>
          <Width>
            <Row gutter={[20, { xs: 30, sm: 30, md: 30, lg: 30 }]}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Card style={{ height: '100%' }} cover={<img alt='Mobile Friendly Platform' src={PhoneMock} />}>
                  <Description>
                    <Heading style={{ lineHeight: '2em', fontWeight: 'bold' }}>Visibility</Heading>
                    <Text>Dynamic directories</Text>
                    <Text>Asset and Staff profiles</Text>
                    <Text>Pertinent documentation and images</Text>
                    <Text>Geo-location tagging</Text>
                    <Text>Flagging for maintenance and services</Text>
                    <Text>Custom reporting</Text>
                  </Description>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Card style={{ height: '100%' }} cover={<img alt='Collaborate with a Team' src={WorkMock} />}>
                  <Description>
                    <Heading style={{ lineHeight: '2em', fontWeight: 'bold' }}>Collaboration</Heading>
                    <Text>Create teams for assets and staff</Text>
                    <Text>Keep track of transfer approvals</Text>
                    <Text>Better manage suborganisations and external agreements</Text>
                    <Text>Have an on-hand digital record of decisions</Text>
                  </Description>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Card style={{ height: '100%' }} cover={<img alt='Business Management' src={MacMock} />}>
                  <Description>
                    <Heading style={{ lineHeight: '2em', fontWeight: 'bold' }}>Accountability</Heading>
                    <Text>Link assets to staff</Text>
                    <Text>Rate and report staff profiles</Text>
                    <Text>See asset and staff history</Text>
                    <Text>View previous services and repairs</Text>
                    <Text>Log asset usage history</Text>
                    <Text>Record actions on profiles.</Text>
                  </Description>
                </Card>
              </Col>
            </Row>
          </Width>
        </Container>

        <Container>
          <ClipBar1>
            <Width>
              <Blurb style={{ textAlign: 'center' }}>
                Our advanced <b>assign-and-track tech</b> and easy to use QR system will allow you to{' '}
                <b>manage your company's interactions</b> like never before; from staff and customers, to assets and
                stock.
              </Blurb>
            </Width>
          </ClipBar1>
        </Container>

        <Container>
          <LCard>
            <Width>
              <Row gutter={[20, { xs: 80, sm: 80, md: 80, lg: 80 }]}>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Heading>Manage Assets and Stock</Heading>
                  <br />
                  <div>
                    <Text>
                      OverSite helps you generate QR codes for individual or bulk assets. From there you can:
                    </Text>
                    <li>
                      <ul>• Attach relevant documents and images to asset ‘profiles’.</ul>
                      <ul>• Assign an asset value and depreciation rate.</ul>
                      <ul>• Categorise assets as you wish (by location, division, per manager in charge).</ul>
                      <ul>• Assign managers to a profile so they can manage the portal to manage assets.</ul>
                      <ul>• Check assets in and/or out.</ul>
                      <ul>• Report an asset for damage, service etc.</ul>
                    </li>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <ImageSize>
                    <img style={{ height: 'inherit' }} alt='OverSite business asset management' src={Deliver} />
                  </ImageSize>
                </Col>
              </Row>
            </Width>
          </LCard>
        </Container>

        <Container>
          <ClipBar2>
            <Width>
              <Blurb style={{ textAlign: 'center' }}>
                <b>No dedicated special hardware required</b> - QR codes can be scanned from any smart phone.
              </Blurb>
            </Width>
          </ClipBar2>
        </Container>

        <Container>
          <LCard>
            <Width>
              <Row gutter={[20, { xs: 80, sm: 80, md: 80, lg: 80 }]}>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Heading>Manage People</Heading>
                  <br />
                  <div>
                    <Text>
                      OverSite allows you to import an existing staff directory or create individual profiles from
                      scratch.
                    </Text>
                    <Text>From there you can:</Text>
                    <li>
                      <ul>• Attach relevant documents and images to profiles.</ul>
                      <ul>• Assign a role, division and/or region.</ul>
                      <ul>• And so much more!</ul>
                    </li>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <ImageSize>
                    <img style={{ height: 'inherit' }} alt='OverSite business asset management' src={Meeting} />
                  </ImageSize>
                </Col>
              </Row>
            </Width>
          </LCard>
        </Container>

        <Container>
          <ClipBar1>
            <Width>
              <Blurb style={{ textAlign: 'center' }}>
                No need to download an app, OverSite is all <b>web based</b> and <b>mobile responsive</b>
              </Blurb>
            </Width>
          </ClipBar1>
        </Container>

        <Container>
          <LCard>
            <Width>
              <Row gutter={[20, { xs: 80, sm: 80, md: 80, lg: 80 }]}>
                <Col
                  xs={{ span: 24, order: 1 }}
                  lg={{ span: 24, order: 1 }}
                  style={{ display: 'inline-grid', alignItems: 'center' }}
                >
                  <Heading>Manage Access</Heading>
                  <br />
                  <div>
                    <Text>
                      Utilizing your existing staff directory, OverSite allows you to set up zones (buildings, floors,
                      off-site locations) and link staff to specific zones. Once a staff member has been linked to a
                      zone that staff member will have a unique QR code on their device that once scanned will grant
                      access to that zone.
                    </Text>
                    <Text>
                      Alternatively access points can be assigned unique QR codes that staff can scan to gain access.
                      The same can be done for temporary staff and visitors - all set up and retracted in seconds.
                    </Text>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  lg={{ span: 12, order: 2 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <ImageSize>
                    <img style={{ height: 'inherit' }} alt='OverSite business asset management' src={QR} />
                  </ImageSize>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  lg={{ span: 12, order: 3 }}
                  style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}
                >
                  <div>
                    <Text>This will record a date and time stamp linked to staff members entering and/or exiting.</Text>
                    <Text>
                      Depending on where staff are working from, the system can be set up to alert/red flag when a staff
                      member is accessing an area they shouldn't be (i.e. the office when they should be working from
                      home or vice versa).
                    </Text>
                    <Text>
                      If need be, an ‘auto pin drop’ linked to the GPS location or physical address can be triggered
                      whenever a person accesses a location.
                    </Text>
                  </div>
                </Col>
              </Row>
            </Width>
          </LCard>
        </Container>

        <Container>
          <Width style={{ textAlign: 'center' }}>
            <Blurb style={{ fontSize: '30px' }}>
              Keeping track of your business has never been easier,
              <br /> faster or more efficient!
            </Blurb>

            <Row style={{ padding: '60px', overflow: 'hidden' }}>
              <Col span={24} style={{ display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  style={{ maxHeight: '400px' }}
                  alt='OverSite business asset management Sign up now'
                  src={SignUp}
                />
              </Col>

              <Col span={24} style={{ display: 'inline-grid', justifyContent: 'center', margin: '-210px -0 0 18px' }}>
                <AntButton
                  style={{ color: '#fff', backgroundColor: '#0F4C81' }}
                  onClick={() => history.push('/contact')}
                >
                  Get Started!
                </AntButton>
              </Col>
            </Row>
          </Width>
        </Container>
        <Width>
          <Blurb style={{ paddingBottom: '20px' }}>Companies who use OverSite</Blurb>
          <Row gutter={[30, { xs: 20, sm: 20, md: 20, lg: 20 }]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <ClientLogo>
                <img style={{ maxHeight: '80px' }} alt='OverSite Client SVS specialised video solutions' src={SVS} />
              </ClientLogo>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <ClientLogo>
                <img style={{ maxHeight: '60px' }} alt='OverSite Client DirectClean' src={DirectClean} />
              </ClientLogo>
            </Col>
          </Row>
        </Width>
        <Footer />
      </Content>
    </React.Fragment>
  );
};

export default WelcomeLanding;
