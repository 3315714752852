import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { GatewayOutlined } from '@ant-design/icons'
import { FieldContainer } from '../antd/FieldContainer'
import { isEmpty, get, compact } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities';
import useDebouncedEffect from 'use-debounced-effect-hook'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const SITE_SEARCH = gql`
  query SITE_SEARCH($keyword: String!) {
    siteSearch(keyword: $keyword) {
      id
      name
      tags
      shortid
      customerId
    }
  }
`;

const { Option } = Select

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

/**
 * takes values and returns LoadedUser keys in a single string
 * @param site
 * @param labelKeys
 * @returns string
 */
const getValues = (site: LoadedSite, labelKeys: (keyof LoadedSite)[]) => {
  return compact(labelKeys.map((l: keyof LoadedSite) => get(site, l, null))).join(' ');
}

interface LoadedSite {
  customerId: string,
  id: string,
  name: string,
  shortid: string,
  tags: string[]
}

interface SiteSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  selectedCustomerId?: string,
  onChange?: (value: any) => void,
  //TODO: define these properly
  valueKey?: keyof LoadedSite,
  labelKeys?: (keyof LoadedSite)[],
  showPicture?: boolean;
  getOptions?: (sites: Partial<LoadedSite>[]) => React.ReactNode[],
  getUser?: (value: any) => void,
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  },
  defaultOptions?: LoadedSite[]
}

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const SiteSelectorComponent: React.FC<SiteSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  {
    getFormik = true,
    getOptions,
    getUser,
    labelKeys = ['name'],
    valueKey = 'id',
    name = '',
    showPicture = true,
    selectedCustomerId,
    onChange,
    disabled,
    label,
    required,
    hidden,
    gridProps,
    className,
    defaultValue,
    defaultOptions,
    ...props
  }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')
  const [sites, setSites] = useState<LoadedSite[]>(defaultOptions || [])
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(selectedCustomerId)
  const [keyword, setKeyword] = useState<string>('')

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const [ fetchSites, {loading} ] = useLazyQuery(SITE_SEARCH, {
    variables: { keyword },
    onCompleted: (result: any) => {
        console.log(JSON.stringify(result, null, 2))
        if(selectedCustomer){
          const fetchedSites = result.siteSearch.filter((site:LoadedSite) => site.customerId === selectedCustomer)
          setSites(fetchedSites)
        } else{
          setSites(result.siteSearch)
        }
    },
    onError: (error: any) => {
      console.log(JSON.stringify(error, null, 2))
    },
    fetchPolicy: "network-only"

  })

  useEffect(() => {
    setSelectedCustomer(selectedCustomerId)
    fetchSites()
  }, [fetchSites, selectedCustomerId])


  const handleSearch = useCallback((_keyword: string) => {
    setKeyword(_keyword)
  }, [setKeyword]);

  // useDebouncedEffect(() => {
  //   if (keyword && keyword !== '') fetchSites({ variables: { keyword } });
  // }, [ keyword, fetchSites, setSites ], 500);

  const _getUser = useCallback((value: any, key: string) => sites.find((u: LoadedSite) => get(u, key) === value), [sites]);

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value);
    if (onChange) onChange(getUser ? getUser(value) : _getUser(value, valueKey));
    setSelected(value);
    setKeyword('');
  }, [name, setSelected, formik, isFormik, onChange, getUser, valueKey, _getUser, setKeyword]);

  /**
   * Select Options constructor. Builds an array of options children from fetched site list.
   */
  const _getOptions = useCallback(() => sites.map((site: any) => (
    <Option key={site.id} value={site.id} label={getValues(site, labelKeys)}>
      {showPicture && <Icon src={getThumbnail(site.profilePicUrl)} size="small" icon={<GatewayOutlined />} />}
      {getValues(site, labelKeys)}
    </Option>
  )), [sites])

  const selectOptions = useMemo(() => sites.length > 0 && (getOptions ? getOptions(sites) : _getOptions()), [sites, getOptions]);

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
      editable={false}
    >
      <FormikSelect
        prefix={<GatewayOutlined className="site-form-item-icon" />}
        name={name}
        onSearch={handleSearch}
        onChange={handleOnChange}
        showSearch
        placeholder={'Search sites...'}
        disabled={disabled || formik.isSubmitting}
        loading={loading}
        filterOption={false}
        {...props}
      >
        {selectOptions}
      </FormikSelect>
    </FieldContainer>
  )

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<GatewayOutlined className='site-form-item-icon' />}
      placeholder={'Search sites...'}
      loading={loading}
      className={`${className} ant-site-selector`}
      onSearch={handleSearch}
      onSelect={handleOnChange}
      showSearch
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {selectOptions}
    </Select>
  )
}

export const SiteSelector = styled(SiteSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
