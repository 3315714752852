import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { message, Layout, Row, Col, Button } from 'antd';
import Footer from '../components/footer/index';
import { Container, PLayout, LCard, Heading, Width } from './WelcomeLanding';
import { FormRow, Input, ContactNumber } from '../components/antd';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useFormData } from '../hooks/use-form-data';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { TextArea } from '../components/antd';
import { FormButton } from '../components/antd';

//BACKGROUNDS
import ContactBG from '../assets/illustrations/contact_bg.png';
import ContactBGLG from '../assets/illustrations/contact_bg_lg.png';
import { cleanError } from '../helpers/error-helper';

const ENQUIRY = gql`
  mutation SEND_MESSAGE($newEnquiryRequest: NewEnquiryRequest!) {
    addEnquiryRequest(newEnquiryRequest: $newEnquiryRequest) {
      id
    }
  }
`;

const { Header, Content } = Layout;

const enquirySchema = Yup.object().shape({
  firstname: Yup.string()
    .required()
    .label('First Name'),
  lastname: Yup.string()
    .required()
    .label('Last Name'),
  email: Yup.string()
    .required()
    .label('Email'),
  contactNumber: Yup.string()
    .required()
    .label('Contact Number'),
  message: Yup.string()
    .nullable()
    .label('Your message (Optional)'),
});

const ContactCard = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  background-image: url(${ContactBG});
  background-size: cover;
  height: 800px;
  display: grid;
  background-position-x: center;

  @media only screen and (max-width: 992px) {
    background-image: none;
    background-color: #0f4c81;
    display: grid;
    height: 100%;
    background-position-x: center;
  }

  @media only screen and (min-width: 1920px) {
    background-image: url(${ContactBGLG});
    padding-top: 100px;
  }
`;

const ClipText = styled.div`
  width: 100%;
  background-size: cover;
  align-items: center;
  display: grid;

  @media only screen and (min-width: 1400px) {
    margin-top: -400px;
  }

  @media only screen and (max-width: 1399px) {
    margin-top: -350px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1920px) {
    margin-top: -275px;
    display: grid;
  }
`;

const SubmitButton = styled(FormButton)`
  width: 100%;
`;

export const Enquire: React.FC<any> = props => {
  const history = useHistory();

  const [sendEnquiry, { loading }] = useMutation(ENQUIRY);

  const { fields, ...formikCTX } = useFormData(enquirySchema, {
    onSubmit: async (values: any, actions: any) => {
      return sendEnquiry({
        variables: {
          newEnquiryRequest: {
            ...values,
          },
        },
      });
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Could not contact. Please check your connection and try again!'), duration: 4 });
    },
    onCompleted: (e: any) => {
      message.error({ content: 'Thanks, we will in touch shortly!', duration: 2 });
    },
  });

  return (
    <React.Fragment>
      <Content style={{minHeight: 'auto'}}>
        <Container>
          <ContactCard>
            <Width>
              <Row gutter={[20, { xs: 50, sm: 50, md: 80, lg: 80 }]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <div>
                    <Heading style={{ color: '#FFFFFF' }}>
                      Contact <b>Us</b>
                    </Heading>
                    <h3 style={{ color: '#FFFFFF' }}>Transparent | Accountable | Efficient</h3>
                    <h3 style={{ color: '#FFFFFF' }}>
                      OverSite is all you will need to manage your business hands on, <br />
                      while being safely hands off!
                    </h3>
                  </div>
                </Col>

                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <LCard style={{ padding: '40px' }}>
                    <Formik {...formikCTX} enableReinitialize validateOnBlur>
                      {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                        <Form layout={'vertical'}>
                          <FormRow gutter={20} justify='space-between'>
                            <Input {...fields.firstname} />
                            <Input {...fields.lastname} />
                            <Input {...fields.email} />
                            <ContactNumber numberField={fields.contactNumber} />
                            <TextArea {...fields.message} rows={4} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }} />
                          </FormRow>
                          <h3>
                            By clicking “Submit,” I acknowledge receipt of the OverSite <br />
                            <Button
                              style={{ fontSize: '16px', color: '#0F4C81', marginLeft: '-16px' }}
                              type='link'
                              onClick={() => history.push('/enquiry')}
                            >
                              Privacy Policy.
                            </Button>
                          </h3>
                          <SubmitButton type='submit'>Submit</SubmitButton>
                        </Form>
                      )}
                    </Formik>
                  </LCard>
                </Col>
              </Row>
            </Width>
          </ContactCard>
          <ClipText>
            <Width>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                  <p>
                    For general enquiries have a look at our
                    <Button
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#0F4C81',
                        marginLeft: '-8px',
                        marginRight: '-12px',
                      }}
                      type='link'
                      onClick={() => history.push('/faq')}
                    >
                      FAQ’s
                    </Button>{' '}
                    to see if your question has already been answered.{' '}
                  </p>
                  <p>
                    For any technical issues please visit our
                    <Button
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#0F4C81',
                        marginLeft: '-8px',
                        marginRight: '-12px',
                      }}
                      type='link'
                      onClick={() => window.open('https://necta.atlassian.net/servicedesk/customer/portal/2')}
                    >
                      Help Center.
                    </Button>
                  </p>
                </Col>
              </Row>
            </Width>
          </ClipText>
        </Container>
      </Content>

      <Footer style={{ marginTop: '20px'}} />
    </React.Fragment>
  );
};

export default Enquire;
