import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const defaultHeight = 300;

const containerStyle: any = {
};

export const Map = ({ fullAddress, lat, lng, width, height }: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || ""
  });

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, [])

  const handleDirections = useCallback(() => {
    window.open(encodeURI('https://www.google.co.za/maps/dir//' + fullAddress), '_blank');
  }, [fullAddress]);

  const [center, setCenter] = useState({ lat, lng });

  const _containerStyle = useMemo(() => {
    return {
      ...containerStyle,
      width: width ? width + 'px' : '100%',
      height: (height ? height : defaultHeight ) + 'px'
    }
  }, [width, height]);

  useEffect(() => {
    if (fullAddress) {
      geocodeByAddress(fullAddress)
        .then((result: any) => getLatLng(result[0]))
        .then((latLng: any) => {
          setCenter(latLng)
        });
    }
  }, [setCenter, fullAddress]);

  if (!center || !center.lat || !center.lng || !isLoaded) return <GoogleMap />;

  return (
    <GoogleMap
      mapContainerStyle={_containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} onClick={handleDirections}/>
    </GoogleMap>
  );
}

export default memo(Map);
