import { useState, useCallback, useMemo } from 'react';
import { FormikValues, FormikHelpers } from 'formik';
import { Yup, getFormData } from '@bit/necta.hooks.get-form-data';
import { useSelector } from 'react-redux';
import { getFormOverrides } from '../selectors';
import { mapArrayToObjects } from '../helpers';
import { cloneDeep } from 'lodash';

export { Yup, getFormData };

interface Options {
  onSubmit: (values: FormikValues, actions: FormikHelpers<any>) => Promise<any>;
  onCompleted?: Function;
  onError?: (e: any) => void;
  override?: string;
}

interface Override {
  field: string;
  module: string;
  type: string;
  description?: string;
  options: string[];
  label?: string;
}

const overrideFields = (fields: any, formOverrides: Override[]) => {
  if (formOverrides.length <= 0) return fields;
  const overridenFields = cloneDeep(fields);
  Object.keys(overridenFields).map((f: any) => {
    const field = overridenFields[f];
    const override = formOverrides.find((fo: any) => fo.field === field.name);
    // Override field options
    if (override?.options && override?.options.length > 0) field.options = mapArrayToObjects(override.options);
    if (override?.label) field.label = override.label;
  })
  return overridenFields;
};

export const useFormData = (yupSchema: Yup.ObjectSchema<any>, { onSubmit, onCompleted, onError, override }: Options) => {
  const { fields, schema, initialValues: initial } = useMemo(() => getFormData(yupSchema), [yupSchema]);
  const formOverrides = useSelector(state => getFormOverrides(state, override));
  const _fields = useMemo(() => overrideFields(fields, formOverrides), [fields, formOverrides]);
  const [initialValues] = useState(initial);
  const handleOnSubmit = useCallback(
    async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      try {
        const result = await onSubmit(values, actions);
        if (result && onCompleted) onCompleted(result);
      } catch (e) {
        console.log("ERROR")
        if (onError) onError(e);
        else console.log(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [onSubmit, onCompleted, onError],
  );
  return {
    fields: _fields,
    validationSchema: schema,
    initialValues,
    onSubmit: handleOnSubmit,
  } as const;
};
