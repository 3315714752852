import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Page, AuthGuard } from '../../components';
import { getIsAdmin, getActiveOrganisationId } from '../../selectors';
import { useSelector } from 'react-redux';
import ProfilePictureUploader from '../../components/profile-picture-uploader';
import { mapArrayToObjects } from '../../helpers';
import { CATEGORIES, CONDITIONS, SERVICE_STATUSES, STATUSES } from '../../constants';
import { useFormData } from '../../hooks';
import { Collapse } from 'antd';
import { Form } from 'formik-antd';
import { Tags, DatePicker, Input, Select, TextArea, FormButton, FormRow } from '../../components/antd';
import Default from '../../assets/asset_picture.png';
import { message } from 'antd'
import { OrganisationSelector } from '../../components/organisation-selector';
import { cleanError } from '../../helpers/error-helper';

const statuses = mapArrayToObjects(STATUSES);
const serviceStatuses = mapArrayToObjects(SERVICE_STATUSES);
const conditions = mapArrayToObjects(CONDITIONS);
const categories = mapArrayToObjects(CATEGORIES);

const { Panel } = Collapse;

const assetSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Asset Name'),
  manufacturer: Yup.string().label('Manufacturer'),
  modelNumber: Yup.string().label('Model Number'),
  registrationNumber: Yup.string().label('Registration Number'),
  condition: Yup.string().label('Condition'),
  dateLastServiced: Yup.string()
    .nullable()
    .meta({ type: 'date' })
    .label('Date of Last Service'),
  datePurchased: Yup.string()
    .nullable()
    .meta({ type: 'date' })
    .label('Date of Purchase'),
  valueOriginal: Yup.string().label('Value of Purchase'),
  valueCurrent: Yup.string().label('Current Book Value'),
  status: Yup.string().label('Status'),
  serviceStatus: Yup.string().label('Service Status'),
  category: Yup.string().label('Category'),
  primaryImage: Yup.string().label('Primary Image'),
  notes: Yup.string().label('Notes'),
  tags: Yup.array().label('Tags'),
  organisationId: Yup.string()
    .required()
    .label('Organisation'),
});

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

const ADD_ASSET = gql`
  mutation ADD_ASSET($newAssetData: NewAssetInput!) {
    addAsset(newAssetData: $newAssetData) {
      id
      name
    }
  }
`;

const Button = styled(FormButton)`
  margin-top: 30px;
`;

export const AssetAdd: React.FC<any> = props => {
  const history = useHistory();
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);

  const [handleAdd] = useMutation(ADD_ASSET);

  const handleBack = useCallback(() => history.goBack(), [history]);

  const { fields, initialValues, ...formikCTX } = useFormData(assetSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { datePurchased, dateLastServiced } = values;
      return handleAdd({
        variables: {
          newAssetData: {
            ...values,
            dateLastServiced: dateLastServiced ? dateLastServiced : null,
            datePurchased: datePurchased ? datePurchased : null,
          },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Asset added successfully', duration: 2 });
      history.push(`/asset/edit/${result?.data?.addAsset?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add asset'), duration: 4 });
    },
    override: 'asset',
  });

  const [defaultValues] = useState({
    ...initialValues,
    organisationId: isAdmin ? null : activeOrgId,
  });

  return (
    <AuthGuard needsActiveOrganisation>

    <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values }) => (
        <Page
          card
          title='Add Asset'
          onBack={handleBack}
        >
          <Form layout={'vertical'}>

            <Collapse defaultActiveKey={['information']} ghost>
              <Panel header='Asset Information' key='information'>
                <ProfilePictureUploader
                  disabled={isSubmitting}
                  fieldKey='primaryImage'
                  defaultPic={Default}
                />

                <FormRow>
                  <Input {...fields.name} />
                  <Input {...fields.manufacturer} />
                  {/*<OrgSelector hidden={ values.role !== 'Organisation' } disabled={!canSelectOrgs} {...fields.activeOrgId}  />*/}
                  <Input {...fields.modelNumber} />
                  <Input {...fields.registrationNumber} />
                  <Select options={conditions} {...fields.condition} allowClear />
                  <DatePicker {...fields.dateLastServiced} />
                  <DatePicker {...fields.datePurchased} />
                  <Input {...fields.valueOriginal} />
                  <Input {...fields.valueCurrent} />
                  <Select options={statuses} {...fields.status} allowClear />
                  <Select options={serviceStatuses} {...fields.serviceStatus} allowClear />
                  <OrganisationSelector {...fields.organisationId} disabled={!isAdmin} />
                </FormRow>
              </Panel>

              <Panel header='Asset Categories' key='categories'>
                <FormRow>
                  <Select gridProps={GRID_PROPS} options={categories} {...fields.category} allowClear />
                  <Tags
                    isEditable={false}
                    gridProps={GRID_PROPS}
                    {...fields.tags}
                    placeholder='Type Text and press Enter'
                  />
                </FormRow>
              </Panel>

              <Panel header='Notes' key='notes'>
                <FormRow>
                  <TextArea {...fields.notes} rows={7} gridProps={GRID_PROPS} />
                </FormRow>
              </Panel>
            </Collapse>
            <Button>Add Asset</Button>
          </Form>
        </Page>
      )}
    </Formik>

    </AuthGuard>
  );
};

export default AssetAdd;
