import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { addressFields, contactFieldsFull } from '../fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const CONTACT_QUERY = gql`
  query($contactQuery: ContactQuery!) {
    contact(contactQuery: $contactQuery) {
      ${contactFieldsFull}
      address {
        ${addressFields}
      }
    }
  }
`;

export const useFetchContactList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchContact, ctx] = useLazyQuery(CONTACT_QUERY, {
    variables: { contactQuery: { organisationId: currentOrg?.id } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchContact();
  }, [fetchContact]);

  return [fetchContact, ctx] as const;
};
