import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AuthGuard, Bar, FaultyAssets, Page, Pie, Stat, WidgetCard } from '../components';
import { DashGrid } from '../components/dash-card';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { canSelectOrganisations, getIsAdmin } from '../selectors';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { assetFields } from '../graphql/fragments';
import { get } from 'lodash';
import { useCurrentOrganisation } from '../components/organisation-selector';
import { formatDate } from '../helpers/string-transforms';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const DashPage = styled(Page)`
  justify-content: flex-start;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  h1 {
    font-weight: bold;
  }
`;

const TestOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px 0 0 0;
  .anticon {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const dateFormat = `Do MMM YYYY`;
const dateFormatShort = `Do MMM`;

const smallGridProps = { md: 8, lg: 8, xl: 8, xxl: 8 };
const largeGridProps = { md: 16, lg: 16, xl: 16, xxl: 16 };
const fullGridProps = { md: 24, lg: 24, xl: 24, xxl: 24 };

const _dateStart = Moment().subtract(3, 'months');
const dateStart = _dateStart.format();

const _dateEnd = Moment().add(3, 'months');
const dateEnd = _dateEnd.format();

const sixMonthsAgo = Moment()
  .subtract(6, 'months')
  .format();
const now = Moment().format();

const params = [
  {
    id: 'NpxTR1QJH',
    key: 'assetsByCategory',
    params: {
      x: 'asset',
      y: 'category',
    },
  },
  {
    id: 'NpxTR1QJH',
    key: 'ticketsByStatus',
    params: {
      x: 'ticket',
      y: 'status',
      filters: { dateRange: { field: 'dueDate', start: `${dateStart}`, end: `${dateEnd}` } },
    },
  },
  {
    id: 'jU6PIiBeZ',
    key: 'faultyAssets',
    params: {
      x: 'ticketStep',
      filters: {
        completionStatus: ['Not Working', 'Unit Replacement Required', 'Parts Replacement Required'],
        dateRange: { field: 'completionDate', start: `${sixMonthsAgo}`, end: `${now}` },
      },
      fetchNested: true,
      fetchKeys: ['assignedTicket', 'assignedAsset'],
    },
  },
  {
    id: 'e6s8Kjwn7', // Count
    key: 'siteCount',
    params: {
      x: 'site',
    },
  },
  {
    id: 'e6s8Kjwn7', // Count
    key: 'ticketCount',
    params: {
      x: 'ticket',
    },
  },
  {
    id: 'e6s8Kjwn7', // Count
    key: 'orgCount',
    params: {
      x: 'organisation',
      orgField: 'parentOrganisationId',
    },
  },
  {
    id: 'e6s8Kjwn7', // Count
    key: 'staffCount',
    params: {
      x: 'staff',
    },
  },
  {
    id: 'e6s8Kjwn7', // Count
    key: 'assetCount',
    params: {
      x: 'asset',
    },
  },
];

const QUERY = gql`
  query($q: [WidgetQuery!]!, $o: String) {
    widgets(widgetQueries: $q, organisationId: $o) {
      id
      key
      name
      data
    }
  }
`;

export const Dashboard: React.FC<any> = props => {
  return (
    <AuthGuard checkForOrganisation>
      <DashboardInner />
    </AuthGuard>
  );
};

export const DashboardInner: React.FC<any> = props => {
  const [currentOrg] = useCurrentOrganisation();
  const canSelectOrgs = useSelector(canSelectOrganisations);

  const ticketsSubtitle = useMemo(() => {
    const now = Moment();
    const startFormat = _dateStart.isSame(now, 'year') ? dateFormatShort : dateFormat;
    const endFormat = _dateEnd.isSame(now, 'year') ? dateFormatShort : dateFormat;
    return _dateStart.format(startFormat) + ' until ' + _dateEnd.format(endFormat);
  }, []);

  const [data, setData] = useState<any>({
    assetsByCategory: [],
    ticketsByStatus: [],
    faultyAssets: [],
    staffCount: 0,
    siteCount: 0,
    ticketCount: 0,
    orgCount: 0,
  });

  const [fetchData, { loading }] = useLazyQuery(QUERY, {
    // TODO: org selection
    variables: { q: params, o: currentOrg?.id },
    fetchPolicy: 'network-only',
    onCompleted: (result: any) => {
      if (result?.widgets) {
        const newData = {
          assetsByCategory: get(findWidget('assetsByCategory', result.widgets), 'data.totals', []),
          ticketsByStatus: get(findWidget('ticketsByStatus', result.widgets), 'data.totals', []),
          faultyAssets: get(findWidget('faultyAssets', result.widgets), 'data.value', []),
          staffCount: get(findWidget('staffCount', result.widgets), 'data.count', 0),
          siteCount: get(findWidget('siteCount', result.widgets), 'data.count', 0),
          orgCount: get(findWidget('orgCount', result.widgets), 'data.count', 0),
          ticketCount: get(findWidget('ticketCount', result.widgets), 'data.count', 0),
          assetCount: get(findWidget('assetCount', result.widgets), 'data.count', 0),
        };
        setData({ ...newData });
      }
    },
  });

  const findWidget = useCallback((key: string, widgets: any[]) => widgets.find((w: any) => w.key === key), []);

  const [user] = useCurrentUser();
  const isAdmin = useSelector(getIsAdmin);

  const baseOrg = useMemo(() => (canSelectOrgs ? currentOrg : user?.activeOrg), [canSelectOrgs, currentOrg, user]);

  const policies = useMemo(() => baseOrg?.policies.map((policy: any) => policy.name) ?? [], [baseOrg]);

  const hasPolicy = useCallback((policy: string) => policies.includes(policy), [policies]);

  const datePassed = useMemo(() => {
    const expires = Moment(user?.activeOrg?.testAccountExpiry);
    const now = Moment();
    return now.isAfter(expires);
  }, [user]);

  const handleHelp = useCallback(() => {
    window.open('https://necta.atlassian.net/servicedesk/customer/portal/2', '_blank');
  }, []);

  useEffect(() => fetchData(), [fetchData]);

  if (!user || policies.length <= 0) return null;

  return (
    <DashPage>
      <DashGrid gutter={[8, 8]}>
        <WidgetCard hidden={!user?.activeOrg?.testAccount} {...fullGridProps}>
          <TestOuter>
            <h1>Demo Account</h1>
            <p>
              { datePassed ? (
                <b>Access expired</b>
              ) : (
                <><b>Expires: </b> {formatDate(user?.activeOrg?.testAccountExpiry)}</>
              )}
              <Tooltip title={'Contact us to extend your trial or upgrade your account'}>
                <QuestionCircleOutlined onClick={handleHelp} />
              </Tooltip>
            </p>
          </TestOuter>
        </WidgetCard>
        <WidgetCard hidden={!hasPolicy('CanCreateAsset')}>
          <Pie data={data.assetsByCategory} title={'Assets'} subtitle={'by category'} loading={loading} />
        </WidgetCard>
        <WidgetCard hidden={!hasPolicy('CanCreateTicket')}>
          <Bar data={data.ticketsByStatus} title={'Recent Tickets'} subtitle={ticketsSubtitle} loading={loading} />
        </WidgetCard>
        <WidgetCard {...smallGridProps}>
          <Stats>
            <h1>Statistics</h1>
            <Stat title='Assets' value={data.assetCount} loading={loading} hidden={!hasPolicy('CanCreateAsset')} />
            <Stat title='Sites' value={data.siteCount} loading={loading} hidden={!hasPolicy('CanCreateSite')} />
            <Stat
              title='Tickets'
              value={data.ticketCount}
              loading={loading}
              hidden={!hasPolicy('CanCreateTicket')}
            />
            <Stat title='Organisations' value={data.orgCount} loading={loading} hidden={!hasPolicy('CanCreateOrg')} />
            <Stat title='Staff' value={data.staffCount} loading={loading} hidden={!hasPolicy('CanCreateStaff')} />
          </Stats>
        </WidgetCard>
        <WidgetCard {...largeGridProps} hidden={!hasPolicy('CanCreateTicket')}>
          <FaultyAssets assets={data.faultyAssets} loading={loading} />
        </WidgetCard>
      </DashGrid>
    </DashPage>
  );
};

export default Dashboard;
