import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../reducer';
import { get } from 'lodash';

export const selectLinkerData = (state: any) => {
  return get(state, 'linker.data', null);
};

const makeSetLinkerData = (dispatch: (action: any) => void) => (data: any) => dispatch(Actions.setLinkerData(data));

export const useSetLinkerData = () => {
  const dispatch = useDispatch();
  return makeSetLinkerData(dispatch);
};

export const useLinkerData = () => {
  const linkerData = useSelector(selectLinkerData);
  const setLinkerData = useSetLinkerData();
  return [linkerData, setLinkerData] as const;
};
