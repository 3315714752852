import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { presentError } from '@necta-tech/alert';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import AutoForm from '../../components/form/auto-form';
import { Page, AuthGuard } from '../../components';
import { useFormData, useImport } from '../../hooks';
import { getActiveOrganisationId, getIsAdmin } from '../../selectors';
import { assetSchema, Asset, confirmAssetSchema } from '../../schemas';
import { exportDataToExcel } from '../../services';
import gql from 'graphql-tag';
import { message, Modal, PageHeader, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FullButtonLg } from '../../components/antd';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../components/antd'
import { useHideImportActions } from '../../hooks'
import { OrganisationSelector } from '../../components/organisation-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../components/importer';

const { confirm } = Modal;

export const IMPORT_ASSETS_MUTATION = gql`
  mutation IMPORT_ASSETS_MUTATION($newAssetData: NewAssetMultipleInput!) {
    addAssetMultiple(newAssetData: $newAssetData)
  }
`;

const { schema, initialValues } = getFormData(assetSchema);

export const AssetImport: React.FC<any> = () => {
  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
    handleUpdate,
  } = useImport(initialValues, 'asset', schema);

  const [assets, setAssets] = useState<Asset[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);

  const activeOrgId = useSelector(getActiveOrganisationId);

  const [importAssets] = useMutation(IMPORT_ASSETS_MUTATION);

  const exportAssets = useCallback(() => {
    exportDataToExcel(fields, assets, 'oversite-assets-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [assets]);

  const clearData = useCallback(() => {
    handleClear();
    setAssets([]);
    setImportSuccess(false);
  }, [handleClear, setAssets, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setAssets(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setAssets, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData()
    } else if (assets.length > 0) {
      setAssets([]);
    } else {
      handleCancel();
    }
  }, [clearData, assets, setAssets, clearData, handleCancel, importSuccess]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmAssetSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid assets to your selected organisation account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, organisationId } = values;
          const assetsData: Asset[] = [...sanitize(assets)];
          try {
            await importAssets({
              variables: { newAssetData: { assets: assetsData, organisationId } },
            });

            setImportSuccess(true);

            if (downloadExcel) {
              exportAssets();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: 'Something went wrong', duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrgId,
      }),
    [activeOrgId, isAdmin],
  );

  const {
    hideCancel,
    hideDownload,
    hideSave
  } = useHideImportActions(importSuccess, values, assets)

  return (
    <AuthGuard needsActiveOrganisation>
      <Page card>
        <PageHeader style={{ width: '100%' }} title='Import Assets' onBack={hasValues ? back : undefined}
          extra={[
            <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
            <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
            <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
          ]}
        />
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || assets.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />
            <FullButtonLg hidden={!hasValues || assets.length > 0} onClick={next}>
              Next
            </FullButtonLg>
            {assets.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {assets.length} asset
                  {assets.length !== 1 && 's'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrgId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import assets</ImportConfirm>}
                      <ConfirmForm>
                        <OrganisationSelector hidden={!isAdmin} {...cFields.organisationId} gridProps={fullWidth} />
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}
        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}
      </Page>
      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>
    </AuthGuard>
  );
};

export default AssetImport;
