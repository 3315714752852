import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, Redirect } from 'react-router-dom';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { useSession } from '@necta-tech/cognito';
import * as Yup from 'yup';
import { Formik, Field, FormikValues, FormikHelpers } from 'formik';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormButton, Input, Password, FormCard as Card, FormRow } from '../../components/antd';
import { useFormData, useRedirectOnLogin } from '../../hooks';
import { Col, message } from 'antd';
import { useSetCurrentOrganisation } from '../../components/organisation-selector';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .label('Email'),
  password: Yup.string()
    .required()
    .label('Password'),
});

const InputField = styled(Input)`
  margin: 5px;
  margin-top: 0;
`;

const PasswordField = styled(Password)`
  margin: 5px;
  margin-bottom: 0;
`;

export const Column = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormCard = styled(Card)`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 40px 40px 40px 40px;
  padding-top: 30px;
  flex-shrink: 0;
  border-radius: 0;
  @media only screen and (max-width: 768px) {
    width: 96%;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

const NavLink = styled.small`
  padding: 0;
  color: ${p => p.theme.primary};
  font-size: 11px;
  margin: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const LoginButton = styled(FormButton)`
  margin-top: 10px;
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const Login: React.FC<any> = props => {
  const history = useHistory();
  const [cognitoUser, setCognitoUser] = useCognitoUser();
  const clearCurrentOrg = useSetCurrentOrganisation()
  const [handleRedirect] = useRedirectOnLogin((path: string) => history.push(path));

  const [handleSignIn, { loading }] = useSession({
    onCompleted: (result: any) => {
      if (result) {
        setCognitoUser(result);
        clearCurrentOrg();
        handleRedirect();
      }
    },
  });

  const { fields, ...formikCTX } = useFormData(loginSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const email = values.email.replace(/\s/g, '').toLowerCase();
      await handleSignIn(email, values.password);
      actions.resetForm();
    },
  });

  if (cognitoUser.inSession) return <Redirect to='/' />;

  return (
    <Formik {...formikCTX} enableReinitialize validateOnBlur>
      {({ handleSubmit }) => (
        <Container>
          <Column>
            <FormCard>
              <h1>Login</h1>
              <FormRow>
                <InputField
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  {...fields.email}
                  gridProps={GRID_PROPS}
                />

                <PasswordField
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  {...fields.password}
                  gridProps={GRID_PROPS}
                />
                <Col {...GRID_PROPS}>
                  <Links>
                    {/*<NavLink onClick={() => history.push('/register')}>register</NavLink>*/}
                    <NavLink onClick={() => history.push('/forgot-password')}>forgot password</NavLink>
                  </Links>
                </Col>
              </FormRow>

              <LoginButton color='primary' loading={loading} type='submit'>
                LOG IN
              </LoginButton>
            </FormCard>
          </Column>
        </Container>
      )}
    </Formik>
  );
};

export default Login;
