import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { default as ButtonBase  } from 'antd/lib/button'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export type SizeType = 'small' | 'middle' | 'large' | undefined;


declare const ButtonTypes: ["default", "primary", "ghost", "dashed", "link", "text"];
export declare type ButtonType = typeof ButtonTypes[number];
declare const ButtonHTMLTypes: ["submit", "button", "reset"];
export declare type ButtonHTMLType = typeof ButtonHTMLTypes[number];
declare const ButtonShapes: ["circle", "round"];
export declare type ButtonShape = typeof ButtonShapes[number];
export interface BaseButtonProps {
    buttonType?: ButtonType;
    icon?: React.ReactNode;
    shape?: ButtonShape;
    size?: SizeType;
    loading?: boolean | {
        delay?: number;
    };
    prefixCls?: string;
    className?: string;
    ghost?: boolean;
    danger?: boolean;
    block?: boolean;
    children?: React.ReactNode;
}
export declare type AnchorButtonProps = {
    href: string;
    target?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps & Omit<React.AnchorHTMLAttributes<any>, 'type' | 'onClick'>;
export declare type NativeButtonProps = {
    type?: ButtonHTMLType;
    onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps & Omit<React.ButtonHTMLAttributes<any>, 'type' | 'onClick'>;
export declare type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>;

interface Props {
    hidden?: boolean,
    buttonType?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text',
    component?: typeof ButtonBase;
}

export type SubmitButtonProps = ButtonProps & Props

const Component: React.FC<SubmitButtonProps & Props> = ({ component: Component, buttonType, type, hidden, ...props }) => {

    const { status, isSubmitting } = useFormikContext() || {}
    const Render = Component || ButtonBase

    const icon = useMemo(() => {
        switch(status) {
            case 'success':
                return <CheckOutlined />
            case 'error':
                return <CloseOutlined />
            default:
                return undefined
        }
    }, [status])

    if (hidden) return null

    return (
        <Render
            htmlType={type}
            type={buttonType}
            loading={isSubmitting}
            icon={icon}
            {...props}
        />
    )
}

export const SubmitButton = Component
