import React, { useMemo } from 'react'
import { EditOutlined as Edit, CloseOutlined as Close } from '@ant-design/icons'
import { PageAction } from '../Page'

interface EditCloseProps {
    size?: 'large' | 'middle' | 'small',
    onClick: (e?: Event) => void,
    edit?: boolean,
    tooltip?: string
}

export const EditCloseAction: React.FC<EditCloseProps> = ({ tooltip, edit, size, onClick, ...props }) => {

    const _tooltip = useMemo(() => tooltip ?? (edit ? 'Close' : 'Edit'), [tooltip, edit])

    return (
        <PageAction size={size} tooltip={_tooltip} onClick={onClick}>
            { edit ? <Close /> : <Edit /> }
        </PageAction>
    )
}