import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Avatar, Button as AntButton, Dropdown, Menu, Space } from 'antd';
import { UserOutlined, InfoCircleOutlined, QuestionCircleOutlined, SettingOutlined, LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getCurrentUser, getIsAdmin, getActiveOrganisation } from '../../../selectors';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useSetLogoutPaneActive } from '@bit/necta.hooks.logout-pane'

const Button = styled(AntButton)<{ centered?: boolean}>`
  border-radius: 0 !important;
  text-align: ${p => p.centered ? 'center' : 'left'};
  height: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding: 10px 15px;
  transition: all 0.5s ease;
`;

const StyledUserInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  font-size: 12px;
`;

const Role = styled.div`
  font-style: italic;
  font-size: 10px;
`;

const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #0f4c81;
`;

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  currentUser: useSelector(getCurrentUser),
  activeOrg: useSelector(getActiveOrganisation)
})

const UserInfo: FC<any> = ({ hidden, children }: any) => {
  if (hidden) return null;
  return <StyledUserInfo>{children}</StyledUserInfo>;
}

interface NavFooterProps {
  collapsed?: boolean;
  actions?: React.ReactNode[];
}

export const AccountBanner: FC<NavFooterProps> = ({ collapsed, actions = [] }) => {

  const { isAdmin, currentUser, activeOrg } = useSelectors();

  const history = useHistory();

  const setLogoutPaneActive = useSetLogoutPaneActive();

  const handleMenuClick = useCallback((e: any) => {
    switch (e.key) {
      case 'settings':
        history.push('/settings');
        break;
      case 'account':
        history.push('/account');
        break;
      case 'terms':
        window.open('https://oversite.imgix.net/legal/OverSite+Privacy+and+Terms+of+Use+16.10.2017+NR.pdf', '_blank');
        break;
      case 'help':
        window.open('https://necta.atlassian.net/servicedesk/customer/portal/2', '_blank');
        break;
      case 'logout':
        setLogoutPaneActive(true)
        break;
    }
  }, [history, setLogoutPaneActive]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </Menu.Item>
      <Menu.Item key="account" icon={<UserOutlined />}>
        My Account
      </Menu.Item>
      <Menu.Item key="terms" icon={<InfoCircleOutlined />}>
        Terms and Conditions
      </Menu.Item>
      <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
        Help
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LockOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AccountContainer>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type={'primary'} block centered={collapsed}>
          <Avatar src={get(currentUser, 'primaryImage')} icon={<UserOutlined />} />
          <UserInfo hidden={collapsed}>
            <Name>{ get(currentUser, 'firstname') } { get(currentUser, 'lastname') }</Name>
            <Role>{ isAdmin ? 'Admin' : activeOrg?.name }</Role>
          </UserInfo>
        </Button>
      </Dropdown>
      <Space>{ actions }</Space>
    </AccountContainer>
  );
};

AccountBanner.defaultProps = {};

export default AccountBanner;
