import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, FormLoading, AuthGuard, NotFound } from '../../components';
import { EditOutlined as Edit } from '@ant-design/icons';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  ASSIGN_STAFF_TO_TICKET,
} from '../../components';
import { FileUploader } from '../../components/file-uploader';
import { cloneDeep } from '@apollo/client/utilities';
import { useFormData } from '../../hooks';
import { TicketSteps } from './components';
import { Form as AntForm } from 'formik-antd';
import { Tags, Input, ContactNumber, FormRow, DatePicker, TextArea, FormButton, Select } from '../../components/antd';
import { Collapse as Collapser, Tabs as AntTabs } from 'antd';
import { message } from 'antd';
import { LinkedSites, LinkedStaff } from '../../components/linked-items';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd';
import { cleanError } from '../../helpers/error-helper';
import { useTicket, useActiveSteps } from './components/redux';
import { ticketSchema } from './schema';
import { FETCH_TICKET, UPDATE_TICKET } from './gql';
import { TicketReportInner } from './TicketReport';
import { Technicians } from './components';
import { mapArrayToObjects } from '../../helpers';
import { TICKET_STATUS, TICKET_TYPE } from '../../constants';
import { LocationSearch } from '../../components/location-search';
import { get } from 'lodash';
import RepeatTickets from './RepeatTickets';

const { Panel } = Collapser;
const { TabPane } = AntTabs;
const ticket_type = mapArrayToObjects(Object.keys(TICKET_TYPE));
const ticket_status = mapArrayToObjects(Object.keys(TICKET_STATUS));

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Tabs = styled(AntTabs)`
  width: 100%;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

interface Props {
  mode: 'view' | 'edit';
}

export const Ticket: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const [ticket, loadTicket, updateTicket] = useTicket();
  const steps = useActiveSteps();

  const { fields, initialValues, ...formikCTX } = useFormData(ticketSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { id, dateLastServiced, datePurchased, documents, assignedSite, assignedStaff, organisationId, organisation, primaryContactNumberFull, steps, comments, assignedTechnicians, ...restValues } = values;
      message.loading({ content: 'Updating ticket...', duration: 10 });
      return handleUpload({
        variables: {
          id,
          updateTicket: { ...restValues },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Ticket updated successfully', duration: 2 });
      updateTicket({ id, ...result?.data?.updateTicket })
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update Ticket'), duration: 4 });
    },
    override: 'ticket',
  });

  // const [ticket, setTicket] = useState<any>({ ...initialValues });
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchTicket, { loading }] = useLazyQuery(FETCH_TICKET, {
    variables: { id },
    onCompleted: (result: any) => {
      // setTicket(result.ticket);
      loadTicket(result.ticket);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchTicket();
  }, []);

  const [handleUpload] = useMutation(UPDATE_TICKET);

  const assignedStaff = useMemo(() => (ticket?.assignedStaff ? cloneDeep(ticket.assignedStaff) : null), [ticket]);
  const assignedSite = useMemo(() => (ticket?.assignedSite ? [cloneDeep(ticket.assignedSite)] : null), [ticket]);

  const handleBack = useCallback(() => history.goBack(), [history]);
  const handleRefresh = useCallback(() => fetchTicket(), [fetchTicket]);
  const handleSetEdit = useCallback((_edit: boolean) => (e?: any) => setEdit(_edit), [setEdit]);

  const handleHelp = useCallback(() => window.open('https://necta.atlassian.net/servicedesk/customer/portal/2', '_blank'), []);

  if (loading) return <FormLoading />;

  if (!ticket) return <NotFound handleBack={() => history.goBack()} handleRefresh={fetchTicket} />;

  if (ticket.archived) return <NotFound handleBack={() => history.goBack()} title={'Ticket Archived'} subTitle={<>This ticket has been archived, please <a onClick={handleHelp}>contact us</a> if you would like to try revert this.</>} />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={ticket || initialValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, dirty }) => (
          <Page
            card
            title={`${edit ? 'Edit' : 'View'} Ticket`}
            onBack={handleBack}
            extra={[
              <EditCloseAction edit={edit} onClick={handleSetEdit(!edit)} />,
              <RefreshAction onClick={handleRefresh} />,
              <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
            ]}
          >
            <Tabs defaultActiveKey='steps' tabPosition='top' size='large'>
              <TabPane tab='Steps' key='steps'>
                <TicketSteps />
              </TabPane>
              <TabPane tab='Repeat Ticket' key='repeat' disabled={!ticket.isRepeats}>
                <RepeatTickets ticket={{...ticket}}/>
              </TabPane>
              <TabPane tab='Ticket' key='ticket'>
                <TicketReportInner ticket={{ ...ticket, steps }}/>
              </TabPane>
              <TabPane tab='Info' key='info'>
                <Form layout='vertical'>
                  <Collapse defaultActiveKey='information' ghost>
                    <Panel header='Contact Information' key='information'>
                      <FormRow disabled={!edit}>
                        <Input {...fields.name} />
                        <Select options={ticket_type} {...fields.type} />
                        <Select options={ticket_status} {...fields.status} />
                        <Input {...fields.customerName} />
                        <Input {...fields.primaryContactName} />
                        <ContactNumber
                          numberField={fields.primaryContactNumber}
                          countryCodeField={fields.primaryContactNumberCountryCode}
                        />
                        <Input {...fields.primaryContactEmail} />
                        <DatePicker
                          format='DD/MM/YYYY HH:mm'
                          { ...fields.dueDate }
                          showTime={{ format: 'HH:mm' }}
                        />
                        <TextArea gridProps={GRID_PROPS} {...fields.description} rows={7} />
                      </FormRow>
                    </Panel>

                    <Panel header='Location' key='location'>
                      <FormRow disabled={!edit}>
                        <LocationSearch {...fields.address} gridProps={GRID_PROPS} parentKey={'address'} defaultValue={get(values, 'address.fullAddress')} />
                        <Input {...fields.address.addressLine1} />
                        <Input {...fields.address.country} />
                        <Input {...fields.address.state} />
                        <Input {...fields.address.postalCode} />
                      </FormRow>
                    </Panel>

                    <Panel header='Ticket Categories' key='categories'>
                      <FormRow disabled={!edit}>
                        <Tags
                          isEditable={false}
                          gridProps={GRID_PROPS}
                          {...fields.tags}
                          placeholder='Type Text and press Enter'
                        />
                      </FormRow>
                    </Panel>
                  </Collapse>
                </Form>
              </TabPane>

              <TabPane tab='Technicians' key='technicians'>
                <FormRow disabled={!edit}>
                  <Technicians ticket={ticket} technicians={ticket.assignedTechnicians} />
                </FormRow>
              </TabPane>

              {/*This will always be disabled. Perhaps this will even change to something more static*/}
              <TabPane tab='Site' key='linkedSite'>
                <FormRow disabled>
                  <LinkedSites item={ticket} linkedItem={null} data={assignedSite} protocol={null} showAssign={false} title={'Site'} />
                </FormRow>
              </TabPane>

              <TabPane tab='Documents' key='documents'>
                <FormRow disabled={!edit}>
                  {ticket?.id && <FileUploader id={ticket?.id} parentType='ticket' fileType='DOCUMENT' initialFiles={ticket?.documents} showFields={['name', 'isPublic']}/>}
                </FormRow>
              </TabPane>
            </Tabs>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

export default Ticket;
