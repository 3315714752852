import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { getIsAdmin, getActiveOrganisationId } from '../../selectors';
import { useFormData } from '../../hooks';
import { CheckBox, CheckBoxGroup, DatePicker } from '../../components/antd';
import { mapArrayToObjects } from '../../helpers';
import { ORGANISATION_DEPARTMENTS, ORGANISATION_STATUSES, REGIONS, WORK_STATUSES } from '../../constants';
import { Form as AntForm } from 'formik-antd';
import { TextArea, Input, ContactNumber, Select, FormRow, FormButton } from '../../components/antd';
import { Card, Collapse as Collapser, Col } from 'antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import Default from '../../assets/default_profile.png';
import { Page, AuthGuard } from '../../components';
import { message } from 'antd';
import { useHistory } from 'react-router';
import { cleanError } from '../../helpers/error-helper';
import Moment from 'moment';

const { Panel } = Collapser;

export const organisationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Company Name'),
  contactName: Yup.string()
    .trim()
    .nullable()
    .label('Primary Contact Name'),
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  contactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  primaryImage: Yup.string()
    .nullable()
    .label('Primary Image'),
  parentOrganisationId: Yup.string()
    .nullable()
    .label('Parent Organisation'),
  attachPolicies: Yup.array()
    .default([])
    .label('Policies'),
  departments: Yup.array().label('Departments'),
  regions: Yup.array().label('Regions'),
  status: Yup.string()
    .nullable()
    .label('Status'),
  workStatus: Yup.string()
    .nullable()
    .label('Work Status'),
  notes: Yup.string()
    .nullable()
    .label('Notes'),
  ownedByEmail: Yup.string()
    .trim()
    .nullable()
    .label('Owner Email'),
  testAccount: Yup.boolean()
    .nullable()
    .label(''),
  testAccountExpiry: Yup.string()
    .nullable()
    .default(null)
    .meta({ type: 'date' })
    .label('Demo Account Expiry'),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }),
});

const departments = mapArrayToObjects(ORGANISATION_DEPARTMENTS);
const statuses = mapArrayToObjects(ORGANISATION_STATUSES);
const regions = mapArrayToObjects(REGIONS);
const workStatuses = mapArrayToObjects(WORK_STATUSES);

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CheckboxOuter = styled.div`
  padding: 5px 15px;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

export const POLICIES = ['CanCreateOrg', 'CanCreateAsset', 'CanCreateStaff', 'CanCreateSite', 'CanCreateTicket'];

export const Checkboxes = ({ hidden, ...props }: any) => {
  if (hidden) {
    return null;
  }
  return (
    <CheckboxOuter>
      <CheckBoxGroup {...props} options={POLICIES} />
    </CheckboxOuter>
  );
};

const ADD_ORGANISATION = gql`
  mutation ADD_ORGANISATION($newOrganisationData: NewOrganisationInput!) {
    addOrganisation(newOrganisationData: $newOrganisationData) {
      id
      name
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const OrganisationAdd: React.FC<any> = props => {
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);
  const history = useHistory();

  const [handleAdd] = useMutation(ADD_ORGANISATION);

  const { fields, initialValues, ...formikCTX } = useFormData(organisationSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      return handleAdd({
        variables: { newOrganisationData: { ...values, testAccountExpiry: values.testAccountExpiry || null } },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Organisation added successfully', duration: 2 });
      history.push(`/organisation/edit/${result?.data?.addOrganisation?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add organisation'), duration: 4 });
    },
    override: 'organisation',
  });

  const [defaultValues] = useState({
    ...initialValues,
    parentOrganisationId: isAdmin ? null : activeOrgId,
  });

  const handleTestAccount = useCallback(
    (setFieldValue: any) => (e: any) => {
      if (e?.target?.checked) {
        setFieldValue(
          'testAccountExpiry',
          Moment()
            .add(90, 'days')
            .format('YYYY-MM-DD'),
        );
      } else {
        setFieldValue('testAccountExpiry', null);
      }
    },
    [],
  );

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <Page card title='Add Organisation'>
              <Form layout='vertical'>
                <Collapser defaultActiveKey='contact' ghost>
                  <Panel header='Contact Information' key='contact'>
                    <ProfilePictureUploader disabled={isSubmitting} fieldKey='primaryImage' defaultPic={Default} />
                    <FormRow>
                      <Input {...fields.name} />
                      <Input {...fields.contactName} />
                      <Input {...fields.contactEmail} />
                      <ContactNumber
                        numberField={fields.contactNumber}
                        countryCodeField={fields.contactNumberCountryCode}
                      />
                      <OrganisationSelector {...fields.parentOrganisationId} disabled={!isAdmin} />
                      <Checkboxes hidden={!isAdmin} {...fields.attachPolicies} />
                    </FormRow>
                  </Panel>

                  <Panel header='Verification Data' key='verification'>
                    <FormRow>
                      <Select options={departments} mode={'multiple'} {...fields.departments} allowClear />
                      <Select options={regions} mode={'multiple'} {...fields.regions} allowClear />
                      <Select options={statuses} {...fields.status} allowClear />
                      <Select options={workStatuses} {...fields.workStatus} allowClear />
                    </FormRow>
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow>
                      <Input {...fields.address.addressLine1} />
                      <Input {...fields.address.country} />
                      <Input {...fields.address.state} />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>

                  <Panel header='Notes' key='notes'>
                    <FormRow>
                      <TextArea {...fields.notes} rows={10} gridProps={GRID_PROPS} />
                    </FormRow>
                  </Panel>

                  {isAdmin && (
                    <Panel header='Settings' key='settings'>
                      <FormRow>
                        <CheckBox {...fields.testAccount} onChange={handleTestAccount(setFieldValue)}>
                          Demo Account
                        </CheckBox>
                        <DatePicker {...fields.testAccountExpiry} />
                      </FormRow>
                    </Panel>
                  )}

                  <Panel header='Owner' key='owner'>
                    <FormRow>
                      <Input {...fields.ownedByEmail} />
                    </FormRow>
                  </Panel>
                </Collapser>

                <Add type={'submit'}>Add</Add>
              </Form>
            </Page>
          );
        }}
      </Formik>
    </AuthGuard>
  );
};

export default OrganisationAdd;
