import { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

const FILE_ADDED = gql`
  subscription FileAdded($id: String!) {
    FileAdded(id: $id) {
      id
      key
      name
      createdAt
    }
  }
`;

const FILE_DELETED = gql`
  subscription FileDeleted($id: String!) {
    FileDeleted(id: $id) {
      id
    }
  }
`;

export const useFileSubscription = (
  id: string,
  initialFiles?: any[],
  options?: {
    onAdded?: (result: any) => void;
    onDeleted?: (result: any) => void;
  },
) => {
  const [files, setFiles] = useState<any[]>([]);

  const onAdded = useSubscription(FILE_ADDED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const newFile = sub.subscriptionData.data.FileAdded;
        setFiles([...files, newFile]);
        if (options && options.onAdded) options.onAdded(newFile);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  const onDeleted = useSubscription(FILE_DELETED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const removedFile = sub.subscriptionData.data.FileDeleted;
        const filteredFiles = files.filter((file: any) => file.id !== removedFile.id);
        setFiles([...filteredFiles]);
        if (options && options.onDeleted) options.onDeleted(removedFile);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (initialFiles && initialFiles.length) setFiles([...initialFiles]);
  }, [initialFiles]);

  return [files, onAdded, onDeleted] as const;
};
