import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getDateFormatted, getDateFull, mapArrayToOptions } from '../../helpers';
import { Page, AuthGuard, Avatar } from '../../components';
import { cloneDeep } from '@apollo/client/utilities';
import { useFetchContactList } from '../../graphql/hooks/use-fetch-contact-list';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';


export const ContactList: React.FC<any> = props => {
  const [contact, setContact] = useState([]);

  const history = useHistory();

  const [fetchContact, { loading }] = useFetchContactList((result: any) => {
    if (result.contact) {
      setContact(cloneDeep(result.contact));
    }
  });

  const columns = useOverrideColumns(
    [
     
      {
        title: 'Site Billing Contact Name',
        key: 'siteBillingName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Billing Contact Email',
        key: 'siteBillingEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Billing Contact Email',
        key: 'email',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Tech Contact Name',
        key: 'techName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Tech Contact Email',
        key: 'techEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Tech Contact Name',
        key: 'siteTechName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Tech Contact Email',
        key: 'siteTechEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Security Contact Name',
        key: 'securityName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Security Contact Email',
        key: 'securityEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Security Contact Name',
        key: 'siteSecurityName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Security Contact Email',
        key: 'siteSecurityEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Management Contact Name',
        key: 'managementName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Management Contact Email',
        key: 'managementEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Management Contact Name',
        key: 'siteManagementName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site Management Contact Email',
        key: 'siteManagementEmail',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Billing Contact Number',
        key: 'contactNumberFull',
        filtering: true,
      },
      {
        title: 'Tech Contact Number',
        key: 'contactNumberFullTech',
        filtering: true,
      },
      {
        title: 'Security Contact Number',
        key: 'contactNumberFullSecurity',
        filtering: true,
      },
      {
        title: 'Management Contact Number',
        key: 'contactNumberFullManagement',
        filtering: true,
      },
      {
        title: 'Shortid',
        key: 'shortid',
        show: false,
        filtering: true,
      },
      {
        title: 'Created At',
        key: 'createdAt',
        sorting: { compare: Sort.DATE },
        render: (r: any) => (r ? getDateFull(r) : ''),
      },
    ],
    'contact',
  );

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page card>
        <Table
          data={contact}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/contact/edit/${record.id}`),
          })}
          refresh={fetchContact}
          title={'Customer Contact List'}
          exportTitle={'Contact'}
          pagination={{
            defaultPageSize: 20,
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default ContactList;
