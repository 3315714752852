import styled from 'styled-components';

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-self: center;
`;

export const ImportForm = styled(Form)`
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 0;
`;

export const Collapser = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
