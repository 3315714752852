import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { presentError } from '@necta-tech/alert';
import { forgotPassword, forgotPasswordSubmit } from '@necta-tech/cognito';
import { useFormData } from '../../hooks';
import { Input, FormButton, FormRow, Password } from '../../components/antd'
import { Button, Card as AntCard } from 'antd';
import { Form as AntForm } from 'formik-antd'
import { Column, FormCard } from './Login';
import { ArrowLeftOutlined } from '@ant-design/icons';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email()
    .required()
    .label('Email'),
  otp: Yup.string().label('OTP'),
  password: Yup.string().label('New Password'),
});

const OTPButton = styled(FormButton)`
  margin: 0;
  margin-top: 20px;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  h1 {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 20px;
    & > span {
      flex: 1;
      text-align: center;
      padding-right: 32px;
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const ForgotPassword: React.FC<any> = props => {
  const history = useHistory();
  const [{ email, reset }, setState] = useState({
    email: true,
    reset: false,
  });

  const handleOnSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    try {
      const cleanEmail = values.email.replace(/\s/g, '').toLowerCase();
      if (email) {
        await forgotPassword(cleanEmail);
        setState({ reset: true, email: false });
      } else if (reset) {
        console.log(values.otp);
        if (values.otp === '' || values.password === '') {
          presentError('otp or password not provided, please try again');
          actions.setFieldValue('otp', '');
          actions.setFieldValue('password', '');
          return;
        }
        await forgotPasswordSubmit(cleanEmail, values.otp, values.password);
        history.push('/');
      }
    } catch (e) {
      presentError(e);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { fields, ...formikCTX } = useFormData(forgotPasswordSchema, { onSubmit: handleOnSubmit });

  const handleBack = useCallback(() => {
    if (email) {
      return history.goBack();
    }
    return setState({ reset: false, email: true });
  }, [email, setState, history]);

  return (
    <Formik {...formikCTX} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <Container>
            <Column>
              <FormCard>
                <h1><Button shape={'circle'} type={'text'} icon={<ArrowLeftOutlined />} onClick={handleBack}/><span>{email ? 'Forgot Password' : 'OTP'}</span></h1>
                {email && (
                  <FormRow style={{ width: '100%'}}>
                    <Input {...fields.email} gridProps={GRID_PROPS} />
                    <OTPButton loading={isSubmitting} type='submit'>
                      SEND OTP
                    </OTPButton>
                  </FormRow>
                )}
                {reset && (
                  <FormRow style={{ width: '100%'}}>
                    <Input gridProps={GRID_PROPS} {...fields.otp} />
                    <Password gridProps={GRID_PROPS} {...fields.password} disabled={values.otp === ''} />
                    <OTPButton loading={isSubmitting} type='submit'>
                      RESET
                    </OTPButton>
                  </FormRow>
                )}
              </FormCard>
            </Column>
          </Container>
        );
      }}
    </Formik>
  );
};

export default ForgotPassword;
