import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { presentError } from '@necta-tech/alert';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import AutoForm from '../../components/form/auto-form';
import { Page, AuthGuard } from '../../components';
import { useFormData, useImport } from '../../hooks';
import { getActiveOrganisationId, getIsAdmin } from '../../selectors';
import { staffSchema, Staff, confirmStaffSchema } from '../../schemas';
import { exportDataToExcel } from '../../services';
import gql from 'graphql-tag';
import { message, Modal, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FullButtonLg } from '../../components/antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../components/importer';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../components/antd'
import { useHideImportActions } from '../../hooks'

const { confirm } = Modal;

export const IMPORT_STAFF_MUTATION = gql`
  mutation IMPORT_STAFF_MUTATION($newStaffData: NewStaffMultipleInput!) {
    addStaffMultiple(newStaffData: $newStaffData)
  }
`;

const { schema, initialValues } = getFormData(staffSchema);

export const StaffImport: React.FC<any> = () => {
  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
    handleUpdate,
  } = useImport(initialValues, 'staff', schema);

  const [staff, setStaff] = useState<Staff[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);

  const activeOrgId = useSelector(getActiveOrganisationId);

  const [importStaff] = useMutation(IMPORT_STAFF_MUTATION);

  const exportStaff = useCallback(() => {
    exportDataToExcel(fields, staff, 'oversite-staff-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [staff]);

  const clearData = useCallback(() => {
    handleClear();
    setStaff([]);
    setImportSuccess(false);
  }, [handleClear, setStaff, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setStaff(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setStaff, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData()
    } else if (staff.length > 0) {
      setStaff([]);
    } else {
      handleCancel();
    }
  }, [clearData, staff, setStaff, clearData, handleCancel, importSuccess]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmStaffSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid staff members to your selected organisation account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, organisationId } = values;
          const staffData: Staff[] = [...sanitize(staff)];

          try {
            await importStaff({
              variables: { newStaffData: { staff: staffData, organisationId } },
            });

            setImportSuccess(true);

            if (downloadExcel) {
              exportStaff();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: 'Something went wrong', duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrgId,
      }),
    [activeOrgId, isAdmin],
  );

  const {
    hideCancel,
    hideDownload,
    hideSave
  } = useHideImportActions(importSuccess, values, staff)

  return (
    <AuthGuard needsActiveOrganisation>
      <Page
        card
        title='Import Staff'
        onBack={hasValues ? back : undefined}
        extra={[
          <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
          <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
          <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
        ]}
      >
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || staff.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />
            <FullButtonLg hidden={!hasValues || staff.length > 0} onClick={next}>
              Next
            </FullButtonLg>
            {staff.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {staff.length} staff
                  member{staff.length !== 1 && 's'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrgId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import staff</ImportConfirm>}
                      <ConfirmForm>
                        <OrganisationSelector hidden={!isAdmin} {...cFields.organisationId} gridProps={fullWidth} />
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}
        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}
      </Page>
      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>
    </AuthGuard>
  );
};

export default StaffImport;
