import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Page, AuthGuard } from '../../components';
import ProfilePictureUploader from '../../components/profile-picture-uploader';
import { mapArrayToObjects } from '../../helpers';
import { useSelector } from 'react-redux';
import { getActiveOrganisationId, getIsAdmin, getIsOrganisation } from '../../selectors';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Password, Input, ContactNumber, Select, FormRow, FormButton, CheckBox } from '../../components/antd';
import { Card, Collapse as Collapser, Col } from 'antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import Default from '../../assets/default_profile.png';
import { message } from 'antd';
import { COUNTRIES, SA_PROVINCES, USER_TYPES } from '../../constants';
import { cleanError } from '../../helpers/error-helper';
import { contactSchema } from './schema';

const { Panel } = Collapser;

const countries = mapArrayToObjects(COUNTRIES);
const provinces = mapArrayToObjects(SA_PROVINCES);

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

const finalSchema = contactSchema;

const ADD_CONTACT = gql`
  mutation ADD_CONTACT($newContact: NewContactInput!) {
    addContact(newContact: $newContact) {
      id
      securityName
      techEmail
      managementName
      managementEmail
      techName 
      securityEmail
      shortid
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const ContactAdd: React.FC<any> = props => {
  const history = useHistory();
  const [cognitoUser] = useCognitoUser();
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);
  const isOrganisation = useSelector(getIsOrganisation);

  const [handleAdd] = useMutation(ADD_CONTACT);
  console.log(finalSchema)

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      /*if (values.role !== 'Admin' && !values.organisationId) {
        message.error({
          content: 'Please ensure to select an active organisation in the Security section!',
          duration: 2,
        });
        return;
      }*/
      console.log(values);
      //return false;
      return handleAdd({ variables: { newContact: { ...values } } });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Contact added successfully', duration: 2 });
      history.push(`/contact/edit/${result?.data?.addContact?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add Contact'), duration: 4 });
    },
    override: 'contact',
  });

  const defaultValues = useMemo(
    () =>
      Object.assign({
        ...initialValues,
        organisationId: isAdmin ? null : activeOrgId,
      }),
    [activeOrgId, isAdmin, isOrganisation],
  );

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <Page card title='Add Contact'>
              <Form layout='vertical'>
                <Collapse defaultActiveKey='verification' ghost>
                  <Panel header='Customer Contact' key='verification'>
                    {/* <ProfilePictureUploader disabled={isSubmitting} fieldKey='profilePicUrl' defaultPic={Default} /> */}
                    <FormRow>
                      <Input {...fields.billingName} />
                      <Input {...fields.email} />
                      <ContactNumber
                        numberField={fields.contactNumber}
                        countryCodeField={fields.contactNumberCountryCode}
                      />
                     
                      <OrganisationSelector {...fields.organisationId} disabled={!isAdmin} />
                      <Input {...fields.techName} />
                      <Input {...fields.techEmail} />
                      <ContactNumber
                        numberField={fields.contactNumberTech}
                        countryCodeField={fields.contactNumberCountryCodeTech}
                      />
                     <Input {...fields.managementName} />
                     <Input {...fields.managementEmail} />
                     <ContactNumber
                        numberField={fields.contactNumberManagement}
                        countryCodeField={fields.contactNumberCountryCodeManagement}
                      />
                      <Input {...fields.securityName} />
                      <Input {...fields.securityEmail} /> 
                      <ContactNumber
                        numberField={fields.contactNumberSecurity}
                        countryCodeField={fields.contactNumberCountryCodeSecurity}
                      /> 
                     </FormRow>
                  </Panel>  
                  
                  <Panel header='Customer Contact' key='sitecontact'>
                    {/* <ProfilePictureUploader disabled={isSubmitting} fieldKey='profilePicUrl' defaultPic={Default} /> */}
                    <FormRow>
                      <Input {...fields.siteBillingName} />
                      <Input {...fields.siteBillingEmail} />
                      <ContactNumber
                        numberField={fields.contactNumberSite}
                        countryCodeField={fields.contactNumberCountryCodeSite}
                      />
                      
                      <Input {...fields.siteTechName} />
                      <Input {...fields.siteTechEmail} />
                      <ContactNumber
                        numberField={fields.contactNumberTechSite}
                        countryCodeField={fields.contactNumberCountryCodeTechSite}
                      />
                      <Input {...fields.siteManagementName} />
                     <Input {...fields.siteManagementEmail} />
                     <ContactNumber
                        numberField={fields.contactNumberManagementSite}
                        countryCodeField={fields.contactNumberCountryCodeManagementSite}
                      />
                    
                     <Input {...fields.siteSecurityName} />
                     <Input {...fields.siteSecurityEmail} /> 
                     <ContactNumber
                        numberField={fields.contactNumberSecuritySite}
                        countryCodeField={fields.contactNumberCountryCodeSecuritySite}
                      /> 
                    </FormRow>
                  </Panel>
                      
                    
                </Collapse>
                <Add loading={isSubmitting} type='submit'>
                  ADD
                </Add>
              </Form>
            </Page>
          );
        }}
      </Formik>
    </AuthGuard>
  );
};

export default ContactAdd;