import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import PicPlaceholder from '../../../assets/default_profile.png';
import {
  ContactNumber,
  FormButton,
  FormRow,
  Input,
  Select,
  ProfilePictureUploader,
} from '../../../components';
import { LocationSearch } from '../../../components/location-search';
import { useFormData } from '../../../hooks';
import * as Yup from 'yup';
import { message, Skeleton } from 'antd';
import { get } from 'lodash';
import { cleanError } from '../../../helpers/error-helper';
import { COUNTRIES, SA_PROVINCES } from '../../../constants';
import { mapArrayToObjects } from '../../../helpers';
import { useUpdateUser } from '../../../graphql/hooks';
import { userSchema } from '../schema';
import styled from 'styled-components';

const provinces = mapArrayToObjects(SA_PROVINCES)
const countries = mapArrayToObjects(COUNTRIES);

const Save = styled(FormButton)`
  margin-top: 20px;
`;

interface EditProfileProps {
  user: any;
  edit?: boolean;
  loading?: boolean;
}

const key = 'user-update';

export const EditMyProfile: FC<EditProfileProps> = (props) => {
  const { user, edit, loading } = props;

  const [handleUpload] = useUpdateUser({});

  const { fields, initialValues, ...formikCTX } = useFormData(userSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', key, duration: 10 });
      const { id, contactNumberFull, activeOrgId, activeOrg, linkedOrganisations, documents, ...rest } = values; // Destructure other values here
      return handleUpload({
        variables: {
          id,
          user: {
            ...rest,
          },
        },
      });
    },
    onError: (error: any) => {
      //console.log(error);
      message.error({ content: cleanError(error, 'Unable to update account'), key, duration: 4 });
    },
    onCompleted: () => {
      message.success({ content: 'Account updated!', key, duration: 2 });
    },
  });

  if (loading) return <Skeleton active avatar paragraph={{ rows: 5 }} />;

  return (
    <Formik {...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
      {({handleSubmit, isSubmitting, setFieldValue, values}) => (
        <Form layout={'vertical'}>
          <ProfilePictureUploader disabled={!edit || isSubmitting} fieldKey="profilePicUrl" defaultPic={PicPlaceholder} />

          <FormRow disabled={!edit}>
            <Input {...fields.firstname} />
            <Input {...fields.lastname} />
            <ContactNumber
              numberField={fields.contactNumber}
              countryCodeField={fields.contactNumberCountryCode}
            />
            <Input {...fields.email} disabled />
            <LocationSearch {...fields.address} parentKey={'address'} defaultValue={get(values, 'address.fullAddress')} />
            <Input {...fields.address.addressLine1} />
            <Select {...fields.address.country} options={countries} />
            <Select {...fields.address.state}  options={provinces} />
          </FormRow>

          <Save hidden={!edit}>
            SAVE
          </Save>

        </Form>
      )}
    </Formik>
  );
};

EditMyProfile.defaultProps = {};

export default EditMyProfile;
