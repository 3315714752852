import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const ActionTypes = Object.freeze({
  SET_REDIRECT_ON_LOGIN: 'SET_REDIRECT_ON_LOGIN',
  CLEAR_REDIRECT_ON_LOGIN: 'CLEAR_REDIRECT_ON_LOGIN'
})

export const Actions = {
  setRedirectOnLogin: (path: any) => {
    return { type: ActionTypes.SET_REDIRECT_ON_LOGIN, payload: path }
  },
  clearRedirectOnLogin: () => {
    return { type: ActionTypes.CLEAR_REDIRECT_ON_LOGIN }
  }
}

export const selector = (state: any) => state.redirectOnLogin

/**
 * A hook for the component that sets up the redirect.
 * @returns A handler that sets the redirect (normally right before rerouting)
 */
export const useSetRedirectOnLogin = () => {
  const dispatch = useDispatch()
  return useCallback((path: string) => { dispatch(Actions.setRedirectOnLogin(path)) }, [dispatch])
}

/**
 * A hook to be called in the component that triggers or handles the redirect.
 * @param onRedirect The callback defining the routing behavior to follow when the redirect is triggered.
 * @param debug a flag for whether to show debug logs.
 */
export const useRedirectOnLogin = (onRedirect: (path: string) => void, debug: boolean = false) => {
  const redirectOnLogin = useSelector(selector)
  const setRedirectOnLogin = useSetRedirectOnLogin()

  const handleRedirect = useCallback((path?: string) => {
    const to = path || redirectOnLogin
    if (debug) console.log('redirecting to:', to)
    onRedirect(to);
    setRedirectOnLogin('');
  }, [debug, onRedirect, redirectOnLogin, setRedirectOnLogin]);

  return [
    handleRedirect,
    {
      redirectOnLogin
    }
  ] as const
}

interface Action {
  type: string,
  payload: any
}

export const redirectOnLogin = (state: string = '', { type, payload }: Action) => {
  switch (type) {
    case 'SET_REDIRECT_ON_LOGIN':
      return payload
    case 'CLEAR_REDIRECT_ON_LOGIN':
      return ''
    default:
      return state
  }
}

export default redirectOnLogin
