import styled from 'styled-components'

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  line-height: 30px;
  word-wrap: break-word;
  width: 100%;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export default CardTitle