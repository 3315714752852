import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { stepFields } from '../fragments';
import { useUpdateSteps } from '../../pages/ticket/components/redux';

const STEP_ADDED = gql`
  subscription TicketStepAdded($id: String!) {
    TicketStepAdded(id: $id) {
      ${stepFields}
    }
  }
`;

const STEP_DELETED = gql`
  subscription TicketStepDeleted($id: String!) {
    TicketStepDeleted(id: $id) {
      id
    }
  }
`;

const STEP_CHANGED = gql`
    subscription TicketStepAdded($id: String!) {
        TicketStepUpdated(id: $id) {
            ${stepFields}
        }
    }
`;

export const useSteps = (
  id: string,
  initialSteps?: any[],
  options?: {
    onAdded?: (result: any) => void;
    onDeleted?: (result: any) => void;
    onChanged?: (result: any) => void;
  },
) => {

  const [add, remove, _, setStep] = useUpdateSteps();

  const onAdded = useSubscription(STEP_ADDED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const newStep = sub.subscriptionData.data.TicketStepAdded;
        add(newStep);
        if (options && options.onAdded) options.onAdded(newStep);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  const onDeleted = useSubscription(STEP_DELETED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const removedStep = sub.subscriptionData.data.TicketStepDeleted;
        remove(removedStep)
        if (options && options.onDeleted) options.onDeleted(removedStep);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  const onChanged = useSubscription(STEP_CHANGED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const changedStep = sub.subscriptionData.data.TicketStepUpdated;
        setStep(changedStep)
        if (options && options.onChanged) options.onChanged(changedStep);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  return [onAdded, onDeleted, onChanged] as const;
};
