import { ThemeConfig, loadThemes } from '@bit/necta.hooks.themes';
import { store } from './redux-config';

const AppThemes = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

const Themes: ThemeConfig = {
  [AppThemes.DEFAULT]: {},
  [AppThemes.DARK]: {},
  [AppThemes.LIGHT]: {},
};

loadThemes(store, Themes);
