import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { getDateFull } from '../../helpers';
import { Page, AuthGuard } from '../../components/Page';
import { cloneDeep } from '@apollo/client/utilities';
import { useOverrideColumns } from '../../hooks';
import Table, { Filter, Sort } from '../../components/antd/table';
import { allTicketFields } from '../../graphql/fragments';
import { message } from 'antd';
import { cleanError } from '../../helpers/error-helper';
import { useCurrentOrganisation } from '../../components/organisation-selector';
import { Collapse as Collapser, Tabs as AntTabs } from 'antd';

const { TabPane } = AntTabs;

const Tabs = styled(AntTabs)`
  width: 100%;
`;

const QUERY = gql`
  query($ticketQuery: TicketQuery!) {
    tickets(queryOptions: { relations: ["organisation"] }, ticketQuery: $ticketQuery ) {
      ${allTicketFields}
      createdAt
    }
  }
`;

export const TicketList: React.FC<any> = props => {
  const [tickets, setTickets] = useState([]);

  const history = useHistory();
  const [currentOrg] = useCurrentOrganisation();

  const [fetchTickets, { loading }] = useLazyQuery(QUERY, {
    variables: { ticketQuery: { organisationId: currentOrg?.id } },
    onCompleted: (result: any) => {
      if (result && result.tickets) {
        /*let isRepeats: any = [];
        let normal: any = [];
        for(let i in result.tickets){
          if(result.tickets[i].isRepeats===true){
            isRepeats.push(result.tickets[i]);
          }else{
            normal.push(result.tickets[i]);
          }
        }*/
        setTickets(cloneDeep(result.tickets));
      }
    },
    onError: (error: any) => {
      console.log(JSON.stringify(error, null, 2));
      message.error({ content: cleanError(error, 'Unable to fetch tickets'), duration: 4 });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchTickets();
  }, []);

  const columns: any[] = useOverrideColumns(
    [
      {
        title: 'Ticket ID',
        key: 'id',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Ticket Name',
        key: 'name',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Repeat',
        key: 'isRepeats',
        filtering: true,
        sorting: true,
        render: (r: any) => (r == true ? 'Yes' : 'No'),
      },
      {
        title: 'Repeat Interval',
        key: 'repeatInterval',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Parent Ticket',
        key: 'repeatId',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Customer Name',
        key: 'customerName',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Site',
        key: 'assignedSite.name',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Contact Name',
        key: 'primaryContactName',
        filtering: true,
        sorting: true,
        show: false,
      },
      {
        title: 'Contact Number',
        key: 'primaryContactNumberFull',
        filtering: true,
        show: false,
      },
      {
        title: 'Email Address',
        key: 'primaryContactEmail',
        filtering: true,
        show: false,
      },
      {
        title: 'Tags',
        key: 'tags',
        filtering: true,
        sorting: true,
        show: true,
        render: (r: any) => r.join(', '),
      },
      {
        title: 'Organisation',
        key: 'organisation.name',
        filtering: true,
        sorting: true,
        show: false,
      },
      {
        title: 'Shortid',
        key: 'shortid',
        show: false,
        filtering: true,
      },
      {
        title: 'Database ID',
        key: 'id',
        show: false,
      },
      {
        title: 'Status',
        key: 'status',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Due Date',
        key: 'dueDate',
        sorting: true,
        render: (r: any) => (r ? getDateFull(r) : ''),
      },
      {
        title: 'Created At',
        key: 'createdAt',
        sorting: { compare: Sort.DATE },
        show: true,
        render: (r: any) => (r ? getDateFull(r) : ''),
      },
    ],
    'ticket',
  );

  return (
    <AuthGuard needsActiveOrganisation checkForOrganisation>
      <Page card>
        <Table
          data={tickets}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/ticket/edit/${record.id}`),
          })}
          refresh={fetchTickets}
          title={'Ticket List'}
          exportTitle={'Ticket'}
          pagination={{
            defaultPageSize: 20,
          }}
          rowClassName={'clickable'}
        />
      </Page>
    </AuthGuard>
  );
};

export default TicketList;
