import * as Yup from 'yup';

export const customerSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .label('Email'),
  companyName: Yup.string()
    .required()
    .label('Company Name'),
  regNum: Yup.string()
    .required()
    .label('Company Registration Number'),
  vatNum: Yup.string()
    .required()
    .label('Company VAT Number'),
  headquarters: Yup.string()
    .required()
    .label('Headquarters'),
  contactName: Yup.string()
    .required()
    .label('Contact Person Name'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumber: Yup.string()
    .required()
    .label('Contact Number'),
  organisationId: Yup.string()
    .required()
    .label('Organisation'),
  // role: Yup.string()
  //   .default('Organisation')
  //   .required()
  //   .label('Role'),
  // organisationId: Yup.string()
  //   .nullable()
  //   .label('Organisation'),
  // isTechnician: Yup.boolean()
  //   .nullable()
  //   .label('Extra Roles:'),

  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }).nullable().label('Search for address'),
  
});
