import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { useFormData } from '../../hooks'
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { presentError } from '@necta-tech/alert';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { Page, AuthGuard } from '../../components';
import { FormButton } from '../../components/antd';
import { mapArrayToObjects } from '../../helpers';
import { getIsAdmin, getActiveOrganisationId } from '../../selectors';
import { ETHNICITIES, GENDERS, STAFF_DEPARTMENTS } from '../../constants'
import { Form as AntForm } from 'formik-antd';
import { Input, ContactNumber, Select, FormRow } from '../../components/antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import { Collapse as Collapser, Col } from 'antd';
import Default from '../../assets/default_profile.png'
import { message } from 'antd'
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser;

export const staffSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  firstname: Yup.string()
    .required()
    .label('First Name(s)'),
  lastname: Yup.string()
    .required()
    .label('Last Name'),
  contactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  position: Yup.string()
    .nullable()
    .label('Position'),
  gender: Yup.string()
    .nullable()
    .label('Gender'),
  ethnicity: Yup.string()
    .nullable()
    .label('Ethnicity'),
  identificationPassport: Yup.string()
    .nullable()
    .label('Passport'),
  identificationIDNumber: Yup.string()
    .nullable()
    .label('ID Number'),
  mainSkill: Yup.string()
    .nullable()
    .label('Skill'),
  organisationId: Yup.string()
    .required()
    .label('Organisation'),
  departments: Yup.array().label('Departments'),

  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }),
});

const { initialValues, schema, fields } = getFormData(staffSchema);

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ethnicities = mapArrayToObjects(ETHNICITIES);
const genders = mapArrayToObjects(GENDERS);
const departments = mapArrayToObjects(STAFF_DEPARTMENTS);

const ADD_STAFF = gql`
  mutation ADD_STAFF($newStaffData: NewStaffInput!) {
    addStaff(newStaffData: $newStaffData) {
      id
      firstname
      lastname
    }
  }
`;

export const StaffAdd: React.FC<any> = props => {
  const history = useHistory();
  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);

  const [handleAdd] = useMutation(ADD_STAFF);

  const { fields, ...formikCTX } = useFormData(staffSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      return handleAdd({ variables: { newStaffData: { ...values } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Staff member added successfully', duration: 2 });
      history.push(`/staff/edit/${result?.data?.addStaff?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add staff member'), duration: 4 });
    },
    override: 'staff'
  });

  const [defaultValues] = useState({
    ...initialValues,
    organisationId: isAdmin ? null : activeOrgId,
  });

  return (
    <AuthGuard needsActiveOrganisation>

    <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <Page
            card
            title='Add Staff'
          >
            <Form layout='vertical'>

              <Collapse defaultActiveKey='verification' ghost>
                <Panel header='Verification Data' key='verification'>
                  <ProfilePictureUploader
                    disabled={isSubmitting}
                    fieldKey='profilePicUrl'
                    defaultPic={Default}
                  />
                  <FormRow>

                    <Input {...fields.firstname} />
                    <Input {...fields.lastname} />
                    <ContactNumber numberField={fields.contactNumber} countryCodeField={fields.contactNumberCountryCode} />
                    <Input {...fields.position} />
                    <Select options={departments} {...fields.departments} mode={'multiple'} allowClear />
                    <OrganisationSelector {...fields.organisationId} disabled={!isAdmin} />
                  </FormRow>
                </Panel>

                <Panel header='Personal Data' key='personal'>
                  <FormRow>
                    <Select options={genders} {...fields.gender} allowClear />
                    <Select options={ethnicities} {...fields.ethnicity} allowClear />
                    <Input {...fields.identificationPassport} />
                    <Input {...fields.identificationIDNumber} />
                    <Input {...fields.contactEmail} />
                    <Input {...fields.mainSkill} />
                  </FormRow>
                </Panel>

                <Panel header='Location' key='location'>
                  <FormRow>
                    <Input {...fields.address.addressLine1} />
                    <Input {...fields.address.country} />
                    <Input {...fields.address.state} />
                    <Input {...fields.address.postalCode} />
                  </FormRow>
                </Panel>
              </Collapse>

              <Add loading={isSubmitting} type='submit'>
                ADD
              </Add>
            </Form>
          </Page>
        );
      }}
    </Formik>

    </AuthGuard>
  );
};

export default StaffAdd;
