import * as Yup from 'yup';
import { CATEGORIES, CONDITIONS, ETHNICITIES, SERVICE_STATUSES, STATUSES } from '../constants';
// import { generatePassword } from '../helpers';
// import { regExPassword } from '../regex';
const DATE_REGEX = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export const assetSchema = Yup.object().shape({
  name: Yup.string()
    .required('Asset Name is a required field')
    .typeError('Asset Name is a required field')
    .label('Asset Name'),
  manufacturer: Yup.string()
    .nullable()
    .notRequired()
    .label('Manufacturer'),
  modelNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Model Number'),
  registrationNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Registration Number'),
  condition: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CONDITIONS])
    .meta({ type: 'select' })
    .label('Condition'),
  category: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CATEGORIES])
    .meta({ type: 'select' })
    .label('Category'),
  dateLastServiced: Yup.date()
    .nullable()
    .notRequired()
    .meta({ type: 'date' })
    .label('Date of Last Service'),
  datePurchased: Yup.date()
    .nullable()
    .notRequired()
    .meta({ type: 'date' })
    .label('Date of Purchase'),
  valueOriginal: Yup.string()
    .nullable()
    .notRequired()
    .label('Value of Purchase'),
  valueCurrent: Yup.string()
    .nullable()
    .notRequired()
    .label('Current Book Value'),
  status: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...STATUSES])
    .meta({ type: 'select' })
    .label('Status'),
  serviceStatus: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...SERVICE_STATUSES])
    .meta({ type: 'select' })
    .label('Service Status'),
  notes: Yup.string()
    .nullable()
    .notRequired()
    .label('Notes'),
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),
  shortid: Yup.string()
    .nullable()
    .notRequired()
    .label('Shortid'),
  assignToStaff: Yup.string()
    .nullable()
    .notRequired()
    .label('Assigned To Staff Member'),
  assignToSite: Yup.string()
    .nullable()
    .notRequired()
    .label('Assigned To Site'),
});

export const confirmAssetSchema = Yup.object().shape({
  organisationId: Yup.string()
    .required('Please select a organisation')
    .typeError('Please select a organisation')
    .label('Organisation'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});

export type Asset = Yup.InferType<typeof assetSchema>;

export default assetSchema;
