
// USAGE: Revoke a user's access to an org
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const REVOKE_ORG_ACCESS = gql`
    mutation REVOKE_ORG_ACCESS ($userId: String!, $organisationId: String!) {
        revokeOrganisationAccess(userId: $userId, organisationId: $organisationId)
    }
`;

interface RevokeOrgAccessOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useRevokeOrgAccess = ({onCompleted, onError}: RevokeOrgAccessOptions) => {
  const ctx = useMutation(REVOKE_ORG_ACCESS, {
    onCompleted,
    onError
  })
  return ctx
}
