import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { addressFields, staffFieldsFull } from '../fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const STAFF_QUERY = gql`
  query($staffQuery: StaffQuery!) {
    staff(staffQuery: $staffQuery) {
      ${staffFieldsFull}
      address {
        ${addressFields}
      }
    }
  }
`;

export const useFetchStaffList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchStaff, ctx] = useLazyQuery(STAFF_QUERY, {
    variables: { staffQuery: { organisationId: currentOrg?.id } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  return [fetchStaff, ctx] as const;
};
