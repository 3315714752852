import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { NotFound, Page } from '../../components'
import { Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { useCurrentUser } from '@bit/necta.hooks.current-user'
import { ChangeEmail, ChangePassword } from './components';
import { useLoadCurrentUser } from '../../graphql/hooks';

const { TabPane } = Tabs;

interface Props {
  mode: 'view' | 'edit'
}

export const Settings: React.FC<Props> = ({ mode }) => {
  const [user] = useCurrentUser();

  const [refetch, { loading }] = useLoadCurrentUser();

  const history = useHistory();

  const handleBack = useCallback(() => history.push('/'), [history]);

  if (!user || isEmpty(user)) {
    return <NotFound subTitle={'Unable to find account'} handleBack={handleBack} />;
  }

  return (
    <Page title='Settings' onBack={handleBack} card>
      <Tabs defaultActiveKey={'Player Info'} tabPosition={'top'} size={'large'}>
        <TabPane tab={'Change Email'} key={'Change Email'}>
          <ChangeEmail user={user} />
        </TabPane>
        <TabPane tab={'Change Password'} key={'Change Password'}>
          <ChangePassword user={user} />
        </TabPane>
      </Tabs>
    </Page>
  )
}
