import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, FormLoading, AuthGuard } from '../../components';
import { EditOutlined as Edit } from '@ant-design/icons';
import { useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import {
  ProfilePictureUploader,
  ASSIGN_STAFF_TO_SITE,
  ASSIGN_ASSET_TO_SITE,
} from '../../components';
import { FileUploader } from '../../components/file-uploader';
import { cloneDeep } from '@apollo/client/utilities';
import { useFormData } from '../../hooks';

import { Form as AntForm } from 'formik-antd';
import { Tags, Input, ContactNumber, Select, FormRow, DatePicker, TextArea } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs } from 'antd';
import Default from '../../assets/asset_picture.png';
import { allSiteFields, documentFields } from '../../graphql/fragments';
import { message } from 'antd';
import { LinkedAssets, LinkedStaff } from '../../components/linked-items';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd';
import { cleanError } from '../../helpers/error-helper';
import { CustomerSelector } from '../../components/customer-selector';

const { Panel } = Collapser;
const { TabPane } = AntTabs;

const Collapse = styled(Collapser)`
  width: 100%;
`;

const Tabs = styled(AntTabs)`
  width: 100%;
`;

const siteSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Site Name'),
  description: Yup.string()
    .nullable()
    .label('Description'),
  customerId: Yup.string()
    .required()
    .label('Customer'),
  customerName: Yup.string()
    .nullable()
    .label('Customer Name'),
  primaryContactName: Yup.string()
    .nullable()
    .label('Contact Name'),
  primaryContactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactNumberCountryCode: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  primaryImage: Yup.string()
    .nullable()
    .label('Primary Image'),
  tags: Yup.array()
    .nullable()
    .label('Tags'),
  zones: Yup.array()
    .nullable()
    .label("Zones"),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }),
});

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateSite: UpdateSiteInput!) {
    updateSite(id: $id, updateSite: $updateSite) {
      ${allSiteFields}
    }
  }
`;

const QUERY = gql`
  query($id: String!) {
    site(queryOptions: {}, id: $id) {
      ${allSiteFields}
    }
  }
`;

interface Props {
  mode: 'view' | 'edit';
}

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const Site: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const { fields, initialValues, ...formikCTX } = useFormData(siteSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { id, dateLastServiced, datePurchased, documents, assignedAssets, assignedStaff, organisationId, primaryContactNumberFull, ...restValues } = values;
      return handleUpload({
        variables: {
          id,
          updateSite: { ...restValues },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Site updated successfully', duration: 2 })
      setSite({ id, ...result?.data?.updateSite })
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update site'), duration: 4 });
    },
    override: 'site',
  });

  const [site, setSite] = useState({ ...initialValues });
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchSite, { loading }] = useLazyQuery(QUERY, {
    variables: { id },
    onCompleted: (result: any) => {
      setSite(result.site);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchSite();
  }, []);

  const [handleUpload] = useMutation(UPLOAD);

  const assignedStaff = useMemo(() => (site && site.assignedStaff ? cloneDeep(site.assignedStaff) : null), [site]);
  const assignedAssets = useMemo(() => (site && site.assignedAssets ? cloneDeep(site.assignedAssets) : null), [site]);

  const customerSelect = function(fileds: any,data: any){
    if(data){
      fileds('customerName', data.contactName);
      fileds('primaryContactName', data.contactName);
      fileds('primaryContactEmail', data.email);
      fileds('primaryContactNumber', data.contactNumber);
      fileds('primaryContactNumberCountryCode', data.contactNumberCountryCode);
      fileds('organisationId', data.organisationId);
    }
  }

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={site} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, setFieldValue, dirty }) => (
          <Page
            card
            title={`${edit ? 'Edit' : 'View'} Site`}
            onBack={() => history.goBack()}
            extra={[
              <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
              <RefreshAction onClick={() => fetchSite()} />,
              <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
            ]}
          >
            <Form layout='vertical'>
              <Tabs defaultActiveKey='info' tabPosition='top' size='large'>
                <TabPane tab='Info' key='info'>
                  <Collapse defaultActiveKey='information' ghost>
                    <Panel header='Contact Information' key='information'>
                      <ProfilePictureUploader disabled={isSubmitting} fieldKey='primaryImage' defaultPic={Default} />
                      <FormRow disabled={!edit}>
                        <Input {...fields.name} />
                        <CustomerSelector {...fields.customerId} onChange={customerSelect.bind(null,setFieldValue)}/>
                        <Input {...fields.customerName} hidden={true}/>
                        <Input {...fields.primaryContactName} />
                        <ContactNumber
                          numberField={fields.primaryContactNumber}
                          countryCodeField={fields.primaryContactNumberCountryCode}
                        />
                        <Input {...fields.primaryContactEmail} />
                        <TextArea gridProps={GRID_PROPS} {...fields.description} rows={7} />
                      </FormRow>
                    </Panel>

                    <Panel header='Location' key='location'>
                      <FormRow disabled={!edit}>
                        <Input {...fields.address.addressLine1} />
                        <Input {...fields.address.country} />
                        <Input {...fields.address.state} />
                        <Input {...fields.address.postalCode} />
                      </FormRow>
                    </Panel>

                    <Panel header="Zone" key='zone'>
                      <FormRow disabled={!edit}>
                        <Tags 
                          gridProps={GRID_PROPS} 
                          isEditable={false} 
                          {...fields.zones} 
                          placeholder="Type Text and press Enter"
                          />
                      </FormRow>
                    </Panel>

                    <Panel header='Site Categories' key='categories'>
                      <FormRow disabled={!edit}>
                        <Tags
                          isEditable={false}
                          gridProps={GRID_PROPS}
                          {...fields.tags}
                          placeholder='Type Text and press Enter'
                        />
                      </FormRow>
                    </Panel>
                  </Collapse>
                </TabPane>

                <TabPane tab='Linked Staff' key='stafflinks'>
                  <FormRow disabled={!edit}>
                    <LinkedStaff item={site} linkedItem={null} data={assignedStaff} protocol={ASSIGN_STAFF_TO_SITE} />
                  </FormRow>
                </TabPane>

                <TabPane tab='Linked Assets' key='sitelinks'>
                  <FormRow disabled={!edit}>
                    <LinkedAssets item={site} linkedItem={null} data={assignedAssets} protocol={ASSIGN_ASSET_TO_SITE} />
                  </FormRow>
                </TabPane>

                <TabPane tab='Documents' key='documents'>
                  <FormRow disabled={!edit}>
                    <FileUploader id={site.id} parentType='site' fileType='DOCUMENT' initialFiles={site.documents} />
                  </FormRow>
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

export default Site;
