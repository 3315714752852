import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Row, Col, Collapse, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Footer from '../components/footer/index';
import { Container, PLayout, Heading, Width } from './WelcomeLanding';

//BACKGROUNDS
import FAQBG from '../assets/illustrations/faq_bg_lg.png';
import FAQMD from '../assets/illustrations/faq_bg_md.png';
import Bar1 from '../assets/illustrations/clipbg_1.png';
import Bar1MD from '../assets/illustrations/clipbg_1_md.png';

const { Header, Content } = Layout;
const { Panel } = Collapse;

const text1 = (
  <p style={{ paddingLeft: 24 }}>
    Contact us!
    <br />
    <br />
    OverSite is not a "one-size-fits-all" platform. We pride ourselves on being able to provide a versatile fit for
    businesses.
    <br />
    <br />
    We know that the requirements for businesses are not standard accross the board, so we like to find the best fit for
    you.
    <br />
    <br />
    As such, OverSite is also a growing platform, with more helpful features on the way!
  </p>
);

const text2 = (
  <p style={{ paddingLeft: 24 }}>
    Yes! OverSite supports Excel imports so that you can easily load in your existing staff and assets.
    <br />
    <br />
    We also provide a template by which you can preemptively link assets to staff who are already in possession of them,
    preserving your line of sight whilst adopting OverSite.
  </p>
);

const text3 = (
  <p style={{ paddingLeft: 24 }}>
    Not only can you assign assets to staff and keep track of who is responsible, OverSite provides much more in the
    way of asset and staff oversight.
    <br />
    <br />
    OverSite is able to customise data fields to your needs; ranging from an asset's type, serial number, date of
    purchase and more, through to a staff member's various verification documents.
  </p>
);

const text4 = (
  <p style={{ paddingLeft: 24 }}>
    Do you know when a vehicle is up for service? What was done in the last service? How much did it cost? Who was
    responsible?
    <br />
    <br />
    We can help you with that! OverSite boasts an easy-to-use flagging system that you control.
    <br />
    <br />
    You set an "expiry date" for any uploaded document, like a software license, and OverSite will send you
    notifications for items about to expire, or have expired and need your attention.
  </p>
);

const text5 = (
  <p style={{ paddingLeft: 24 }}>
    OverSite is a versatile platform that will allow any business of any size to maintain line of sight on their
    assets and staff.
    <br />
    <br />
    We are also a growing platform that takes our clients' feedback into account when planning and forming future
    features!
  </p>
);

const text6 = (
  <p style={{ paddingLeft: 24 }}>
    OverSite knows that no two businesses are the same.
    <br />
    <br />
    Because of this, we have adopted a dynamic and adaptive pricing model that looks at your management requirements and
    charges accordingly.
    <br />
    <br />
    Contact us to find out more!
  </p>
);

const ClipCard = styled.div`
  width: 100%;
  background-image: url(${FAQBG});
  background-size: cover;
  height: 450px;
  display: grid;
  padding-top: 20px;
  background-position-x: center;

  @media only screen and (max-width: 992px) {
    background-image: url(${FAQMD});
    background-size: cover;
    padding-top: 100px;
    height: 600px;
    display: grid;
    background-position-x: center;
  }

  @media only screen and (min-width: 1920px) {
    background-image: url(${FAQBG});
    background-size: cover;
    background-position-x: center;
    height: 600px;
    display: grid;

    padding-top: 100px;
  }
`;

const ClipBar2 = styled.div`
  height: 300px;
  width: 100%;
  align-items: center;
  display: flex;

  background-image: url(${Bar1});
  background-size: cover;
  height: 500px;
  display: grid;

  @media only screen and (max-width: 992px) {
    background-image: url(${Bar1MD});
    background-size: cover;
    display: grid;
  }
  @media only screen and (min-width: 1920px) {
    background-image: url(${Bar1});
    background-size: cover;
    height: 650px;
    display: grid;
  }
`;

export const FAQ: React.FC<any> = props => {
  const history = useHistory();

  return (
    <PLayout className='layout'>

        <Content>

          <ClipCard>
            <Width>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                  <Heading style={{ color: '#FFFFFF' }}>
                    Frequently Asked <b>Questions</b>
                  </Heading>
                  <h3 style={{ color: '#FFFFFF' }}>
                    We’ve put together some commonly asked questions to give you more information about OverSite{' '}
                    <br /> and what we offer.
                  </h3>
                </Col>
              </Row>
            </Width>
          </ClipCard>

          <Container>
            <Width>
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                // style={{backgroundColor: '#FFF'}}>
              >
                <Panel header='How do I get a OverSite Account?' key='1'>
                  {text1}
                </Panel>
                <Panel header='Can I import an existing staff directories and asset lists?' key='2'>
                  {text2}
                </Panel>
                <Panel header="Can I assign specific assets to staff, and keep track of the asset's history?" key='3'>
                  {text3}
                </Panel>
                <Panel header='Can I set a reminder for recurring services or expiring licenses?' key='4'>
                  {text4}
                </Panel>
                <Panel header='How does all of this help my business?' key='5'>
                  {text5}
                </Panel>
                <Panel header='What are the costs involved?' key='6'>
                  {text6}
                </Panel>
              </Collapse>
            </Width>
          </Container>

          <Container>
            <ClipBar2 style={{ textAlign: 'center' }}>
              <Width>
                <h2>
                  Have some more specific questions? If you have an account with us, then you have access to our
                  <Button
                    style={{
                      fontSize: '21px',
                      fontWeight: 'bold',
                      color: '#0F4C81',
                      marginLeft: '-8px',
                      marginRight: '-8px',
                    }}
                    type='link'
                    onClick={() => window.open('https://necta.atlassian.net/servicedesk/customer/portal/2')}
                  >
                    Service Desk!
                  </Button>
                  <br />
                  Otherwise, please{' '}
                  <Button
                    style={{
                      fontSize: '21px',
                      fontWeight: 'bold',
                      color: '#0F4C81',
                      marginLeft: '-10px',
                      marginRight: '-10px',
                    }}
                    type='link'
                    onClick={() => history.push('/contact')}
                  >
                    contact
                  </Button>{' '}
                  us with your question.
                </h2>
              </Width>
            </ClipBar2>
          </Container>
        </Content>
        <Footer />
    </PLayout>
  );
};

export default FAQ;
