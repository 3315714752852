import React, { useMemo } from 'react'
import { SaveOutlined as Save } from '@ant-design/icons'
import { PageAction } from '../Page'

interface SaveProps {
    onClick: (e?: Event) => void,
    hidden?: boolean,
    disabled?: boolean,
    tooltip?: string
}

export const SaveAction: React.FC<SaveProps> = ({ tooltip, disabled, hidden, onClick, ...props }) => {

    return (
        <PageAction 
            hidden={hidden} 
            disabled={disabled} 
            tooltip={ tooltip ?? 'Save'}
            onClick={onClick}
        >
            {  <Save /> }
        </PageAction>
    )
}