import gql from "graphql-tag";
import { allTicketFields, stepFields, userFields } from '../../graphql/fragments';

export const ADD_TICKET = gql`
  mutation ADD_TICKET($newTicketData: NewTicketInput!) {
    addTicket(newTicketData: $newTicketData) {
      id
      name
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation($id: String!, $updateTicket: UpdateTicketInput!) {
    updateTicket(id: $id, updateTicket: $updateTicket) {
      ${allTicketFields}
      steps {
        ${stepFields}
      }
    }
  }
`;

export const FETCH_TICKET = gql`
  query($id: String!) {
    ticket(queryOptions: {}, id: $id) {
      ${allTicketFields}
      steps {
        ${stepFields}
      }
    }
  }
`;

export const FETCH_TICKET_SAFE = gql`
  query($id: String!) {
    ticketByShortid(id: $id) {
      ${allTicketFields}
      steps {
        ${stepFields}
      }
    }
  }
`;

export const FETCH_REPEAT_TICKET = gql`
  query($id: String!) {
    repeatTicketById(id: $id){
      ${allTicketFields}
    }
  }
`;

export const ORDER_STEPS = gql`
  mutation($id: String!, $order: [String!]!) {
    orderSteps(id: $id, order: $order)
  }
`

export const ADD_STEP = gql`
  mutation ADD_STEP($newStep: NewTicketStepInput!) {
    addTicketStep(newTicketStepData: $newStep) {
      ${stepFields}
    }
  }
`;

export const DELETE_STEP = gql`
  mutation($id: String!) {
    archiveTicketStep(id: $id)
  }
`;

export const EDIT_STEP = gql`
  mutation($id: String!, $step: UpdateTicketStepInput!) {
    updateTicketStep(id: $id, updateTicketStep: $step) {
      id
    }
  }
`;

export const ADD_TECHNICIAN = gql`
    mutation($id: String!, $userId: String!) {
        addTechnicianToTicket(id: $id, userId: $userId) {
            ${userFields}
        }
    }
`;

export const REMOVE_TECHNICIAN = gql`
    mutation($id: String!, $userId: String!) {
        removeTechnicianFromTicket(id: $id, userId: $userId) {
            id
        }
    }
`;
