import React, { useMemo } from 'react';
import styled from 'styled-components'
import { Checkbox, CheckboxGroupProps } from 'formik-antd'
import { InputFieldProps, FieldProps } from './types'
import { FieldContainer } from './FieldContainer'
import { useFormikContext } from 'formik'

const { Group } = Checkbox;

const CheckGroup = styled(Group)`
  .ant-checkbox-wrapper {
    width: 100%;
  }
`;

const Component: React.FC<InputFieldProps<CheckboxGroupProps, FieldProps>> = ({ hidden, label, required, className, options, gridProps, ...props }) => {

  const { isSubmitting } = useFormikContext() || {}

  return (
    <FieldContainer
      {...gridProps}
      name={props.name}
      label={label}
      required={required}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <CheckGroup
        disabled={isSubmitting}
        options={options}
        {...props}
      />
    </FieldContainer>

  )
}

export const CheckBoxGroup = styled(Component)`
`
