import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { Page, Action as PageAction, FormLoading, AuthGuard } from '../../components';
import { useFetchContact } from '../../graphql/hooks';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ETHNICITIES, GENDERS, STAFF_DEPARTMENTS } from '../../constants';
import { mapArrayToObjects } from '../../helpers';
import * as Yup from 'yup';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Input, ContactNumber, Select, FormRow, FormButton } from '../../components/antd';
import { Collapse as Collapser, Col, Tabs as AntTabs } from 'antd';
import { message } from 'antd';
import { EditCloseAction, RefreshAction, SaveAction } from '../../components/antd';
import { allContactFields } from '../../graphql/fragments';
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser;

const Collapse = styled(Collapser)`
  width: 100%;
`;

const stafSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .label('Billing Contact Email'),
  siteBillingEmail: Yup.string()
    .trim()
    .nullable()
    .label('Site Billing Contact Email'),
  billingName: Yup.string()
    .required()
    .label('Billing Contact Name'),
  siteBillingName: Yup.string()
    .required()
    .label('Site Billing Contact Name'),
  securityName: Yup.string()
    .required()
    .label('Security Contact Name'),
  siteSecurityName: Yup.string()
    .required()
    .label('Site Security Contact Name'),
  techEmail: Yup.string()
    .required()
    .label('Tech Contact Email '),
  siteTechEmail: Yup.string()
    .required()
    .label('Site Tech Contact Email '),
  managementName: Yup.string()
    .required()
    .label('Management Contact Name '),
  siteManagementName: Yup.string()
    .required()
    .label('Site Management Contact Name '),
  managementEmail: Yup.string()
    .required()
    .label('Management Contact Email '),
  siteManagementEmail: Yup.string()
    .required()
    .label('Site Management Contact Email '),
  techName: Yup.string()
    .required()
    .label('Tech Contact Name'),
  siteTechName: Yup.string()
    .required()
    .label('Site Tech Contact Name'),
  securityEmail: Yup.string()
    .required()
    .label('Security Contact Email'),
  siteSecurityEmail: Yup.string()
    .required()
    .label('Site Security Contact Email'),
  contactNumber: Yup.string()
    .nullable()
    .label('Billing Contact Number'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),

  contactNumberTech: Yup.string()
    .nullable()
    .label('Tech Contact Number'),
  contactNumberCountryCodeTech: Yup.string()
    .default('+27')
    .label('Tech Country Code'),
  contactNumberTechSite: Yup.string()
    .nullable()
    .label('Tech Contact Number'),
  contactNumberCountryCodeTechSite: Yup.string()
    .default('+27')
    .label('Tech Country Code'),
  contactNumberSecurity: Yup.string()
    .nullable()
    .label('Billing Contact Number'),
  contactNumberCountryCodeSecurity: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumberSecuritySite: Yup.string()
    .nullable()
    .label('Billing Contact Number'),
  contactNumberCountryCodeSecuritySite: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumberManagement: Yup.string()
    .nullable()
    .label('Tech Contact Number'),
  contactNumberCountryCodeManagement: Yup.string()
    .default('+27')
    .label('Tech Country Code'),
  contactNumberManagementSite: Yup.string()
    .nullable()
    .label('Site Management Contact Number'),
  contactNumberCountryCodeManagementSite: Yup.string()
    .default('+27')
    .label('Site Mnagement Country Code'),
  contactNumberSite: Yup.string()
    .nullable()
    .label('Billing Contact Number'),
  contactNumberCountryCodeSite: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string()
        .nullable()
        .label('Address Line 1'),
      state: Yup.string()
        .nullable()
        .label('Province'),
      country: Yup.string()
        .nullable()
        .label('Country'),
      postalCode: Yup.string()
        .nullable()
        .label('Postal Code'),
    })
    .nullable(),
});

const { initialValues, schema, fields } = getFormData(stafSchema);

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UPLOAD = gql`
  mutation($id: String!, $updateContact: UpdateContactInput!) {
    updateContact(id: $id, updateContact: $updateContact) {
      ${allContactFields}
    }
  }
`;

const Submit = styled(FormButton)`
  margin-top: 30px;
`;

interface Props {
  mode: 'view' | 'edit';
}

export const Contact: React.FC<Props> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();

  const [contact, setContact] = useState(initialValues);
  const history = useHistory();
  const [edit, setEdit] = useState(mode === 'edit');

  const [fetchContact, { loading }] = useFetchContact({
    id,
    onCompleted: (result: any) => {
      setContact(result.contactSingle);
    },
  });

  const [handleUpload] = useMutation(UPLOAD);

  const { fields, ...formikCtx } = useFormData(schema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      console.log(values);
      const { id, contactNumberFull, ...rest } = values;
      return handleUpload({
        variables: {
          id,
          updateContact: {
            ...rest,
          },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Contact updated successfully', duration: 2 });
      setContact({ id, ...result?.data?.updateContact });
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to update Contact'), duration: 4 });
    },
    override: 'contact',
  });

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCtx} initialValues={contact} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, dirty }) => (
          <Page
            card
            title={`${edit ? 'Edit' : 'View'} Contact`}
            onBack={() => history.goBack()}
            extra={[
              <EditCloseAction edit={edit} onClick={() => setEdit(!edit)} />,
              <RefreshAction onClick={() => fetchContact()} />,
              <SaveAction onClick={() => handleSubmit()} hidden={!edit || !dirty} />,
            ]}
          >
            <Form layout='vertical'>
              <Collapse defaultActiveKey='verification' ghost>
                <Panel header='Customer contact' key='verification'>
                  <FormRow disabled={!edit}>
                    <Input {...fields.billingName} />
                    <Input {...fields.email} />
                    <ContactNumber
                      numberField={fields.contactNumber}
                      countryCodeField={fields.contactNumberCountryCode}
                    />
                    <Input {...fields.techName} />
                    <Input {...fields.techEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberTech}
                      countryCodeField={fields.contactNumberCountryCodeTech}
                    />
                    <Input {...fields.managementName} />
                    <Input {...fields.managementEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberManagement}
                      countryCodeField={fields.contactNumberCountryCodeManagement}
                    /> 
                    <Input {...fields.securityName} />
                    <Input {...fields.securityEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberSecurity}
                      countryCodeField={fields.contactNumberCountryCodeSecurity}
                    />
                   </FormRow>
                  </Panel>
                  <Panel header='Site contact' key='sitecontact'>
                   <FormRow disabled={!edit}>
                    
                    <Input {...fields.siteBillingName} />
                    <Input {...fields.siteBillingEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberSite}
                      countryCodeField={fields.contactNumberCountryCodeSite}
                    />
                   
                    <Input {...fields.siteTechName} />
                    <Input {...fields.siteTechEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberTechSite}
                      countryCodeField={fields.contactNumberCountryCodeTechSite}
                    />
                    <Input {...fields.siteManagementName} />
                    <Input {...fields.siteManagementEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberManagementSite}
                      countryCodeField={fields.contactNumberCountryCodeManagementSite}
                    /> 
                    
                    <Input {...fields.siteSecurityName} />
                    <Input {...fields.siteSecurityEmail} />
                    <ContactNumber
                      numberField={fields.contactNumberSecuritySite}
                      countryCodeField={fields.contactNumberCountryCodeSecuritySite}
                    />
                    
                  </FormRow>
                </Panel>
              </Collapse>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

export default Contact;
