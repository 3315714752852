import styled from 'styled-components';
import { Avatar as AntAvatar } from 'antd';

export const Avatar = styled(AntAvatar)<{ inverse?: boolean }>`
  align-self: center;
  background: ${p => p.inverse ? 'white' : p.theme.primary} !important;
  svg {
    color: ${p => p.inverse ? p.theme.primary : 'white' } !important;
  }
`;

