import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar } from '../../../components';
import { Button, Dropdown, Menu, Modal, message, Tooltip } from 'antd';
import { getThumbnail } from '@bit/necta.hooks.s3';
import { PictureOutlined, ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useCurrentUser } from '@bit/necta.hooks.current-user'
import styled from 'styled-components';
import { cleanError } from '../../../helpers/error-helper';
import { Table } from '../../../components/antd/table';
import { useAdminUpdateUser, useRevokeOrgAccess } from '../../../graphql/hooks';

const { confirm } = Modal;

const Primary = styled.span`
  color: #3ECC80 !important;
  svg {
    margin-right: 6px;
  }
`;

const SELF_REVOKE_TEXT = 'Leave';
const OTHER_REVOKE_TEXT = 'Remove';
const SELF_REVOKE_TITLE = 'Leave organisation';
const OTHER_REVOKE_TITLE = 'Revoke access';
const SELF_REVOKE_DESC = 'Are you sure you want to leave this organisation? Warning: leaving an organisation without activating a new one first may result in losing access to the system.';
const OTHER_REVOKE_DESC = 'Are you sure you want to revoke access to this organisation? Warning: revoking access to an organisation without activating a new one first may result in the user losing access to the system.';

interface LinkedOrganisationsProps {
  user: any;
  loading?: boolean;
  refresh?: () => void;
}

export const LinkedOrganisations: React.FC<any> = ({ user, loading, refresh }: LinkedOrganisationsProps) => {

  const [myUser] = useCurrentUser();

  const editingSelf = useMemo(() => user.id === myUser.id, [user, myUser]);

  const [handleRevoke] = useRevokeOrgAccess({
    onCompleted: (err) => {
      message.success({ content: 'Organisation access has been revoked', duration: 4 });
      if (refresh) refresh();
    },
    onError: (err) => {
      message.error({ content: cleanError(err, 'Unable to revoke organisation access'), duration: 4 })
    }
  });

  const [handleUpdate] = useAdminUpdateUser({
    onCompleted: (err) => {
      message.success({ content: 'Organisation has been activated', duration: 4 });
      if (refresh) refresh();
    },
    onError: (err) => {
      message.error({ content: cleanError(err, 'Unable to update active organisation'), duration: 4 })
    }
  });

  const history = useHistory();

  const orgs = useMemo(() => user.linkedOrganisations ? cloneDeep(user.linkedOrganisations) : [], [user])

  const handleActivate = useCallback((id: string) => async (e?: any) => {
    e.stopPropagation();
    confirm({
      title: 'Activate organisation',
      icon: <ExclamationCircleOutlined />,
      content: 'This organisation will be set as active.',
      okText: 'Activate',
      onOk() {
        return handleUpdate({
          variables: {
            id: user.id,
            user: {
              activeOrgId: id,
            },
          },
        });
      },
      onCancel() {},
    });
  }, [user]);

  const handleRemove = useCallback((id: any) => async (e?: any) => {
    e?.domEvent?.stopPropagation();
    confirm({
      title: editingSelf ? SELF_REVOKE_TITLE : OTHER_REVOKE_TITLE,
      icon: <ExclamationCircleOutlined />,
      content: editingSelf ? SELF_REVOKE_DESC : OTHER_REVOKE_DESC,
      okType: 'danger',
      okText: editingSelf ? SELF_REVOKE_TEXT : OTHER_REVOKE_TEXT,
      onOk() {
        return handleRevoke({
          variables: {
            userId: user.id,
            organisationId: id
          }
        })
      },
      onCancel() {},
    });
  }, []);

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, [])

  const menu = (id: string) => (
    <Menu>
      <Menu.Item key='leave' onClick={handleRemove(id)}>
        {editingSelf ? SELF_REVOKE_TEXT : OTHER_REVOKE_TEXT}
      </Menu.Item>
    </Menu>
  );

  const ActivateOrg = ({ id }: any) => {
    return (
      <div onClick={handleCancel}>
        <Dropdown.Button onClick={handleActivate(id)} overlay={menu(id)}>
          Set Active
        </Dropdown.Button>
      </div>
    )
  }

  const CurrentOrg = ({ id }: any) => {
    return (
      // <Tooltip title={'Activate another org if you want to leave'}>
      //   <Button type='primary' style={{ width: '125px' }} onClick={handleCancel}>Active</Button>
      // </Tooltip>
      <div onClick={handleCancel}>
        <Dropdown.Button  onClick={handleCancel} overlay={menu(id)}>
          <Primary><CheckOutlined /> Active</Primary>
        </Dropdown.Button>
      </div>
    )
  }

  const columns = useMemo(() => [
    {
      title: '',
      key: 'primaryImage',
      render: (r: any) => <Avatar src={getThumbnail(r)} icon={<PictureOutlined/>} inverse />
    },
    {
      title: 'Organisation',
      filtering: true,
      sorting: true,
      key: 'name',
    },
    {
      title: 'Primary Contact',
      key: 'primaryContactName',
    },
    {
      title: 'Contact Number',
      key: 'primaryContactNumberFull',
    },
    {
      title: 'Email',
      key: 'primaryContactEmail',
    },
    {
      title: '',
      key: 'id',
      render: (r: string) => user?.activeOrg?.id === r ? <CurrentOrg id={r} /> : <ActivateOrg id={r} />
    }
  ], [user]);

  return (
    <Table
      data={orgs}
      columns={columns}
      loading={loading}
      searchable={false}
      exportable={false}
      columnSelection={false}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/organisation/edit/${record.id}`)
      })}
      rowClassName={'clickable'}
    />
  );
};

export default LinkedOrganisations;
