import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

// USAGE: updating any user (permission check applies)
// This can be used to update passwords and emails too
export const UPDATE_USER = gql`
    mutation UPDATE_USER ($id: String!, $user: UpdateUserInput!) {
        adminUpdateUser(id: $id, user: $user) {
            id
        }
    }
`

interface AdminUpdateOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useAdminUpdateUser = ({onCompleted, onError}: AdminUpdateOptions) => {
  const ctx = useMutation(UPDATE_USER, {
    onCompleted,
    onError
  })
  return ctx
}
