import React from 'react';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { ObjectSchema } from 'yup';
import { Field } from '../../../services';
import { Form } from 'formik-antd';
import { FormRow, FormButton } from '../../antd';

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

const SubForm: React.FC<any> = ({ fields }: any) => {
  return (
    <>
      {fields.map((f: any) => {
        const { component: Component, typeInfo, ...field } = f;
        if (field.fields) return <SubForm fields={field.fields} />;
        return (
          <Component key={field.name} {...field} gridProps={GRID_PROPS} />
        );
      })}
    </>
  );
};

interface AutoFormProps {
  schema: ObjectSchema<any>;
  fields: Field[];
  values: any;
  onComplete: (values: FormikValues, actions: FormikHelpers<any>) => void;
  text?: string;
}

export const AutoForm: React.FC<any> = ({ schema, fields, values, onComplete, text }: AutoFormProps) => {
  return (
    <Formik initialValues={values} enableReinitialize validateOnBlur validationSchema={schema} onSubmit={onComplete}>
      {({ }) => (
        <Form layout={'vertical'}>
          <FormRow>
            <SubForm fields={fields} />
          </FormRow>
          <FormButton>Save</FormButton>
        </Form>
      )}
    </Formik>
  );
};

export default AutoForm;
