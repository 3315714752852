import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TagProps } from 'antd/lib/tag';
import { ColProps } from 'antd/lib/grid';
import { useField } from 'formik';
import { FieldContainer } from './FieldContainer';
import { useAntRef } from '@bit/necta.hooks.use-ant-ref';

export interface AddedTagProps {
  isEditable?: boolean;
  label?: string;
  required?: boolean;
  gridProps?: ColProps;
  name: string;
  closable?: boolean;
}

export type handlerType = (() => void) | ((e: any) => void);

export interface AdornmentProps {
  edit?: boolean;
  hidden?: boolean;
  value: string | undefined;
  onChange: handlerType;
  onTagAdd: handlerType;
  onClickOrBlur: handlerType;
  inputRef?: any;
}

export const Adornment: React.FC<AdornmentProps> = ({
  hidden,
  edit,
  onClickOrBlur,
  value,
  onChange,
  onTagAdd,
  ...props
}) => {
  const [ref, initializeRef] = useAntRef(
    () => {
      if (ref.current && edit) ref.current.focus();
    },
    (ref: any) => [ref.current, edit],
  );

  const { current } = ref;

  useEffect(() => {
    if (edit && current) current.focus();
  }, [edit, current]);

  if (hidden) return null;

  if (edit)
    return (
      <Input
        name='tag-input'
        value={value || ''}
        onChange={onChange}
        onPressEnter={onTagAdd}
        ref={initializeRef}
        onBlur={onClickOrBlur}
      />
    );

  return (
    <DashedTag onClick={onClickOrBlur}>
      <PlusOutlined /> Add
    </DashedTag>
  );
};

export type TagsComponentProps = TagProps & AddedTagProps;

export const TagsComponent: React.FC<TagsComponentProps> = ({
  isEditable,
  hidden,
  name,
  label,
  required,
  className,
  gridProps,
  closable = true,
  ...props
}) => {
  const [_, { value }, helpers] = useField({ name, ...props });
  const [{ edit, inputValue }, setState] = useState<{ edit: boolean; inputValue: string | undefined }>({
    edit: false,
    inputValue: undefined,
  });

  const handleOnChange = useCallback(
    (e: any) => {
      setState({ edit, inputValue: e.target.value });
    },
    [setState, edit, helpers],
  );

  const handleOnTagAdd = useCallback(() => {
    helpers.setValue([...value, inputValue]);
    setState({ inputValue: undefined, edit: false });
  }, [setState, value, inputValue, helpers]);

  const handleClose = useCallback(
    (removedTag: string) => (e: any) => {
      helpers.setValue([...value.filter((tag: string) => tag !== removedTag)]);
    },
    [value, helpers],
  );

  return (
    <FieldContainer
      {...gridProps}
      name={name}
      label={label}
      required={required}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      {value && value.length > 0
        ? value.map((tag: any) => (
            <Tag key={tag} onClose={handleClose(tag)} closable={closable}>
              {tag}
            </Tag>
          ))
        : null}

      <Adornment
        value={inputValue}
        edit={edit}
        onChange={handleOnChange}
        onTagAdd={handleOnTagAdd}
        onClickOrBlur={e => setState({ edit: !edit, inputValue })}
      />
    </FieldContainer>
  );
};

export const Tags = styled(TagsComponent)`
  width: 100%;
  .ant-input {
    border: 1px solid lightgray;
    height: 20px;
    width: 65px;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .ant-tag svg {
    margin-right: 5px;
  }
  .ant-tag {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
`;

export const DashedTag = styled(Tag)`
  background: #fff;
  border-style: dashed;
  padding-left: 12px !important;
  padding-right: 12px !important;
  cursor: pointer;
`;
