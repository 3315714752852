import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useNewLinker } from '../linking/linker/hooks/use-new-linker';
import { Avatar } from '../Avatar';
import { getThumbnail } from '@necta-tech/s3';
import { AuditOutlined, LinkOutlined } from '@ant-design/icons';
import Table from '../antd/table';
import { Button } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';

export const ORGANISATION_COLUMNS: any[] = [
  {
    title: 'Picture',
    key: 'primaryImage',
    render: (r: any) => <Avatar src={getThumbnail(r)} icon={<AuditOutlined />} />,
  },
  {
    title: 'Name',
    key: 'name',
    filtering: true,
    sorting: true,
  },
  {
    title: 'Primary Contact Name',
    key: 'contactName',
    filtering: true,
    sorting: true,
  },
  {
    title: 'Contact Number',
    key: 'contactNumberFull',
    filtering: true,
  },
  {
    title: 'Email Address',
    key: 'contactEmail',
    filtering: true,
  },
];

interface IProps {
  protocol: any;
  item: any | null;
  linkedItem: any | null;
  data?: any[] | null;
  hidden?: boolean;
  showAssign?: boolean;
  title?: string;
}

export const LinkedOrganisations: FC<IProps> = (props) => {
  const { protocol, item, linkedItem, hidden, data, showAssign, title } = props;

  const history = useHistory();

  const setLinker = useNewLinker();

  const handleOrganisationClick = useCallback((organisation: any) => (e?: any) => history.push(`/organisation/edit/${organisation.id}`), [history]);

  const handleAssign = useCallback(() => {
    setLinker({ ...protocol, item, linkedItem });
    history.push(`/linking`);
  }, [history, setLinker, item, linkedItem]);

  const tableData = useMemo(() => cloneDeep(data) || [], [data]);

  if (hidden) return null;

  return (
    <div style={{ width: '96%', margin: '0 auto' }}>
      <Table
        title={title}
        data={tableData}
        columns={ORGANISATION_COLUMNS}
        onRow={(record: any, rowIndex: any) => ({
          onClick: handleOrganisationClick(record)
        })}
        rowClassName={'clickable'}
        searchable={false}
        exportable={false}
        columnSelection={false}
        size={'middle'}
        hidden={tableData.length <= 0}
      />
      {showAssign && <Button onClick={handleAssign}>Assign Organisations <LinkOutlined /></Button>}
    </div>
  );
};

LinkedOrganisations.defaultProps = {
  showAssign: true,
  title: 'Linked Organisations:'
};

export default LinkedOrganisations;
