export const documentFields = `
  id
  key
  name
  createdAt
  sendExpiryNotification
  expires
  signedUrl
  secure
  isPublic
`

export const siteFields = `
  id
  name
  description
  customerName
  customerId
  primaryContactName
  primaryContactNumber
  primaryContactNumberCountryCode
  primaryContactNumberFull
  primaryContactEmail
  primaryImage
  tags
  zones
  shortid
  organisationId
`;

export const ticketFields = `
  id
  name
  type
  description
  customerName
  primaryContactName
  primaryContactNumber
  primaryContactNumberCountryCode
  primaryContactNumberFull
  primaryContactEmail
  dueDate
  tags
  shortid
  organisationId
  status
  isRepeats
  repeatInterval
  repeatId
  completionComment
  completionDate
  comments
  startDate
  archived
`;

export const assetFields = `
  id
  name
  primaryImage
  manufacturer
  modelNumber
  registrationNumber
  condition
  valueOriginal
  valueCurrent
  status
  dateLastServiced
  datePurchased
  tags
  notes
  category
  shortid
  serviceStatus
  organisationId
`;

export const customerFields = `
  id
  companyName
  contactName
  regNum
  vatNum
  headquarters 
  email
  contactNumber
  contactNumberCountryCode
  contactNumberFull
  shortid
  organisationId
`;

export const contactFields = `
id
billingName
securityEmail
techEmail
securityName
managementEmail
managementName
techName
email
siteBillingName
siteSecurityEmail
siteTechEmail
siteSecurityName
siteManagementEmail
siteManagementName
siteTechName
siteBillingEmail
contactNumber
contactNumberCountryCode
contactNumberSite
contactNumberCountryCodeSite
contactNumberFull
contactNumberFullSite
contactNumberTech
contactNumberCountryCodeTech
contactNumberFullTech
contactNumberTechSite
contactNumberCountryCodeTechSite
contactNumberFullTechSite
contactNumberSecurity
contactNumberCountryCodeSecurity
contactNumberFullSecurity
contactNumberSecuritySite
contactNumberCountryCodeSecuritySite
contactNumberFullSecuritySite
contactNumberManagement
contactNumberCountryCodeManagement
contactNumberFullManagement
contactNumberManagementSite
contactNumberCountryCodeManagementSite
contactNumberFullManagementSite
shortid
organisationId
`;


export const staffFields = `
  id
  firstname
  lastname
  gender
  ethnicity
  contactEmail
  contactNumber
  contactNumberCountryCode
  contactNumberFull
  identificationPassport
  identificationIDNumber
  mainSkill
  position
  profilePicUrl
  departments
  shortid
  dateOfBirth
  organisationId
`;

export const stepFields = `
  id
  title
  description
  dueDate
  assignedAsset {
    ${assetFields}
  }
  tags
  completionInfo
  completionStatus
  completionDate
  completionComment
  done
  archived
  isVerified
  assignedAssetId
  assignedTicketId
  assignedOrganisationId
  priority
  shortid
  disabled
  comments
  documents {
    ${documentFields}
  }
`;

export const customerFieldsFull = `
  ${customerFields}
  createdAt
  archived
`;

export const contactFieldsFull = `
  ${contactFields}
  createdAt
  archived
`;

export const staffFieldsFull = `
  ${staffFields}
  profileCompletion
  createdAt
  rating
  archived
`;

export const addressFields = `
  fullAddress
  addressLine1
  postalCode
  country
  state
`;

export const organisationFields = `
  id
  name
  primaryImage
  contactName
  contactNumber
  contactNumberCountryCode
  contactNumberFull
  contactEmail
  receiveFileNotifications
  notificationEmail
  notificationEmailAlt
  regions
  departments
  status
  workStatus
  notes
  shortid
  ownedById
  ownedByEmail
  testAccount
  testAccountExpiry
`;

export const userFields = `
  id
  shortid
  profilePicUrl
  firstname
  lastname
  email
  contactNumber
  contactNumberCountryCode
  contactNumberFull
  role
  profilePicUrl
  isTechnician
`;

export const allSiteFields = `
  ${siteFields}
  address {
    ${addressFields}
  }
  assignedAssets {
    ${assetFields}
  }
  assignedStaff {
    ${staffFields}
  }
  documents {
    ${documentFields}
  }
`;

export const allTicketFields = `
  ${ticketFields}
  address {
    ${addressFields}
  }
  assignedSite {
    ${siteFields}
  }
  assignedTechnicians {
    ${userFields}
  }
  documents {
    ${documentFields}
  }
`;

export const allCustomerFields = `
  ${customerFields}
  address {
    ${addressFields}
  }
`
export const allContactFields = `
  ${customerFields}
  address {
    ${addressFields}
  }
`


export const allStaffFields = `
  ${staffFields}
  address {
    ${addressFields}
  }
  documents {
    ${documentFields}
  }
  assignedAssets {
    ${assetFields}
  }
  assignedSite {
    ${siteFields}
  }
`

export const allAssetFields = `
  ${assetFields}
  documents {
    ${documentFields}
  }
  assignedTo {
    ${staffFields}
  }
  assignedSite {
    ${siteFields}
  }
`;

export const allOrganisationFields = `
  ${organisationFields}
  address {
    ${addressFields}
  }
  childOrganisations {
    ${organisationFields}
  }
  parentOrganisation {
    ${organisationFields}
  }
  documents {
    ${documentFields}
  }
  policies {
    name
  }
  ownedBy {
    firstname
    lastname
    email
  }
`;

export const allTicketInstructionFields = `
  id
  description
  module
  category
  organisation {
    ${organisationFields}
  }
`
export const allUserDataSingle = `
  ${userFields}
  linkedOrganisations {
    ${organisationFields}
  }
  activeOrg {
    ${organisationFields}
  }
  documents {
    ${documentFields}
  }
`;

export const formOverrideFields = `
  id
  type
  field
  module
  description
  options
  label
`;

export const myUserData = `
  ${userFields}
  linkedOrganisations {
    ${organisationFields}
  }
  activeOrg {
    ${organisationFields}
    childOrganisations {
      id
      name
    }
    formOverrides {
      ${formOverrideFields}
    }
    policies {
      name
      description
    }
  }
  documents {
    ${documentFields}
  }
`;
