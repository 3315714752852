import React, { FC, useCallback, useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { FormButton, FormRow } from '../../../components/antd';
import { UserSelector } from '../../../components/user-selector';
import { useFormData, useOverrideColumns } from '../../../hooks';
import { ticketAddTechSchema } from '../schema';
import { Col, message, Popconfirm } from 'antd';
import { cleanError } from '../../../helpers/error-helper';
import styled from 'styled-components';
import { Form as AntForm } from 'formik-antd';
import { useMutation } from '@apollo/client';
import { ADD_TECHNICIAN, REMOVE_TECHNICIAN } from '../gql';
import { Button } from 'antd';
import { Avatar, Page } from '../../../components';
import { getThumbnail } from '@necta-tech/s3';
import { USER_TYPES } from '../../../constants';
import { Sort } from '../../../components/antd/table/helpers/sorters';
import { getDateFull } from '../../../helpers';
import { UserOutlined } from '@ant-design/icons';
import Table from '../../../components/antd/table/components/table';
import { useHistory } from 'react-router';

const AddTechnician = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Add = styled(FormButton)`
  width: 100%;
  margin-top: 30px;
`;

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface TechniciansProps {
  ticket: any;
  technicians: any[];
  [x: string]: any;
}

const GRID_MAX = { xs: 18, sm: 18, md: 18, lg: 18, xl: 20, xxl: 20 };
const GRID_MIN = { xs: 6, sm: 6, md: 6, lg: 6, xl: 4, xxl: 4 };

export const Technicians: FC<TechniciansProps> = ({ ticket, technicians }) => {

  const [handleUpload, { loading }] = useMutation(ADD_TECHNICIAN);

  const [handleRemove, { loading: removing }] = useMutation(REMOVE_TECHNICIAN, {
    onCompleted: (result: any) => {
      const user = result?.removeTechnicianFromTicket;
      if (user) {
        message.success({ content: 'Technician removed from ticket', duration: 2 });
        setTechnicians((t: any[]) => t.filter((_t: any) => _t.id !== user.id))
      }
    }
  });

  const [_technicians, setTechnicians] = useState(technicians);

  const { fields, initialValues, ...formikCTX } = useFormData(ticketAddTechSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      try {
        const { userId } = values;
        message.loading({ content: 'Adding technician to ticket...', duration: 10 });
        const result = await handleUpload({
          variables: {
            id: ticket?.id,
            userId,
          },
        });
        if (result) {
          const _new = result?.data?.addTechnicianToTicket;
          if (_new) {
            message.success({ content: 'Technician assigned to ticket successfully', duration: 2 });
            setTechnicians((t: any[]) => [...t, _new])
            actions.resetForm();
          }
        }
      } catch (e) {
        console.log(e);
        message.error({ content: cleanError(e, 'Unable to assign technician to ticket'), duration: 4 });
      } finally {
        actions.setSubmitting(false);
      }
    }
  });

  const handleRemoveClick = useCallback((userId: string) => (e?: any) => {
    e.stopPropagation();
    message.loading({ content: 'Removing technician from ticket...', duration: 10 });
    handleRemove({ variables: { userId, id: ticket?.id }})
  }, [handleRemove]);

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  const columns = useOverrideColumns([
    {
      title: 'Picture',
      key: 'profilePicUrl',
      render: (r: any) => (
        <Avatar src={getThumbnail(r)} icon={<UserOutlined />} />
      ),
      export: false,
    },
    {
      title: 'First Name',
      key: 'firstname',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Surname',
      key: 'lastname',
      filtering: true,
      sorting: true,
    },
    {
      title: 'Email',
      key: 'email',
      filtering: true,
    },
    {
      title: 'Cell',
      key: 'contactNumberFull',
      filtering: true,
    },
    {
      title: 'Role',
      key: 'role',
      show: false,
      getValues: (r: any) => USER_TYPES.find((u: any) => u.id === r)?.name,
      render: (r: any) => USER_TYPES.find((u: any) => u.id === r)?.name,
      sorting: true,
      filtering: true,
    },
    {
      title: 'Shortid',
      key: 'shortid',
      show: false,
      filtering: true,
    },
    {
      title: 'Signed Up',
      key: 'createdAt',
      show: false,
      sorting: { compare: Sort.DATE },
      render: (r: any) => (r ? getDateFull(r) : ''),
    },
    { title: '', key: 'id', render: (id: string) =>
        <Popconfirm
          title='Are you sure you want to remove this technician from the ticket?'
          onConfirm={handleRemoveClick(id)}
          onCancel={handleCancel}
          okText='Yes'
          cancelText='No'
        >
          <Button onClick={handleCancel} type='link' danger>Remove</Button>
        </Popconfirm>
    },
  ], 'user');

  const history = useHistory();

  return (
    <div style={{ width: '96%', margin: '0 auto' }}>
      { _technicians?.length > 0 && (
        <Table
          data={_technicians}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/management/user-edit/${record.shortid}`)
          })}
          title={'Technicians'}
          exportTitle={'Technicians'}
          pagination={{
            defaultPageSize: 5
          }}
          searchable={false}
          rowClassName={'clickable'}
        />
      )}
      <Formik {...formikCTX} initialValues={initialValues} enableReinitialize validateOnBlur>
        {({ }) => (
          <AddTechnician>
            <Form layout={'vertical'}>
              <FormRow style={{ width: '100%' }} disabled={removing}>
                <UserSelector {...fields.userId} filters={{ activeOrgId: ticket?.organisationId, isTechnician: true }} gridProps={GRID_MAX} />
                <Col {...GRID_MIN}><Add>Add</Add></Col>
              </FormRow>
            </Form>
          </AddTechnician>
        )}
      </Formik>
    </div>
  );
};

Technicians.defaultProps = {};

export default Technicians;
