import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { presentError } from '@necta-tech/alert';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import AutoForm from '../../components/form/auto-form';
import { Page, AuthGuard } from '../../components/Page';
import { useFormData, useImport } from '../../hooks';
import { getActiveOrganisationId, getIsAdmin } from '../../selectors';
import { organisationSchema, Organisation, confirmOrganisationSchema } from '../../schemas';
import { exportDataToExcel } from '../../services';
import gql from 'graphql-tag';
import { message, Modal, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FullButtonLg } from '../../components/antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../components/importer';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../components/antd'
import { useHideImportActions } from '../../hooks'

const { confirm } = Modal;

export const IMPORT_ORGANISATIONS_MUTATION = gql`
  mutation IMPORT_ORGANISATIONS_MUTATION($newOrganisationData: NewOrganisationMultipleInput!) {
    addOrganisationMultiple(newOrganisationData: $newOrganisationData)
  }
`;

const { schema, initialValues } = getFormData(organisationSchema);

export const OrganisationImport: React.FC<any> = () => {
  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
    handleUpdate,
  } = useImport(initialValues, 'organisation', schema);

  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);

  const activeOrgId = useSelector(getActiveOrganisationId);

  const [importOrganisations] = useMutation(IMPORT_ORGANISATIONS_MUTATION);

  const exportOrganisations = useCallback(() => {
    exportDataToExcel(fields, organisations, 'oversite-organisations-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [organisations]);

  const clearData = useCallback(() => {
    handleClear();
    setOrganisations([]);
    setImportSuccess(false);
  }, [handleClear, setOrganisations, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setOrganisations(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setOrganisations, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData()
    } else if (organisations.length > 0) {
      setOrganisations([]);
    } else {
      handleCancel();
    }
  }, [clearData, organisations, setOrganisations, clearData, handleCancel, importSuccess]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmOrganisationSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid organisations to your selected organisation account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, parentOrganisationId } = values;
          const organisationsData: Organisation[] = [...sanitize(organisations)];

          try {
            await importOrganisations({
              variables: { newOrganisationData: { organisations: organisationsData, parentOrganisationId } },
            });

            setImportSuccess(true);

            if (downloadExcel) {
              exportOrganisations();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: 'Something went wrong', duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrgId,
      }),
    [activeOrgId, isAdmin],
  );

  const {
    hideCancel,
    hideSave,
    hideDownload
  } = useHideImportActions(importSuccess, values, organisations)

  return (
    <AuthGuard needsActiveOrganisation>
      <Page
        card
        title='Import Organisations'
        onBack={hasValues ? back : undefined}
        extra={[
          <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
          <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
          <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
        ]}
      >
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || organisations.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />
            <FullButtonLg hidden={!hasValues || organisations.length > 0} onClick={next}>
              Next
            </FullButtonLg>
            {organisations.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {organisations.length} organisation
                  {organisations.length !== 1 && 's'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrgId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import organisations</ImportConfirm>}
                      <ConfirmForm>
                        <OrganisationSelector hidden={!isAdmin} {...cFields.parentOrganisationId} gridProps={fullWidth} />
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}
        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}
      </Page>
      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>
    </AuthGuard>
  );
};

export default OrganisationImport;
