import * as Yup from 'yup';

export const contactSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .label('Billing Contact Email'),
  siteBillingEmail: Yup.string()
    .trim()
    .required()
    .label('Site Billing Contact Email'),
  billingName: Yup.string()
    .required()
    .label('Billing Contact Name'),
  siteBillingName: Yup.string()
    .required()
    .label('Site Billing Contact Name'),
  securityName: Yup.string()
    .required()
    .label('Security Contact Name'),
  siteSecurityName: Yup.string()
    .required()
    .label('Site Security Contact Name'),
  techEmail: Yup.string()
    .required()
    .label('Tech Contact Email'),
  siteTechEmail: Yup.string()
    .required()
    .label('Site Tech Contact Email'),
  managementEmail: Yup.string()
    .required()
    .label('Management Contact Email'),
  siteManagementEmail: Yup.string()
    .required()
    .label('Site Management Contact Email'),
  managementName: Yup.string()
    .required()
    .label('Management Contact Name'),
  siteManagementName: Yup.string()
    .required()
    .label('Site Management Contact Name'),
  techName: Yup.string()
    .required()
    .label('Tech Contact Name'),
  siteTechName: Yup.string()
    .required()
    .label('Site Tech Contact Name'),
  securityEmail: Yup.string()
    .required()
    .label('Security Contact Email'),
  siteSecurityEmail: Yup.string()
    .required()
    .label('Site Security Contact Email'),
  contactNumberCountryCode: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumber: Yup.string()
    .required()
    .label('Billing Contact Number'),
  contactNumberCountryCodeSite: Yup.string()
    .default('+27')
    .label('Number Country Code'),
  contactNumberSite: Yup.string()
    .required()
    .label('Billing Contact Number'),
  contactNumberCountryCodeTech: Yup.string()
    .default('+27')
    .label('Tech Contact Country Code'),
  contactNumberTech: Yup.string()
    .required()
    .label('Tech Contact Number'),
  contactNumberCountryCodeTechSite: Yup.string()
    .default('+27')
    .label('Tech Contact Country Code'),
  contactNumberTechSite: Yup.string()
    .required()
    .label('Tech Contact Number'),
  contactNumberCountryCodeSecurity: Yup.string()
    .default('+27')
    .label('Security Country Code'),
  contactNumberSecurity: Yup.string()
    .required()
    .label('Security Contact Number'),
  contactNumberCountryCodeSecuritySite: Yup.string()
    .default('+27')
    .label('Security Country Code'),
  contactNumberSecuritySite: Yup.string()
    .required()
    .label('Security Contact Number'),
  contactNumberCountryCodeManagement: Yup.string()
    .default('+27')
    .label('Management Contact Country Code'),
  contactNumberManagement: Yup.string()
    .required()
    .label('Management Contact Number'),
  contactNumberCountryCodeManagementSite: Yup.string()
    .default('+27')
    .label('Management Contact Country Code'),
  contactNumberManagementSite: Yup.string()
    .required()
    .label('Management Contact Number'),
 
  organisationId: Yup.string()
    .required()
    .label('Organisation'),
  // role: Yup.string()
  //   .default('Organisation')
  //   .required()
  //   .label('Role'),
  // organisationId: Yup.string()
  //   .nullable()
  //   .label('Organisation'),
  // isTechnician: Yup.boolean()
  //   .nullable()
  //   .label('Extra Roles:'),

  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }).nullable().label('Search for address'),
  
});
