import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useLogout } from '@bit/necta.hooks.use-logout';
import { useLogoutPaneActive } from '@bit/necta.hooks.logout-pane';
import { Button, Space } from 'antd';
import NoAccessSvg from '../../assets/403.png';
import { useSetRedirectOnLogin } from '../../hooks';
import { useHistory } from 'react-router';

interface ContainerProps {
  active: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: visibility 0s, opacity 0.3s ease-in;

  ${props =>
    !props.active
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};

  width: 100vw;
  height: 100vh;

  background-color: rgb(0, 0, 0, 0.65);
  color: white;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 10px;
`;

const IconContainer = styled.div`
  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    filter: brightness(80%);
  }
`;

const Confirm = styled.div`
  color: ${p => p.theme.primaryColor};
  padding: 30px 10px;
  font-size: 16px;
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 768px) {
    max-width: none;
    font-size: 14px;
    padding: 10px;
  }
`;

export const LogoutPane: React.FC = () => {
  const [logoutPaneActive, setLogoutPaneActive] = useLogoutPaneActive();
  const setRedirectOnLogin = useSetRedirectOnLogin();
  const history = useHistory();

  const [handleLogout, { loading }] = useLogout({
    onLogout: () => {
      history.push('');
      setRedirectOnLogin('');
      setLogoutPaneActive(false)
    },
  });

  const logout = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleLogout();
    },
    [handleLogout, history],
  );

  const close = useCallback(
    (e: any) => {
      e.stopPropagation();
      setLogoutPaneActive(false);
    },
    [setLogoutPaneActive],
  );

  const stopEvents = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  return (
    <Container active={logoutPaneActive} onClick={close}>
      <FlexContainer>
        <IconContainer onClick={stopEvents}>
          <img src={NoAccessSvg} alt={'Log out'} />
        </IconContainer>
        <Confirm onClick={stopEvents}>
          Are you sure you want to <b>log out</b>?
        </Confirm>
        <Space>
          <Button onClick={close} type='primary' danger ghost>
            Cancel
          </Button>
          <Button onClick={logout} type='primary' loading={loading}>
            Log out
          </Button>
        </Space>
      </FlexContainer>
    </Container>
  );
};

export default LogoutPane;
