import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import { Page, AuthGuard } from '../../components';
import { getIsAdmin, getActiveOrganisationId } from '../../selectors';
import { useFormData } from '../../hooks';
import { Form as AntForm } from 'formik-antd';
import { Tags, TextArea, Input, ContactNumber, Select, FormRow, FormButton } from '../../components/antd';
import { Card, Collapse as Collapser, Col } from 'antd';
import { OrganisationSelector } from '../../components/organisation-selector';
import Default from '../../assets/asset_picture.png';
import { message } from 'antd';
import { cleanError } from '../../helpers/error-helper';
import { useHistory } from 'react-router';
import { CustomerSelector } from '../../components/customer-selector';

const { Panel } = Collapser;

const siteSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Site Name'),
  description: Yup.string()
    .nullable()
    .label('Description'),
  customerId: Yup.string()
    .required()
    .label('Customer'),
  customerName: Yup.string()
    .trim()
    .nullable()
    .label('Customer Name'),
  primaryContactName: Yup.string()
    .nullable()
    .label('Contact Name'),
  primaryContactNumber: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactNumberCountryCode: Yup.string()
    .nullable()
    .label('Contact Number'),
  primaryContactEmail: Yup.string()
    .trim()
    .nullable()
    .label('Email'),
  primaryImage: Yup.string()
    .nullable()
    .label('Primary Image'),
  tags: Yup.array()
    .nullable()
    .label('Tags'),
  zones: Yup.array()
    .nullable()
    .label("Zones"),
  organisationId: Yup.string()
    .nullable()
    .label('Organisation'),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .label('Province'),
    country: Yup.string()
      .nullable()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .label('Postal Code'),
  }),
});

const Form = styled(AntForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Add = styled(FormButton)`
  margin-top: 30px;
`;

const ADD_SITE = gql`
  mutation ADD_SITE($newSiteData: NewSiteInput!) {
    addSite(newSiteData: $newSiteData) {
      id
      name
    }
  }
`;

const GRID_PROPS = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

export const SiteAdd: React.FC<any> = props => {

  const isAdmin = useSelector(getIsAdmin);
  const activeOrgId = useSelector(getActiveOrganisationId);

  const [handleAdd] = useMutation(ADD_SITE);
  const history = useHistory();

  const { fields, initialValues, ...formikCTX } = useFormData(siteSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      return handleAdd({
        variables: {
          newSiteData: { ...values },
        },
      });
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Site added successfully', duration: 2 });
      history.push(`/site/edit/${result?.data?.addSite?.id}`);
    },
    onError: (e: any) => {
      console.log(e);
      message.error({ content: cleanError(e, 'Unable to add site'), duration: 4 });
    },
    override: 'site'
  });
  

  const [defaultValues] = useState({
    ...initialValues,
    organisationId: isAdmin ? null : activeOrgId,
  });

  const customerSelect = function(fileds: any,data: any){
    fileds('customerName', data.contactName);
    fileds('primaryContactName', data.contactName);
    fileds('primaryContactEmail', data.email);
    fileds('primaryContactNumber', data.contactNumber);
    fileds('primaryContactNumberCountryCode', data.contactNumberCountryCode);
    fileds('organisationId', data.organisationId);
  }

  return (
    <AuthGuard needsActiveOrganisation>
      <Formik {...formikCTX} initialValues={defaultValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <Page card title='Add Site'>
              <Form layout='vertical'>
                <Collapser defaultActiveKey='contact' ghost>
                  <Panel header='Contact Information' key='contact'>
                    <ProfilePictureUploader disabled={isSubmitting} fieldKey='primaryImage' defaultPic={Default} />
                    <FormRow>
                      <Input {...fields.name} />
                      <CustomerSelector {...fields.customerId} onChange={customerSelect.bind(null,setFieldValue)}/>
                      <Input {...fields.customerName} hidden={true}/>
                      <Input {...fields.primaryContactName}/>
                      <ContactNumber
                        numberField={fields.primaryContactNumber}
                        countryCodeField={fields.primaryContactNumberCountryCode}
                      />
                      <Input {...fields.primaryContactEmail}/>
                      <OrganisationSelector {...fields.organisationId} disabled={!isAdmin} />
                      <TextArea gridProps={GRID_PROPS} {...fields.description} rows={7} />
                    </FormRow>
                  </Panel>

                  <Panel header='Location' key='location'>
                    <FormRow>
                      <Input {...fields.address.addressLine1} />
                      <Input {...fields.address.country} />
                      <Input {...fields.address.state} />
                      <Input {...fields.address.postalCode} />
                    </FormRow>
                  </Panel>

                  <Panel header="Zone" key='zone'>
                    <Tags gridProps={GRID_PROPS} {...fields.zones} />
                  </Panel>

                  <Panel header='Categories' key='categories'>
                    <Tags gridProps={GRID_PROPS} {...fields.tags} />
                  </Panel>
                </Collapser>

                <Add loading={isSubmitting} type={'submit'}>
                  Add
                </Add>
              </Form>
            </Page>
          );
        }}
      </Formik>
    </AuthGuard>
  );
};

export default SiteAdd;
