import React, { useMemo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd';
import Select, { SelectProps } from 'antd/lib/select';
import { ColProps } from 'antd/lib/grid';
import { useFormikContext } from 'formik';
import { FieldContainer } from '../antd/FieldContainer';
import { get, isEmpty } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LoadedOrganisation } from './types';
import { formOverrideFields } from '../../graphql/fragments';

const ORGANISATION_SELECT_QUERY = gql`
  query ORGANISATIONS($organisationQuery: OrganisationQuery!) {
    organisations(organisationQuery: $organisationQuery) {
      id
      name
      createdBy
      contactName
      contactEmail
      contactNumber
      contactNumberCountryCode
      primaryImage
      isVerified
      shortid
      canCreateEvents
      receiveFileNotifications
      notificationEmail
      workStatus
      policies {
        name
        description
      }
      address {
        addressLine1
        country
        state
        postalCode
      }
      formOverrides {
        ${formOverrideFields}
      }
      regions
      status
      createdAt
    }
  }
`;

type Organisation = any;

const { Option } = Select;

interface OrganisationSelectorProps {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  gridProps?: ColProps;
  defaultValue?: any;
  name?: string;
  onChange?: (value: any) => void;
  //TODO: define these properly
  valueKey?: keyof Organisation;
  labelKey?: keyof Organisation;
  getOptions?: (orgs: Partial<Organisation>[]) => React.ReactNode[];
  //TODO: update this to a more sophisticated prop
  getFormik?: boolean;
  value?: string;
}

export type OrganisationSelectorComponentProps = OrganisationSelectorProps & (FormikSelectProps | SelectProps<string>);

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const OrganisationSelectorComponent: React.FC<OrganisationSelectorComponentProps> = ({
  value,
  getFormik,
  getOptions,
  labelKey = 'name',
  valueKey = 'id',
  name = '',
  onChange,
  disabled,
  label,
  required,
  hidden,
  gridProps,
  className,
  defaultValue = value,
  ...props
}) => {
  //the loaded list of orgs from the api
  const [organisations, setOrganisations] = useState<any[]>([]);

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>(value || '');

  //if incoming value defined, diffcheck and set as current value (should override other routines)
  useEffect(() => {
    if (value !== undefined && value !== selected) setSelected(value);
  }, [value]);

  const formik = useFormikContext() || {};
  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik]);

  const getOrganisation = useCallback(
    (key: string | number | symbol, value: any) =>
      organisations.find((organisation: Organisation) => get(organisation, key, null) === value),
    [organisations],
  );

  //TODO: review the flow of handleOnChange and defaultValue
  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback(
    (value: any) => {
      if (isFormik) formik.setFieldValue(name, value);
      if (onChange) onChange(getOrganisation(valueKey, value));
      setSelected(value);
    },
    [getOrganisation, valueKey, name, formik, onChange],
  );

  /**
   * Select Options constructor. Builds an array of options children from loaded orgs list.
   */
  const _getOptions = useCallback(
    () =>
      organisations.map((organisation: any) => (
        <Option key={organisation.id} value={organisation[valueKey]} label={organisation[labelKey]}>
          {organisation[labelKey]}
        </Option>
      )),
    [organisations],
  );

  //query here
  const [fetchOrganisationList, { loading, data }] = useLazyQuery(ORGANISATION_SELECT_QUERY, {
    variables: { organisationQuery: {} },
    onCompleted: (result: any) => {
      if (result && result.organisations) setOrganisations(result.organisations);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !data) fetchOrganisationList();
  }, [fetchOrganisationList, loading]);

  useEffect(() => {
    if (organisations.length > 0) {
      setSelected(defaultValue);
    }
  }, [organisations, defaultValue]);

  // TODO: review this flow
  useEffect(() => {
    if (data && data.organisations) setOrganisations(data.organisations);
  }, [data, setOrganisations]);

  //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik)
    return (
      <FieldContainer
        {...gridProps}
        name={name}
        required={required}
        label={label}
        className={`${className} ant-field-container`}
        hidden={hidden}
        editable={false}
      >
        <FormikSelect
          name={name}
          loading={loading}
          disabled={disabled || (loading && organisations.length <= 0)}
          onChange={handleOnChange}
          {...props}
        >
          {/* call option getOptions prop to fetch children. default to map over orgs. */}
          {getOptions ? getOptions(organisations) : _getOptions()}
        </FormikSelect>
      </FieldContainer>
    );

  if (hidden) return null;

  //fallback return using no formik context
  return (
    <Select
      value={loading && organisations.length <= 0 ? 'Loading...' : selected}
      placeholder={'Select organisation'}
      loading={loading}
      disabled={disabled || (loading && organisations.length <= 0)}
      className={`${className} ant-org-selector`}
      onChange={handleOnChange}
      {...props}
    >
      {organisations.length > 0 && (getOptions ? getOptions(organisations) : _getOptions())}
    </Select>
  );
};

OrganisationSelectorComponent.defaultProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'label',
  getFormik: true,
};

export * from './redux';
export * from './types';

export const OrganisationSelector = styled(OrganisationSelectorComponent)`
  width: 100%;
  min-width: 170px;
  .ant-select-selector,
  input {
    min-width: 170px;
  }
`;

export const FilledOrgSelector = styled(OrganisationSelector)`
  .ant-select-selector,
  input {
    border: none !important;
  }
  .ant-select-selector,
  .an {
    background: ${p => p.theme.secondary} !important;
    border-radius: 6px !important;
    -webkit-box-shadow: 0 1px 2px #33333333 !important;
    -moz-box-shadow: 0 1px 2px #33333333 !important;
    box-shadow: 0 1px 2px #33333333 !important;
  }
  .ant-select-clear {
    background: ${p => p.theme.secondary};
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #eee !important;
  }
`;
