import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { addressFields, customerFieldsFull } from '../fragments';
import { useCurrentOrganisation } from '../../components/organisation-selector';

const CUSTOMER_QUERY = gql`
  query($customerQuery: CustomerQuery!) {
    customer(customerQuery: $customerQuery) {
      ${customerFieldsFull}
      address {
        ${addressFields}
      }
    }
  }
`;

export const useFetchCustomerList = (onCompleted?: (result: any) => void) => {
  const [currentOrg] = useCurrentOrganisation();
  const [fetchCustomer, ctx] = useLazyQuery(CUSTOMER_QUERY, {
    variables: { customerQuery: { organisationId: currentOrg?.id } },
    fetchPolicy: 'network-only',
    onError: (error: any) => {
      console.log(error);
    },
    onCompleted,
  });

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  return [fetchCustomer, ctx] as const;
};
