import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { Options } from './index';

export const SEARCH_USERS = gql`
    query SEARCH_USERS ($keyword: String!, $queryOptions: BaseQuery, $filters: JSONObject) {
        userSearch(keyword: $keyword, queryOptions: $queryOptions, filters: $filters) {
            id
            firstname
            lastname
            email
            shortid
            isTechnician
            role
        }
    }
`;

interface SearchUsersOptions extends Options {
  variables?: {
    keyword: string,
    queryVariables?: any,
  }
  filters?: {
    role?: string | false;
    activeOrgId?: string | false;
  }
}
// activeOrgId should be sent along with filters to restrict search to a specific organisation
export const useFetchUserSearch = ({ QUERY = SEARCH_USERS, onCompleted, onError, variables = { keyword: '' }, filters = { } }: SearchUsersOptions) => {

  const [fetchUsers, ctx] = useLazyQuery(QUERY, {
    variables: { filters },
    onError,
    onCompleted: (result: any) => {
      if (result && onCompleted) onCompleted(result.userSearch || []);
    },
  });

  useEffect(() => {
    if (variables && variables.keyword !== '') {
      fetchUsers({ variables })
    }
  }, [fetchUsers, variables]);

  // Clear data when important variables update
  useEffect(() => {
    if (onCompleted) onCompleted([]);
  }, [filters])

  return [fetchUsers, ctx] as const;
};
